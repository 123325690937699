import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CurrentUserContext } from '../../../contexts/current-user';

const AccountMenu = () => {
  const { t } = useTranslation();
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <ul className="py-4 px-4 md:space-y-2 w-72">
      <li className="flex justify-between items-center w-full  text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/account" exact className="w-full h-full px-2 py-2 rounded">
          {t('profile_settings')}
        </NavLink>
      </li>
      <li className="flex justify-between items-center w-full text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/account/organizations" exact className="w-full h-full px-2 py-2 rounded">
          {t('my_organizations')}
        </NavLink>
      </li>
    </ul>
  )
};

export default AccountMenu;