import React, { useContext, useState, useEffect, Fragment } from 'react';

import { Redirect, NavLink, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';

import { CurrentUserContext } from '../../../contexts/current-user';
import { Menu, Transition } from '@headlessui/react';
import useFetch from "../../../hooks/use-fetch";

function AccountNav() {
  const history = useHistory();
  const { t } = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [goToHome, setGoToHome] = useState(false);

  const [{response: responseAppsList}, doFetchAppsList] = useFetch('/applications');
  const [{response, isLoading, error: loginError}, doFetchLogout] = useFetch('/users/sessions', true);


  const clickLogout = () => {
    localStorage.removeItem('weld-jwt');
    doFetchLogout({
      method: 'DELETE',
    });
    setCurrentUserState({});
    history.push("/login");
  };

  useEffect(() => {
    if (!currentUserState.sites) {
      return;
    }
    setLoading(false);

    return () => {
      setGoToHome(false);
    };
  }, [currentUserState]);

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block">
        <div>
          <Menu.Button className="inline-flex justify-center w-full px-2 py-2 lg:mr-2 text-sm font-medium text-gray-700 rounded-md">
            <div className="w-8 h-8">
                {currentUserState?.currentUser?.attributes?.image?.id === null || currentUserState?.currentUser?.attributes?.image?.id === undefined ? <Avatar name={currentUserState.currentUser.attributes.email || currentUserState.currentUser.attributes.name} size="32" className="text-xs rounded-full font-medium"/> : (
                  <img src={`https://api.labsflow.ru/api/v1/uploads/${currentUserState.currentUser.attributes.image.id}`} alt="" className="text-xs rounded-full font-medium"/>
                )}
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items as="ul" className="absolute right-0 mt-2 origin-top-right w-48 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
            <Menu.Item as="li" className="block space-y-2 px-4 py-4 text-right text-gray-500">
              <div>{t('you_enter_as')}:</div>
              <div>{currentUserState.currentUser.attributes.email}</div>
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <li className={`${active && 'bg-blue-500 text-white'} flex justify-end`}>
                  <Link
                    className="px-4 py-2 cursor-pointer w-full"
                    to="/account" 
                  >
                    {t('profile')}
                  </Link>
                </li>

              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <li className={`${active && 'bg-blue-500 text-white'} flex justify-end`}>
                <Link
                  className="px-4 py-2 cursor-pointer w-full"
                  to="/account/organizations"
                >
                  {t('my_organizations')}
                </Link>
                </li>

              )}
            </Menu.Item>
            {currentUserState.currentUser.attributes.admin ? 
            <Menu.Item>
              {({ active }) => (
                <li className={`${active && 'bg-blue-500 text-white'} flex justify-end`}>
                <Link
                  className="px-4 py-2 cursor-pointer w-full"
                  to="/admin"
                >
                  {t('admin')}
                </Link>
                </li>

              )}
            </Menu.Item> : ""
            }
            <Menu.Item>
              {({ active }) => (
                <li onClick={() => clickLogout()} className={`${active && 'bg-blue-500 text-white'} flex justify-end`}>
                  <div className="px-4 py-2 cursor-pointer w-full">
                    {t('logout')}
                  </div>
                </li>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
};

export default AccountNav;
