import React, {useEffect, useState, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from 'react-i18next';
import { useHistory } from "react-router-dom";
import {CurrentUserContext} from "../../../../contexts/current-user";

import Layout from '../../../../components/layout';
import useFetch from '../../../../hooks/use-fetch';
import Spinner from '../../../../components/loader';
import ScreenSettings from './settings';

import ScreenEquipments from './equipment';
import ScreenUsers from './users';
import ScreenShifts from './shifts';

const ScreenEdit = (props) => {
  const [currentUserState, ] = useContext(CurrentUserContext);
  const {t} = useTranslation();
  const [itemId, ] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState('');
  const [divisions, setDivisions] = useState([])

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/directories/screens/${itemId}`);

  const [{response: divisionsListResponse, isLoading: divisionsListIsLoading, error: divisionsListError}, doFetchDivisionsList] = useFetch(`/directories/divisions`);

  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/screens/${itemId}`);

  useEffect(() => {
    setLoading(true);
    doFetchItem();
    doFetchDivisionsList();
  },[])

  useEffect(() => {
    if(!response) {
      return;
    } 
    setCurrentItem(response.data);
  },[response]);

  useEffect(() => {
    if(!divisionsListResponse) {
      return;
    }

    const list = [{label: t('without_division'), value: ""}]
    divisionsListResponse.data.map(it => {
      list.push({label: it.attributes.name, value: it.id})
    })
    setDivisions(list)
  },[divisionsListResponse])



  useEffect(() => {
    if(!response || !divisionsListResponse) {
      return;
    } 
    setLoading(false);
  },[response, divisionsListResponse]);
  
  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    setCurrentItem(itemUpdateResponse.data);
  }, [itemUpdateResponse]);

  const onUpdate = (data) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: data
      }
    });
  };

  const headerData = {
    title: currentItem?.attributes?.name ? `${currentItem?.attributes?.name}` : "..."
  };

  return (
    <Layout 
      headerData={headerData}
      isWide={true}
    >
      {loading ? <Spinner/> : (
        <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('settings')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('equipment')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('participants')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('shifts')}
            </Tab>
          </TabList>
          <TabPanel>
            <ScreenSettings 
              currentItem = {currentItem}
              divisions = {divisions}
              onUpdate = {onUpdate}
            />
          </TabPanel>
          <TabPanel>
            <div className="pt-8 pb-10">
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col mb-4">
                  <div className="inner-title">{t('equipment')}</div>
                  <div className="text-xs text-gray-400 pt-1">{t('screen_equipment_text')}</div>
                </div>
              </div>
              <ScreenEquipments 
                screen = {currentItem}
                setScreen = {setCurrentItem}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="pt-8 pb-10">
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col mb-4">
                  <div className="inner-title">{t('participants')}</div>
                  <div className="text-xs text-gray-400 pt-1">{t('screen_participants_text')}</div>
                </div>
              </div>
              <ScreenUsers 
                screen = {currentItem}
                setScreen = {setCurrentItem}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="pt-8 pb-10">
              <div className="flex flex-row justify-between items-start">
                <div className="flex flex-col mb-4">
                  <div className="inner-title">{t('participants')}</div>
                  <div className="text-xs text-gray-400 pt-1">{t('screen_participants_text')}</div>
                </div>
              </div>
              <ScreenShifts 
                screen = {currentItem}
                setScreen = {setCurrentItem}
              />
            </div>
          </TabPanel>
        </Tabs>
      )}
    </Layout>
  );
};

export default ScreenEdit;
