import React, { useEffect, useState } from 'react';
import Table from '../../../../../components/form/table';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';


const ScreenEquipments = ({ screen, setScreen }) => {
  const {t} = useTranslation();
  const [equipment, setEquipment] = useState([]);
  const [addedEquipment, setAddedEquipment] = useState([]);
  const [equipmentRelationId, setEquipmentRelationId] = useState(null)

  const [{response: equipmentResponse, isLoading: equipmentIsLoading, error: equipmentError}, doFetchEquipment] = useFetch(`/directories/equipment_items`);
  const [{response: equipmentAddResponse, isLoading: equipmentAddIsLoading, error: equipmentAddError}, doFetchEquipmentAdd] = useFetch(`/directories/screens/${screen.id}/equipment_items`);
  const [{response: equipmentRemoveResponse, isLoading: equipmentRemoveIsLoading, error: equipmentRemoveError}, doFetchEquipmentRemove] = useFetch(`/directories/screens/${screen.id}/equipment_items/${equipmentRelationId}`);

  useEffect(() => {
    setAddedEquipment(screen?.attributes?.equipment_item_relations?.map(ur => {return ur?.equipment_item?.id?.toString()}))
  },[screen])

  console.log(screen)

  useEffect(() => {
    doFetchEquipment()
  }, [])

  useEffect(() => {
    if(!equipmentResponse) {
      return;
    } 
    console.log(equipmentResponse)
    setEquipment(
      equipmentResponse.data.map(equipment =>       
        { 
          return {
            id: equipment.id,
            inventory_number: equipment.attributes.inventory_number,
            name: equipment.attributes.name,
            equipment_model_name: equipment.attributes.equipment_model_name,
            equipment_type_name: equipment.attributes.equipment_type_name,
            producer_name: equipment.attributes.producer_name,
            factory_number: equipment.attributes.factory_number,
            division_name: equipment.attributes.division_name,
            with_sensors: equipment.attributes.with_sensors,
          }
        }
      )
    );
  },[equipmentResponse]);

  useEffect(() => {
    if(!equipmentAddResponse) {return}
    setScreen({...screen, attributes: {...screen.attributes, equipment_item_relations: [...screen.attributes.equipment_item_relations, {id: equipmentAddResponse.data.id, equipment_item: equipmentAddResponse.data.attributes.equipment_item}]}})
  }, [equipmentAddResponse])

  useEffect(() => {
    if(!equipmentRemoveResponse) {return}
    let updatedEquipmentRelations = screen.attributes.equipment_item_relations.filter(ur => ur.id != equipmentRelationId)
    setScreen({...screen, attributes: {...screen.attributes, equipment_item_relations: updatedEquipmentRelations}})
    setEquipmentRelationId(null)
  }, [equipmentRemoveResponse])

  function onEquipmentAdd(id) {
    doFetchEquipmentAdd({
      method: 'POST',
      data: {
        data: {equipment_item_id: id}
      }
    });
  }

  function onEquipmentRemove(id) {
    let equpmentRelation = screen?.attributes?.equipment_item_relations.find(ur => ur.equipment_item.id == id)
    if(equpmentRelation.id) {
      setEquipmentRelationId(equpmentRelation.id)
      doFetchEquipmentRemove({
        method: 'DELETE'
      })
    }
  }

  
  const columns = [
    {
      label: t('equipment'),
      prop: "name",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      renderHeader: () => {
        return (
          <div className='flex flex-col'>
            <span>Тип, Производитель, Модель</span>
            <span>Название в системе</span>
          </div>
        )
      },
      render: (row) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {row.equipment_type_name}, {row.producer_name}, {row.equipment_model_name}
            </div>
            <div className="text-gray-500 truncate">
              {row.name}
            </div>
            
          </div>
        )
      }
    },
    {
      label: `${t('inventory_number')} / ${t('factory_number')}`,
      prop: "producer",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      renderHeader: () => {
        return (
          <div className='flex flex-col'>
            <span>{t('inventory_number')}</span>
            <span>{t('factory_number')}</span>
          </div>
        )
      },
      render: (row) => {
        return (
          <div>
            <div className="font-medium text-gray-900 w-full flex flex-col">
              <span className='truncate'>{row.inventory_number}</span>
              <span className='truncate'>{row.factory_number}</span>
            </div>
          </div>
        )
      }
    },
    {
      label: t('division'),
      prop: "division_name",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
    },

    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "invisible w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        if(addedEquipment.includes(row.id)) {
          return (
            <div className="flex space-x-2 justify-end">
              <button 
                onClick={() => onEquipmentRemove(row.id)}
                className="text-red-600 hover:text-red-900 cursor-pointer bg-red-100 hover:bg-red-200 px-2 py-1 flex items-center justify-center rounded ">
                {t('disconnect')}
              </button>
            </div>
          )
        } else {
          return (
            <div className="flex space-x-2 justify-end">
              <button 
                onClick={() => onEquipmentAdd(row.id)}
                className="text-indigo-600 hover:text-indigo-900 cursor-pointer bg-blue-100 hover:bg-blue-200 px-2 py-1 flex items-center justify-center rounded ">
                {t('connect')}
              </button>
            </div>
          )
        }
      }
    },
  ]

  return ( 
    <Table
      border={true}
      columns={columns}
      data={equipment}
    />
  );
};

export default ScreenEquipments;