import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {DateTime} from 'luxon'
import CanvasJSReact from '../../../../assets/canvasjs.react';

import {secondsToTime} from '../../../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;


const ChartDivision = ({eqData, staffData}) => {
  let {t} = useTranslation()
  const [loading, setLoading] = useState(false);
  const [chartRef, setChartRef] = useState([])
  const [chartEqWData, setChartEqWData] = useState([]);
  const [chartEqIData, setChartEqIData] = useState([]);
  const [chartPWData, setChartPWData] = useState([]);
  const [chartPIData, setChartPIData] = useState([]);

  useEffect(() => {
    if (!eqData || !staffData) {
      return;
    }
    let eqWorkedData = [];
    let eqIdleData = [];

    eqData.forEach(it => {
      eqWorkedData.push({
        x: DateTime.fromSeconds(it.at).toJSDate(),
        y: it.worked / (it.worked + it.stopped + it.turned_off) * 100
      })
      eqIdleData.push({
        x: DateTime.fromSeconds(it.at).toJSDate(),
        y: it.stopped / (it.worked + it.stopped + it.turned_off) * 100
      })
    });

    let pWorkedData = [];
    let pIdleData = [];

    staffData.forEach(it => {
      pWorkedData.push({
        x: DateTime.fromSeconds(it.at).toJSDate(),
        y: it.worked / (it.worked + it.stopped + it.turned_off) * 100
      })
      pIdleData.push({
        x: DateTime.fromSeconds(it.at).toJSDate(),
        y: it.stopped / (it.worked + it.stopped + it.turned_off) * 100
      })
    });

    setChartEqWData(eqWorkedData);
    setChartEqIData(eqIdleData)
    setChartPWData(pWorkedData);
    setChartPIData(pIdleData)
    setLoading(false)
  }, [eqData, staffData]);


  const baseOptions = {
    animationEnabled: true,
    height: 200,
    axisX: {
      crosshair: {
        enabled: true,
        labelFontFamily: "helvetica, arial",
        labelFontColor: '#2563eb',
        labelBackgroundColor: '#dbeafe',
        labelFontSize: 10,
      },
      interval: 1,
      intervalType: "day",
      valueFormatString: 'DD',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY:{
      includeZero: true,
      lineColor: 'transparent',
      valueFormatString: "## '%'",
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      labelPlacement:"inside"
    },
    toolTip: {
      fontSize: 12,
      fontFamily: "helvetica, arial",
      fontColor: "rgba(37, 99, 235, 1)",
      shared: true,
      cornerRadius: 2,
      borderThickness: 0,
      backgroundColor: "rgba(219, 234, 254,.9)",
      contentFormatter: function (e) {
				var content = " ";
				for (var i = 0; i < e.entries.length; i++) {
					content += e.entries[i].dataSeries.name === 'PW' ? t('staff_load') : t('equipment_load');
					content += "<br/>";
          content += "<strong>" + e.entries[i].dataPoint.y.toFixed(2) + " %" + "</strong>";
          content += "<br/>";
				}
				return content;
			}
    },
  };

  const options = {
    data: [{
      type: "spline",
      showInLegend: false,
      color: "#5B5F97",
      name: "EW",
      dataPoints: chartEqWData
      },
      // {        
      //   type: "spline",
      //   showInLegend: false,
      //   name: "EI",
      //   color: "#FFC145",
      //   dataPoints: chartEqIData
      // },
      {        
        type: "spline",
        showInLegend: false,
        name: "PW",
        color: "#517962",
        dataPoints: chartPWData
      },
      // {        
      //   type: "spline",
      //   showInLegend: false,
      //   name: "PI",
      //   color: "#FFE0A0",
      //   dataPoints: chartPIData
      // }
    ]
  };

  return (
    <div className="text-sm">
      {loading ?     
      <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div>  : 
      <CanvasJSChart options = {{...options, ...baseOptions}} onRef={ref => setChartRef(ref)}/>
      }
    </div>
  )
};

export default ChartDivision;
