import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelectFilter from './multiselect';
import SelectFilter from './select';
import CheckboxFilter from './checkbox';
import SelectedFilters from './selected';
import FilterPopover from './popover';
import SearchInFilter from './search';
import ShiftsInFilter from './shifts';
import IntensiveCalendar from '../form/calendar/intensiveCalendar';
import SimpleCalendar from '../form/calendar/simpleDatePicker';
import MonthDatePicker from '../form/calendar/monthDatePicker';


const Filters = ({filtersData}) => {
  const {t} = useTranslation();
  const {search, calendar, shifts, button, filtersList, selectedFilters, onChangeFilters} = filtersData;


  function addFilter(value, filter) {
    let newSelection
    switch (filter.type) {
      case 'multiselect':
        onChangeFilters([...selectedFilters, {label: value.label, value: value.value, name: filter.name, type: filter.type, key: filter.key}])
        break;
      case 'select':
        newSelection = selectedFilters.filter(sf => sf.key !== filter.key)
        onChangeFilters([...newSelection, {label: value.label, value: value.value, name: filter.name, type: filter.type, key: filter.key}])
        break;
      case 'checkbox':
        onChangeFilters([...newSelection, {label: value.label, value: value.value, name: filter.name, type: filter.type, key: filter.key}])
        break;
      default:
        break;
    }
   
  }

  const removeFilter = (value, filter) => {
    let newSelection
    let filterSelection
    switch (filter.type) {
      case 'multiselect':
        newSelection = selectedFilters.filter(sf => sf.key !== filter.key)
        filterSelection = selectedFilters.filter(sf => sf.key === filter.key).filter(sf => sf.value !== value.value);
        onChangeFilters([...newSelection, ...filterSelection])
        break;
      case 'select':
        newSelection = selectedFilters.filter(sf => sf.key !== filter.key)
        onChangeFilters(newSelection)
        break;
      case 'checkbox':
        newSelection = selectedFilters.filter(sf => sf.key !== filter.key)
        onChangeFilters(newSelection)
        break;
      default:
        break;
    }
  };

  const clearAllFilters = () => {
    onChangeFilters([])
  } 

  function buildDatePicker() {
    switch (calendar.type) {
      case 'simple': return (
        <div className='ml-4'>
          <SimpleCalendar
            selectedDate={calendar.selectedDate}
            setSelectedDate={calendar.onChange}
          />
        </div>
      )
      case 'month': return <div className='ml-4'><MonthDatePicker onChange={calendar.onChange} selectedDate={calendar.selectedDate} /></div>
      case 'range': return <>Range</>  
      case 'intensive': return (
        <div className='ml-4'>
          <IntensiveCalendar
            currentShift={calendar.currentShift}
            selectedDate={calendar.selectedDate}
            setSelectedDate={calendar.onChange}
            calendarData={calendar.calendarData}
            loadMonthData={calendar.loadMonthData}
          />
        </div>
      )
      default: <></>
    }
  }

  function buildFilters() {
    return filtersList.map(filter => (
      getFilter(filter)
    ))
  }

  function getFilter(filter) {
    switch (filter.type) {
      case 'multiselect': return <MultiSelectFilter key={filter.name} selected={selectedFilters.filter(it => it.name === filter.name)} filter={filter} addFilter={addFilter} removeFilter={removeFilter}/>
      case 'select': return <SelectFilter key={filter.name} selected={selectedFilters.filter(it => it.name === filter.name)[0]} filter={filter} addFilter={addFilter} removeFilter={removeFilter}/>
      case 'checkbox': return <CheckboxFilter key={filter.name} filter={filter} addFilter={addFilter} removeFilter={removeFilter}/>  
      default: <></>
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full">
        <div className='flex w-1/2 items-center gap-4'>
          {!!filtersList && <FilterPopover clearAll={clearAllFilters} selected={selectedFilters}>{buildFilters()}</FilterPopover>}
          {!!button && <div><button className='text-gray-800 bg-white ring-gray-200 ring rounded-sm text-sm px-2 py-1.5 font-normal hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75' onClick={button.onChange}>{button.value}</button></div>}
        </div>
        <div className='flex flex-row w-1/2 justify-end'>
          {!!search && <SearchInFilter search={search}/>}
          {!!shifts && shifts.options.length > 1 && <ShiftsInFilter shifts={shifts}/>}
          {!!calendar && buildDatePicker()}
        </div>
      </div>
      {!!filtersList &&
        <div>
          {selectedFilters.length === 0 ? '' : (
            <SelectedFilters
              tags={selectedFilters}
              removeFilter={removeFilter}
            />
          )}
        </div>
      }
    </>
  );
};

export default Filters;
