import { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../hooks/use-fetch';

import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';

import ErrorsBlock from '../../../../components/errors';


export default function FormUnit({itemId, closeModal, isOpen, reloadTable, tableData}) {
  const {t} = useTranslation();
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState([]);
  const [label, setLabel] = useState([]);

  const isNewItem = itemId === 'new';

  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/directories/units`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/units/${itemId}`);


  useEffect(() => {
    if (itemId === "new") {
      setName('');
      setLabel('');
      return;
    }
    const unit = tableData.find(item => item.id === itemId);
    setName(unit.attributes.name);
    setLabel(unit.attributes.label);
  }, [itemId]);


  useEffect(() => {
    if (!itemSaveResponse) {
      return;
    }
    closeModal();
    reloadTable();
  }, [itemSaveResponse]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    closeModal();
    reloadTable();
  }, [itemUpdateResponse]);


  const onSave = (data) => {
    if (isNewItem) {
      doFetchItemSave({
        method: 'POST',
        data: {
          data: data
        }
      });
    } else {
      doFetchItemUpdate({
        method: 'PUT',
        data: {
          data: data
        }
      });
    }
  };

  useEffect(() => {
    if(itemSaveError) {
      setErrors(itemSaveError.errors)
    }
    if(itemUpdateError) {
      setErrors(itemUpdateError.errors)
    }
  }, [itemSaveError, itemUpdateError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md max-wd-lg p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNewItem ? t('adding_unit') : t('editing_unit')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('unit_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => onSave(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="name" defaultValue={name} label={t('name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="label" defaultValue={label} label={t('unit_label')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>

                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
