import {DateTime} from "luxon";

const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const convertTimezone = (date) => {
  const localTz = new Date(date).getTimezoneOffset()
  const siteTz = DateTime.fromMillis(date).offset
  
  return (siteTz + localTz) * 60000
}

const getMonthName = (month) => {
  const names = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь' ];
  return names[month];
};

const getMonthNameInline = (month) => {
  const names = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря' ];
  return names[month];
};

const secondsToTime = (timeInSeconds) => {
  var sec_num = parseInt(timeInSeconds, 10);
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}

  if(timeInSeconds < 0 || timeInSeconds == undefined || timeInSeconds == null) {
    return "00:00:00"
  } else {
    return hours+':'+minutes+':'+seconds;
  }
  
};

const convertTime = (timestamp) => {
  if(timestamp < 0 || timestamp == undefined || timestamp == null) {
    return "00:00:00"
  } else {
    return DateTime.fromSeconds(timestamp).toFormat('HH:mm:ss')
  }
};

const convertTimeHHMM = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
  if(timeInSeconds < 0) {
    return "00:00"
  } else {
    return `${hours > 0 ? hours < 10 ? '0' + hours + ':' : hours + ':' : '00:'}${minutes > 0 ? minutes < 10 ? '0' + minutes : minutes : '00'}`;
  }
};

const covertTimeToSeconds = (time) => {
  if(!time) {
    return 0;
  }
  const a = time.split(':');
  const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
  return seconds
}

const convertDate = (timeInSeconds) => {
  const t =  timeInSeconds * 1000;
  return `${new Date(t).getDate()}.${new Date(t).getMonth() + 1}.${new Date(t).getFullYear()}`
};

const convertToTimestamp = (datetime) => {
  return new Date(new Date(datetime).getFullYear(), new Date(datetime).getMonth(), new Date(datetime).getDate()).getTime() / 1000;
}
const convertDateTimeToTimestamp = (datetime) => {
  return new Date(datetime).getTime();
}

const convertFromTimestamp = (timeInSeconds) => {
  return new Date(timeInSeconds * 1000)
}

const getPerfectDate = (value) => {
  let date = Number;

  if(typeof value == 'number') {
    date = value - convertTimezone(value);
  } else {
    date = new Date(value).getTime() - convertTimezone(new Date(value).getTime())
  }

  return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}.${new Date(date).getMonth() < 9 ? '0' + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1}.${new Date(date).getFullYear()}`
};

const getJustDate = (ts) => {
  return DateTime.fromMillis(ts).toFormat('dd')
};

const getPerfectDateTime = (date) => {
  return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}.${new Date(date).getMonth() < 9 ? '0' + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1}.${new Date(date).getFullYear()} ${new Date(date).getHours() < 10 ? '0' + (new Date(date).getHours()) : new Date(date).getHours()}:${new Date(date).getMinutes() < 10 ? '0' + (new Date(date).getMinutes()) : new Date(date).getMinutes()}`
};

const convertToDateWithHoursFromTimestamp = (date) => {
  const hours = new Date(date).getHours()
  return new Date(new Date(date).setHours(hours, 0, 0, 0))
};

const getPerfectDateTimeWithHours = (date) => {
  return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}.${new Date(date).getMonth() < 9 ? '0' + (new Date(date).getMonth() + 1) : new Date(date).getMonth() + 1}.${new Date(date).getFullYear()} ${new Date(date).getHours() < 10 ? '0' + (new Date(date).getHours()) : new Date(date).getHours()}:00:00`
}

const getPerfectTime = (date) => {
  if (date === null) {
    return `Нет данных`
  }
  return `${new Date(date).getHours() < 10 ? '0' + (new Date(date).getHours()) : new Date(date).getHours()}:${new Date(date).getMinutes() < 10 ? '0' + (new Date(date).getMinutes()) : new Date(date).getMinutes()}:${new Date(date).getSeconds() < 10 ? '0' + (new Date(date).getSeconds()) : new Date(date).getSeconds()}`
};

const getPerfectHours = (date) => {
  if (date === null) {
    return `Нет данных`
  }
  return `${new Date(date).getHours() < 10 ? '0' + (new Date(date).getHours()) : new Date(date).getHours()}:${new Date(date).getMinutes() < 10 ? '0' + (new Date(date).getMinutes()) : new Date(date).getMinutes()}`
};

const getStatusString = (id) => {
  switch (id) {
    case 'works':
      return 'Исправен';
    case 'broken':
      return 'Не исправен';
    case 'decommissioned':
      return 'Списан';
    default:
      return ''
  }
};

const uniqBy = (a, key) => {
  const seen = {};
  return a.filter(function(item) {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  })
};

const sortSelectList = (list) => {
  return (
    list.sort(function(a,b) {
      const x = a.label;
      const y = b.label;
      return x < y ? -1 : x > y ? 1 : 0;
    })
  )
}

const sortDataList = (data) => {
  return (
    data.sort(function(a,b) {
      const x = a.attributes.name;
      const y = b.attributes.name;
      return x < y ? -1 : x > y ? 1 : 0;
    })
  )
}

const objectMap = (obj, fn) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )

export {
  convertTimezone,
  secondsToTime,
  daysInMonth,
  getMonthName,
  getMonthNameInline,
  convertTime,
  convertTimeHHMM,
  covertTimeToSeconds,
  convertDate,
  getJustDate,
  convertToTimestamp,
  convertDateTimeToTimestamp,
  convertFromTimestamp,
  getPerfectDate,
  getPerfectDateTime,
  getPerfectTime,
  getPerfectHours,
  getStatusString,
  getPerfectDateTimeWithHours,
  convertToDateWithHoursFromTimestamp,
  uniqBy,
  sortSelectList,
  sortDataList,
  objectMap
}
