import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilAltIcon, SortDescendingIcon, SortAscendingIcon } from '@heroicons/react/outline';
import { IoIosWarning } from "react-icons/io";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

import Spinner from "../../../components/loader";
import { getPerfectDate } from "../../../utils";
import Table from '../../../components/form/table';


const MaintenancesTable = ({ onItemEdit, tableData, sorting, sortKey, direction }) => {
  const {t} = useTranslation();


  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        description: tableItem.attributes.description,
        equipment_item_id: tableItem.attributes.equipment_item_id,
        equipment_item_name: tableItem.attributes.equipment_item_name,
        equipment_model_name: tableItem.attributes.equipment_model_name,
        producer_name: tableItem.attributes.producer_name,
        maintenance_time: tableItem.attributes.maintenance_time,
        maintenance_type: tableItem.attributes.maintenance_type,
        maintenance_description: tableItem.attributes.maintenance_description,
        next_maintenance_after: tableItem.attributes.next_maintenance_after,
        previous_maintenance_at: tableItem.attributes.previous_maintenance_at,
        next_maintenance_at: tableItem.attributes.next_maintenance_at,
        expired: tableItem.attributes.expired
      }
    )
  });

  const columns = [
    {
      label: t('equipment'),
      prop: "name",
      labelClassName: "w-full lg:w-7/12",
      className: "w-full lg:w-7/12 flex-shrink-0",
      renderHeader: () => {
        return (
          <div className='flex flex-col'>
            <span>{t('maintenance_name')}</span>
            <span>{t('equipment')}</span>
          </div>
        )
      },
      render: (row) => {
        return (
          <>
            <div className="text-gray-500 flex flex-row items-center space-x-2">
              {row.expired ? <IoIosWarning className='text-red-500'/> : ""} 
              <div className={`inline-flex px-2 py-0.5 rounded-sm text-xs text-white ${row.maintenance_type === 'service' ? 'bg-green-800' : 'bg-yellow-800'}`}>
                {t(`maintenance_${row.maintenance_type}`)}
              </div>
            </div>
            <div className="text-gray-900 flex flex-row items-center space-x-2">
              <span className='break-normal'>{row.description}</span>
            </div>
            <div className="text-gray-500 flex flex-row items-center space-x-2">
              <span className='break-normal'>{row.maintenance_description}</span>
            </div>
            <div className="font-medium text-gray-900 truncate">
              {row.equipment_model_name}, {row.producer_name}, {row.equipment_item_name}
            </div>
          </>
        )
      }
    },
    {
      label: t('maintenance_time'),
      prop: "maintenance",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      renderHeader: () => {
        return (
          <div className='flex space-x-2 hover:text-blue-600 cursor-pointer items-start'>
            {sortKey === 'working_from_previous_maintenance' ? 
              direction === 'asc' ?
                <button type='button' className='flex' onClick={() => sorting('working_from_previous_maintenance')}>
                  <FaSortUp size={16}/>
                  <div className='flex flex-col items-start font-medium uppercase pl-2'>
                    <span>{t('maintenance_fact_plan')}</span>
                  </div>
                </button>
                :
                <button type='button' className='flex' onClick={() => sorting('working_from_previous_maintenance')}>
                  <FaSortDown size={16}/>
                  <div className='flex flex-col items-start font-medium uppercase pl-2'>
                    <span>{t('maintenance_fact_plan')}</span>
                  </div>
                </button>
              :
              <button type='button' className='flex' onClick={() => sorting('working_from_previous_maintenance')}>
                <FaSort size={16}/>
                <div className='flex flex-col items-start font-medium uppercase pl-2'>
                  <span>{t('maintenance_fact_plan')}</span>
                </div>
              </button>
            }

          </div>
        )
      },
      render: (row) => {
        return (
          <div className="font-medium truncate lg:pl-6 flex w-full">
            <span className='text-gray-900'>{row.next_maintenance_after}</span> / <span className='text-gray-500'>{row.maintenance_time}</span>
          </div>
        )
      }
    },
    {
      label: t('next_maintenance_at'),
      prop: "maintenance",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      renderHeader: () => {
        return (
          <div className='flex items-center space-x-2 hover:text-blue-600 cursor-pointer'>
            {sortKey === 'next_maintenance_at' ? 
              direction === 'asc' ?
                <button type='button' className='flex' onClick={() => sorting('next_maintenance_at')}>
                  <FaSortUp size={16}/>
                  <div className='flex flex-col items-start font-medium uppercase pl-2'>
                    <span>{t('next_maintenance_at')}</span>
                  </div>
                </button>
                :
                <button type='button' className='flex' onClick={() => sorting('next_maintenance_at')}>
                  <FaSortDown size={16}/>
                  <div className='flex flex-col items-start font-medium uppercase pl-2'>
                    <span>{t('next_maintenance_at')}</span>
                  </div>
                </button>
              :
              <button type='button' className='flex' onClick={() => sorting('next_maintenance_at')}>
                <FaSort size={16}/>
                <div className='flex flex-col items-start font-medium uppercase pl-2'>
                  <span>{t('next_maintenance_at')}</span>
                </div>

              </button>
            }
          </div>
        )
      },
      render: (row) => {
        return (
          <div className='lg:pl-6'>
            <div className="font-medium text-gray-900 truncate">
              {getPerfectDate(row.next_maintenance_at)}
            </div>
            <div className="text-gray-500 text-xs truncate">
              <span>{t('previous')}: </span>
              {getPerfectDate(row.previous_maintenance_at)}
            </div>
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "invisible w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemEdit(row.equipment_item_id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    },
  ]


  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  )
};

export default MaintenancesTable;
