import React, { useState, useEffect, useContext, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useFetch from '../../../hooks/use-fetch';
import Select from '@atlaskit/select';
import {DateTime} from "luxon";
import Checkbox from '@atlaskit/checkbox';


import { convertTimezone, covertTimeToSeconds } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';

import {CurrentUserContext} from "../../../contexts/current-user";

import DateRangePicker from '../../../components/form/calendar/dateRangePicker';
import PersonWorkCalendar from './calendar';
import Interaction from './interaction';


const PersonReportPage = (props) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [itemId, ] = useState(props.match.params.id);
  const [currentItem, setCurrentItem] = useState();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(DateTime.local().startOf('day'));
  const [endDate, setEndDate] = useState(DateTime.local().endOf('day'));
  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" });
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400});
  const [showByDays, setShowByDays] = useState(false)

  const dateRangePickerRef = useRef(null);

  function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
        props.location.search.substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }

  const [{response, isLoading, error}, doFetchPerson] = useFetch(`/directories/personnels/${itemId}`);

  useEffect(() => {
    setLoading(true)

    const innerDate = DateTime.fromMillis(Number.parseInt(findGetParameter('date')))

    if(innerDate && !innerDate.invalid) {
      setStartDate(innerDate.startOf('day'))
      setEndDate(innerDate.endOf('day'))
    }

    doFetchPerson();

    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]
    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat("HH:mm:ss"), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat("HH:mm:ss")})
      })
    }
    setShiftList(shifts);
  },[])


  useEffect(() => {
    if(!response) {
      return
    }
    setCurrentItem(response.data)

    setLoading(false)
  },[response]);


  const onDateChoice = (dateRange) => {
    if (dateRange === null) {
      return;
    } else if (dateRange[1] === null) {
      const start = DateTime.local(dateRange[0].getFullYear(), dateRange[0].getMonth() + 1, dateRange[0].getDate())
      setStartDate(start);
      setEndDate(null);
    } else {
      const start = DateTime.local(dateRange[0].getFullYear(), dateRange[0].getMonth() + 1, dateRange[0].getDate())
      const end = DateTime.local(dateRange[1].getFullYear(), dateRange[1].getMonth() + 1, dateRange[1].getDate())
      
      setStartDate(start);
      setEndDate(end.endOf('day'));
    }
  };

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  const headerData = {
    title: currentItem === '' ? '' : `${t('report_by')} ${currentItem?.attributes.last_name} ${currentItem?.attributes.first_name} ${currentItem?.attributes.middle_name}`,
    isWide: true
  };

  return loading ? <Spinner /> : (
    <Layout
      headerData={headerData}
    >
      <div className="flex rounded mb-6">
        <div className="flex items-center text-sm space-x-1">
          {currentItem?.attributes.division_name ? <span className='font-medium text-gray-700'>{currentItem?.attributes.division_name} / </span> : <></> }
          {currentItem?.attributes.post_name ? <span className='text-gray-500'>{currentItem?.attributes.post_name}</span> : <></> }
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 mt-4">
        <div>
          <Select 
            className="text-sm justify-start"
            options={shiftList} 
            value={currentShift.value === null ? shiftList.find(it => it.value === null) : shiftList.find(it => it.value === currentShift.value)}
            onChange={value => handleChangeShift(value)}
          />
        </div>
        <div className='flex items-center text-sm'>
          <Checkbox  
            label={t('show_by_days')}
            isChecked={showByDays}
            onChange={() => setShowByDays(!showByDays)}
          />
        </div>
        <div className="lg:col-end-5 col-span-1">
          <DateRangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} onChange={onDateChoice} />
        </div>
      </div>
      <PersonWorkCalendar person={currentItem} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} convertedShift={convertedShift} />
      <Interaction person={currentItem} endDate={endDate} startDate={startDate} convertedShift={convertedShift} showByDays={showByDays}></Interaction>
      
    </Layout>
  );
};

export default PersonReportPage;