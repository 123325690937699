import React, {useState, useEffect, useRef, useContext} from 'react';
import { useLocation } from "react-router-dom";
import HeatMap from '@uiw/react-heat-map';
import Tooltip from '@uiw/react-tooltip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import {DateTime, Info} from 'luxon';

import useFetch from '../../../hooks/use-fetch';
import Spinner from '../../../components/loader';
import Layout from '../../../components/layout';

import DeviceData from './data';
// import DeviceSettings from './settings';
// import DeviceConstants from './constants';
import { getPerfectDate } from '../../../utils';

// const today = DateTime.fromJSDate(new Date());

const today = DateTime.local()


const ElectricityItemPage = (props) => {
  const location = useLocation();
  const {t} = useTranslation();
  const [itemId, ] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState('');
  const [deviceDataKeys, setDeviceDataKeys] = useState([]);
  const [rpSensor, setRpSensor] = useState({});
  const [phOne, setPhOne] = useState({});
  const [phTwo, setPhTwo] = useState({});
  const [phThree, setPhThree] = useState({});

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/devices/devices/${itemId}`);
  

  useEffect(() => {
    doFetchItem();
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }
    getSensorsDataKeys(response.data);
    setCurrentItem(response.data);
    setLoading(false);
  }, [response]);

  const headerData = {
    title: 'Электропотребление',
    isWide: false
  };

  function getSensorsDataKeys(item) {
    const data_keys = []
    item.attributes.slots.map(slot => {
      slot.sensors.map(sensor => {
        switch (sensor.sensor_type.data_key_name) {
          case "rp":
            setRpSensor(sensor)
            break;
          case "p1_rp":
            setPhOne(sensor)
            break;
          case "p2_rp":
            setPhTwo(sensor)
            break;
          case "p3_rp":
            setPhThree(sensor)
            break; 
          default:
            break;
        }
        data_keys.push(sensor.sensor_type.data_key_name)
      })
    })
    setDeviceDataKeys(data_keys)
  }

  return (
    <Layout
      isWide={false}
      headerData={headerData}
    >
      {!loading && deviceDataKeys.length > 0 && deviceDataKeys.includes('rp') ? 
        <>
          <div className='text-sm text-gray-600'>Для устройства: {currentItem.attributes.serial_number}, оборудованиe: {rpSensor ? rpSensor.equipment_item_name : "Не привязано"} </div>
          <Tabs
            className="text-gray-800 font-medium text-sm mt-4"
            id="default"
          >
            <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
              <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
                {t('data')}
              </Tab>
              {/* <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
                {t('constants')}
              </Tab>
              <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
                {t('settings')}
              </Tab> */}
            </TabList>
            <TabPanel>
              <DeviceData 
                currentItem={currentItem}
                rpSensor={rpSensor}
                phOne={phOne}
                phTwo={phTwo}
                phThree={phOne}
              />
            </TabPanel>
            {/* <TabPanel>
              <DeviceConstants 
                sensorTypes={sensorTypes}
                tableData={currentItem.attributes.slots}
                reloadTable={reloadTable}
              />
            </TabPanel>
            <TabPanel>
              <DeviceSettings 
                currentItem={currentItem}
                onUpdate={onUpdate}
              />
            </TabPanel> */}
          </Tabs>
        </>
       : <Spinner/>}
    </Layout>
  )
};

export default ElectricityItemPage;
