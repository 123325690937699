import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import МaintenancesTable from './table';
import ExplForm from './form';
import Spinner from '../../../../../components/loader';
import { maintenanceType } from '../../../../../enums';


const EquipmentМaintenances = ({ currentItem, reloadCurrentItem }) => {
  const {t} = useTranslation();
  const [maintenancesData, setМaintenancesData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [maintenance, setМaintenance] = useState('');
  const [maintenanceId, setМaintenanceId] = useState(null);
  const maintenanceTypes = maintenanceType.map(type => ({label: t(`maintenance_${type}`), value: type}))

  const [errors, setErrors] = useState([]);

  const [{response, isLoading, error}, doFetchМaintenances] = useFetch(`/directories/equipment_items/${currentItem.id}/maintenances`);
  const [{response: saveResponse, isLoading: saveIsLoading, error: saveError}, doFetchItemSave] = useFetch(`/directories/equipment_items/${currentItem.id}/maintenances`);
  const [{response: updateResponse, isLoading: updateIsLoading, error: updateError}, doFetchItemUpdate] = useFetch(`/directories/equipment_items/${currentItem.id}/maintenances/${maintenanceId}`);
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/equipment_items/${currentItem.id}/maintenances/${maintenanceId}`);
  
  useEffect(() => {
    doFetchМaintenances()
  }, []);


  useEffect(() => {
    if(!response) {
      return;
    }
    setМaintenancesData(response.data)
  },[response])

  useEffect(() => {
    if(!saveError) {
      return;
    }
    setErrors(saveError.errors)
  }, [saveError])

  useEffect(() => {
    if(!updateError) {
      return;
    }
    setErrors(updateError.errors)
  }, [updateError])

  useEffect(() => {
    closeModal();
    reloadData();
  },[saveResponse])

  useEffect(() => {
    closeModal();
    reloadData();
  },[updateResponse])

  function onSaveItem(data) {
    if(!maintenanceId) {
      doFetchItemSave({
        method: 'POST',
        data: {
          data: {
            previous_maintenance_at: data.at || new Date(),
            description: data.description || null,
            maintenance_time: data.time || null,
            maintenance_type: data.type.value || 'service',
            maintenance_description: data.maintenance_description || '',
          }
        }
      });
    } else {
      doFetchItemUpdate({
        method: 'PUT',
        data: {
          data: {
            previous_maintenance_at: data.at || new Date(),
            description: data.description || null,
            maintenance_time: data.time || null,
            maintenance_type: data.type.value || 'service',
            maintenance_description: data.maintenance_description || '',
          }
        }
      });
    }
  } 

  function closeModal() {
    setМaintenanceId(null);
    setМaintenance(null);
    setErrors([]);
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    setМaintenanceId(null)
    setМaintenance(null)
    openModal();
  };

  function onEdit(id) {
    setМaintenanceId(id)
    setМaintenance(maintenancesData.find(it => it.id == id))
    openModal();
  };

  const handleDelete = (id) => {
    setМaintenanceId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    if(deleteIsLoading) {
      return;
    }
    setМaintenanceId(null);
    setМaintenance(null)
    reloadData();
  }, [deleteIsLoading]);

  const reloadData = () => {
    reloadCurrentItem();
    doFetchМaintenances();
  };

  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('equipment_maintenances')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('equipment_maintenances_text')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('add_maintenances')}
        </button>
      </div>
      {isLoading ? <Spinner /> :
      maintenancesData.length ? 
      <МaintenancesTable
        tableData={maintenancesData}
        maintenanceTypes={maintenanceTypes}
        onItemEdit={onEdit}
        onItemDelete={handleDelete}
      /> : <div className='flex items-center justify-center my-10'>{t('no_data')}</div>}
      {isOpen && <ExplForm 
        isLoading={saveIsLoading || updateIsLoading}
        maintenanceTypes={maintenanceTypes}
        maintenance={maintenance}
        onSave={onSaveItem}
        closeModal={closeModal}
        errors={errors}
        isOpen={isOpen}
        reloadData={reloadData}
      /> }
    </div>
  );
};

export default EquipmentМaintenances;
