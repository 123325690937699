import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PencilAltIcon } from '@heroicons/react/outline'; 
import Spinner from "../../../../components/loader";
import Table from '../../../../components/form/table';
import EditConstnats from './edit';

const DeviceConstants = ({tableData, sensorTypes, reloadTable}) => {
  const {t} = useTranslation();
  let [isOpen, setIsOpen] = useState(false);
  const [sensor, setSensor] = useState({})

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const onItemEdit = (id, factor, idle_level, power_factor) => {
    setSensor({id: id, idle_level: idle_level, factor: factor, power_factor: power_factor});
    openModal();
  }

  const items = sensorTypes.map(sensor => {
    return (
      tableData.map((tableItem, index) => {
        const currentSensor = tableItem.sensors.find(it => it.sensor_type.data_key_name === sensor.data_key_name);
        return (
          {
            id: currentSensor.id,
            number: tableItem.number,
            idle_level: currentSensor.idle_level,
            factor: currentSensor.factor,
            power_factor: currentSensor.power_factor,
            sensor_type_name: t(currentSensor.sensor_type.data_key_name),
            last_activity: ""
          }
        )
      })
    )
  }).flat();
  

  const columns = [
    {
      label: t('data_type'),
      prop: "sensor_type_name",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
    },
    {
      label: t('slot'),
      prop: "number",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0"
    },
    {
      label: t('constant'),
      prop: "factor",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
    },
    {
      label: t('idle_level'),
      prop: "idle_level",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
    },
    {
      label: t('last_activity'),
      prop: "last_activity",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-3/12 invisible",
      className: "w-full lg:w-3/12 flex-shrink-0 ",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemEdit(row.id, row.factor, row.idle_level, row.power_factor)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <div className="mt-8">
      <div className="flex flex-col mb-4">
        <div className="inner-title">{t('sensors')}</div>
        <div className="text-xs text-gray-400 pt-1">{t('sensors_on_device')}</div>
      </div>
      <Table
        border={true}
        columns={columns}
        data={items}
      />
      <EditConstnats closeModal={closeModal} sensor={sensor} isOpen={isOpen} reloadTable={reloadTable}/>
    </div>
  );
};

export default DeviceConstants;
