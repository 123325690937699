import React, {useState, useEffect, useContext} from 'react';
import useFetch from "../../../hooks/use-fetch";
import {CurrentUserContext} from '../../../contexts/current-user';

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import AppsTable from './table';

const SettingsApps = () => {
  const [, setCurrentUserState] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState({});
  const [appId, setAppId] = useState('');

  const [{response, isLoading, error}, doFetch] = useFetch('/applications');
  const [{response: updateResponse, isLoading: updateIsLoading, error: updateError}, doFetchItemUpdate] = useFetch(`/applications/${appId}`);

  useEffect(() => {
    doFetch();
  },[]);

  const updateAppsContext = (data) =>{
    const currentApplications = {};
    data.forEach(application => {
      currentApplications[application.id] = application.attributes.enabled;
    });
    setCurrentUserState(state => ({
      ...state,
      applications: currentApplications
    }));
  }

  useEffect(() => {
    if(!response) {
      return
    }
    setTableData(response.data);
    setLoading(false);
    updateAppsContext(response.data);
  }, [response])

  useEffect(() => {
    if(updateIsLoading) {
      return;
    }
    setAppId('');
    reloadTable();
  }, [updateIsLoading]);

  const onItemEdit = (id, state) => {
    setLoading(true);
    setAppId(id);
    onItemSave({
      data: {
        enabled: state
      }
    })
  } 

  const onItemSave = (item) => {
    setLoading(true);
    doFetchItemUpdate({
      method: 'PUT',
      data: item,
    });
  };

  const reloadTable = () => {
    doFetch();
  }

  const headerData = {
    title: 'Приложения'
  };

  return (
    <Layout
      headerData={headerData}
    >
      <div className="content">
        {loading ? <Spinner/> : (
          <div className="flex flex-col mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
                  <AppsTable 
                    isLoading={isLoading}
                    tableData={tableData}
                    error={error}
                    onItemEdit={onItemEdit}
                  />
                </div>
              </div>
            </div>
          </div>
        )}          
      </div>
    </Layout>
  );
};

export default SettingsApps;
