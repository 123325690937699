import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@atlaskit/select';
import useFetch from '../../../../../hooks/use-fetch';
import { moveStatuses } from '../../../../../enums';
import TrackingForm from './form';
import _ from 'lodash';
import EquipmentOnMap from '../map';
import Spinner from '../../../../../components/loader';
import { getPerfectDateTime } from '../../../../../utils';
import { watchDogSettings } from '../../../../../enums';


const EquipmentTracking = ({ currentItem, onChangeItem }) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false);
  const [lastPosition, setLastPosition] = useState(null);
  const [history, setHistory] = useState([])
  const [type, setType] = useState('current')
  const [watchdog, setWatchDog] = useState(currentItem.attributes.watchdog_interval ? currentItem.attributes.watchdog_interval : "never")
  const statuses = moveStatuses.map(status => ({label: t(`move_${status}`), value: status}))

  const [{response, isLoading, error}, doFetchChanges] = useFetch(`/directories/equipment_items/${currentItem.id}/changes`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/equipment_items/${currentItem.id}`);

  useEffect(() => {
    doFetchChanges()
  }, []);

  useEffect(() => {
    if(!response) {
      return;
    }

    let d = response.data.filter(i => i.attributes.latitude && i.attributes.longitude)
    let l = _.orderBy(d, ['id'], ['desc']);

    let hlist = []

    l.slice(0, 10).map(i => {
      hlist.push({date: i.attributes.at, status: i.attributes.status, coordinates: [Number.parseFloat(i.attributes.latitude), Number.parseFloat(i.attributes.longitude)]})
    })

    setLastPosition(hlist[0])
    setHistory(hlist)
    setLoading(false)
  },[response])

  useEffect(() => {
    if(!itemUpdateResponse) {return}
    onChangeItem(itemUpdateResponse.data)
    setWatchDog(itemUpdateResponse.data.attributes.watchdog_interval)
  }, [itemUpdateResponse])

  function closeModal() {
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  function handleWatchInterval(interval) {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: {watchdog_interval: interval.value}
      }
    });
  }


  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('tracking')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('tracking_text')}</div>
        </div> 
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={openModal}
        >
          {t('connect_tracking_qrcode')}
        </button>
      </div>
      {currentItem.attributes.qrcode && <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 p-8 bg-gray-50 mb-4'>
        <div className='lg:col-span-3 '>{t('setup_watchdog_text')}</div>
        <div><AddWatchInterval value={watchdog} onChange={handleWatchInterval}/></div>
      </div>}
      <div className='grid grid-cols-1 lg:grid-cols-2'>
        <div>
          <div className="flex flex-col mt-2">
            <label className='text-xs font-semibold text-gray-500 mb-2'>{t('tracking_responsible')}</label>
            <div className='flex h-10 justify-between items-center'>
              <div>{currentItem.attributes.responsible_name ? currentItem.attributes.responsible_name : t('no_responsible')}</div>  
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <label className='text-xs font-semibold text-gray-500 mb-2'>{t('tracking_performer')}</label>
            <div className='flex h-10 justify-between items-center'>
              <div>{currentItem.attributes.performer_name ? currentItem.attributes.performer_name : t('no_performer')}</div>  
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <label className='text-xs font-semibold text-gray-500 mb-2'>{t('tracking_qrcode')}</label>
            <div className='flex h-10 justify-between items-center'>
              <div>{currentItem.attributes.qrcode ? currentItem.attributes.qrcode : t('no_qrcode')}</div>  
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <label className='text-xs font-semibold text-gray-500 mb-2'>{t('tracking_status')}</label>
            <div className='flex h-10 justify-between items-center'>
              <div>{currentItem.attributes.status ? statuses.find(it => it.value == currentItem.attributes.move_status).label : t('no_status')}</div>  
            </div>
          </div>
          <div className="flex flex-col mt-2">
            <label className='text-xs font-semibold text-gray-500 mb-2'>{t('tracking_last_change_at')}</label>
            <div className='flex h-10 justify-between items-center'>
              <div>{getPerfectDateTime(currentItem.attributes.move_status_changed_at)}</div>  
            </div>
          </div>
          {/* <div className="flex flex-col mt-2">
            <label className='text-xs font-semibold text-gray-500 mb-2'>{t('tracking_interval')}</label>
            <div className='flex h-10 justify-between items-center'>
              <div>{currentItem.attributes.status ? statuses.find(it => it.value == currentItem.attributes.status).label : t('no_status')}</div>  
            </div>
          </div> */}
        </div>
        {loading  ? <Spinner/> : 
          lastPosition &&
          <div className='flex flex-col'>
            <div className='flex flex-row  space-x-2 items-center'>
              <div onClick={() => setType('current')} className={`px-2 py-1 rounded-sm cursor-pointer text-xs ${type === 'current' ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-600"}`}>Текущее местоположение</div>
              <div onClick={() => setType('history')} className={`px-2 py-1 rounded-sm cursor-pointer text-xs ${type === 'history' ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-600"}`}>Последние 10</div>
            </div>
            <EquipmentOnMap history={history} type={type} position={lastPosition}/>
          </div>
        }
      </div>
      <TrackingForm 
        currentItem={currentItem}
        onChangeItem={onChangeItem}
        closeModal={closeModal}
        isOpen={isOpen}
      />
    </div>
  );
};

export default EquipmentTracking;


const AddWatchInterval = ({value, onChange}) => {
  const {t} = useTranslation();
  const options = watchDogSettings.map(w => ({label: t(`watchdog_${w}`), value: w}))

  return (
    <div className='relative w-full'>
      <Select onChange={onChange} value={options.find(o => o.value == value)} options={options} placeholder={t('select_placeholder')} className="text-sm"/>
    </div>
  )
}