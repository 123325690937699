import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/use-fetch';

import { uniqBy } from 'lodash';
import {CurrentUserContext} from "../../contexts/current-user";

import Spinner from '../../components/loader';
import Layout from '../../components/layout';
import ReactPaginate from 'react-paginate';
import Filters from '../../components/filters';
import PageFilter from '../../components/page_filter';
import DevicesTable from "./table";
import AddDevice from './add';


const DevicesPage = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [showPaging, setShowPaging] = useState(false);
  const [totalItems, setTotalItems] = useState(null);

  const [sortedBy, setSortedBy] = useState('serialNumber');
  const [sortedDown, setSortedDown] = useState(true);

  const [item, setItem] = useState('');
  const [sensorTypes, setSensorTypes] = useState([]);

  const [filters, setFilters] = useState([]);

  const [filtersTypes, setFiltersTypes] = useState([]);


  const [pageSearch, setPageSearch] = useState('');
  const [filterListTypes, setFilterListTypes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [params, setParams] = useState(new URLSearchParams({}))

  const [pagingParams, setPagingParams] = useState(new URLSearchParams({
    'page': currentPage,
    'per': itemsPerPage,
    'sort': 'inventory_number',
    'direction': 'asc'
  })) 

  let [isOpen, setIsOpen] = useState(false)

  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch((`/devices/devices?${params.toString()}&${pagingParams.toString()}`).replace(/,/g, ""));

  useEffect(() => {
    if (!response) {
      return
    }
    const newFilterListTypes = [];
    response.data.forEach(item => {
      item.attributes.sensor_types.forEach(it => {
        newFilterListTypes.push({ value: it.data_key_name, label: t(it.data_key_name)});
      });
    });

    setFilterListTypes(uniqBy(newFilterListTypes, JSON.stringify));

    setTableData(response.data);

    if (props.location.search !== '') {
      const query = new URLSearchParams(props.location.search);
      const id = response.data.find(it => it.attributes.serial_number === query.get('serial')).id;
      const types = response.data.find(it => it.id === id).attributes.sensor_types;
      onShow(id, types, query.get('serial'));
    }
    setLoading(false);
  }, [response]);


  const headerData = {
    title: 'devices',
    buttonsList: [
      {
        text: 'create_device',
        action: () => openModal()
      }
    ],
  };

  const filtersData = {
    search: {
      placeholder: 'Поиск',
      value: pageSearch,
      onChange: setPageSearch
    },
    filtersList: [
      {
        name: 'data_type',
        key: 'sensor_types_key[]',
        placeholder: 'Поиск',
        type: 'multiselect',
        option_search: true,
        options: filterListTypes
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    pageSearch ? newParams.append('search', pageSearch) : newParams.delete('search')
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [pageSearch, selectedFilters])

  // TODO: Здесь ошибка на бэке не сделано
  useEffect(() => {
    setLoading(true);
    const newFiltersTypes = [];
    filters.forEach(item => {
      if (item.filterCategory === 'dataType') {
        newFiltersTypes.push(item.filter.value)
      }
    });
    setFiltersTypes(newFiltersTypes);
    doFetchTableData();
  }, [pageSearch, sortedBy, sortedDown, filters]);

  // Paging

  useEffect(() => {
    if (!meta) {
      return;
    }
    setTotalItems(meta.total_pages * itemsPerPage);
    if (meta.total_pages > 1) {
      setShowPaging(true);
    } else {
      setShowPaging(false);
    }
  }, [meta]);

  const listPage = (page) => {
    setCurrentPage(page);
    doFetchTableData();
  };

  useEffect(() => {
    setLoading(true);
    doFetchTableData();
  }, []);



  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    doFetchTableData();
  };

  const onShow = (id, types, serial) => {
    setItem(id);
    setSensorTypes(types);
    history.push({
      pathname: `/devices/${id}`,
      state: { serial: serial, types: types }
    });
  };

  return (
    <Layout
      isWide={true}
      headerData={headerData}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
        {loading ? <Spinner/> : (
          <DevicesTable
            // sortedBy={sortedBy}
            // sortedDown={sortedDown}
            // onSortClick={onSortClick}
            tableData={tableData}
            onShow={onShow}
            // onItemDelete={onItemDelete}
            isLoading={isLoading}
            // error={false}
          />
        )}
        {showPaging && (
          <ReactPaginate 
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            initialPage={currentPage}
            pageCount={totalItems}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(currentPage) => listPage(currentPage)}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        )}
      <AddDevice closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable}/>
    </Layout>
  );
};

export default DevicesPage;
