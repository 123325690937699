import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import LazyLoad from 'react-lazyload';


const ReportItem = ({analyticType, selectedDate, convertedShift}) => {
  const {t} = useTranslation();
  let history = useHistory();

  
  return (
    <LazyLoad height={300} offset={100} once>
      <div className="flex h-full border border-gray-100 rounded-sm px-4 py-4 text-sm">
        <div className="w-full">
          {/* <div className="flex w-full h-80">
            <EquipmentMonthLoadWidget item={equipment} selectedDate={selectedDate} convertedShift={convertedShift} onBarClick={onBarClick}/>
          </div> */}
        </div>
      </div>
    </LazyLoad>
  )
}

export default ReportItem;