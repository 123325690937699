import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartPieIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';

import Spinner from "../../../components/loader";
import { getPerfectDate, getPerfectDateTime } from "../../../utils";
import Table from '../../../components/form/table';


const LogsTable = ({ onItemEdit, onItemDelete, onItemReport, isLoading, error, tableData }) => {
  const {t} = useTranslation(['logs']);

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        user: tableItem.attributes.user_name,
        created_at: getPerfectDateTime(tableItem.attributes.created_at),
        action: tableItem.attributes.action,
        controller: tableItem.attributes.controller,
        url: tableItem.attributes.url
      }
    )
  });

  const columns = [
    {
      label: t('date'),
      prop: "created_at",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className='flex items-center'>
            <div className={`flex rounded w-3 h-3 mr-3 ${["create", "update"].includes(row.action) ? "bg-green-400" : "bg-gray-300"} ${["delete"].includes(row.action) ? "bg-red-400" : "bg-gray-300"}`}></div>
            <div className="font-medium text-gray-900 truncate">
              {row.created_at}
            </div>
          </div>
        )
      }
    },
    {
      label: t('action'),
      prop: "action",
      labelClassName: "w-full lg:w-6/12",
      className: "w-full lg:w-6/12 flex-shrink-0",
      render: (row) => {
        return (
          <div>
            <div className="font-medium text-gray-900 truncate">
              {t(row.action)}
            </div>
            <div className="font-medium text-gray-900 truncate">
              {t(row.controller)}
            </div>
          </div>
        )
      }
    },
    {
      label: "user",
      prop: "user",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
    }
  ]


  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  )
};

export default LogsTable;
