import React, {useEffect, useRef, useState, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from 'react-i18next';

import Layout from '../../../../components/layout';
import useFetch from '../../../../hooks/use-fetch';
import Spinner from '../../../../components/loader';

import StaffInfo from './info';
import StaffChanges from './changes';
import StaffAccess from './access';


const StaffEdit = (props) => {
  const {t} = useTranslation();
  const [itemId, ] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState('');

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/directories/personnels/${itemId}`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/personnels/${itemId}`);

  useEffect(() => {
    setLoading(true);
    doFetchItem();
  },[])

  useEffect(() => {
    if(!response) {
      return;
    } 
    setCurrentItem(response.data);
  },[response]);



  useEffect(() => {
    if(!response) {
      return;
    } 
    setLoading(false);
  },[response]);
  

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    setCurrentItem(itemUpdateResponse.data);
  }, [itemUpdateResponse]);

  const onUpdate = (formData) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: formData
      }
    });
  };

  const reloadItem = () => {
    doFetchItem();
  }

  const headerData = {
    title: currentItem?.attributes?.last_name ? `${currentItem?.attributes?.last_name} ${currentItem?.attributes?.first_name}` : "..."
  };

  return (
    <Layout 
      headerData={headerData}
      isWide={false}
    >
      {loading ? <Spinner/> : (
        <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('main_info')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('changes')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('access')}
            </Tab>
          </TabList>
          <TabPanel>
            <StaffInfo 
              currentItem = {currentItem}
              onUpdate = {onUpdate}
            />
          </TabPanel>
          <TabPanel>
            <StaffChanges 
              currentItem = {currentItem}
              reloadItem = {reloadItem}
            />
          </TabPanel>
          <TabPanel>
            <StaffAccess 
              currentItem = {currentItem}
            />
          </TabPanel>
        </Tabs>
      )}
    </Layout>
  );
};

export default StaffEdit;
