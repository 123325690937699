import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";

import {CurrentUserContext} from "../../contexts/current-user";
import useFetch from '../../hooks/use-fetch';
import Layout from '../../components/layout';
import PageFilter from '../../components/page_filter';
import Spinner from '../../components/loader';

import DirectoriesTable from './table';
import AddDirectory from './add';

const Directories = () => {
  let history = useHistory();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [itemId, setItemId] = useState('new');
  const [pageSearch, setPageSearch] = useState('');

  const [{response, isLoading, error}, doFetchTableData] = useFetch('/directories/directories');
  const [{response: itemDeleteResponse, isLoading: itemDeleteIsLoading, error: itemDeleteError}, doFetchItemDelete] = useFetch(`/directories/directories/${itemId}`);

  useEffect(() => {
    setLoading(true);
    doFetchTableData();
  },[])

  const headerData = {
    title: 'directories',
    buttonsList: [
      {
        text: 'create_directory',
        action: () => onAdd()
      }
    ],
  };

  const pageFiltersData = {
    search: {
      placeholder: 'search_by_name',
      action: ''
    },
    defaultFiltersCount: 3,
    filtersList: [],
    calendarShow: false,
  };

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
    setCurrentUserState(state => ({
      ...state,
      directories: response.data,
    }));
    setLoading(false);
  }, [response]);

  const onAdd = () => {
    setItemId('new');
    openModal();
  };

  const onItemEdit = (id) => {
    if (id !== 'new') {
      setItemId(id);
      history.push({
        pathname: `/directories/${id}`
      });
    }
  };

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    if (itemDeleteIsLoading) {
      return;
    }
    reloadTable();
  }, [itemDeleteIsLoading]);

  
  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setItemId("new")
    }, 300);
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    doFetchTableData();
  };

  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      
      {/* <PageFilter
        filters={filters}
        pageFilters={pageFiltersData}
        setFilters={setFilters}
        pageSearch={pageSearch}
        setPageSearch={setPageSearch}
      /> */}
      <div className="flex flex-col mt-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 rounded-sm mb-10">
            {loading ? <Spinner/> : (
              <DirectoriesTable
                tableData={tableData}
                onItemEdit={onItemEdit}
                onItemDelete={onItemDelete}
                // pageSearch={pageSearch}
                isLoading={isLoading}
                error={error}
              />
            )}
            </div>
          </div>
        </div>   
      </div>
      <AddDirectory itemId={itemId} closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable}/>
    </Layout>
  );
};

export default Directories;
