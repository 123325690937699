import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import RelatedTable from './table';


const EquipmentRelated = ({ childrens }) => {
  const {t} = useTranslation();
  const [relatedData, setRelatedData] = useState([]);
  
  const [{response, isLoading, error}, doFetchRelated] = useFetch((`/directories/equipment_items/?${childrens.map(it => 'ids[]=' + it + '&')}`).replace(/,/g, ""));



  useEffect(() => {
    doFetchRelated()
  }, []);

  useEffect(() => {
    if(!response) {
      return;
    }
    setRelatedData(response.data)
  },[response])


  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('equipment_related')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('equipment_related_text')}</div>
        </div>
      </div>
      <RelatedTable
        tableData={relatedData}
      />
    </div>
  );
};

export default EquipmentRelated;
