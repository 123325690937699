import React, { useState, useEffect } from 'react';

import CanvasJSReact from '../../assets/canvasjs.react';
import Spinner from "../loader";
import {getPerfectDateTime, convertTimezone} from "../../utils";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJSReact.CanvasJS.addCultureInfo("ru",
  {
    months: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"]
  });

const ChartRfidHold = ({ loading, responseData, baseOptions, timeZone }) => {
  const [chartDataIn, setChartDataIn] = useState([]);
  const [chartDataOut, setChartDataOut] = useState([]);

  useEffect(() => {
    if (!responseData.attributes) {
      return;
    }
    const itemDataIn = [];
    const itemDataOut = [];
    responseData.attributes.data.forEach(it => {
      if (it.v === 'in') {
        itemDataIn.push({
          x: new Date(it.at + convertTimezone(it.at)),
          y: 1,
          rfid: it.rfid
        })
      } else if (it.v === 'out') {
        itemDataOut.push({
          x: new Date(it.at + convertTimezone(it.at)),
          y: 1,
          rfid: it.rfid
        })
      } else if (it.v === undefined || it.v === null) {
        itemDataIn.push({
          x: new Date(it.at + convertTimezone(it.at)),
        })
        itemDataOut.push({
          x: new Date(it.at + convertTimezone(it.at)),
        })
      } else {
        return;
      }
    });
    setChartDataOut(itemDataOut);
    setChartDataIn(itemDataIn);
  }, [responseData]);

  const options = {
    height: 100,
    axisY:{
      maximum: 2,
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: 'IBMPlexSansMedium',
      labelFontColor: '#596890',
      labelFontSize: 0,
    },

    toolTip: {
      shared: true,
      contentFormatter: function (e) {

        var content = " ";
        for (var i = 0; i < e.entries.length; i++) {
          if (e.entries[i].dataPoint.markerType === 'none'){
            continue;
          }
          if (e.entries[i].dataPoint.personnel) {
            content += 'Персонал: ' + e.entries[i].dataPoint.personnel.first_name + ' ' + e.entries[i].dataPoint.personnel.middle_name + ' ' + e.entries[i].dataPoint.personnel.last_name;
          } else {
            content += 'Персонал: неизвесный';
          }
          content += '</br>Номер карты: ' + e.entries[i].dataPoint.rfid;
          content += '</br>Время: ' + getPerfectDateTime(e.entries[i].dataPoint.x);
        }
        return content;
      }
    },

    // axisY: {
    //   includeZero: true,
    //   lineColor: 'transparent',
    //   gridDashType: 'dash',
    //   gridColor: '#E7ECF8',
    //   tickLength: 8,
    //   tickColor: 'transparent',
    //   labelFontFamily: 'IBMPlexSansMedium',
    //   labelFontColor: '#596890',
    //   labelFontSize: 0,
    //   // interval: 1,
    //   maximum: 1.9
    // },

  //   data: [{
  //     xValueFormatString: "HH:mm:ss",
  //     // type: "stepLine",
  //     type: "scatter",
  //     color: '#113FB4',
  //     fillOpacity: .2,
  //     lineColor: '#113FB4',
  //     lineThickness: 1,
  //     markerType: "circle",
  //     markerSize: 6,
  //     dataPoints: chartDataIn
  //   },
  //   {
  //     xValueFormatString: "HH:mm:ss",
  //     // type: "stepLine",
  //     type: "scatter",
  //     color: '#E10A0A',
  //     fillOpacity: .2,
  //     lineColor: '#E10A0A',
  //     lineThickness: 1,
  //     markerType: "circle",
  //     markerSize: 6,
  //     dataPoints: chartDataOut
  //   }
  
  // ],

    data: [{
      xValueFormatString: "HH:mm:ss",
      // type: "stepLine",
      type: "scatter",
      // type: "area",
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 10,
      dataPoints: chartDataIn
    },
    
    {
      xValueFormatString: "HH:mm:ss",
      // type: "stepLine",
      type: "scatter",
      // type: "area",
      color: '#E10A0A',
      fillOpacity: .2,
      lineColor: '#E10A0A',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 10,
      dataPoints: chartDataOut
    },
  ]
  };


  return (
    <div className="device-details-history-item__chart">
      {loading ?       <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div>  : <CanvasJSChart options = {{...baseOptions, ...options}}/>}
    </div>
  )
};

export default ChartRfidHold;
