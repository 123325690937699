import { useEffect, useState } from "react"
import _ from 'lodash';
import useFetch from "../../../hooks/use-fetch";
import Select from '@atlaskit/select';
import { useTranslation } from "react-i18next";
import {Interval, DateTime, Duration} from 'luxon';
import useAsyncFetch from "../../../hooks/useAsyncFetch";
import { convertTimeHHMM, covertTimeToSeconds } from "../../../utils";
import IdelTimelineWidget from "../../../components/widgets/idle_timeline";
import Spinner from "../../../components/loader";
import { ResponsiveBar } from '@nivo/bar'
import BottelNeckItem from "./item";

export default function EquipmentsMonthAnalytic({selectedDate, convertedShift}) {
  const {t} = useTranslation();
  const tz = DateTime.local().toFormat('ZZ');
  const [dates, setDates] = useState();
  const [equipmentList, setEquipmentList] = useState([])
  const [equipmentIds, setEquipmentIds] = useState(null)
  const [params, setParams] = useState(new URLSearchParams({'with_sensors': true}))
  const [usageParams, setUsageParams] = useState(new URLSearchParams({}))
  const [loading, setLoading] = useState(false)
  const [timeMarkers, setTimeMarkers] = useState([0, 21600, 43200, 64800, 86400])
  const [urls, setUrls] = useState([])
  const [data, setData] = useState([]);

  const [{response, isLoading, error}, doFetchEquipment] = useFetch(`/directories/equipment_items?${params.toString()}`);
  const [{response: usageDataResponse, isLoading: usageDataIsLoading, error: usageDataError}, doFetchUsageData] = useFetch(`/directories/equipment_items/collection_full_timelines?${usageParams.toString()}`);

  useEffect(() => {
    doFetchEquipment()
  }, [])

  useEffect(() => {
    const shiftStart = covertTimeToSeconds(convertedShift.start);
    const shiftEnded = covertTimeToSeconds(convertedShift.end);

    setTimeMarkers([
      shiftStart, 
      shiftStart + (convertedShift.duration / 4) > 86400 ? (shiftStart + (convertedShift.duration / 4)) - 86400 : shiftStart + (convertedShift.duration / 4), 
      shiftStart + (convertedShift.duration / 2) > 86400 ? (shiftStart + (convertedShift.duration / 2)) - 86400 : shiftStart + (convertedShift.duration / 2), 
      shiftStart + (convertedShift.duration / 4) * 3 > 86400 ? (shiftStart + (convertedShift.duration / 4) * 3) - 86400 : shiftStart + (convertedShift.duration / 4) * 3, 
      shiftEnded
    ])

    setDates(Interval.fromDateTimes(selectedDate[0], selectedDate[1]).splitBy({ day: 1 }))

    setData([])
  }, [selectedDate, convertedShift])

  useEffect(() => {
    if(!response?.data) {
      return
    }
    setEquipmentList(response.data)
    setEquipmentIds(response.data.map(i => i.id))
  }, [response?.data])

  function generate() {
    if (equipmentIds === null || equipmentIds.length === 0) {
      return;
    }

    const start = selectedDate[0].startOf('day').toFormat("yyyy-MM-dd")
    const end = selectedDate[1].endOf('day').toFormat("yyyy-MM-dd")

    let startDateString
    let endDateString

    if(covertTimeToSeconds(convertedShift.started_at) > covertTimeToSeconds(convertedShift.ended_at)) {
      startDateString = `${start}T${convertedShift.start}.000${tz}`

      let nextEndDayString = selectedDate.endOf('month').plus({day: 1}).toFormat("yyyy-MM-dd")
      endDateString = `${nextEndDayString}T${convertedShift.end}.000${tz}`
    } else {
      startDateString  = `${start}T${convertedShift.start}.000${tz}`
      endDateString = `${end}T${convertedShift.end}.000${tz}`
    }

    let newUsageParams = new URLSearchParams({})
    newUsageParams.append('from', startDateString)
    newUsageParams.append('to', endDateString)
    newUsageParams.append('day_time_from', convertedShift.start)
    newUsageParams.append('day_time_to', convertedShift.end)

    equipmentIds.map(i => {
      newUsageParams.append('equipment_item_ids[]', i)
    })

    setUsageParams(newUsageParams)
    doFetchUsageData();
  }


  useEffect(() => {
    if(!usageDataResponse?.data) { return }
    let count = selectedDate[1].endOf('day').day
    let groupData = _.groupBy(usageDataResponse?.data, 'equipment_item_id')
    let preData = equipmentList.map(eq => {
      let s = groupData[eq.id] ? groupData[eq.id].filter(i => i.state === "stopped").reduce((total, obj) => obj.duration + total,0) : 0
      let w = groupData[eq.id] ? groupData[eq.id].filter(i => i.state === "worked").reduce((total, obj) => obj.duration + total,0) : 0
      return ({
        id: eq.id,
        equipment: `${eq.attributes.equipment_type_name} ${eq.attributes.producer_name} ${eq.attributes.equipment_model_name}`,
        worked:  w,
        stopped:  s,
        no_data: (count * convertedShift.duration) - (w + s),
        data: groupData[eq.id] ? groupData[eq.id] : []
      })
    })

    setData(_.orderBy(preData, 'worked', 'desc'))
  },[usageDataResponse?.data])

  return (
    <>
      <div className="py-10 w-full h-full min-h-96">
        {usageDataIsLoading ? <Spinner /> : data.length > 0 ?
        <div className="space-y-4">
          {data.map(item => (
            <BottelNeckItem key={item.id} item={item} />
          ))}
        </div>
        :
        <div className="flex flex-col w-full h-full items-center justify-center">
          <span className="text-gray-600 py-8">Сформировать аналитический отчет</span>
          <button className="bg-blue-600 text-white py-2 px-8 rounded-sm font-medium text-sm" onClick={() => generate()}>Сформировать</button>
        </div>
        }
      </div>
    </>
  )
}