import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import useFetch from '../../../../hooks/use-fetch';

const CollectionMultiSelectField = ({slug, label, fid, values, required, setCurrentSelect}) => {
  const {t} = useTranslation();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const [{response, isLoading}, doFetchTableData] = useFetch(`/directories/${slug}`);

  useEffect(() => {
    doFetchTableData()
  },[slug])

  useEffect(() => {
    if (!response) {
      return;
    }
    const list = [];
    response.data.forEach(item => {
      list.push({
        label: item.attributes.name,
        value: item.id,
        uuid: fid
      })
    });
    setOptions(list);
  }, [response]);

  useEffect(() => {
    if (values === null || values === undefined || options.length === 0) {
      return;
    } else {
      const newSelected = [];
      values.forEach(item => {
        if(options.find(it => it.value == item)) {
          newSelected.push(options.find(it => it.value == item))
        } else {
          newSelected.push({label: 'ОШИБКА! Запись удалена.', value: ''})
        } 
      })
      setSelected(newSelected);
    } 
  }, [options, values]);

  const onItemClick = (values) => {
    const valuesArr = values.map(val => val.value)
    setCurrentSelect(fid, valuesArr);
  };

  return ( 
    <Field name={fid} defaultValue={selected === null ? [] : selected} label={label} isRequired={required} >
      {({ fieldProps }) => <Select className="text-sm" options={options} {...fieldProps} onChange={value => onItemClick(value)} placeholder={t('select_placeholder')} isMulti/>}
    </Field>
  )
}

export default CollectionMultiSelectField;