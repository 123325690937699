import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from "../../../hooks/use-fetch";
import {CurrentUserContext} from '../../../contexts/current-user';

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';

import ShiftsTable from './table';
import FormShifts from './form';


const SettingsShifts = () => {
  const { t } = useTranslation();
  const [, setCurrentUserState] = useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [shiftId, setShiftId] = useState("new");

  const [{response, isLoading, error}, doFetch] = useFetch('/directories/working_shifts');
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/working_shifts/${shiftId}`);


  useEffect(() => {
    doFetch();
    setLoading(true);
  },[]);

  const updateShiftsContext = (data) =>{
    setCurrentUserState(state => ({
      ...state,
      shifts: data
    }));
  }

  useEffect(() => {
    if(!response) {
      return
    }
    setTableData(response.data);
    updateShiftsContext(response.data)
    setLoading(false);
  }, [response])


  const onAdd = () => {
    setShiftId("new");
    openModal(true);
  }

  const onEdit = (id) => {
    setShiftId(id);
    openModal(true);
  }
  
  const onDelete = (id) => {
    setShiftId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  }

  useEffect(() => {
    if (deleteIsLoading) {
      return;
    }
    setShiftId("new");
    reloadTable();
  }, [deleteIsLoading]);


  const reloadTable = () => {
    doFetch();
  };

  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setShiftId("new")
    }, 300);
  }

  function openModal() {
    setIsOpen(true)
  }

  const headerData = {
    title: 'shifts',
    buttonsList: [
      {
        text: 'create_shift',
        action: () => onAdd()
      }
    ],
  };

  return (
    <>
      <Layout
        headerData={headerData}
        isWide={true}
      >
        <div className="content">
          {loading ? <Spinner/> : (
            <div className="flex flex-col mt-8">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
                    <ShiftsTable 
                      isLoading={isLoading}
                      tableData={tableData}
                      error={error}
                      onItemEdit={onEdit}
                      onItemDelete={onDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}          
        </div>
        <FormShifts itemId={shiftId} closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} tableData={tableData}/>
      </Layout>
    </>

  );
};

export default SettingsShifts;
