import {Switch} from 'react-router-dom';
import PrivateRoute from '../auth/private';

import UploadPage from "../pages/upload";
import OazisUploadPage from '../pages/oazis';

const UploadRoutes = () => {
  return (
    <Switch>
      <PrivateRoute path="/upload/log" component={UploadPage} exact/>
      <PrivateRoute path="/upload/oazis" component={OazisUploadPage} exact/>
    </Switch>
  );
};

export default UploadRoutes;
