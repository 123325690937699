import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import ChangesTable from './table';



const EquipmentChanges = ({ currentItem, reloadCurrentItem }) => {
  const {t} = useTranslation();
  const [changesData, setChangesData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeId, setChangeId] = useState('');

  const [{response, isLoading, error}, doFetchChanges] = useFetch(`/directories/equipment_items/${currentItem.id}/changes`);
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/equipment_items/${currentItem.id}/changes/${changeId}`);

  useEffect(() => {
    doFetchChanges()
  }, []);

  useEffect(() => {
    if(!response) {
      return;
    }
    setChangesData(response.data)
  },[response])

  function closeModal() {
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  // const onAdd = () => {
  //   openModal();
  // };


  // const handleDelete = (id) => {
  //   setChangeId(id);
  //   doFetchItemDelete({
  //     method: 'DELETE'
  //   });
  // };

  // useEffect(() => {
  //   if(deleteIsLoading) {
  //     return;
  //   }
  //   setChangeId(null);
  //   reloadData();
  // }, [deleteIsLoading]);

  const reloadData = () => {
    reloadCurrentItem();
    doFetchChanges();
  };

  return (
    <div className="pt-8 pb-10">
      <ChangesTable
        tableData={changesData}
      />
    </div>
  );
};

export default EquipmentChanges;
