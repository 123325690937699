import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { getPerfectDate } from '../../../../../utils';
import { DateTime } from 'luxon';
import ChartItem from './chartItem';


const FullInfo = ({closeModal, isOpen, item}) => {
  const {t} = useTranslation();

  const items = item.sensors.map((it, index) => {
    return (
      <ChartItem key={index} currentSensorId={it} date={item.day}/>
    )
  })

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center">

          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="block w-screen h-screen overflow-y-auto text-left align-middle transition-all transform bg-white">
              <div className="flex max-w-7xl h-16 justify-between mx-auto w-full items-center">
                <Dialog.Title
                  as="h3"
                  className="text font-medium leading-6 text-gray-900 capitalize"
                >
                  {DateTime.fromMillis(item.day).weekdayLong}, {DateTime.fromMillis(item.day).toFormat("dd.MM.yyyy")}
                </Dialog.Title>
                <div className="flex flex-row">
                  <button onClick={() => closeModal()} type="button" 
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    {t('close')}
                  </button>
                </div>
              </div>
              <div className="mt-2 h-full space-y-4 ">
                {items}
              </div>

            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )

}

export default FullInfo;