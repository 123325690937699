import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';
import Spinner from '../../../components/loader';
import {DateTime} from 'luxon';
import {getPerfectHours} from '../../../utils/index';

const ShiftsTable = ({tableData, isLoading, onItemEdit, onItemDelete, error}) => {
  const { t } = useTranslation();
  const items = tableData.map(tableItem => {
    return (
      <tr
        key={tableItem.id}
        className="hover:bg-gray-50"
      >
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{tableItem.attributes.name}</div></td>
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{DateTime.fromISO(tableItem.attributes.started_at).toFormat("HH:mm")}</div></td>
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{DateTime.fromISO(tableItem.attributes.ended_at).toFormat("HH:mm")}</div></td>
        <td 
          className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end space-x-2"
        >
          <button onClick={() => onItemEdit(tableItem.id)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded"><PencilAltIcon className="w-4 h-4"/></button>
          <button onClick={() => onItemDelete(tableItem.id)} className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded"><TrashIcon className="w-4 h-4"/></button>
        </td>
      </tr>
    )
  });

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr
          className=""
        >
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('shift_name')}</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('shift_start_time')}</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('shift_end_time')}</th>
          <th scope="col" className="relative px-6 py-3"><span className="sr-only">{t('actions')}</span></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
      {isLoading ? (
        <tr>
          <td colSpan="7">
            <Spinner/>
          </td>
        </tr>
      ) : error ? (
        <tr>
          <td colSpan="7">
            <div className="text-center text-xs py-8 text-gray-600">{t('loading_error')}</div>
          </td>
        </tr>
      ) : tableData.length === 0 ? (
        <tr>
          <td colSpan="7">
            <div className="text-center text-xs py-8 text-gray-600">{t('no_data')}</div>
          </td>
        </tr>
      ) : items}
      </tbody>
    </table>
  )
};

export default ShiftsTable;