import React from 'react';
import Table from '../../../components/form/table';
import Spinner from "../../../components/loader";
import { useTranslation } from 'react-i18next';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';

const EquipmentTypesTable = ({ error, tableData, isLoading, onItemDelete, onItemEdit }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        name: tableItem.attributes.name
      }
    )
  });

  const columns = [
    {
      label: t('title_name'),
      prop: "name",
      labelClassName: "w-full lg:w-10/12",
      className: "w-full lg:w-10/12 flex-shrink-0",
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemEdit(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
      // onRowClick={(data) => onShow(data.id)}
    />
  );

};

export default EquipmentTypesTable;
