import React from 'react';
import Table from '../../../../../components/form/table';
import Spinner from "../../../../../components/loader";
import { useTranslation } from 'react-i18next';
import { PlusCircleIcon, PencilAltIcon, TrashIcon, FolderIcon, FolderOpenIcon } from '@heroicons/react/outline';

const EquipmentTypeOptionsTable = ({ tableData, onItemEdit, onItemDelete, onAddChild, unitsList }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    let tree = [
      {
        id: tableItem.uuid,
        parent: null,
        name: tableItem.label,
        type: tableItem.type,
        required: tableItem.required,
        default_value: tableItem.default_value,
        unit: unitsList.find(un => un.value === tableItem.unit_id)
      }
    ]

    if(tableItem.children?.length > 0) {
      tableItem.children.map(itemChild => {
        tree.push (
          {
            id: itemChild.uuid,
            parent: tableItem.uuid,
            name: itemChild.label,
            type: itemChild.type,
            required: itemChild.required,
            default_value: itemChild.default_value,
            unit: tableItem.unit_id
          }
        )
      })
    }
    
    return (
      tree 
    )
  }).flat();

  const columns = [
    {
      label: t('title_name'),
      prop: "name",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex items-center">
            {row.parent === null ? <FolderIcon className={`mr-2 w-4 h-4`}/> : <FolderOpenIcon className={`mr-4 w-4 h-4`}/>}
            {row.name}
          </div>
        )
      }
    },
    {
      label: t('type'),
      prop: "type",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => { return t(row.type)}
    },
    {
      label: t('required_field'),
      prop: "required",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
    },
    {
      label: t('default_value'),
      prop: "default_value",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
    },
    {
      label: t('unit'),
      prop: "unit",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => {return row.unit?.label}
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            {row.type === 'folder' && row.parent === null ? 
              <button 
                onClick={() => onAddChild(row.id)} 
                className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded-sm">
                <PlusCircleIcon className="w-4 h-4"/>
              </button> : ""
            }
            <button 
              onClick={() => onItemEdit(row.id, row.parent)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded-sm">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id, row.parent)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );

};

export default EquipmentTypeOptionsTable;
