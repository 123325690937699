import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../components/form/calendar/dateRangePicker';
import Select from "@atlaskit/select";
import {DateTime} from 'luxon';
import {convertTimezone} from '../../../../utils';


import DeviceDataItem from "./dataItem";
import AcDebug from './acDebug';

import locale from '../../../../components/form/locale/lang/ru';
import {useStore} from "../../../../hooks-store/store";


locale.el.datepicker.today = DateTime.local();

const DeviceData = ({ sensorTypes, currentItem, selectedDate, setSelectedDate }) => {
  const {t} = useTranslation();
  const [currentSlot, setCurrentSlot] = useState('');
  const [currentType, setCurrentType] = useState('');
  const [slotsOptions, setSlotsOptions] = useState([]);
  const [sensorsOptions, setSensorsOptions] = useState([]);
  const [filteredSensorTypes, setFilteredSensorTypes] = useState(sensorTypes);
  const [filteredSlots, setFilteredSlots] = useState(currentItem.attributes.slots);

  // const [acDebugSensors, setAcDebugSensors] = useState([]);
  // const dateRangePickerRef = useRef(null);


  useEffect(() => {
    if(currentItem.attributes.slots.length > 1) {
      const newSlotsOptions = []
      currentItem.attributes.slots.forEach(item => {
        newSlotsOptions.push({
          label: `${t('slot')} ${item.number}`,
          value: item.number
        })
      });
      newSlotsOptions.unshift({label: t('all_slots'), value: ''});
      setSlotsOptions(newSlotsOptions);
    } else {
      return;
    }
  }, []);


  useEffect(() => {
    if(sensorTypes.length > 1) {
      const newSensorsOptions = []
      sensorTypes.forEach(item => {
        newSensorsOptions.push({
          label: t(item.data_key_name),
          value: item.data_key_name
        })
      });
      newSensorsOptions.unshift({label: t('all_types'), value: ''});
      setSensorsOptions(newSensorsOptions);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (currentType === '') {
      setFilteredSensorTypes(sensorTypes);
    } else {
      const newSensorTypes = sensorTypes.slice().filter(item => item.data_key_name === currentType);
      setFilteredSensorTypes(newSensorTypes);
    }
  }, [currentType]);

  useEffect(() => {
    if (currentSlot === '') {
      setFilteredSlots(currentItem.attributes.slots);
      // const acdebug = []
      // currentItem.attributes.slots.slice().map(item => 
      //   acdebug.push(item.sensors.filter(s => s.sensor_type.data_key_name == "ac_min" || s.sensor_type.data_key_name == "ac_max" || s.sensor_type.data_key_name == "ac_avg"))
      // )
      // setAcDebugSensors(acdebug)
    } else {
      const newSlots = currentItem.attributes.slots.slice().filter(item => item.number === currentSlot);
      setFilteredSlots(newSlots);
    }
  }, [currentSlot]);


  const onDateChoice = (dateRange) => {
    if (dateRange === null) {
      return;
    } else if (dateRange[1] === null) {
      const start = DateTime.local(dateRange[0].getFullYear(), dateRange[0].getMonth() + 1, dateRange[0].getDate())
      setSelectedDate([start, null]);
    } else {
      const start = DateTime.local(dateRange[0].getFullYear(), dateRange[0].getMonth() + 1, dateRange[0].getDate())
      const end = DateTime.local(dateRange[1].getFullYear(), dateRange[1].getMonth() + 1, dateRange[1].getDate())
      
      setSelectedDate([start, end.endOf('day')]);
    }
  };


  return (
    <div className="device-details-history">
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 mt-4">
          {currentItem.attributes.slots.length > 1 && (
            <div>
              <Select
                className="text-sm"
                options={slotsOptions}
                value={slotsOptions[slotsOptions.findIndex(it => it.value == currentSlot)]}
                onChange={value => setCurrentSlot(value.value)}
                isSearchable={false}
              />
            </div>
          )}
          {sensorTypes.length > 1 && (
            <div>
            <Select
              options={sensorsOptions}
              value={sensorsOptions[sensorsOptions.findIndex(it => it.value == currentType)]}
              onChange={value => setCurrentType(value.value)}
              isSearchable={false}
            />
            </div>
          )}
        <div className="lg:col-end-5 col-span-1">
          <DateRangePicker 
            startDate={selectedDate[0] ? selectedDate[0] : null} 
            endDate={selectedDate[1] ?  selectedDate[1] : null} 
            onChange={onDateChoice} 
          />
        </div>
      </div>
      <div className="space-y-4 pb-10 mt-4">
        {filteredSensorTypes.map(sensor => {
          return (
            filteredSlots.map((item, index) => {         
              const currentSensor = item.sensors.find(it => it.sensor_type.data_key_name === sensor.data_key_name);
              return (
                currentSensor && <DeviceDataItem
                  key={index}
                  currentSensor={currentSensor}
                  currentItem={item}
                  slotsCount={currentItem.attributes.slots.length}
                  filteredDate={selectedDate}
                  isOnline={currentItem.attributes.online}
                />
              )
            })
          )
        })}
        {/* {
          acDebugSensors.length > 0 ? <AcDebug sensors={acDebugSensors} filteredDate={selectedDate}/> : ""
        } */}
      </div>
    </div>
  )
};

export default DeviceData;
