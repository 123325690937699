
import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import AccessTable from './table';
import AccessForm from './form';


const StaffAccess = ({ currentItem }) => {
  const {t} = useTranslation();
  const [accessData, setAccessData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeId, setChangeId] = useState('');

  const [{response, isLoading, error}, doFetchTableData] = useFetch(`/directories/personnels/${currentItem.id}/access_tokens`);
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/personnels/${currentItem.id}/access_tokens/${changeId}`);

  const statuses = [
    {label: t('issued'), value: 'issued'},
    {label: t('take_away'), value: 'take_away'},
    {label: t('replaced'), value: 'replaced'},
  ];

  useEffect(() => {
    doFetchTableData()
  }, []);

  useEffect(() => {
    if(!response) {
      return;
    }
    setAccessData(response.data)
  },[response])

  function closeModal() {
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    openModal();
  };

  const handleDelete = (id) => {
    setChangeId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    if(deleteIsLoading) {
      return;
    }
    setChangeId(null);
    reloadData();
  }, [deleteIsLoading]);

  const reloadData = () => {
    doFetchTableData();
  };


  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('access_token_changes')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('access_token_changes_text')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('add_access_token')}
        </button>
      </div>
      <AccessTable
        tableData={accessData}
        onItemDelete={handleDelete}
      />
      <AccessForm 
        currentItem={currentItem}
        closeModal={closeModal}
        isOpen={isOpen}
        statuses={statuses}
        reloadTable = {reloadData}
      />
    </div>
  );
};

export default StaffAccess;
