import React, { useState, useEffect, forwardRef, useContext } from 'react';
import {DateTime} from "luxon";
import {CurrentUserContext} from "../../../contexts/current-user";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {ChevronLeftIcon, ChevronRightIcon, CalendarIcon} from '@heroicons/react/outline';
import {convertTimezone, getPerfectDate} from "../../../utils";
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';
import { enGB, ru } from 'date-fns/locale';

registerLocale("ru", ru)
registerLocale("en", enGB)


const SimpleCalendar = ({selectedDate, setSelectedDate}) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);
  
  const [previousVisibleMonth, setPreviousVisibleMonth] = useState(selectedDate.month - 1);
  const [currentVisibleMonth, setCurrentVisibleMonth] = useState(selectedDate.month);
  const [nextVisibleMonth, setNextVisibleMonth] = useState(selectedDate.month + 1);
  const [currentVisibleYear, setCurrentVisibleYear] = useState(selectedDate.year);

  setDefaultLocale(currentUserState.currentUser.attributes.locale)

  useEffect(() => {
    setCurrentVisibleMonth(selectedDate.month);
    setCurrentVisibleYear(selectedDate.year);
  }, [selectedDate]);

  useEffect(() => {
    if (currentVisibleMonth === 1) {
      setPreviousVisibleMonth(12);
      setNextVisibleMonth(2);
    } else {
      if (currentVisibleMonth === 12) {
        setPreviousVisibleMonth(11);
        setNextVisibleMonth(1);
      } else {
        setPreviousVisibleMonth(currentVisibleMonth - 1);
        setNextVisibleMonth(currentVisibleMonth + 1);
      }
    }
  }, [currentVisibleMonth]);

  const monthDecrease = () => {
    if (currentVisibleMonth === 1) {
      setCurrentVisibleMonth(12);
      setCurrentVisibleYear(currentVisibleYear - 1);
    } else {
      setCurrentVisibleMonth(currentVisibleMonth - 1);
    }
  };

  const monthIncrease = () => {
    if (currentVisibleMonth === 12) {
      setCurrentVisibleMonth(1);
      setCurrentVisibleYear(currentVisibleYear + 1);
    } else {
      setCurrentVisibleMonth(currentVisibleMonth + 1);
    }
  };

  const CalendarButton = forwardRef(({ value, onClick }, ref) => (
    <button className="h-8 bg-white ring ring-gray-200 focus:ring-blue-300 rounded-sm text-gray-800 text-sm w-full flex items-center px-2" onClick={onClick} ref={ref}>
      <CalendarIcon className="w-4 h-4 mr-2"/>
      <span className="capitalize">{DateTime.fromFormat(value, "MM/dd/yyyy").weekdayLong}, {DateTime.fromFormat(value, "MM/dd/yyyy").toFormat("dd.MM.yyyy")}</span>
    </button>
  ));

  // const renderDayContents = (day, date) => {
  //   let today = DateTime.local()
  //   let inputDate = DateTime.fromJSDate(date)
  //   console.log(today.month === inputDate.month)

  //   if(today.month === inputDate.month) {
  //     return <span className="bg-white h-full w-full flex items-center text-xs justify-center text-gray-800">{date.getDate()}</span>;
  //   } else {
  //     return <span className="bg-white h-full w-full flex items-center text-xs justify-center text-gray-400">{date.getDate()}</span>
  //   }
  // };

  const renderCustomHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }) => {

    const handelDecrease = () => {
      monthDecrease()
      decreaseMonth()
    }

    const handelIncrease = () => {
      monthIncrease()
      increaseMonth()
    }

    return (
      <div className="flex justify-between font-medium text-gray-800 mb-4 text-sm items-center">
        <div className="flex items-center space-x-2 text-xs capitalize">
          <div
              className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center"
              onClick={handelDecrease}
            >
            <ChevronLeftIcon className="w-4 h-4"/>
          </div>
        </div>
        <div className="capitalize text-xs">
            {monthDate.toLocaleString(t("locale"), {
              month: "long",
              year: "numeric",
            })}
        </div>
        <div className="flex items-center space-x-2 text-xs capitalize">
          <div
            className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center"
            onClick={handelIncrease}
          >
            <ChevronRightIcon className="w-4 h-4"/>
          </div>
        </div>
      </div>
    )
  }


  const onDateChoice = (date) => {
    const selected = date.getTime() - convertTimezone(date.getTime())
    setSelectedDate(DateTime.fromMillis(selected))
  };

  return (
    <DatePicker 
      calendarClassName="simple"
      selected={new Date(selectedDate.toMillis() + convertTimezone(selectedDate.toMillis()))}
      onChange={(date) => onDateChoice(date)}
      renderCustomHeader={(data) => renderCustomHeader(data)}
      // renderDayContents={renderDayContents}
      customInput={<CalendarButton />}
      popperPlacement="bottom-end"
      maxDate={DateTime.local().toJSDate}
      calendarStartDay={1}
    />
  )


}
export default SimpleCalendar;