import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Layout from '../../../components/layout';
import useFetch from '../../../hooks/use-fetch';

import Spinner from '../../../components/loader';
import Filters from '../../../components/filters';

import ManufacturersTable from './table';
import AddManufacture from './add'

const Manufacturers = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSearch, setPageSearch] = useState('');
  const [params, setParams] = useState(new URLSearchParams({}))
  const [itemId, setItemId] = useState('new');

  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error}, doFetchTableData] = useFetch(`/directories/producers?${params.toString()}`);
  const [{response: itemDeleteResponse, isLoading: itemDeleteIsLoading, error: itemDeleteError}, doFetchItemDelete] = useFetch(`/directories/producers/${itemId}`);

  useEffect(() => {
    doFetchTableData();
  }, []);

  useEffect(() => {
    if (itemDeleteIsLoading) {
      return;
    }
    setItemId('new');
    doFetchTableData();
  }, [itemDeleteIsLoading]);

  useEffect(() => {
    if (!response) {
      return
    }
    const newTableData = [];
    response.data.forEach(it => {
      const typeNames = [...it.attributes.equipment_type_names, ...it.attributes.material_type_names];
      newTableData.push({
        id: it.id,
        attributes: {
          name: it.attributes.name,
          modelsCount: it.attributes.models_count,
          country: it.attributes.country,
          equipmentTypes: typeNames.join(', ')
        }
      })
    });
    setTableData(newTableData);
    setLoading(false)
  }, [response]);


  const headerData = {
    title: 'manufactures',
    buttonsList: [
      {
        text: 'add_manufacture',
        action: () => openModal()
      }
    ],
  };

  const filtersData = {
    search: {
      placeholder: 'Поиск',
      value: pageSearch,
      onChange: setPageSearch
    }
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    pageSearch ? newParams.append('search', pageSearch) : newParams.delete('search')

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [pageSearch])

  const onItemEdit = (id) => {
    history.push({
      pathname: `/manufactures/${id}`,
    });
  };

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  const reloadTable = () => {
    doFetchTableData();
  };

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      <div className="content">
        {loading ? <Spinner/> :
        <ManufacturersTable
          tableData={tableData}
          onItemEdit={onItemEdit}
          onItemDelete={onItemDelete}
          isLoading={isLoading}
          error={error}
        />}
      </div>
      <AddManufacture closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable}/>
    </Layout>
  );
};

export default Manufacturers;
