import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field, CheckboxField} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { DatePicker } from '@atlaskit/datetime-picker';
import Select, {AsyncSelect} from '@atlaskit/select';
import { Checkbox } from '@atlaskit/checkbox';
import { useTranslation } from "react-i18next";

import useFetch from '../../../../../hooks/use-fetch';

import ErrorsBlock from '../../../../../components/errors';

const TrackingForm = ({currentItem, onChangeItem, closeModal, isOpen}) => {
  const {t} = useTranslation();

  const [errors, setErrors] = useState([]);

  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/equipment_items/${currentItem.id}`);

  function handleUpdate(data) {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: data
      }
    });
  }

  useEffect(()=> {
    if(!itemUpdateError) {
      return
    }
    setErrors(itemUpdateError.errors)
  }, [itemUpdateError])

  useEffect(() => {
    if(!itemUpdateResponse) {
      return
    }
    onChangeItem(itemUpdateResponse.data);
    closeModal()
  },[itemUpdateResponse])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('manage_tracking_qrcode')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('manage_tracking_qrcode_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => handleUpdate(data)}>
                {({ formProps }) => (
                  <form {...formProps}>
                      <Field name="qrcode" defaultValue={currentItem.attributes.qrcode || ""} label={t('tracking_qrcode')}>
                        {({ fieldProps }) => <TextField {...fieldProps} className="flex w-full"/>}
                      </Field>
                      {/* <Field name="qrcode_interval" defaultValue={currentItem.attributes.qrcode || ""} label={t('tracking_qrcode_interval')}>
                        {({ fieldProps }) => <TextField {...fieldProps} className="flex w-full"/>}
                      </Field> */}
                      <div className="flex justify-between mt-8">
                        <button 
                          onClick={() => closeModal()}
                          type="button" 
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        >
                          {t('cancel')}
                        </button>
                        <button 
                          type="submit" 
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                        >
                          {t('save')}
                        </button>
                      </div>
                  </form>
                  )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
export default TrackingForm;