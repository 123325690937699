import React, {useState, useEffect} from 'react';
import _, { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../hooks/use-fetch';
import {DateTime} from "luxon";
import {getPerfectDate} from '../../../../utils';

import InteractionItem from './item';
import InteractionChart from './chart';

const Interaction = ({person, endDate, startDate, convertedShift, showByDays}) => {
  const {t} = useTranslation();
  const [startDateTime, setStartDateTime] = useState(startDate)
  const [endDateTime, setEndDateTime] = useState(endDate)
  const [startTime, setStartTime] = useState(convertedShift.start)
  const [endTime, setEndTime] = useState(convertedShift.end)
  const [interactionLoaded, setInteractionLoaded] = useState(false)
  const [interactionList, setInteractionList] = useState()
  const [interactionGroups, setInteractionGroups] = useState()

  const [{response, isLoading, error}, doFetchInteraction] = useFetch(`/directories/ng_personnel_data/iteractions?personnel_ids[]=${person.id}&from=${startDateTime}&to=${endDateTime}&day_time_from=${startTime}&day_time_to=${endTime}`);

  useEffect(()=>{
    if(!endDate) { return }
    if(convertedShift.start < convertedShift.end) {
      setStartDateTime(startDate)
      setEndDateTime(endDate)
    } else {
      setStartDateTime(startDate)
      setEndDateTime(endDate.plus({days: 1}))
    }
    setStartTime(convertedShift.start)
    setEndTime(convertedShift.end)
    setInteractionLoaded(false)
    doFetchInteraction()
  },[convertedShift, startDate, endDate])

  useEffect(()=>{
    if(!response?.data) {
      return
    }
    setInteractionList(response.data.reverse())

    const groups = _.groupBy(response.data, 'at')
    const groupInteractions = Object.keys(groups).map((date) => {
      return {
        date,
        interactions: groups[date]
      };
    })

    setInteractionGroups(groupInteractions)

    // let asen = []
    // response.data.forEach(it => {
    //   asen.push(it.equipment_item.sensors)
    // })

    // const avsens = uniqBy(asen.flat(), 'id').filter(s => !(['rfid', 'rfid_hold'].includes(s.data_key_name)))

    // setSensors(avsens)

    // const markersGroups = response.data.reduce((groups, item) => {
    //   avsens.forEach(s => {
    //     const date = item.equipment_item.sensors.find(i => i.data_key_name === s.data_key_name).data_key_name;
    //     if (!groups[date]) {
    //       groups[date] = [];
    //     }
    //     groups[date].push(item);
    //   })
    //   return groups;
    // }, {});
    
    // setMarkers(markersGroups)

    setInteractionLoaded(true)
  },[response])

  return (
  <div className='pb-6'>
    {interactionLoaded && interactionList.length > 0 ?
      <>
        {/* {sensors.map(sensor => (
          <InteractionChart currentSensor={sensor} endDate={endDate} startDate={startDate} startTime={convertedShift.start} endTime={convertedShift.end} currentItem={person} markers={markers}/>
        ))} */}
        <div className='flex flex-col w-full rounded-sm border'>
          {
            interactionGroups.map((group, idx) => (
              <div key={idx} className='flex flex-col w-full'>
                <span className='flex items-center justify-center h-10 text-sm bg-gray-100'>{getPerfectDate(group.date)} / {convertedShift.start} - {convertedShift.end}</span>
                {group.interactions.map((it, idx) => (
                  <InteractionItem key={idx} item={it} showByDays={showByDays}/>
                ))}
              </div>
            ))
          }
        </div>
      </>
      :
      isLoading ? 
        <div className="flex items-center justify-center w-full h-80">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
        </div> 
        : 
        <div className='flex flex-col w-full mt-4 p-4 h-48 items-center justify-center rounded-sm border'>{t('no_intercation_at_this_day')}</div>
    }
  </div>)
  
}
export default Interaction;