import React, {useState, useEffect, Fragment, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {CurrentUserContext} from "../../../../../contexts/current-user";
import {DateTime, Interval, Duration} from 'luxon';
import Skeleton from 'react-loading-skeleton';

import useFetch from "../../../../../hooks/use-fetch";
import {convertTimezone, secondsToTime} from "../../../../../utils";
import ChartDC from "../../../../../components/widgets/charts/base/chartDC";
import ChartAC from "../../../../../components/widgets/charts/base/chartAC";
import ChartRP from "../../../../../components/widgets/charts/base/chartRP";
import ChartACPower from '../../../../../components/widgets/charts/base/chartACPower';
import ChartRPPower from '../../../../../components/widgets/charts/base/chartACPower';
import ChartUniversal from '../../../../../components/widgets/charts/base/chartUniversal';

// import ChartU from "../../../../components/charts/chartU";
// import ChartT from "../../../../components/charts/chartT";
// import ChartAng from "../../../../components/charts/chartAng";
// import ChartTAir from "../../../../components/charts/chartTair";
// import ChartRfidHold from "../../../../components/charts/chartRfidHold";
// import ChartRfid from "../../../../components/charts/chartRfid";
// import ChartGas from "../../../../components/charts/chartGas";
// import ChartWire from "../../../../components/charts/chartWire";
import CanvasJSReact from '../../../../../assets/canvasjs.react';
// import ChartRP from '../../../../../components/widgets/charts/special/chartRP';


const Item = ({ currentSensor, startDate, endDate, startTime, endTime, shiftDepths, currentItem }) => {
  const [currentUserState, _] = useContext(CurrentUserContext);
  const {t} = useTranslation();
  const {t: tw} = useTranslation('widgets'); 
  const [autoReload, setAutoReload] = useState(true);
  const [defaultPeriod, setDefaultPeriod] = useState(true);
  const [zoomed, setZoomed] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [power, setPower] = useState();
  const [powerGraph, setPowerGraph] = useState([]);
  const [powerTotals, setPowerTotals] = useState({});
  const [loadingPower, setLoadingPower] = useState(true);
  const [reset, setReset] = useState(false)

  const [idleLevel, setIdleLevel] = useState(0);
  const [sensorUsage, setSensorUsage] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingU, setLoadingU] = useState(true);
  // RECIEVE DATES
  const [startDateRD, setStartDateRD] = useState(startDate.toISO());
  const [endDateRD, setEndDateRD] = useState(endDate ? endDate.endOf('day').toISO() : null);
  // USAGE DATES
  const [startDateU, setStartDateU] = useState(startDate.toISO());
  const [endDateU, setEndDateU] = useState(endDate ? endDate.endOf('day').toISO() : null);
  const [startTimeU, setStartTimeU] = useState((secondsToTime(startTime / 1000)))
  const [endTimeU, setEndTimeU] = useState(DateTime.fromFormat((secondsToTime(endTime / 1000)), "hh:mm:ss") > DateTime.local().toISO() ? DateTime.local().toFormat("hh:mm:ss") : secondsToTime(endTime / 1000))
  
  const [currentIntervalType, setCurrentIntervalType] = useState('hours');
  const [shiftMarkers, setShiftMarkers] = useState();
  const [empty, setEmpty] = useState(false)

  const [{response, isLoading, error}, doFetchData] = useFetch(`/devices/sensors/${currentSensor.id}/received_data?from=${startDateRD}&to=${endDateRD}`);

  const [{response: responsePower, isLoading: powerIsLoading, error: errorPower}, doFetchPower] = useFetch(`/directories/equipment_items/${currentItem.id}/power_usage?from=${startDateRD}&to=${endDateRD}`);
  const [{response: resposneUsage, isLoading: usageIsLoading, error: errorUsage}, doFetchUsage] = useFetch(`/directories/ng_equipment_items_data/period_usage_data?from=${startDateRD}&to=${endDateRD}&equipment_item_ids[]=${currentItem.id}`);


  useEffect(() => {
    if(!currentUserState || !currentUserState.currentSite) {
      return
    }

    if(!endDate) { return }

    setStartDateRD(DateTime.fromMillis(startDate.ts + startTime).toISO());
    if (startDate && endDate && endDate.toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
      if(startTime > endTime) {
        setEndDateRD(DateTime.fromMillis(endDate.endOf('day').ts + endTime).toISO());
      } else {
        setEndDateRD(DateTime.fromMillis(endDate.startOf('day').ts + endTime).toISO());
      }
    } else {
      if(startTime > endTime) {
        setEndDateRD(DateTime.fromMillis(DateTime.local().endOf('day').ts + endTime).toISO());
      } else {
        setEndDateRD(DateTime.fromMillis(DateTime.local().startOf('day').ts + endTime).toISO());
      }
    }
    
    setStartDateU(startDate.toISO())
    if (startDate && endDate && endDate.toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
      if(startTime > endTime) {
        setEndDateU(endDate.toISO())
      } else {
        setEndDateU(endDate.toISO())
      }  
    } else {
      if(startTime > endTime) {
        setEndDateU(endDate.toISO())
      } else {
        setEndDateU(endDate.toISO())
      } 
    }

    setIdleLevel(currentSensor.attributes.idle_level)

    setLoading(true);

    if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.sensor_type.data_key_name === 'i' || currentSensor.attributes.sensor_type.data_key_name === 'rp') {
      doFetchUsage();
      doFetchData();
      setLoadingU(true)
    } else {
      setEmpty(true)
    }

    if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.power_factor) {
      doFetchPower()
      setLoadingPower(true)
    } 

    if(currentSensor.attributes.sensor_type.data_key_name === 'rp' || currentSensor.attributes.power_factor) {
      doFetchPower()
      setLoadingPower(true)
    } 
    

    if (startDate && endDate) {
      setCurrentIntervalType(calculateIntervalType(startDate.ts, endDate.ts));
    } else {
      setCurrentIntervalType(calculateIntervalType(Math.floor((DateTime.local().ts / (1000 * 60 * 60 * 24))) * (1000 * 60 * 60 * 24), DateTime.local().ts));
    }
  },[endDate, currentUserState])

  useEffect(() => {
    if(!resposneUsage) {
      return
    }
    let worked = 0;
    let stopped = 0;
    let turned_off = 0;
    resposneUsage.data.map(it => {
      worked = worked + it.worked
      stopped = stopped + it.stopped
      turned_off = turned_off + it.turned_off
    })
    setSensorUsage({worked: worked, stopped: stopped, turned_off: turned_off})
  },[resposneUsage])

  useEffect(() => {
    if(!responsePower) {
      return
    }
    setPower(responsePower)
    buildPower(DateTime.fromISO(startDateRD).ts, DateTime.fromISO(endDateRD).ts, responsePower.data.hourly_data)
  },[responsePower])

  useEffect(() => {
    if(!shiftDepths) {
      setShiftMarkers({                
        startValue: startTime,
        endValue: endTime,                
        color:"#E9F1F7"                
      })
      return
    }
    let res = []
    shiftDepths.map(d => {
      // Конвертируем время организации во время клиента для отрисовки на графике
      const sd = DateTime.fromMillis(d[0]).toISO({ includeOffset: false })
      const ed = DateTime.fromMillis(d[1]).toISO({ includeOffset: false })

      res.push({                
        startValue: new Date(sd),
        endValue: new Date(ed),                
        color:"#E9F1F7"                
      })
    })
    setShiftMarkers(res)
  },[shiftDepths])

  useEffect(() => {
    if (!response) {
      return;
    }
    setReset(false)
    setResponseData(response.data);
    setLoadingU(false);
    setLoading(false);

    CanvasJSReact.CanvasJS.addCultureInfo ("ru", 
    {      
      decimalSeparator: ",",// Observe ToolTip Number Format
      digitGroupSeparator: ".", // Observe axisY labels
      days: ["понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскреснье"],
      shortDays: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
      months: ["январь", "февраль", "март", "аперль", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
      shortMonths: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
      
    });

  }, [response]);

  function buildPower(sd, ed, data) {
    let total = Number()
    let total_worked = Number()
    let total_idle = Number()
    const worked_data = []
    const idle_data = []
    calculatePower(sd, ed, data)
    .map(it => {
      total = it.v ? total + it.v / 3600 : total + 0
      total_worked = it.worked ? total_worked + it.worked / 3600 : total_worked + 0
      total_idle = it.idle ? total_idle + it.idle / 3600 : total_idle + 0
      worked_data.push({
        x: new Date(it.at * 1000 + convertTimezone(it.at)),
        y: it.worked / 3600 / 1000,
        click: (e) => it.type ? onClickPowerColumn(e, it.type) : ""
      })
      idle_data.push({
        x: new Date(it.at * 1000 + convertTimezone(it.at)),
        y: it.idle / 3600 / 1000,
        click: (e) => it.type ? onClickPowerColumn(e, it.type) : ""
      })
    })
    setPowerTotals({total: total / 1000, total_worked: total_worked / 1000, total_idle: total_idle / 1000});
    setPowerGraph({worked: worked_data, idle: idle_data});
    setLoadingPower(false)
  }

  function onClickPowerColumn(e, type){
    if(type === 'day') {
      let min = DateTime.fromJSDate(e.dataPoint.x).startOf('day')
      let max = DateTime.fromJSDate(e.dataPoint.x).endOf('day')
      setCurrentIntervalType(calculateIntervalType(min.ts, max.ts));
      setStartDateU(min.toISO());
      setEndDateU(max.toISO());
      if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.sensor_type.data_key_name === 'i') {
        doFetchData();
        doFetchUsage();
      }
  
      if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.power_factor) {
        doFetchPower()
        setLoadingPower(true)
      } 
      setZoomed(true)
    } else if(type === 'month') {
      let min = DateTime.fromJSDate(e.dataPoint.x).startOf('month')
      let max = DateTime.fromJSDate(e.dataPoint.x).endOf('month')
      setCurrentIntervalType(calculateIntervalType(min.ts, max.ts));
      setStartDateU(min.toISO());
      setEndDateU(max.toISO());
      if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.sensor_type.data_key_name === 'i') {
        doFetchData();
        doFetchUsage();
      }
  
      if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.power_factor) {
        doFetchPower()
        setLoadingPower(true)
      } 
      setZoomed(true)
    }  
  }

  const calculateIntervalType = (min, max) => {
    const interval = Math.floor(max / 1000) - Math.floor(min / 1000);
    if (interval <= 3600) {
      return 'minutes';
    }
    if (interval > 3600 && interval <= 172800) {
      return 'hours';
    }
    if (interval > 172800 && interval <= 2678400) {
     return 'days';
    }
    if (interval > 2678400) {
      return 'month';
    }
  };

  const calculatePower = (min, max, power) => {
    let data = power.filter(i => 'v' in i).map(i => ({at: i.at, v: i.v ? i.v : 0, worked: i.worked ? i.worked : 0, idle: i.idle ? i.idle : 0}))
    const count = Interval.fromDateTimes(DateTime.fromMillis(Math.floor(min)), DateTime.fromMillis(Math.floor(max))).count('days')

    if (count === 0) {
      return data.filter(h => DateTime.fromMillis(min).startOf('hour').ts / 1000 <= h.at && DateTime.fromMillis(max).endOf('hour').ts / 1000 > h.at)
    }
    if (count > 0 && count <= 3) {
      return data.filter(h => DateTime.fromMillis(min).startOf('hour').ts / 1000 <= h.at && DateTime.fromMillis(max).endOf('hour').ts / 1000 > h.at)
    }
    if (count > 3 && count <= 90) {
      const groups = data.reduce((groups, item) => {
        const date = DateTime.fromSeconds(item.at).startOf('day');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
  
      const days = Object.keys(groups).map((date) => {
        const sum = groups[date].reduce((accumulator, object) => {
          return accumulator + object.v;
        }, 0);

        const worked = groups[date].reduce((accumulator, object) => {
          return accumulator + object.worked;
        }, 0);

        const idle = groups[date].reduce((accumulator, object) => {
          return accumulator + object.idle;
        }, 0);

        return {
          at: DateTime.fromISO(date).ts / 1000 + 43200,
          v: sum,
          worked: worked,
          idle: idle,
          d: groups[date],
          count: groups[date].length,
          type: 'day'
        };
      })
      return days.filter(m => m.at >= DateTime.fromMillis(min).ts / 1000 && m.at < DateTime.fromMillis(max).ts / 1000)
    }
    if (count > 90) {
      const groups = data.reduce((groups, item) => {
        const date = DateTime.fromSeconds(item.at).startOf('month');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
  
      const months = Object.keys(groups).map((date) => {
        const sum = groups[date].reduce((accumulator, object) => {
          return accumulator + object.v;
        }, 0);

        const worked = groups[date].reduce((accumulator, object) => {
          return accumulator + object.worked;
        }, 0);

        const idle = groups[date].reduce((accumulator, object) => {
          return accumulator + object.idle;
        }, 0);

        return {
          at: DateTime.fromISO(date).ts / 1000 + 43200,
          v: sum,
          worked: worked,
          idle: idle,
          d: groups[date],
          count: groups[date].length,
          type: 'month'
        };
      })
      return months.filter(m => m.at >= startDate.ts / 1000 && m.at < endDate.ts / 1000)
    }
  }

  const rangeChanged = (attr) => {
    if (attr.trigger === 'reset') {
      setStartDateRD(DateTime.fromMillis(startDate.ts + startTime).toISO());
      if (startDate && endDate && endDate.toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
        if(startTime > endTime) {
          setEndDateRD(DateTime.fromMillis(endDate.endOf('day') + endTime).toISO());
        } else {
          setEndDateRD(DateTime.fromMillis(endDate.startOf('day') + endTime).toISO());
        }
      } else {
        if(startTime > endTime) {
          setEndDateRD(DateTime.fromMillis(DateTime.local().endOf('day').ts + endTime).toISO());
        } else {
          setEndDateRD(DateTime.fromMillis(DateTime.local().startOf('day').ts + endTime).toISO());
        }
      }

      setStartTimeU((secondsToTime(startTime / 1000)))
      setEndTimeU((secondsToTime(endTime / 1000)))
      setStartDateU(startDate);

      if (startDate && endDate && endDate.toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
        setEndDateU(endDate.endOf('day'));
      } else {
        setEndDateU(DateTime.local().endOf('day'));
      }

      setCurrentIntervalType(calculateIntervalType(startDate.ts, endDate.ts));
      setZoomed(false);
    } else {
      setDefaultPeriod(false);
     
      const min = attr.axisX[0].viewportMinimum;
      const max = attr.axisX[0].viewportMaximum;

      const minDT = DateTime.local(new Date(min).getFullYear(), new Date(min).getMonth() + 1, new Date(min).getDate(), new Date(min).getHours(), new Date(min).getMinutes(), new Date(min).getSeconds())
      const maxDT = DateTime.local(new Date(max).getFullYear(), new Date(max).getMonth() + 1, new Date(max).getDate(), new Date(max).getHours(), new Date(max).getMinutes(), new Date(max).getSeconds())

      setStartDateU(minDT.toISO())
      setEndDateU(maxDT.toISO());
      setStartTimeU(minDT.toISO())
      setEndTimeU(maxDT.toISO())

      setStartDateRD(minDT.toISO());
      setEndDateRD(maxDT.toISO());

      setCurrentIntervalType(calculateIntervalType(minDT.ts, maxDT.ts));
      setZoomed(true);
    }

    if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.sensor_type.data_key_name === 'i' || currentSensor.attributes.sensor_type.data_key_name === 'rp') {
      doFetchData();
      doFetchUsage();
      setLoadingU(true);
    }

    if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.power_factor) {
      doFetchPower()
      setLoadingPower(true)
    } 
  };

  function handleReset() {
    setReset(true)

    setStartDateRD(DateTime.fromMillis(startDate.ts + startTime).toISO());
    if (startDate && endDate && endDate.toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
      if(startTime > endTime) {
        setEndDateRD(DateTime.fromMillis(endDate.endOf('day') + endTime).toISO());
      } else {
        setEndDateRD(DateTime.fromMillis(endDate.startOf('day') + endTime).toISO());
      }
    } else {
      if(startTime > endTime) {
        setEndDateRD(DateTime.fromMillis(DateTime.local().endOf('day').ts + endTime).toISO());
      } else {
        setEndDateRD(DateTime.fromMillis(DateTime.local().startOf('day').ts + endTime).toISO());
      }
    }

    setStartDateU(startDate.toISO());
    setEndDateU(endDate.toISO());
    setStartTimeU((secondsToTime(startTime / 1000)))
    setEndTimeU((secondsToTime(endTime / 1000)))
    setCurrentIntervalType(calculateIntervalType(startDate.ts, endDate.ts));
    if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.sensor_type.data_key_name === 'i' || currentSensor.attributes.sensor_type.data_key_name === 'rp') {
      doFetchData();
      doFetchUsage();
      setLoadingU(true)
    }

    if(currentSensor.attributes.sensor_type.data_key_name === 'ac_rms' || currentSensor.attributes.power_factor) {
      doFetchPower()
      setLoadingPower(true)
    } 
    setZoomed(false)
  }

  const getItemContent = () => {
    switch (currentSensor.attributes.sensor_type.data_key_name) {
      case 'i':
        return (
          <>
            <div className="flex items-center h-6 px-2 text-xs uppercase justify-end text-gray-500">
              <span>{tw('load_by_dc')}</span>
            </div>
            <div className="border-t">
              <div className='flex h-10 w-full items-center justify-between px-2 border-b'>
                <div className='flex flex-row gap-4 items-center'>
                </div> 
                {zoomed && <button className="text-xs font-medium px-2 rounded py-1 bg-gray-300 text-gray-800" onClick={() => handleReset()}>{t('reset')}</button>}
              </div>
              <div className="text-sm min-h-16">
              {sensorUsage ? 
                <div className='grid grid-cols-4 h-16 px-2 items-center'>
                  <div className='flex flex-col'>
                    <span className='font-normal text-gray-600'>{t('in_work')}:</span>
                    <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.worked) : <Skeleton width={100} height={16}/>}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='font-normal text-gray-600'>{t('in_idle')}:</span>
                    <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.stopped) : <Skeleton width={100} height={16}/>}</span>
                  </div>
                  <div className='flex flex-col'>
                    <span className='font-normal text-gray-600'>{t('turned_off_time')}:</span>
                    <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.turned_off) : <Skeleton width={100} height={16}/>}</span>
                  </div>
                </div> : <></> }
              </div>
            </div>
            <ChartDC
              loading={loading}
              data={responseData}
              idleLevel={idleLevel}
              shiftMarkers={shiftMarkers}
              rangeChanged={rangeChanged}
              currentIntervalType={currentIntervalType}
              reset={reset}
            />
          </>
        );
      case 'ac_rms':
        return (
          <>
            <div className="flex items-center h-6 px-2 text-xs uppercase justify-end text-gray-500">
              <span>{tw('load_by_ac')}</span>
            </div>
            <div className="border-t">
              <div className='flex h-10 w-full items-center justify-between px-2 border-b'>
                <div className='flex flex-row gap-4 items-center'>
                </div> 
                {zoomed && <button className="text-xs font-medium px-2 rounded py-1 bg-gray-300 text-gray-800" onClick={() => handleReset()}>{t('reset')}</button>}
              </div>
              <div className="text-sm">
                {sensorUsage ? 
                <div className='grid grid-cols-4 h-16 px-2 items-center'>
                  <div className='flex flex-col'>
                    <span className='font-normal text-gray-600'>{t('in_work')}:</span>
                    <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.worked) : <Skeleton width={100} height={16}/>}</span>
                    {/* <span className='font-medium text-gray-800'>{secondsToTime(sensorUsage.general.period_worked)}</span> */}
                  </div>
                  <div className='flex flex-col'>
                    <span className='font-normal text-gray-600'>{t('in_idle')}:</span>
                    <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.stopped) : <Skeleton width={100} height={16}/>}</span>
                    {/* <span className='font-medium text-gray-800'>{secondsToTime(sensorUsage.general.period_stopped)}</span> */}
                  </div>
                  <div className='flex flex-col'>
                    <span className='font-normal text-gray-600'>{t('turned_off_time')}:</span>
                    <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.turned_off) : <Skeleton width={100} height={16}/>}</span>
                  </div>
                </div> : <></> }
              </div>
            </div> 
            <ChartAC
              loading={loading}
              currentIntervalType={currentIntervalType}
              shiftMarkers={shiftMarkers}
              idleLevel={idleLevel}
              data={responseData}
              rangeChanged={rangeChanged}
              reset={reset}
            />
            {power && <ChartACPower loading={loadingPower} data={powerGraph} powerTotals={powerTotals} currentIntervalType={currentIntervalType} rangeChanged={rangeChanged} reset={reset} shiftMarkers={shiftMarkers}/>}
          </>
        );
      case 'rp':
          return (
            <>
              <div className="flex items-center h-6 px-2 text-xs uppercase justify-end text-gray-500">
                <span>{tw('load_by_rp')}</span>
              </div>
              <div className="border-t">
                <div className='flex h-10 w-full items-center justify-between px-2 border-b'>
                  <div className='flex flex-row gap-4 items-center'>
                  </div> 
                  {zoomed && <button className="text-xs font-medium px-2 rounded py-1 bg-gray-300 text-gray-800" onClick={() => handleReset()}>{t('reset')}</button>}
                </div>
                <div className="text-sm">
                  {sensorUsage ? 
                  <div className='grid grid-cols-4 h-16 px-2 items-center'>
                    <div className='flex flex-col'>
                      <span className='font-normal text-gray-600'>{t('in_work')}:</span>
                      <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.worked) : <Skeleton width={100} height={16}/>}</span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='font-normal text-gray-600'>{t('in_idle')}:</span>
                      <span className='font-medium text-gray-800'>{!loadingU ? secondsToTime(sensorUsage.stopped) : <Skeleton width={100} height={16}/>}</span>
                    </div>
                  </div> : <></> }
                </div>
              </div>
              <ChartRP
                loading={loading}
                currentIntervalType={currentIntervalType}
                shiftMarkers={shiftMarkers}
                idleLevel={idleLevel}
                data={responseData}
                rangeChanged={rangeChanged}
                reset={reset}
              />
              {power && <ChartRPPower loading={loadingPower} data={powerGraph} powerTotals={powerTotals} currentIntervalType={currentIntervalType} rangeChanged={rangeChanged} reset={reset} shiftMarkers={shiftMarkers}/>}
            </>
          );
      // case 'u':
      //   return (
      //     <ChartU
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 't':
      //   return (
      //     <Fragment>
      //       <ChartT
      //         loading={loading}
      //         responseData={responseData}
      //         baseOptions={baseOptions}
      //         timeZone={0}
      //       />
      //     </Fragment>
      //   );
      // case 'ang':
      //   return (
      //     <ChartAng
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 't_air':
      //   return (
      //     <Fragment>
      //       <ChartTAir
      //         loading={loading}
      //         responseData={responseData}
      //         baseOptions={baseOptions}
      //         timeZone={0}
      //       />
      //     </Fragment>
      //   );
      // case 'rfid_hold':
      //   return (
      //     <ChartRfidHold
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 'rfid':
      //   return (
      //     <ChartRfid
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 'gas':
      //   return (
      //     <ChartGas
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 'gas1':
      //   return (
      //     <ChartGas
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 'gas2':
      //   return (
      //     <ChartGas
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      // case 'wire':
      //   return (
      //     <ChartWire
      //       loading={loading}
      //       responseData={responseData}
      //       baseOptions={baseOptions}
      //       timeZone={0}
      //     />
      //   );
      default: 
        // break;
        return (
          <ChartUniversal
            loading={loading}
            currentIntervalType={currentIntervalType}
            shiftMarkers={shiftMarkers}
            data={responseData}
            rangeChanged={rangeChanged}
          />
        );
    }
  };

  return (
    
    empty ? <></> : getItemContent()

  )
};

export default Item;
