import Layout from "../../../components/layout";
import EquipmentEdit from "./edit";

const EquipmentEditPage = (props) => {
  const itemId = props.match?.params?.id

  return (
    <Layout
      // headerData={headerData}
      isWide={true}
    >
      <div className="pt-8">
        <EquipmentEdit currentItemId={itemId} />
      </div>
    </Layout>
  )
}

export default EquipmentEditPage;