import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from "lodash";
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/use-fetch';
import {convertTimezone, getPerfectDate, covertTimeToSeconds} from '../../utils';
import Select from '@atlaskit/select';
import {DateTime} from 'luxon';

import Layout from '../../components/layout';
import Spinner from '../../components/loader';
import {CurrentUserContext} from "../../contexts/current-user";

import IntensiveCalendar from '../../components/form/calendar/intensiveCalendar';
import DailyWorkWidget from '../../components/widgets/daily_work';
import HomeTimelines from './timelines';
import Filters from '../../components/filters';
import Skeleton from 'react-loading-skeleton';
import { equipmentJustStatuses } from '../../enums';


const HomePage = (props) => {
  const {t} = useTranslation();
  const tz = DateTime.local().toFormat('ZZ');
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [equipments, setEquipments] = useState([])
  const [equipmentIds, setEquipmentIds] = useState(null)
  const [selectedDate, setSelectedDate] = useState(DateTime.local());
  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59", duration: 86400 });

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterListDivisions, setFilterListDivisions] = useState([]);
  const [filterListTypes, setFilterListTypes] = useState([]);
  const statuses = equipmentJustStatuses.map(st => ({label: t(`${st}`), value: st}))
  
  const [dailyData, setDailyData] = useState({at: DateTime.local(), value: ''});
  const [usageDataByItem, setUsageDataByItem] = useState([])

  const [params, setParams] = useState(new URLSearchParams({}))
  const [usageParams, setUsageParams] = useState(new URLSearchParams({}))


  const [{response: usageDataResponse, isLoading: usageDataIsLoading, error: usageDataError}, doFetchUsageData] = useFetch(`/directories/ng_equipment_items_data/usage_data_by_item?${usageParams.toString()}`);
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError}, doFetchDivisions] = useFetch('/directories/divisions');
  const [{response: equipmentTypesResponse, isLoading: equipmentTypesIsLoading, error: equipmentTypesError}, doFetchEquipmentTypes] = useFetch('/directories/equipment_types');

  const [{response: responseItems, isLoading: isLoadingItems, error: errorItems}, doFetchItems] = useFetch(`/directories/equipment_items?${params.toString()}&with_sensors=true`);

  useEffect(() => {
    doFetchItems()
    doFetchDivisions()
    doFetchEquipmentTypes()
  },[])

  useEffect(() => {
    if(!responseItems) {
      return
    }
    setEquipments(responseItems.data)
    setEquipmentIds(responseItems.data.map(eq => eq.id))
  },[responseItems])

  useEffect(() => {
    if (!divisionsResponse || !equipmentTypesResponse) {
      return;
    }

    const newFilterListTypes = equipmentTypesResponse.data.sort(function(a,b) {
      const x = a.attributes.name;
      const y = b.attributes.name;
      return x < y ? -1 : x > y ? 1 : 0;
    })
    .map(item => ({label: item.attributes.name, value: item.id}));

    const newFilterListDivisions = divisionsResponse.data.sort(function(a,b) {
        const x = a.attributes.name;
        const y = b.attributes.name;
        return x < y ? -1 : x > y ? 1 : 0;
    })
    .map(item => ({label: item.attributes.name, value: item.id}));

    setFilterListTypes(newFilterListTypes);
    setFilterListDivisions(newFilterListDivisions);
  }, [divisionsResponse, equipmentTypesResponse]);

  useEffect(() => {
    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59", duration: 86400 }]
    
    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {

        const startTime = DateTime.fromISO(shift.attributes.started_at).toSeconds()
        const endTime = DateTime.fromISO(shift.attributes.ended_at).toSeconds()
        const duration = (endTime > startTime ? endTime - startTime : endTime + (86400 - startTime))

        shifts.push({
          label: shift.attributes.name, 
          value: shift.id, 
          started_at: DateTime.fromISO(shift.attributes.started_at).toFormat("HH:mm:ss"), 
          ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat("HH:mm:ss"),
          duration: duration
        })
      })
    }
    setShiftList(shifts);
  }, [currentUserState.currentSite])


  useEffect(() => {
    if (currentUserState.currentSite === null) {
      return;
    }
    if (currentUserState.currentSite.subdomain === '') {
      return;
    }
    if (equipmentIds === null) {
      return;
    }

    let newUsageParams = new URLSearchParams({})
    newUsageParams.append('from', selectedDate)
    //TODO НА СЕРВЕРЕ ОШИБКА 
    if(currentShift.started_at > currentShift.ended_at && selectedDate.startOf('day').ts === DateTime.local().startOf('day').ts) {
      newUsageParams.append('to',  selectedDate.plus({days: 1}))
    } else {
      newUsageParams.append('to', selectedDate)
    }
    newUsageParams.append('day_time_from', currentShift.started_at)
    newUsageParams.append('day_time_to', currentShift.ended_at)
    equipmentIds.map(i => {
      newUsageParams.append('equipment_item_ids[]', i)
    })

    if(equipmentIds.length > 0) {
      setUsageParams(newUsageParams)
      setLoadingUsage(true)
      doFetchUsageData();
    } else {
      setDailyData({
        "worked": 0,
        "stopped": 0,
        "turned_off": 0,
        "at": selectedDate.ts / 1000,
        "equipment_items_count": 0
      })
    }

  }, [currentUserState.currentSite, equipmentIds, selectedDate, currentShift])


  useEffect(() => {
    if (!usageDataResponse) {
      return;
    }

    setUsageDataByItem(usageDataResponse.data)

    const equipment_items_count = usageDataResponse.data.length
    const total_duration = currentShift.duration * equipment_items_count
    const total_worked = _.sum(usageDataResponse.data.map(i => i.worked))
    const total_stopped = _.sum(usageDataResponse.data.map(i => i.stopped))
    const total_turned_off = total_duration - (total_worked + total_stopped)


    setDailyData({worked: total_worked, stopped: total_stopped, turned_off: total_turned_off, equipment_items_count: equipment_items_count});
    setLoadingUsage(false);
  }, [usageDataResponse, selectedDate]);


  const headerData = {
    title: 'dashboard',
    isWide: false
  };

  const filtersData = {
    calendar: {
      type: 'simple',
      selectedDate: selectedDate,
      onChange: setSelectedDate
    },
    shifts: {
      options: shiftList,
      value: currentShift,
      onChange: setCurrentShift
    },
    filtersList: [
      {
        name: 'division',
        key: 'division_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterListDivisions
      },
      {
        name: 'equipment_type',
        key: 'equipment_type_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterListTypes
      },
      {
        name: 'status',
        key: 'statuses[]',
        type: 'select',
        option_search: false,
        options: statuses
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })
    setParams(newParams)
    setLoading(true)
    doFetchItems();

  }, [selectedFilters])

  return (
    <Layout
      headerData={headerData}
    >
      <div className="mt-6">
        <Filters filtersData={filtersData}/>
        <div className="mt-8">
          <div className="flex items-center w-full h-full">
            <div className='flex flex-col relative border rounded-sm px-4 pt-4 pb-6 w-full h-full'>
              <div className="font-medium mb-6">{t('widgets:daily_work_header')}: {selectedDate.isValid && selectedDate.toFormat("dd MMMM yyyy")} ({currentShift.label})</div>
              <DailyWorkWidget loading={usageDataIsLoading} worked={dailyData.worked} stopped={dailyData.stopped} turned_off={dailyData.turned_off} count={dailyData.equipment_items_count}/>
            </div>
          </div>
        </div>
      </div>
      <HomeTimelines loading={isLoadingItems} equipments={equipments} selectedDate={selectedDate} currentShift={currentShift}/>
    </Layout>
  );
};

export default HomePage;