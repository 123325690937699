import React, {useState, useEffect, Fragment} from 'react';
import { useTranslation } from 'react-i18next';

import useFetch from "../../../../hooks/use-fetch";
import useMassFetch from '../../../../hooks/use-mass-fetch';
import {convertTime} from "../../../../utils";
import CanvasJSReact from '../../../../assets/canvasjs.react';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
// const timeZone = 14400000;
const timeZone = 0;
const AcDebug = ({ sensors, filteredDate }) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sensorsData, setSensorsData] = useState([]);
  const [urls, setUrls] = useState([]);
  const [startDateString, setStartDateString] = useState('');
  const [startTime, setStartTime] = useState('00:00:00');
  const [endDateString, setEndDateString] = useState('');
  const [endTime, setEndTime] = useState(`${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`);

  const [{response: sensorsDataResponse, isLoading: sensorsDataIsLoading, error: sensorsDataError}, doMassFetchSensors] = useMassFetch(urls);


  useEffect(() => {
    const todayDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
    const startDate = `${new Date(filteredDate[0]).getFullYear()}-${new Date(filteredDate[0]).getMonth() + 1}-${new Date(filteredDate[0]).getDate()}`
    const endDate = `${new Date(filteredDate[1]).getFullYear()}-${new Date(filteredDate[1]).getMonth() + 1}-${new Date(filteredDate[1]).getDate()}`
    
    setStartDateString(`${startDate}T${startTime}.000+03:00`);
    
    if (endDate === todayDate) {
      setEndDateString(`${new Date(filteredDate[1]).getFullYear()}-${new Date(filteredDate[1]).getMonth() + 1}-${new Date(filteredDate[1]).getDate()}T${endTime}.999+03:00`);
    } else {
      setEndDateString(`${new Date(filteredDate[1]).getFullYear()}-${new Date(filteredDate[1]).getMonth() + 1}-${new Date(filteredDate[1]).getDate()}T23:59:59.999+03:00`);
    }
    setLoading(true);
  },[filteredDate, endTime])

  useEffect(()=>{
    if(sensors.length === 0) {
      return
    }
    if(startDateString == "" || endDateString == "") {
      return
    }
    const u = []
    sensors[0].forEach(s => {
      u.push(`/devices/sensors/${s.id}/received_data?from=${startDateString}&to=${endDateString}&capacity=raw`)
    });
    setUrls(u);
    doMassFetchSensors();
  },[sensors, startDateString, endDateString])


  


  useEffect(() => {
    if(!sensorsDataResponse) {
      return
    }
    const bigData = []
    sensorsDataResponse.map(sensor => {
      if (sensor.data.attributes.sensor_type.data_key_name == "ac_max") {
        sensor.data.attributes.data.map(value => {
          bigData.push({x: new Date(value.at + 250).toLocaleTimeString(), y: value.v})
        })
      } else if(sensor.data.attributes.sensor_type.data_key_name == "ac_min") {
        sensor.data.attributes.data.map(value => {
          bigData.push({x: new Date(value.at + 750), y: value.v})
        })
      } else {
        sensor.data.attributes.data.map(value => {
          bigData.push({x: new Date(value.at), y: value.v}, {x: new Date(value.at + 500), y: value.v})
        })
      }
    })
    const big = bigData.sort((a,b)=>a.x - b.x);
    
    setSensorsData(big)
    setLoading(false)
  },[sensorsDataResponse])


  const baseOptions = {
    animationEnabled: false,
    zoomEnabled: true,
    height: 200,
    culture:  "ru",
    axisX:{
      lineColor: '#E7ECF8',
      intervalType: 'millisecond',
      valueFormatString: 'HH:mm:ss',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontSize: 10,
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontSize: 10,
    }
  };

  const options = {
    data: [{
      xValueFormatString: "HH:mm:ss",
      type: "spline",
      // type: "area",
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: sensorsData
    }]
  };

  return (
    <div className="p-4 border rounded h-8">
      <CanvasJSChart options = {{...baseOptions, ...options}}/>
    </div>
  )
};

export default AcDebug;
