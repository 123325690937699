import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../../../components/form/table';
import { PencilAltIcon, TrashIcon, LinkIcon } from '@heroicons/react/outline';
import Spinner from "../../../../components/loader";
import {getPerfectDate} from "../../../../utils";

const SensorsTable = ({ tableData, onItemEdit, onItemDelete }) => {
  const {t} = useTranslation();


  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        name: tableItem.attributes.name,
        short_name: tableItem.attributes.short_name,
        long_name: tableItem.attributes.long_name,
        data_key_name: tableItem.attributes.data_key_name,
        chart_type: tableItem.attributes.chart_type
      }
    )
  });

  const columns = [
    {
      label: `${t('name')}`,
      prop: "name",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
    },
    {
      label: `${t('long_name')}`,
      prop: "long_name",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
    },
    {
      label: `${t('short_name')}`,
      prop: "short_name",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
    },
    {
      label: `${t('data_key_name')}`,
      prop: "data_key_name",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
    },
    {
      label: `${t('chart_type')}`,
      prop: "chart_type",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "invisible w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemEdit(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    },
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );
};

export default SensorsTable;
