import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import {HiChevronDown, HiChevronUp, HiCheck} from 'react-icons/hi';

const AnalyticTypesInFilter = ({items}) => {
  const {value, onChange} = items;
  const [selectedType, setSelectedType] = useState(value)

  function handleChange(value) {
    setSelectedType(value)
    onChange(value)
  }

  return (
    <Listbox value={selectedType} onChange={(value) => handleChange(value)}>
      {({ open }) => (
        <div className='relative'>
          <Listbox.Button className={`relative w-full text-sm cursor-default rounded-sm ring ring-gray-200 bg-white py-1.5 pl-3 pr-10 text-left ${open ? "ring-blue-300" : ""}`}>
            <span className={`block truncate ${open ? "text-blue-600" : "text-gray-800"}`}>{selectedType.label}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              {open ? <HiChevronUp className="h-5 w-5 text-blue-600" aria-hidden="true"/> : <HiChevronDown className="h-5 w-5 text-gray-800" aria-hidden="true"/>}
            </span>
          </Listbox.Button>
          {open && <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
             <Listbox.Options className="absolute left-0 z-10 mt-3 text-sm bg-white max-w-min min-w-full transform">
              <div className="overflow-hidden rounded-sm shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative flex flex-col py-2 px-2 ">
                  {items.options.map((type, typeIdx) => (
                    <Listbox.Option
                      key={typeIdx}
                      className={({ active }) => `relative cursor-default select-none py-2 px-4 rounded-sm hover:bg-blue-200 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'}`}
                      value={type}
                    >
                      <span className={`block truncate ${type.value === selectedType.value ? 'font-medium' : 'font-normal'}`}>
                        {type.label}
                      </span>
                    </Listbox.Option>
                  ))}
                </div>
              </div>
            </Listbox.Options>
          </Transition>}
        </div>
        )}
    </Listbox>
  )
}

export default AnalyticTypesInFilter;