import React, {useState, useEffect, Fragment, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {CurrentUserContext} from "../../../../../../contexts/current-user";
import {DateTime, Info} from 'luxon';

import useFetch from "../../../../../../hooks/use-fetch";
import {convertTime, secondsToTime} from "../../../../../../utils";
import ChartDC from "../../../../../../components/widgets/charts/interaction/chartDC";
import ChartAC from "../../../../../../components/widgets/charts/interaction/chartAC";
import CanvasJSReact from '../../../../../../assets/canvasjs.react';
import ChartUniversal from '../../../../../../components/charts/chartUniversal';



const DeviceDataItem = ({ currentItem, currentSensor, filteredDate}) => {
  const {t} = useTranslation();
  const [defaultPeriod, setDefaultPeriod] = useState(true);
  const [zoomed, setZoomed] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [idleLevel, setIdleLevel] = useState(0);
  const [usage, setUsage] = useState()
  const [loading, setLoading] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState([DateTime.fromSQL(currentItem.start), DateTime.fromSQL(currentItem.finish)])
  const [currentIntervalType, setCurrentIntervalType] = useState('hours');

  const [{response, isLoading, error}, doFetchData] = useFetch(`/devices/sensors/${currentSensor.id}/received_data?from=${selectedPeriod[0]}&to=${selectedPeriod[1]}`);
  const [{response: responseUsage, isLoading: usageIsLoading, error: errorUsage}, doFetchUsage] = useFetch(`/directories/ng_equipment_items_data/period_usage_data?from=${selectedPeriod[0]}&to=${selectedPeriod[1]}&equipment_item_ids[]=${currentItem.equipment_item_id}`);
  const [{response: sensorResponse}, doFetchSensor] = useFetch(`/devices/sensors/${currentSensor.id}`);

  useEffect(() => {
    setLoading(true);
    doFetchSensor()
    doFetchData();
    doFetchUsage();
  }, [currentSensor]);

  useEffect(() => {
    if (!response || !sensorResponse || !responseUsage) {
      return;
    }
    setIdleLevel(sensorResponse.data.attributes.idle_level)
    setResponseData(response.data);
    setUsage(responseUsage.data[0])
    setLoading(false);

    CanvasJSReact.CanvasJS.addCultureInfo ("ru", 
    {      
      decimalSeparator: ",",// Observe ToolTip Number Format
      digitGroupSeparator: ".", // Observe axisY labels
      days: ["понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскреснье"],
      shortDays: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
      months: ["январь", "февраль", "март", "аперль", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
      shortMonths: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
      
    });
    setCurrentIntervalType(calculateIntervalType(DateTime.fromISO(selectedPeriod[0]).ts, DateTime.fromISO(selectedPeriod[1]).ts));
  }, [response, sensorResponse, responseUsage]);

  const calculateIntervalType = (min, max) => {
    const interval = Math.floor(max / 1000) - Math.floor(min / 1000);
    if (interval <= 3600) {
      return 'minutes';
    }
    if (interval > 3600 && interval <= 172800) {
      return 'hours';
    }
    if (interval > 172800 && interval <= 2678400) {
      return 'days';
    }
    if (interval > 2678400) {
      return 'month';
    }
  };

  const rangeChanged = (attr) => {
    if (attr.trigger === 'reset') {
      setSelectedPeriod([DateTime.fromSQL(currentItem.start, { setZone: false }), DateTime.fromSQL(currentItem.finish, { setZone: false })]);
      setCurrentIntervalType(calculateIntervalType(Math.floor(DateTime.fromSQL(currentItem.start, { setZone: false }).ts), Math.floor(DateTime.fromSQL(currentItem.finish, { setZone: false }).ts)));
      doFetchData()
      doFetchUsage()
      setZoomed(false);
    } else {
      setDefaultPeriod(false);
      const min = attr.axisX[0].viewportMinimum;
      const max = attr.axisX[0].viewportMaximum;

      const minDT = DateTime.local(new Date(min).getFullYear(), new Date(min).getMonth() + 1, new Date(min).getDate(), new Date(min).getHours(), new Date(min).getMinutes(), new Date(min).getSeconds())
      const maxDT = DateTime.local(new Date(max).getFullYear(), new Date(max).getMonth() + 1, new Date(max).getDate(), new Date(max).getHours(), new Date(max).getMinutes(), new Date(max).getSeconds())


      setSelectedPeriod([minDT, maxDT]);
      setCurrentIntervalType(calculateIntervalType(minDT.ts, maxDT.ts));
      setZoomed(true);
      doFetchData()
      doFetchUsage()
    }
  };

  const getItemContent = () => {
    switch (currentSensor.data_key_name) {
      case 'i':
        return (
          <div className='border-b'>
            {usage ? 
            <div className='grid grid-cols-4 h-16 px-12 text-sm border-b items-center bg-gray-100'>
              <div className='flex flex-col'>
                <span className='font-normal text-gray-600'>{t('duration')}:</span>
                <span className='font-medium text-gray-800'>{secondsToTime(usage.worked + usage.stopped)}</span>
              </div>
              <div className='flex flex-col'>
                <span className='font-normal text-gray-600'>{t('in_work')}:</span>
                <span className='font-medium text-gray-800'>{secondsToTime(usage.worked)}</span>
              </div>
              <div className='flex flex-col'>
                <span className='font-normal text-gray-600'>{t('in_idle')}:</span>
                <span className='font-medium text-gray-800'>{secondsToTime(usage.stopped)}</span>
              </div>
            </div> : <></> }
            <div className='pl-10 pr-2 pt-4'>
              <ChartDC
                loading={loading}
                responseData={responseData}
                baseOptions={baseOptions}
                timeZone={DateTime.local().offset * 60000}
              />
            </div>
          </div>
        );

      case 'ac_rms':
        return (
          <div className='border-b'>
            {usage ? 
            <div className='grid grid-cols-4 h-16 px-12 text-sm border-b items-center bg-gray-100'>
              <div className='flex flex-col'>
                <span className='font-normal text-gray-600'>{t('duration')}:</span>
                <span className='font-medium text-gray-800'>{secondsToTime(usage.worked + usage.stopped)}</span>
              </div>
              <div className='flex flex-col'>
                <span className='font-normal text-gray-600'>{t('in_work')}:</span>
                <span className='font-medium text-gray-800'>{secondsToTime(usage.worked)}</span>
              </div>
              <div className='flex flex-col'>
                <span className='font-normal text-gray-600'>{t('in_idle')}:</span>
                <span className='font-medium text-gray-800'>{secondsToTime(usage.stopped)}</span>
              </div>
            </div> : <></> }
            <div className='pl-10 pr-2 pt-4'>
              <ChartAC
                loading={loading}
                responseData={responseData}
                baseOptions={baseOptions}
                timeZone={DateTime.local().offset * 60000}
              />
            </div>
          </div>
        );
      case 'rfid_hold':
        break;
      case 'rfid':
        break;
      default:
        return (
          <ChartUniversal
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
    }
  };

  const baseOptions = {
    animationEnabled: false,
    zoomEnabled: true,
    height: 200,
    culture: DateTime.local().locale,
    backgroundColor: "#F9FAFB",
    axisX:{
      lineColor: '#E7ECF8',
      intervalType: currentIntervalType,
      valueFormatString: currentIntervalType === 'minutes' ? 'HH:mm:ss' : currentIntervalType === 'hours' ? 'HH:mm' : currentIntervalType === 'days' ? 'DD MMM' : 'MMM YYYY',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      stripLines:[
        {                
          startValue:0,
          endValue: idleLevel,                
          color:"#d8d8d8"                
        }
        ],
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontFamily: "helvetica, arial",
      labelFontSize: 10,
      labelPlacement:"inside"
    },
    rangeChanged: rangeChanged
  };

  return (
    <div className="bg-gray-50">
      {getItemContent()}
    </div>
  )
};

export default DeviceDataItem;
