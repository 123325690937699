import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import useMassFetch from '../../../../../hooks/use-mass-fetch';
import DailyWorkWidget from '../../../../../components/widgets/daily_work';
import {DateTime, Duration} from 'luxon';
import Item from '../item';
import {covertTimeToSeconds} from '../../../../../utils'
import ClassEngineering from '../../../../../components/vendors/classengineering';

const ReportEquipmentByDay = ({data, urls, sensors, startDate, endDate, currentItem, convertedShift}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState();
  const [vendorCE, setVendorCE] = useState(false);
  const [commonSensors, setCommonSensors] = useState();
  const [vendorCESensors, setVendorCESensors] = useState();
  const [startTime, setStartTime] = useState(covertTimeToSeconds(convertedShift.start) * 1000)
  const [endTime, setEndTime] = useState(covertTimeToSeconds(convertedShift.end) * 1000)
  const [periodData, setPeriodData] = useState({worked: 0, stopped: 0, turned_off: 0, count: 1})
  const [{response: sensorsDataResponse, isLoading: sensorsDataIsLoading, error: sensorsDataError}, doMassFetchSensors] = useMassFetch(urls);

  useEffect(() => {
    setStartTime(covertTimeToSeconds(convertedShift.start) * 1000)
    setEndTime(covertTimeToSeconds(convertedShift.end) * 1000)
  },[convertedShift])


  useEffect(() => {
    if(!endDate) { return }
    let worked = 0;
    let stopped = 0;
    let turned_off = 0;

    let days = data.length

    data.map(it => {
      worked = worked + it.worked
      stopped = stopped + it.stopped
      turned_off = turned_off + it.turned_off
    })

    setPeriodData({
      worked: worked, 
      stopped: stopped, 
      turned_off: turned_off, 
      count: days
    })
  },[data])

  useEffect(() => {
    let cs = []
    let vCE = []
    sensors.map(s => {
      if(s.attributes.sensor_type.data_key_name === 'mb_count') {
        setVendorCE(true)
        vCE.push(s)
      } else {
        cs.push(s)
      }
    });
    setCommonSensors(cs)
    setVendorCESensors(vCE)
    setLoading(false)
  },[sensors])

  return (
    <div className="flex flex-col space-y-4 pb-10">
      <div className="">
        <div className="flex items-center w-full h-full">
          <div className='flex flex-col relative border border-gray-200 rounded-sm px-4 py-4 w-full h-full'>
            <div className="font-medium mb-6">{t('data_by_period')}: {startDate.toFormat('dd.MM.yyyy')} - {endDate ? endDate.toFormat('dd.MM.yyyy') : ""} / {Duration.fromMillis(startTime).toFormat('hh:mm:ss')} - {Duration.fromMillis(endTime).toFormat('hh:mm:ss')}</div>
            <DailyWorkWidget worked={periodData.worked} stopped={periodData.stopped} turned_off={periodData.turned_off} count={periodData.count}/>
          </div>
        </div>
      </div>
      {loading ? <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div> : 

      startDate && endDate && data.filter(d => DateTime.fromFormat(d.at, "yyyy-MM-dd").ts >= startDate.ts && DateTime.fromFormat(d.at, "yyyy-MM-dd").ts <= endDate.ts).reverse().map(item => {  
        if(item.turned_off !== null && (item.worked !== 0 || item.stopped !== 0)) {
          return (
            <LazyLoad key={item.at} height={400} offset={200} once>
              <div className="p-4 border rounded">
              <div className='capitalize'>{DateTime.fromFormat(item.at, "yyyy-MM-dd").weekdayLong}, {DateTime.fromFormat(item.at, "yyyy-MM-dd").toFormat("dd.MM.yyyy")}</div>
              {vendorCE &&  
                <ClassEngineering
                  sensor={vendorCESensors[0]}
                  startDate={DateTime.fromFormat(item.at, "yyyy-MM-dd").startOf('day')}
                  endDate={DateTime.fromFormat(item.at, "yyyy-MM-dd").endOf('day')}
                  startTime={startTime}
                  endTime={endTime}
                  byDays={true}
                />
              }
              {commonSensors.map(s => (
                <Item key={s.id} currentSensor={s} startDate={DateTime.fromFormat(item.at, "yyyy-MM-dd").startOf('day')} endDate={DateTime.fromFormat(item.at, "yyyy-MM-dd").endOf('day')} startTime={startTime} endTime={endTime} currentItem={currentItem}/>
              ))} 
              </div>
            </LazyLoad>
          )
        }
      })}

    </div>
  )
}

export default ReportEquipmentByDay;