import React, { useState, useEffect } from 'react';
import { FolderIcon, PencilAltIcon, PlusCircleIcon, TrashIcon} from '@heroicons/react/outline';
import { LogoutIcon } from '@heroicons/react/solid';
import { useTranslation } from "react-i18next";

import Spinner from "../../../../components/loader";


const DirectoryHierarchicTable = ({ tableData, onDelete, onEdit, onAddChild, isLoading, errors }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      <tr key={tableItem.id} className="hover:bg-gray-50">
        {tableItem.attributes.depth === 0 ? 
          <td className="px-4 py-4 whitespace-nowrap"><FolderIcon className="w-4 h-4"/></td> :
          <td className="px-4 py-4 whitespace-nowrap"><LogoutIcon className={`ml-${tableItem.attributes.depth} w-4 h-4`}/></td>
        }

        <td className="px-6 py-4 whitespace-wrap">
          <div className="text-sm font-medium text-gray-900">
            {tableItem.attributes?.name}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">
            {tableItem.attributes?.abbr}
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">
            {tableItem.attributes?.description}
          </div>
        </td>
        <td 
          className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end"
        >
          <button 
            onClick={() => onAddChild(tableItem.id)} 
            className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded-sm mr-4">
            <PlusCircleIcon className="w-4 h-4"/>
          </button>
          <button 
            onClick={() => onEdit(tableItem.id)} 
            className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded-sm mr-4">
            <PencilAltIcon className="w-4 h-4"/>
          </button>
          <button onClick={() => onDelete(tableItem.id)} className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm"><TrashIcon className="w-4 h-4"/></button>
        </td>
      </tr>
    )
  });

  return (
    <div className="flex flex-col mt-4">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 rounded-sm mb-10">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="w-4">
                    <span className="sr-only">Действия</span>
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('name')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('abbreviation')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('description')}
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">{t('actions')}</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td colSpan="7">
                      <Spinner/>
                    </td>
                  </tr>
                ) : errors ? (
                  <tr>
                    <td colSpan="7">
                      <div className="text-center text-xs py-8 text-gray-600">{t('loading_error')}</div>
                    </td>
                  </tr>
                ) : tableData.length === 0 ? (
                  <tr>
                    <td colSpan="7">
                      <div className="text-center text-xs py-8 text-gray-600">{t('no_data')}</div>
                    </td>
                  </tr>
                ) : items}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectoryHierarchicTable;
