import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import DevicesTable from './table';
import DeviceForm from './form';

const EquipmentDevice = ({ currentItem }) => {
  const {t} = useTranslation();
  const [devicesData, setDevicesData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sensorId, setSensorId] = useState('');


  const [{response, isLoading, error}, doFetchDevices] = useFetch(`/directories/equipment_items/${currentItem.id}/sensors`);
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchDelete] = useFetch(`/directories/equipment_items/${currentItem.id}/sensors/${sensorId}`);

  useEffect(() => {
    doFetchDevices()
  }, []);

  useEffect(() => {
    if(!response) {
      return;
    }
    setDevicesData(response.data)
  },[response]);


  function closeModal() {
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    openModal();
  };

  const handleDelete = (id) => {
    setSensorId(id);
    doFetchDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    if(deleteIsLoading) {
      return;
    }
    setSensorId(null);
    reloadData();
  }, [deleteIsLoading]);

  const reloadData = () => {
    doFetchDevices();
  };


  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('equipment_devices')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('equipment_devices_text')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('connect_device')}
        </button>
      </div>
      <DevicesTable
        tableData={devicesData}
        onItemDelete={handleDelete}
      />
      <DeviceForm 
        currentItem={currentItem}
        closeModal={closeModal}
        isOpen={isOpen}
        reloadData = {reloadData}
      />
    </div>
  );
};

export default EquipmentDevice;
