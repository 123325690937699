import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import PeriodWorkWidget from '../../../../../components/widgets/period_work';
import { DateTime } from "luxon";
import PeriodPersonWorkWidget from "../../../../../components/widgets/period_person_work";

const TotalByPeriod = ({data, startDate, endDate, duration}) => {
  const {t} = useTranslation();
  const [total, setTotal] = useState();

  useEffect(() => {
    if(!data || !startDate || !endDate || !startDate?.ts || !endDate?.ts) {
      return
    }

    let worked = 0
    let stopped = 0
    let no_card = 0
    let count = 0
    
    data.map(it => {
      if(startDate.ts <= DateTime.fromSQL(it.at).ts && DateTime.fromSQL(it.at).ts < endDate.ts) {
        worked = worked + it.worked
        stopped = stopped + it.stopped
        no_card = no_card + it.no_card
        count = count + 1
      }
    })
    setTotal({worked: worked, stopped: stopped, no_card: no_card, count: count})
  },[data, startDate, endDate])

  console.log(total)
  
  return (
    <>
      <div className="">
        <div className="flex items-center w-full h-full mt-4">
          <div className='flex flex-col relative border border-gray-200 rounded-sm px-4 py-4 w-full h-full'>
            <div className="font-medium text-sm mb-6">{t('data_by_period')}: {startDate.toFormat('dd.MM.yyyy')} - {endDate ? endDate.toFormat('dd.MM.yyyy') : ""}</div>
            {total && <PeriodPersonWorkWidget worked={total.worked} stopped={total.stopped} no_card={total.no_card} count={total.count} duration={duration}/>}
          </div>
        </div>
      </div>
    </>
  )
}

export default TotalByPeriod;