import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/canvasjs.react';
import { getPerfectTime, convertTimezone } from '../../../utils';
import { useTranslation } from 'react-i18next';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartRPDay = ({data}) =>  {
  const {t} = useTranslation(['widgets']);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const itemData = [];
    data.forEach(it => {
      itemData.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: it.v ? (it.v > 0 ? it.v / 1000 : 0) : 0  
      })
    });

    setChartData(itemData);
    setLoading(false);
  }, [data]);

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    culture:  "ru",
    toolTip: {
			shared: true,
      fontFamily: "helvetica, arial",
      cornerRadius: 2,
      borderThickness: 0,
			contentFormatter: function (e) {
				var content = " ";
				for (var i = 0; i < e.entries.length; i++) {
          content += "<div class='p-2'>";
          content += getPerfectTime(e.entries[i].dataPoint.x);
          content += "<br/>";
					content += t('rp') + " " + "<strong>" + e.entries[i].dataPoint.y.toFixed(2) + "</strong>";
          content += "</div>";
				}
				return content;
			}
		},
    axisX:{
      lineColor: '#E7ECF8',
      intervalType: "hours",
      valueFormatString: 'HH:mm',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      labelPlacement:"inside",
    },
  };

  const options = {
    data: [{
      yValueFormatString: "####0.##",
      indexLabel: "{y}",
      indexLabelFontFamily: "helvetica, arial",
      indexLabelFontColor: "#fb8500",
      indexLabelPlacement: "inside",
      xValueFormatString: "HH:mm",
      type: "column",
      color: '#424874',
      fillOpacity: 1,
      lineColor: '#424874',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartData
    }]
  };



  return (
    <div className="my-4 border-t">
      <div className="flex items-center h-10">
        <span>{t('rp')}</span>
      </div>
      <div className="text-sm">
        <CanvasJSChart options = {{...baseOptions, ...options}}/>
      </div>
    </div>
  );
}

export default ChartRPDay;