import React, {Component} from 'react';
import Signin from './signin';
import Signup from './signup';
import Logo from '../../components/logo';

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../../locales/en/translation.json";
import translationRU from "../../locales/ru/translation.json";
import widgetsEN from "../../locales/en/widgets.json";
import widgetsRU from "../../locales/ru/widgets.json";

const resources = {
  en: {
    translation: translationEN,
    widgets: widgetsEN
  },
  ru: {
    translation: translationRU,
    widgets: widgetsRU
  }
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  fallbackLng: "en"
});

const lang = i18n.language;


class EnterPage extends Component {
  state = {
    currentForm: 'signin'
  };

  onSigninClick = () => {
    this.setState({currentForm: 'signin'})
  };

  onSignupClick = () => {
    this.setState({currentForm: 'signup'})
  };

  onRecoveryClick = () => {
    this.setState({currentForm: 'recovery'})
  };

  render() {
    return (
      <div className="grid min-h-screen place-items-center">
        <div className="w-full p-12 bg-white md:w-full lg:w-5/12 xl:w-4/12">
          <div className="flex justify-center">
            <Logo lang={lang}/>
          </div>
          {this.state.currentForm === 'signin' ? (
            <Signin
              onSignupClick={this.onSignupClick}
              onRecoveryClick={this.onRecoveryClick}
            />
          ) : ''}
          {this.state.currentForm === 'signup' ? (
            <Signup
              onSigninClick={this.onSigninClick}
            />
          ) : ''}
        </div>
      </div>
    );
  }
}

export default EnterPage;