import React, {useEffect, useRef, useState, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from 'react-i18next';

import Layout from '../../../../components/layout';
import useFetch from '../../../../hooks/use-fetch';
import Spinner from '../../../../components/loader';

import EquipmentSettings from './settings';
import EquipmentChanges from './changes';
import EquipmentDevice from './device';
import EquipmentRelated from './related';
import EquipmentМaintenances from './maintenances';

import ErrorsBlock from '../../../../components/errors';

import { CurrentUserContext } from "../../../../contexts/current-user";
import EquipmentTracking from './tracking';

const EquipmentEdit = (props) => {
  const {t} = useTranslation();
  const [currentUserState, ] = useContext(CurrentUserContext);
  const [itemId, ] = useState(props.currentItemId ? props.currentItemId : props.match?.params?.id);
  const currentTab = useState(props.currentTab ? props.currentTab : '');
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [currentItem, setCurrentItem] = useState('');
  const [producerId, setProducerId] = useState('');
  const [producersList, setProducersList] = useState([]);
  const [modelsList, setModelsList] = useState([]);
  const [personsList, setPersonsLst] = useState([])
  const [childrens, setChildrens] = useState([]);

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/directories/equipment_items/${itemId}`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/equipment_items/${itemId}`);

  const [{response: producersResponse, isLoading: producersIsLoading, error: producersError}, doFetchProducers] = useFetch(`/directories/producers`);
  const [{response: modelsResponse, isLoading: modelsIsLoading, error: modelsError}, doFetchModels] = useFetch(`/directories/equipment_models?producer_id=${producerId}`);

  const [{response: responesPersons, isLoading: isLoadingPersons, error: errorPersons}, doFetchPersons] = useFetch(`/directories/personnels`);

  useEffect(() => {
    setLoading(true);
    if(!currentUserState.applications.tracking) {
      doFetchPersons()
    }
    doFetchItem();
    doFetchProducers();
  },[])

  useEffect(() => {
    if(!response) {
      return;
    } 
    setCurrentItem(response.data);
    setChildrens(response.data.attributes.children_ids);
    setProducerId(response.data.attributes.producer_id);
    doFetchModels()
  },[response]);

  useEffect(() => {
    if(!producersResponse) {
      return;
    }
    const options = []
    producersResponse.data.map(it => {
      options.push({label: it.attributes.name, value: it.id})
    })
    setProducersList(options);

  },[producersResponse]);

  useEffect(() => {
    if(!modelsResponse) {
      return;
    } 
    const options =[]
    modelsResponse.data.map(it => {
      options.push({label: it.attributes.name, value: it.id})
    })
    setModelsList(options);
  },[modelsResponse]);

  useEffect(() => {
    if(!responesPersons) {
      return;
    }
    const options =[{label: t('no_selected'), value: null}]
    responesPersons.data.map(it => {
      options.push({label: `${it.attributes.last_name} ${it.attributes.first_name} ${it.attributes.middle_name} `, value: it.id})
    })
    setPersonsLst(options);

  },[responesPersons]);

  useEffect(() => {
    if(!response || !modelsResponse || !producersResponse) {
      return;
    } 
    setLoading(false);
  },[response, modelsResponse, producersResponse]);
  

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    setCurrentItem(itemUpdateResponse.data);
  }, [itemUpdateResponse]);

  const onUpdate = (formData) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: formData
      }
    });
  };

  useEffect(() => {
    reloadModels()
  }, [producerId])

  const reloadModels = () => {
    doFetchModels();
  };

  const reloadCurrentItem = () => {
    doFetchItem();
  }

  const headerData = {
    title: currentItem?.attributes?.name ? `${currentItem?.attributes?.name}` : "..."
  };

  useEffect(() => {
    if(!itemUpdateError) {
      return;
    }
    setErrors(itemUpdateError.errors)
  }, [itemUpdateError])

  return (
    loading ? <Spinner/> : (
      <>
        <div className='font-medium'>{currentItem?.attributes?.name ? `${currentItem?.attributes?.name}` : "..."}</div>
        <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('main_info')}
            </Tab>
            { childrens.length > 0 ? 
              <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
                {t('related_equipment')}
              </Tab> : "" 
            }
            {currentUserState.applications.devices && <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('device')}
            </Tab> }
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('maintenances')}
            </Tab>
            {currentUserState.applications.tracking && <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('tracking')}
            </Tab>}
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('changes_log')}
            </Tab>
          </TabList>
          <TabPanel>
            <EquipmentSettings 
              currentItem = {currentItem}
              onChangeItem={setCurrentItem}
              producersList = {producersList}
              personsList = {personsList}
              modelsList = {modelsList}
              producerId = {producerId}
              setProducerId = {setProducerId}
              onUpdate = {onUpdate}
            />
            {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
          </TabPanel>
          {childrens.length > 0 ? 
            <TabPanel>
              <EquipmentRelated
                childrens = {childrens}
              />
            </TabPanel> : "" }

          {currentUserState.applications.devices && <TabPanel>
            <EquipmentDevice 
              currentItem = {currentItem}
            />
          </TabPanel> }
          <TabPanel>
            <EquipmentМaintenances 
              currentItem = {currentItem}
              reloadCurrentItem = {reloadCurrentItem}
            />
          </TabPanel>
          {currentUserState.applications.tracking && <TabPanel>
            <EquipmentTracking
              currentItem = {currentItem}
              onChangeItem={setCurrentItem}
              onUpdate = {onUpdate}
            />
          </TabPanel>}
          <TabPanel>
            <EquipmentChanges 
              currentItem = {currentItem}
              reloadCurrentItem = {reloadCurrentItem} 
            />
          </TabPanel>
        </Tabs>
      </>
    )
  );
};

export default EquipmentEdit;
