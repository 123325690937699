import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import TextField from '@atlaskit/textfield';
import Form, {Field, ErrorMessage} from '@atlaskit/form';
import { useTranslation } from 'react-i18next';


const DeviceSettings = ({ currentItem, onUpdate }) => {
  const {t} = useTranslation();
  const [name, setName] = useState(currentItem.attributes.name);
  const [serialNumber, setSerialNumber] = useState(currentItem.attributes.serial_number);
  const [initializedDate, setInitializedDate] = useState(null);

  useEffect(() => {
    setInitializedDate(currentItem.attributes.initialized_at === null ? null : new Date(DateTime.fromISO(currentItem.attributes.initialized_at).toMillis()));
  }, []);

  const handleUpdate = () => {
    onUpdate(name)
  }

  return (
    <div className="mt-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
        <Field name="name" label={t('device_name')}>
          {({ fieldProps, error }) => (
            <>
              <TextField
                autoComplete="off"
                {...fieldProps}
                onChange={(evt) => setName(evt.target.value)}
                value={name === null ? '' : name}
              />
              {error && (
                <ErrorMessage>
                  This user name is already in use, try another one.
                </ErrorMessage>
              )}
            </>
          )}
        </Field>
        <Field name="serialNumber" label={t('serial_number')} isDisabled>
          {({ fieldProps, error }) => (
            <>
              <TextField
                autoComplete="off"
                {...fieldProps}
                onChange={(evt) => setSerialNumber(evt.target.value)}
                value={serialNumber === null ? '' : serialNumber}
              />
              {error && (
                <ErrorMessage>
                  This user name is already in use, try another one.
                </ErrorMessage>
              )}
            </>
          )}
        </Field>
        <Field name="date" label={t('installation_date')} isDisabled>
          {({ fieldProps, error }) => (
            <>
              <TextField
                autoComplete="off"
                {...fieldProps}
                onChange={(evt) => setInitializedDate(evt.target.value)}
                value={initializedDate === null ? '' : initializedDate}
              />
              {error && (
                <ErrorMessage>
                  This user name is already in use, try another one.
                </ErrorMessage>
              )}
            </>
          )}
        </Field>
      </div>
      <button 
        type="button"
        onClick={() => handleUpdate()} 
        className="inline-flex mt-8 justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      >
        {t('update')}
      </button>
    </div>
  )
};

export default DeviceSettings;
