import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MultiSelectFilter from './multiselect';
import SelectFilter from './select';
import CheckboxFilter from './checkbox';
import SelectedFilters from './selected';
import FilterPopover from './popover';
import SearchInFilter from './search';
import ShiftsInFilter from './shifts';
import IntensiveCalendar from '../form/calendar/intensiveCalendar';
import SimpleCalendar from '../form/calendar/simpleDatePicker';
import MonthDatePicker from '../form/calendar/monthDatePicker';
import AnalyticTypesInFilter from './analyticTypes';


const AnalyticFilters = ({filtersData}) => {
  const {t} = useTranslation();
  const {search, calendar, shifts, analyticTypes, filtersList, selectedFilters, onChangeFilters} = filtersData;


  function buildDatePicker() {
    switch (calendar.type) {
      case 'simple': return (
        <div className='ml-4'>
          <SimpleCalendar
            selectedDate={calendar.selectedDate}
            setSelectedDate={calendar.onChange}
          />
        </div>
      )
      case 'month': return <div className='ml-4'><MonthDatePicker onChange={calendar.onChange} selectedDate={calendar.selectedDate} /></div>
      case 'range': return <>Range</>  
      case 'intensive': return (
        <div className='ml-4'>
          <IntensiveCalendar
            currentShift={calendar.currentShift}
            selectedDate={calendar.selectedDate}
            setSelectedDate={calendar.onChange}
            calendarData={calendar.calendarData}
            loadMonthData={calendar.loadMonthData}
          />
        </div>
      )
      default: <></>
    }
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full">
        <div className='flex w-1/2'>
          {!!analyticTypes && <AnalyticTypesInFilter items={analyticTypes}/>}
        </div>
        <div className='flex flex-row w-1/2 justify-end'>
          {!!search && <SearchInFilter search={search}/>}
          {!!shifts && shifts.options.length > 1 && <ShiftsInFilter shifts={shifts}/>}
          {!!calendar && buildDatePicker()}
        </div>
      </div>
    </>
  );
};

export default AnalyticFilters;
