import React from 'react';

const Spinner = () => {
  return (
    <div className="blackout">
      <div className="spinner" />
    </div>
  )
};

export default Spinner;
