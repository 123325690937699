import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import Select, {AsyncSelect} from '@atlaskit/select';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useTranslation } from "react-i18next";
import useFetch from '../../../../../../hooks/use-fetch';

import ErrorsBlock from '../../../../../../components/errors';

const DivisionForm = ({currentItem, onChangeItem, closeModal, open}) => {
  const {t} = useTranslation();
  const [divisionsOptions, setDivisionsOptions] = useState([]);

  // const [at, setAt] = useState('');
  const [divisionId, setDivisionId] = useState(currentItem.attributes.division_id);
  const [errors, setErrors] = useState([]);

  const [{response: saveResponse, isLoading: saveIsLoading, error: saveError}, doFetchItemSave] = useFetch(`/directories/equipment_items/${currentItem.id}/changes`);
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError}, doFetchDivisions] = useFetch(`/directories/divisions`);

  useEffect(() => {
    doFetchDivisions();
  }, []);


  useEffect(() => {
    if(!divisionsResponse) {
      return;
    }
    const list = []
    divisionsResponse.data.map(it => {
      list.push({label: it.attributes.name, value: it.id})
    })
    setDivisionsOptions([...divisionsOptions, ...list])
  },[divisionsResponse]);

  useEffect(() => {
    if(!saveResponse) {
      return
    }
    closeModal()
  },[saveResponse])

  const handleUpdate = (data) => {
    let date = new Date()
    onChangeItem({...currentItem, attributes: {...currentItem.attributes, division_id: data.division.value, division_name: data.division.label, division_changed_at: date}})
    doFetchItemSave({
      method: 'POST',
      data: {
        data: {
          at: new Date(),
          division_id: data.division.value || null,
        }
      }
    });
  };

  return (
    <Transition appear show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {t('change_division')}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {t('change_division_form_text')}
              </p>
            </div>
            {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
            <Form onSubmit={data => handleUpdate(data)}>
              {({ formProps }) => (
                <form {...formProps} className="text-sm">
                  <div className="text-sm">
                    <Field name="division" label={t('division')} defaultValue={divisionsOptions ? divisionsOptions.find(it => it.value == divisionId) : ""}>
                      {({ fieldProps, error, meta: { valid } }) => 
                        <Select {...fieldProps} options={divisionsOptions} isSearchable={false} placeholder={t('select_placeholder')} className="text-sm"/>
                      }
                    </Field>
                    {/* <Field name="at" label={t('at')} defaultValue={at || ""} isRequired>
                      {({ fieldProps, error, meta: { valid } }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={at}/>}
                    </Field> */}
                  </div>
                  <div className="flex justify-between mt-8">
                    <button 
                      onClick={() => closeModal()}
                      type="button" 
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      {t('cancel')}
                    </button>
                    <button 
                      // onClick={() => handleUpdate()}
                      type="submit" 
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      {t('save')}
                    </button>

                  </div>
                </form>
              )}
            </Form>
          </div>
        </Transition.Child>
      </div>
      
    </Dialog>
  </Transition>
  )
}

export default DivisionForm;