import React, { useContext, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Select from '@atlaskit/select';
import useFetch from '../../../hooks/use-fetch';
import { CurrentUserContext } from '../../../contexts/current-user';
import Layout from '../../../components/layout';
import ChangePassword from './change';


const AccountProfile = () => {
  const { t } = useTranslation();
  const [, setCurrentUserState] = useContext(CurrentUserContext);
  const [formData, setFormData] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [locale, setLocale] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState('');
  const [imageId, setImageId] = useState('');

  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error}, doFetchUser] = useFetch('/users/account', true);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchUserSave] = useFetch(`/users/account`, true);
  const [{response: imageUploadResponse, isLoading: imageUploadIsLoading, error: imageUploadError}, doFetchImageUpload] = useFetch(`/uploads`, true);

  const locales = [
    {value: "en", label: t("english")},
    {value: "ru", label: t("russian")},
  ] 

  const headerData = {
    title: 'profile_settings'
  };

  useEffect(() => {
    setFormData(new FormData());
    doFetchUser();
  }, []);



  useEffect(() => {
    if (!response) {
      return
    }
    const user = response.data.attributes;
    setFirstName(user.first_name === null ? '' : user.first_name);
    setMiddleName(user.middle_name === null ? '' : user.middle_name);
    setLastName(user.last_name === null ? '' : user.last_name);
    setEmail(user.email === null ? '' : user.email);
    setLocale(user.locale === null ? '' : locales.find(locale => locale.value === user.locale))
    setPhone(user.phone === null ? '' : user.phone);
    setOrganization(user.organization === null ? '' : user.organization);
    setImageId(user.image.id ? user.image.id : '');
    setCurrentUserState(state => ({
      ...state,
      currentUser: response.data,
    }));
  }, [response]);

  useEffect(() => {
    if (!imageUploadResponse) {
      return;
    }
    doFetchUserSave({
      method: 'PUT',
      data: {
        data: {
          image: { ...imageUploadResponse.data },
        }
      }
    });
  }, [imageUploadResponse]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    doFetchUser();
    setCurrentUserState(state => ({
      ...state,
      isLoading: true
    }));
  }, [itemUpdateResponse]);

  const onFileChoice = (value) => {
    formData.set('attachment', value);
    doFetchImageUpload({
      method: 'POST',
      data: formData
    });
  };

  const onSave = (data) => {
    doFetchUserSave({
      method: 'PUT',
      data: {
        data: {
          "email": data.email,
          "first_name": data.firstName,
          "middle_name": data.middleName,
          "last_name": data.lastName,
          "locale":  data.locale.value,
        }
      }
    });
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <Layout
      headerData={headerData}
      isWide={false}
    >
      <div className="mt-4">
        <div className="bg-white flex">
          <div className="mb-4 w-32 h-32">
            {imageId === '' ? <Avatar name={firstName} /> : (
              <img src={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${imageId}`} alt="" className="w-auto mx-auto rounded-full object-cover object-center"/>
            )}
          </div>
          <div className="ml-10 flex flex-col">
            <span className="text-gray-800 text-xs font-light mb-4">{t('avatar_upload_text')}</span>
            <label className="cursor-pointer">
              <span className="mt-2 leading-normal px-4 py-2 text-blue-900 bg-blue-100 text-xs rounded-full" >{t('avatar_upload')}</span>
              <input
                type="file"
                className="hidden"
                onChange={(evt) => onFileChoice(evt.target.files[0])}
              />
            </label>
            
          </div>
        </div>
      </div>
      <Form onSubmit={data => onSave(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1">
            <Field name="firstName" defaultValue={firstName} label={t("name")} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field name="lastName" defaultValue={lastName} label={t("surname")} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps}/>}
            </Field>
            <Field name="middleName" defaultValue={middleName} label={t("middlename")}>
              {({ fieldProps }) => <TextField {...fieldProps}/>}
            </Field>
            <Field name="locale" label={t("interface_language")} defaultValue={locale}> 
              {({ fieldProps }) => <Select {...fieldProps} options={locales} className="text-sm"/>}
            </Field>
            <Field name="email" defaultValue={email} label={t("email")} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps}/>}
            </Field>
            <div className="flex justify-between mt-8">
            <button 
                type="button" 
                onClick={openModal}
                className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("change_password")}
            </button>
            </div>
            <div className="flex justify-between mt-8">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("save")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
      <ChangePassword closeModal={closeModal} isOpen={isOpen}/>
    </Layout>
  );
};

export default AccountProfile;
