import React, { useState, useEffect, useContext, forwardRef, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/use-fetch';
import {DateTime} from 'luxon';
import Select from '@atlaskit/select';
import MonthDatePicker from '../../components/form/calendar/monthDatePicker';
import { convertToTimestamp, covertTimeToSeconds } from '../../utils';
import Layout from '../../components/layout';
import Spinner from '../../components/loader';
import {CurrentUserContext} from "../../contexts/current-user";
import ElectricityItem from './item';




const ReportElectrocityPage = (props) => {
  const tz = DateTime.local().toFormat('ZZ');
  const {t} = useTranslation();
  
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([DateTime.local().startOf('month'), DateTime.local().endOf('month')]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(DateTime.local().toMillis()));
  const [devices, setDevices] = useState([]);

  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" });
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400});

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');

  const [{response, isLoading, error}, doFetchDevices] = useFetch(`/directories/electricities?from=${startDateString}&to=${endDateString}&day_time_from=${startTime}&day_time_to=${endTime}`);

  useEffect(() => {
    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]

    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat('HH:mm:ss'), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat('HH:mm:ss')})
      })
    }
    setShiftList(shifts);
  },[])

  useEffect(() => {
    if(!selectedDate[1]) {
      return
    }

    const start = selectedDate[0].toFormat("yyyy-MM-dd")
    const end = selectedDate[1].toFormat("yyyy-MM-dd")
    
    if(convertedShift.id === null) {
      setStartTime(convertedShift.start)
      setEndTime(convertedShift.end)
    } else {
      setStartTime(convertedShift.start)
      setEndTime(convertedShift.end)
    }

    if(convertedShift.start < convertedShift.end) {
      setStartDateString(`${start}T${convertedShift.start}.000${tz}`);
      setEndDateString(`${end}T${convertedShift.end}.000${tz}`);
    } else {
      setStartDateString(`${start}T${convertedShift.start}.000${tz}`);

      let nextEndDayString = selectedDate[1].plus({day: 1}).toFormat("yyyy-MM-dd");
      setEndDateString(`${nextEndDayString}T${convertedShift.end}.000${tz}`);
    }
    
    setLoading(true);
    doFetchDevices();

  },[selectedDate, convertedShift])


  useEffect(() => {
    if(isLoading) {
      setDevices([])
    }
  }, [isLoading])

  useEffect(() => {
    if(!response) {
      return
    }

    setDevices(sortDevicesList(response.data))
    setLoading(false)
  },[response])

  const sortDevicesList = (data) => {
    return (
      data.sort(function(a,b) {
        const x = a.sum;
        const y = b.sum;
        return x > y ? -1 : x < y ? 1 : 0;
      })
    )
  }

  const items = devices.map((item, index) => {      
    return (
      <ElectricityItem key={item.id} device={item} selectedDate={selectedDate}/> 
    )
  })


  const onMonthChoice = (date) => {
    if(date === null) {
      return
    }
    const ts = new Date(date).getTime();

    setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.fromMillis(ts).endOf('month')]);

    setSelectedMonth(date)
  }

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  const headerData = {
    title: 'electricity_load_report',
    isWide: true
  };


  return loading ? <Spinner /> : (
    <Layout
      headerData={headerData}
    >

        <div className="flex flex-row justify-between mt-4 gap-4">
          <div className='md:w-1/4 w-1/2'>
            <Select 
              className="text-sm"
              options={shiftList} 
              value={currentShift.value === null ? shiftList.find(it => it.value === null) : shiftList.find(it => it.value === currentShift.value)}
              onChange={value => handleChangeShift(value)}
              isSearchable={false}
            />
          </div>
          <div className='md:w-1/4 w-1/2'>
            <MonthDatePicker onChange={onMonthChoice} selectedDate={selectedMonth} />
          </div>
        </div>
      {loading ? <Spinner/> :

        <div className="flex flex-col space-y-6 pb-10 mt-8">
          {devices.length > 0 ? items : ""}
        </div>
      }
      
    </Layout>
  );
};

export default ReportElectrocityPage;