import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';

const MultiSelectField = ({fid, label, required, list, values, setCurrentSelect}) => {
  const {t} = useTranslation();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (list === undefined) {
      return;
    }
    const newSelectList = [];
    list.forEach(it => {
      newSelectList.push({
        label: it.label,
        value: it.uuid,
        uuid: it.uuid
      })
    });
    setOptions(newSelectList);
  }, []);

  useEffect(() => {
    if (values === null || values === undefined || options.length === 0) {
      return;
    } else {
      const newSelected = [];

      values.forEach(item => {
        if(options.find(it => it.value == item)) {
          newSelected.push(options.find(it => it.value == item))
        } else {
          newSelected.push({label: 'ОШИБКА! Запись удалена.', value: ''})
        }   
      })
      setSelected(newSelected);
    }
  }, [options, values]);

  const onItemClick = (values) => {
    const valuesArr = values.map(val => val.value)
    setCurrentSelect(fid, valuesArr);
  };

  return ( 
    <Field name={fid} defaultValue={selected === null ? [] : selected} label={label} isRequired={required}>
      {({ fieldProps }) => 
        <Select {...fieldProps} className="text-sm" 
          options={options} 
          onChange={value => onItemClick(value)}
          placeholder={t('select_placeholder')} 
          isMulti
          />
      }
    </Field>
  )
}

export default MultiSelectField;