import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../hooks/use-fetch';
import Spinner from '../../../../components/loader';

import Layout from '../../../../components/layout';

import UnitsTable from './table';
import FormUnit from './form';

const UnitsDirectory = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [itemId, setItemId] = useState('new');
  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error}, doFetchTableData] = useFetch('/directories/units');
  const [{response: itemDeleteResponse, isLoading: itemDeleteIsLoading, error: itemDeleteError}, doFetchItemDelete] = useFetch(`/directories/units/${itemId}`);

  const headerData = {
    title: 'units',
    buttonsList: [
      {
        text: 'create_unit',
        action: () => onAdd()
      }
    ],
  };

  useEffect(() => {
    doFetchTableData();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);


  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function onAdd() {
    setItemId('new');
    openModal();
  };

  function onEdit(id) {
    setItemId(id);
    openModal();
  };

  function onDelete(id) {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    if (itemDeleteIsLoading) {
      return;
    }
    reloadTable();
  }, [itemDeleteIsLoading]);

  function reloadTable() {
    doFetchTableData();
    setLoading(true);
  };

  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className="mt-8">
      {loading ? <Spinner/> : (
        <UnitsTable
          tableData={tableData}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
      </div>
      <FormUnit 
        itemId={itemId}
        closeModal={closeModal}
        isOpen={isOpen}
        reloadTable={reloadTable}
        tableData={tableData}
      />
    </Layout>
  );
};

export default UnitsDirectory;
