import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';
import TimelineItem from './item';


const HomeTimelines = ({loading, equipments, selectedDate, currentShift}) => {
  const {t} = useTranslation();
  
  const items = equipments.map((item, index) => {      
    return (
      <TimelineItem key={item.id} equipment={item} selectedDate={selectedDate} currentShift={currentShift}/> 
    )
  })

  return (
    <div className="w-full mt-8 space-y-2 pb-10">
      {loading ? (
        <div className="flex items-center justify-center w-full h-80">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
        </div>
      ) : 
        <div className="flex flex-col space-y-6 pb-10 mt-8">
          {equipments.length > 0 ? items : ""}
        </div>
      }
    </div>
  )

}

export default HomeTimelines;