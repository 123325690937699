import React, { useEffect, useRef } from 'react';


const TimelineOnlineWidget = ({data, width, duration}) => {
  const canvasRef = useRef(null)
  const w = width / 864
  const k = 86400 * w / duration;

  const draw = (ctx, start, end, color) => {
    ctx.restore();
    ctx.fillStyle = color;
    ctx.fillRect((start/100)*k, 0, (end/100)*k, 24);
    ctx.save();
  }

  const drawTime = (ctx, time) => {
    ctx.restore();
    ctx.fillStyle = "white";
    ctx.fillRect((time/100)*k, 0, 1 * w, 24);
    ctx.save();
  }
  
  useEffect(() => {
    
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    context.clearRect(0, 0, canvas.width, canvas.height);
    const render = () => {
      let start = 0;
      data.map(it => {
        if(it.state === 'worked') {
          draw(context, start, it.seconds, `rgba(16, 185, 129)`)
        } else if (it.state === 'stopped') {
          draw(context, start, it.seconds, `rgba(252, 211, 77)`)
        } else {
          draw(context, start, it.seconds, `rgba(220, 38, 38)`)
        }
        start = start + it.seconds;
      })
      drawTime(context, 0)
      drawTime(context, duration / 4)
      drawTime(context, duration / 2)
      drawTime(context, (duration / 4) * 3)
      drawTime(context, duration)
    }

    render()
    
  }, [data])



  return (
    <canvas 
      ref={canvasRef} 
      width={864 * w} 
      height="24" 
      className="w-full h-5"
    />
  )
}

export default TimelineOnlineWidget;