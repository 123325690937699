import React, { useState, useEffect } from 'react';
import TextField from "@atlaskit/textfield";
import Form, {ErrorMessage, Field, CheckboxField} from "@atlaskit/form";
import { Checkbox } from '@atlaskit/checkbox';
import { useTranslation } from 'react-i18next';

const DircetorySettings = ({ currentItem, onUpdate }) => {
  const {t} = useTranslation();
  const [name, setName] = useState(currentItem.attributes.name);
  const [showInMenu, setShowInMenu] = useState(currentItem.attributes.show_in_menu);
  const [hierarchic, setHierarchic] = useState(currentItem.attributes.hierarchic);

  const handleUpdate = () => {
    onUpdate({name: name, show_in_menu: showInMenu, hierarchic: hierarchic})
  }

  return (
    <div className="mt-4">
      <div className="grid gap-x-8 gap-y-4 grid-cols-1 text-sm">
        <Field name="name" label={t('directory_name')}>
          {({ fieldProps, error }) => (
            <>
              <TextField
                autoComplete="off"
                {...fieldProps}
                onChange={(evt) => setName(evt.target.value)}
                value={name === null ? '' : name}
              />
              {error && (
                <ErrorMessage>
                  This user name is already in use, try another one.
                </ErrorMessage>
              )}
            </>
          )}
        </Field>
        <div className="flex flex-row text-sm space-x-2">
          <CheckboxField name="show_in_menu">
            {({ fieldProps, error }) => (
              <Checkbox {...fieldProps} 
                label={t('show_in_menu')}
                isChecked={showInMenu}
                onChange={() => setShowInMenu(!showInMenu)}
              />
            )}
          </CheckboxField>
          <CheckboxField name="hierarchic" >
            {({ fieldProps, error }) => (
              <Checkbox {...fieldProps} 
                label={t('hierarchic')}
                isChecked={hierarchic}
                onChange={() => setHierarchic(!hierarchic)}
              />
            )}
          </CheckboxField>
        </div>
        <div className="flex justify-between mt-8">
          <button 
            type="submit" 
            onClick={() => handleUpdate()}
            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-sm hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          >
            {t('save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DircetorySettings;