import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';

import useFetch from "../../../hooks/use-fetch";
import {CurrentUserContext} from '../../../contexts/current-user';

import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Select from '@atlaskit/select';

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';

import ErrorsBlock from '../../../components/errors';



const OrganizationEdit = () => {
  const { t } = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [itemId, setItemId] = useState('');
  const [formData, setFormData] = useState('');
  const [name, setName] = useState('');
  const [shortName, setShortName] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [timezone, setTimezone] = useState('');
  const [imageId, setImageId] = useState('');
  const [errors, setErrors] = useState([]);

  const [timezones, setTimezones] = useState([]);
  const [loading, setLoading] = useState(false);

  const [{response: userResponse}, doFetchUser] = useFetch('/users/account', true);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/users/sites/${itemId}`, true);
  const [{response: imageUploadResponse, isLoading: imageUploadIsLoading, error: imageUploadError}, doFetchImageUpload] = useFetch(`/uploads`, true);

  const [{response: timezonesResponse}, doFetchTimezones] = useFetch('/timezones', true);

  useEffect(() => {
    if(currentUserState.currentSite === undefined) {
      return
    }

    setFormData(new FormData());
    setLoading(true)
    doFetchTimezones();
    
    setItemId(currentUserState.currentSite.id)
    setName(currentUserState.currentSite.name);
    setSubdomain(currentUserState.currentSite.subdomain);
    setTimezone(currentUserState.currentSite.timezone)
    setImageId(currentUserState.currentSite.image.id ? currentUserState.currentSite.image.id : '');
  },[currentUserState]);

  useEffect(() => {
    if(!timezonesResponse) {
      return
    }
    const list = []
    timezonesResponse.data.map(tz => {
      list.push({label: tz, value: tz})
    })
    setTimezones(list);
    
    setLoading(false);
  }, [timezonesResponse])

  useEffect(() => {
    if (!imageUploadResponse) {
      return;
    }
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: {
          image: { ...imageUploadResponse.data },
        }
      }
    });
    setImageId(imageUploadResponse.data.id)
  }, [imageUploadResponse]);

  useEffect(() => {
    if (!userResponse) {
      return;
    }
    setCurrentUserState(state => ({
      ...state,
      sites: userResponse.data.attributes.sites,
      currentSite: userResponse.data.attributes.sites.find(item => item.id === state.currentSite.id)
    }));
  }, [userResponse]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    doFetchUser();
  }, [itemUpdateResponse]);

  const onFileChoice = (value) => {
    formData.set('attachment', value);
    doFetchImageUpload({
      method: 'POST',
      data: formData
    });
  };

  const onSaveClick = (data) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: {
          name: data.name,
          timezone: data.timezone.value
        }
      }
    });
  };

  useEffect(() => {
    if (itemUpdateError) {
      setErrors(itemUpdateError.errors)
    }
  }, [itemUpdateError]);


  const headerData = {
    title: 'company_settings'
  };

  return (
    <>
      <Layout
        headerData={headerData}
        isWide={false}
      >
        <div className="content">
          {loading ? <Spinner/> : (
            <div className="flex flex-col mt-8">
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <div className="mt-4">
                <div className="bg-white flex">
                  <div className="mb-4 w-32 h-32">
                    {imageId === '' ? <Avatar name={name} /> : (
                      <img src={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${imageId}`} alt="" className="w-auto mx-auto rounded-lg object-cover object-center"/>
                    )}
                  </div>
                  <div className="ml-10 flex flex-col">
                    <span className="text-gray-800 text-xs font-light mb-4">{t('avatar_upload_text')}</span>
                    <label className="cursor-pointer">
                      <span className="mt-2 leading-normal px-4 py-2 text-blue-900 bg-blue-100 text-xs rounded-full" >{t('avatar_upload')}</span>
                      <input
                        type="file"
                        className="hidden"
                        onChange={(evt) => onFileChoice(evt.target.files[0])}
                      />
                    </label>
                    
                  </div>
                </div>
              </div>
              <Form onSubmit={data => onSaveClick(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="name" defaultValue={name || ""} label={t('title_name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    {/* <Field name="short_name" defaultValue={currentItem.shortName || ""} label={t('abbreviation')}>
                      {({ fieldProps }) => <TextField {...fieldProps}/>}
                    </Field> */}
                    <Field name="subdomain" defaultValue={subdomain} label="Поддомен:" isDisabled>
                      {({ fieldProps }) => <TextField {...fieldProps}/>}
                    </Field>
                    <Field name="timezone" defaultValue={timezone ? timezones.find(tz => tz.value === timezone) : ""} label="Часовой пояс:">
                      {({ fieldProps }) => <Select className="text-sm" styles="z-index: 100" placeholder="Выберите часовой пояс" options={timezones} {...fieldProps}/>}
                    </Field>
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          )}          
        </div>

      </Layout>
    </>

  );
};

export default OrganizationEdit;
