import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useFetch from "../../../hooks/use-fetch";
import {CurrentUserContext} from '../../../contexts/current-user';

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';

import ScreensTable from './table';
import AddScreen from './add';

const Monitors = () => {
  const { t } = useTranslation();
  const [, setCurrentUserState] = useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [screenId, setScreenId] = useState("new");

  let history = useHistory();

  const [{response, isLoading, error}, doFetch] = useFetch('/directories/screens');
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/screens/${screenId}`);


  useEffect(() => {
    doFetch();
    // setLoading(true);
  },[]);



  useEffect(() => {
    if(!response) {
      return
    }
    console.log(response)
    setTableData(response.data);
    setLoading(false);
  }, [response])


  const onAdd = () => {
    setScreenId("new");
    openModal(true);
  }

  const onItemEdit = (id) => {
    history.push({
      pathname: `/settings/screens/${id}`,
    });
  };
  
  const onDelete = (id) => {
    setScreenId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  }

  useEffect(() => {
    if (deleteIsLoading) {
      return;
    }
    setScreenId("new");
    reloadTable();
  }, [deleteIsLoading]);


  const reloadTable = () => {
    doFetch();
  };

  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setScreenId("new")
    }, 300);
  }

  function openModal() {
    setIsOpen(true)
  }

  const headerData = {
    title: 'screens',
    buttonsList: [
      {
        text: `${t('create_screen')}`,
        action: () => onAdd()
      }
    ],
  };

  return (
    <>
      <Layout
        headerData={headerData}
        isWide={true}
      >
        <div className="content">
          {loading ? <Spinner/> : (
            <div className="flex flex-col mt-8">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
                    <ScreensTable 
                      isLoading={isLoading}
                      tableData={tableData}
                      error={error}
                      onItemEdit={onItemEdit}
                      onItemDelete={onDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}          
        </div>
        <AddScreen closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} tableData={tableData}/>
      </Layout>
    </>

  );
};

export default Monitors;
