import {useState, useEffect} from 'react';
import axios from 'axios';



export default (url) => {
  const baseUrl = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_SENSOR_URL;
  const baseOption = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [meta, setMeta] = useState(null);
  const [options, setOptions] = useState({});

  const doFetch = (options = {}) => {
    setOptions({
      ...baseOption,
      ...options
    });
    setIsLoading(true);
  };

  useEffect(() => {
    let skipGetResponseAfterDestroy = false;
    if (!isLoading) {
      return;
    }
    setIsLoading(true);
    axios(baseUrl + url, options)
      .then((res) => {
        if (!skipGetResponseAfterDestroy) {
          if(res.status == 204) {
            setResponse({data: {status: "ok"}});
          } else {
            setResponse(res.data);
            if(res.data.meta) {
              setMeta(res.data.meta);
            }
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (!skipGetResponseAfterDestroy) {
          if(err.response){
            setError(err.response.data);
          }
          setIsLoading(false);
        }
      });

    return () => {
      skipGetResponseAfterDestroy = true;
    };
  }, [isLoading]);

  return [{response, isLoading, error, meta}, doFetch];
}
