import React, { useEffect, useState } from 'react';
import Table from '../../../../../components/form/table';
import { useTranslation } from 'react-i18next';
import { PencilAltIcon } from '@heroicons/react/outline';
import useFetch from '../../../../../hooks/use-fetch';


const ScreenUsers = ({ screen, setScreen }) => {
  const {t} = useTranslation();
  const [users, setUsers] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [userRelationId, setUserRelationId] = useState(null)

  const [{response: usersResponse, isLoading: usersIsLoading, error: usersError}, doFetchUsers] = useFetch(`/users`);
  const [{response: userAddResponse, isLoading: userAddIsLoading, error: userAddError}, doFetchUserAdd] = useFetch(`/directories/screens/${screen.id}/user_relations`);
  const [{response: userRemoveResponse, isLoading: userRemoveIsLoading, error: userRemoveError}, doFetchUserRemove] = useFetch(`/directories/screens/${screen.id}/user_relations/${userRelationId}`);

  useEffect(() => {
    setAddedUsers(screen?.attributes?.user_relations?.map(ur => {return ur?.user_relation?.id?.toString()}))
  },[screen])

  useEffect(() => {
    doFetchUsers()
  }, [])

  useEffect(() => {
    if(!usersResponse) {
      return;
    } 
    setUsers(
      usersResponse.data.map(user =>       
        { 
          return {
            id: user.id,
            first_name: user.attributes.first_name,
            middle_name: user.attributes.middle_name,
            last_name: user.attributes.last_name,
          }
        }
      )
    );
  },[usersResponse]);

  useEffect(() => {
    if(!userAddResponse) {return}
    setScreen({...screen, attributes: {...screen.attributes, user_relations: [...screen.attributes.user_relations, {id: userAddResponse.data.id, user_relation: userAddResponse.data.attributes.user_relation}]}})
  }, [userAddResponse])

  useEffect(() => {
    if(!userRemoveResponse) {return}
    let updatedUserRelations = screen.attributes.user_relations.filter(ur => ur.id != userRelationId)
    setScreen({...screen, attributes: {...screen.attributes, user_relations: updatedUserRelations}})
    setUserRelationId(null)
  }, [userRemoveResponse])

  function onUserAdd(id) {
    doFetchUserAdd({
      method: 'POST',
      data: {
        data: {user_relation_id: id}
      }
    });
  }

  function onUserRemove(id) {
    let userRelation = screen?.attributes?.user_relations.find(ur => ur.user_relation.id == id)
    if(userRelation.id) {
      setUserRelationId(userRelation.id)
      doFetchUserRemove({
        method: 'DELETE'
      })
    }
  }


  const columns = [

    {
      label: t('surname'),
      prop: "last_name",
      labelClassName: "w-full lg:w-5/12",
      className: "w-full lg:w-5/12 flex-shrink-0",
      render: ({last_name}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="font-medium text-gray-900 truncate">
              {last_name}
            </div>
          </div>
        )
      }
    },
    {
      label: t('first_name'),
      prop: "first_name",
      labelClassName: "w-full lg:w-5/12",
      className: "w-full lg:w-5/12 flex-shrink-0",
      render: ({first_name}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="text-gray-900 truncate">
              {first_name}
            </div>
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        if(addedUsers.includes(row.id)) {
          return (
            <div className="flex space-x-2 justify-end">
              <button 
                onClick={() => onUserRemove(row.id)}
                className="text-red-600 hover:text-red-900 cursor-pointer bg-red-100 hover:bg-red-200 px-2 py-1 flex items-center justify-center rounded ">
                {t('disconnect')}
              </button>
            </div>
          )
        } else {
          return (
            <div className="flex space-x-2 justify-end">
              <button 
                onClick={() => onUserAdd(row.id)}
                className="text-indigo-600 hover:text-indigo-900 cursor-pointer bg-blue-100 hover:bg-blue-200 px-2 py-1 flex items-center justify-center rounded ">
                {t('connect')}
              </button>
            </div>
          )
        }
      }
    }
  ]

  return ( 
    <Table
      border={true}
      columns={columns}
      data={users}
      // onRowClick={(data) => onPersonClick(data.id)}
    />
  );
};

export default ScreenUsers;