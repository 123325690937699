import React from 'react';
import Table from '../../../../../components/form/table';
import Spinner from "../../../../../components/loader";
import { useTranslation } from 'react-i18next';
import {getPerfectDate} from '../../../../../utils/index'
import { PlusCircleIcon, PencilAltIcon, TrashIcon, FolderIcon, FolderOpenIcon } from '@heroicons/react/outline';

const ChangesTable = ({ tableData, onItemDelete }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        type: tableItem.type,
        at: tableItem.attributes.at,
        post_name: tableItem.attributes.post_name,
        division: tableItem.attributes.division_name,
        parent: tableItem.attributes.parent_name,
        position: tableItem.attributes.position,
        status: tableItem.attributes.status
      }
    )
  });

  const columns = [
    {
      label: t('at'),
      prop: "at",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex items-center">
            {getPerfectDate(row.at)}
          </div>
        )
      }
    },
    {
      label: t('post'),
      prop: "post",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => { 
        return (
          <>
            <div className="font-medium text-gray-900 truncate">
              {row.post_name}
            </div>
          </>
        )
      }
    },
    {
      label: t('division'),
      prop: "division",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: (row) => { 
        return (
          <div className="flex items-center">
            {row.division_name}
          </div>
        )
      }
    },
    {
      label: t('status'),
      prop: "status",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => { 
        return (
          <div className="flex items-center">
            {t(`staff_${row.status}`)}
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );

};

export default ChangesTable;
