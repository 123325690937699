import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../assets/canvasjs.react';
import { convertTimezone } from '../../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const WireWeightRow = ({data}) =>  {
  const [loading, setLoading] = useState(true);
  const [totalWeight, setTotalWeigth] = useState();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if(!data) {
      return
    }
    let total = 0;
    const new_data = []
    data.data.map(it => {
      total = it.v ? total + it.v : total + 0
      new_data.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: it.v
      })
    })
    // TODO вынести вес 
    const weight = total * 0.009

    setTotalWeigth(weight.toFixed(2));
    setChartData(new_data);
    setLoading(false);
  },[data])

  return (
    <div className="grid grid-cols-12 border-t py-3">
      <div className="col-start-1 col-end-8">Потребление проволоки</div>
      <div className="col-start-9 col-end-11 font-normal flex justify-end">килограмм</div>
      <div className="col-start-12 col-end-12 flex justify-end">{totalWeight}</div>
    </div>
  );
}

export default WireWeightRow;
