import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';

const SelectField = ({fid, label, required, list, value, setCurrentSelect}) => {
  const {t} = useTranslation();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (list === undefined) {
      return;
    }
    const optionsList = []
    list.map(it => {
      optionsList.push({label: it.label, value: it.uuid, uuid: fid})
    })
    setOptions(optionsList)
  },[])

  useEffect(() => {
    if (value === null || value === undefined || options.length === 0) {
      return;
    } else {
      setSelected(options.find(it => it.value == value))
    }
  }, [options, value]);

  const onItemClick = (uuid, id) => {
    setCurrentSelect(uuid, id);
  };
  

  return ( 
    <Field name={fid} defaultValue={selected === null ? "" : selected} label={label} isRequired={required}>
      {({ fieldProps }) => 
        <Select {...fieldProps} className="text-sm" 
          onChange={value => onItemClick(value.uuid, value.value)}
          options={options} 
          placeholder={t('select_placeholder')} 
        />
      }
    </Field>
  )
}

export default SelectField;