import { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';

import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import TextField from '@atlaskit/textfield';

import ErrorsBlock from '../../../components/errors';

export default function AddDevice({closeModal, isOpen, reloadTable, devicesModels}) {
  const {t} = useTranslation();
  const [errors, setErrors] = useState([]);
  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/administration/devices/devices`, true);

  const onSaveClick = (formData) => {
    doFetchItemSave({
      method: 'POST',
      data: {
        data: {
          device_model_id: formData.model.value,
          serial_number: formData.serial_number
        }
      }
    });
  };

  useEffect(() => {
    if (itemSaveResponse) {
      closeModal();
    }
    reloadTable();
  }, [itemSaveResponse]);

  useEffect(() => {
    if (itemSaveError) {
      setErrors(itemSaveError.errors)
    }
  }, [itemSaveError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('adding_device')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('adding_device_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => onSaveClick(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="model" defaultValue="" label={t('device_model')} isRequired>
                      {({ fieldProps }) => <Select options={devicesModels} {...fieldProps} />}
                    </Field>
                    <Field name="serial_number" defaultValue="" label={t('serial_number')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
