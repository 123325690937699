import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {PencilAltIcon, TrashIcon} from '@heroicons/react/outline';

import Table from '../../../../components/form/table';

const UnitsTable = ({ tableData, onEdit, onDelete }) => {
  const {t} = useTranslation();

  const items = tableData.map((item) => {
    return (
      {
        id: item.id,
        name: item.attributes.name,
        label: item.attributes.label
      }
    )
  });

  const columns = [
    {
      label: t('name'),
      prop: "unit_name",
      labelClassName: "w-full lg:w-8/12",
      className: "w-full lg:w-8/12 flex-shrink-0",
      render: (row) => row.name
    },
    {
      label: t('unit_label'),
      prop: "unit_label",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => row.label
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onEdit(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );
};

export default UnitsTable;
