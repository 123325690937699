import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import useFetch from '../../../hooks/use-fetch';
import { covertTimeToSeconds, convertTimeHHMM, secondsToTime} from '../../../utils';
import TimelineOnlineWidget from '../../../components/widgets/timelineOnline';
import { ScreenContext } from '../../../contexts/screenContext';

function Box({ children }) {
  return (
      <div className='flex w-full h-4 items-center 
      bg-gradient-to-r 
      from-green-400 
      to-red-600 
      via-yellow-200
      animate-gradient-x'
      >
          {children}
      </div>
  )
}

const ScreenEquipmentData = ({equipment}) => {
  const {t} = useTranslation();

  const {
    screenWidth,
    currentShift, 
    screenData
  } = useContext(ScreenContext)
  const mainData = screenData[equipment.id]
  const tz = DateTime.local().toFormat('ZZ');
  const [loading, setLoading] = useState(true);
  const [equipmentTimeline, setEquipmentTimeline] = useState([])
  const [timeMarkers, setTimeMarkers] = useState([0, 21600, 43200, 64800, 86400])

  useEffect(() => {
    if(!mainData) {return}
    setEquipmentTimeline(equipment?.stop_threshold > 0 ? transformTimelineByStopThreshold(mainData.timeline) : mainData.timeline)

    const shiftStart = covertTimeToSeconds(currentShift.started_at);
    const shiftEnded = covertTimeToSeconds(currentShift.ended_at);

    setTimeMarkers([
      shiftStart, 
      shiftStart + (currentShift.duration / 4) > 86400 ? (shiftStart + (currentShift.duration / 4)) - 86400 : shiftStart + (currentShift.duration / 4), 
      shiftStart + (currentShift.duration / 2) > 86400 ? (shiftStart + (currentShift.duration / 2)) - 86400 : shiftStart + (currentShift.duration / 2), 
      shiftStart + (currentShift.duration / 4) * 3 > 86400 ? (shiftStart + (currentShift.duration / 4) * 3) - 86400 : shiftStart + (currentShift.duration / 4) * 3, 
      shiftEnded
    ])

    setLoading(false)
  },[currentShift, mainData])


  const getLoadPercent = (arr) => {
    let turnedOffCount = 0;
    let stoppedCount = 0;
    let workedCount = 0;

    arr.forEach(lineItem => {
      switch (lineItem.state) {
        case 'turned_off':
          turnedOffCount = turnedOffCount + lineItem.seconds;
          break;
        case 'stopped':
          if(lineItem.seconds <= equipment.stop_threshold) {
            workedCount = workedCount + lineItem.seconds;
          } else {
            stoppedCount = stoppedCount + lineItem.seconds;
          }
          break;
        case 'worked':
          workedCount = workedCount + lineItem.seconds;
          break;
        default: 
          break;
      }
    });

    if(workedCount > 0) {
      return <div className='font-semibold'>{Math.round(workedCount / (stoppedCount + turnedOffCount + workedCount) * 10000 , -2) / 100} %</div>
    } else {
      return <div className='font-semibold'>0 %</div>
    }
  };

  const getIdlePercent = (arr) => {
    let turnedOffCount = 0;
    let stoppedCount = 0;
    let workedCount = 0;

    arr.forEach(lineItem => {
      switch (lineItem.state) {
        case 'turned_off':
          turnedOffCount = turnedOffCount + lineItem.seconds;
          break;
        case 'stopped':
          if(lineItem.seconds <= equipment.stop_threshold) {
            workedCount = workedCount + lineItem.seconds;
          } else {
            stoppedCount = stoppedCount + lineItem.seconds;
          }
          break;
        case 'worked':
          workedCount = workedCount + lineItem.seconds;
          break;
        default: 
          break;
      }
    });

    if(stoppedCount > 0) {
      return <div className='font-semibold'>{Math.round(stoppedCount / (stoppedCount + turnedOffCount + workedCount) * 10000 , -2) / 100} %</div>
    } else {
      return <div className='font-semibold'>0 %</div>
    }
  };

  const getIntensivity = (arr) => {
    let turnedOffCount = 0;
    let stoppedCount = 0;
    let workedCount = 0;
    let operationsCount = 0;
    let idleCount = 0;

    arr.forEach(lineItem => {
      switch (lineItem.state) {
        case 'turned_off':
          turnedOffCount = turnedOffCount + lineItem.seconds;
          break;
        case 'stopped':
          idleCount = idleCount + 1;
          stoppedCount = stoppedCount + lineItem.seconds;
          break;
        case 'worked':
          operationsCount = operationsCount + 1;
          workedCount = workedCount + lineItem.seconds;
          break;
        default: 
          break;
      }
    });

    const avgOperationTime = operationsCount > 0 ? Math.round(workedCount / operationsCount) : 0;

    if(avgOperationTime > 0) {
      return (
        <>
          <div className='text-xs px-2 py-1'>{t('count_of_operations')}: {operationsCount}</div>
          <div className='text-xs px-2 py-1'>{t('avg_operation_time')}: {secondsToTime(avgOperationTime)}</div>
        </>
      )
    } else {
      return <></>
    }
  };

  function transformTimelineByStopThreshold(timeline) {
    let newT = [...timeline]
    let res = []
    newT.forEach(function(item){
      if(item.state === 'stopped' && item.seconds <= equipment.stop_threshold && res.length > 0){
        if(res[res.length - 1].state === 'worked') {
          res[res.length - 1] = {...res[res.length - 1], seconds: res[res.length - 1].seconds + item.seconds}
        } else {
          res.push({...item, state: 'worked'});
        }
      } else {
        res.push(item);
      }
    })
    return res;
  }

  return (
    <div className='flex space-x-2'>
      {/* <div className='flex-none pt-2 pb-1 h-full'>
        <div className='flex bg-green-300 rounded-t w-20 text-center h-1/2 items-center justify-center text-base text-green-800'>{getLoadPercent(mainData.timeline)}</div>
        <div className='flex bg-yellow-300 rounded-b w-20 text-center h-1/2 items-center justify-center text-base text-yellow-800'>{getIdlePercent(mainData.timeline)}</div>
      </div> */}
      <div className='flex-1'>
        <div className="flex items-center mt-2 justify-between mb-2">
          <div className='flex items-center space-x-2'>
            <div className='flex bg-green-300 rounded w-20 text-center h-1/2 items-center justify-center text-base text-green-800'>{getLoadPercent(mainData.timeline)}</div>
            <div className="text-sm font-medium text-gray-500 truncate">{mainData.current_personnel ? <span>{mainData.current_personnel}</span> : <span className='text-gray-400'>Без сотрудника</span>}</div>
          </div>
          <div className="text-sm font-medium text-gray-900 truncate">{equipment.equipment_type_name} / {equipment.equipment_model_name} / {equipment.name} </div>
        </div>
        <div className="grid grid-cols-4 text-xs text-gray-500">
          <div className="flex">{convertTimeHHMM(timeMarkers[0])}</div>
          <div className="flex">{convertTimeHHMM(timeMarkers[1])}</div>
          <div>{convertTimeHHMM(timeMarkers[2])}</div>
          <div className="flex justify-between"><span>{convertTimeHHMM(timeMarkers[3])}</span></div>
        </div>
        <div className="flex overflow-hidden w-full h-5 py-1 mb-1"> 
          {
            loading ? 
            <Box /> 
            : 
            <div className='flex w-full h-full'>
              <TimelineOnlineWidget data={equipmentTimeline} width={screenWidth} duration={currentShift.duration}/>
            </div>
          }
        </div> 
      </div>
    </div>
  )
}

export default ScreenEquipmentData;