import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';

import ModelsTable from './table';
import FormModel from './form';

const Models = ({ producer, itemModels, equipmentTypes, reloadTable }) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [modelId, setModelId] = useState("new");
  const [tableData, setTableData] = useState([]);

  const [{response: modelDeleteResponse, isLoading: modelDeleteIsLoading, error: modelDeleteError}, doFetchModelDelete] = useFetch(`/directories/equipment_models/${modelId}`);

  useEffect(() => {
    if (modelDeleteIsLoading) {
      return;
    }
    setModelId("new");
    reloadTable();
  }, [modelDeleteIsLoading]);

  const onDelete = (id) => {
    setModelId(id);
    doFetchModelDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    const modelsData = []
    itemModels.map(model => {
      let type = equipmentTypes.find(t => t.id == model.attributes.equipment_type_id)
      modelsData.push({id: model.id, name: model.attributes.name, equipment_type_id: model.attributes.equipment_type_id, type: type.attributes.name})
    })
    setTableData(modelsData)
  },[itemModels]);

  function closeModal() {
    setTimeout(() => {
      setModelId("new");
    }, 300);
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    setModelId("new");
    openModal();
  };

  const onEdit = (id) => {
    setModelId(id);
    openModal();
  };

  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('models')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('models_producer_text')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('add_model')}
        </button>
      </div>
      <ModelsTable
        tableData={tableData}
        onItemEdit={onEdit}
        onItemDelete={onDelete}
      />
      <FormModel modelId={modelId} tableData={tableData} closeModal={closeModal} isOpen={isOpen} equipmentTypes={equipmentTypes} producer={producer} reloadTable={reloadTable}/>
    </div>
  );
};

export default Models;
