import React, { useState, useEffect, useContext, forwardRef, Suspense } from 'react';
import {orderBy} from "lodash";
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';
import {DateTime} from 'luxon';
// import Select from '@atlaskit/select';
import Select from 'react-select';
import MonthDatePicker from '../../../components/form/calendar/monthDatePicker';
import { convertToTimestamp, covertTimeToSeconds } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import {CurrentUserContext} from "../../../contexts/current-user";
import DivisionItem from './item';
import ShiftsInFilter from '../../../components/filters/shifts';


const ReportDivisions = (props) => {
  const {t} = useTranslation();
  
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([DateTime.local(), DateTime.local()]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(DateTime.local().toMillis()));
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState({});
  const [selectedDivisionId, setSelectedDivisionId] = useState({});
  const [division, setDivision] = useState();
  const [empty, setEmpty] = useState(false)

  const [divisionList, setDivisionList] = useState([]);

  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" });
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400})

  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsDataError}, doFetchDivisions] = useFetch('/directories/divisions');

  useEffect(() => {
    setLoading(true)
    doFetchDivisions();

    setSelectedDate([DateTime.local().startOf('month'), DateTime.local().endOf('month')]);

    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]

    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat('HH:mm:ss'), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat('HH:mm:ss')})
      })
    }
    setShiftList(shifts);
  },[])


  useEffect(() => {
    if(!divisionsResponse) {
      return
    }

    if(!divisionsResponse?.data || !divisionsResponse.data.length) return (
      setEmpty(true)
    )

    setDivisions(divisionsResponse.data)
    let d = orderBy(divisionsResponse.data, ['attributes.depth'], ['asc'])[0]
    
    setDivisionList(divisionsResponse.data.map(it => ({label: it.attributes.name,  value: it.id})))

    setSelectedDivision(d)
    setDivision(buildDivision(divisionsResponse.data, d))
    setLoading(false)
  },[divisionsResponse]);

  function buildDivision(data, selected) {
    let flatList = []
    data.map(it => {
      if(it.attributes.depth > selected.attributes.depth) {
        flatList.push({
          id: it.id,
          name: it.attributes.name,
          parent_id: it.attributes.parent_id,
          real_parent_id: it.attributes.parent_id,
          equipment_items_count: it.attributes.equipment_items_count,
          personnels_count: it.attributes.personnels_count,
          depth: it.attributes.depth
        })
      } else if(it.id === selected.id) {
        flatList.push({
          id: it.id,
          name: it.attributes.name,
          parent_id: null,
          real_parent_id: it.attributes.parent_id,
          equipment_items_count: it.attributes.equipment_items_count,
          personnels_count: it.attributes.personnels_count,
          depth: it.attributes.depth
        })
      }
    })

    let pds =
    list_to_tree(flatList, selected.id)
    .map(div => {
      let acc = []
      let subdivisions = flatChilds(div.children, acc)
      return {division: div, subdivisions: subdivisions}
    })
    
    return pds[0]
  }

  function list_to_tree(list, id) {
    let map = {}, node, roots = [], i;
    
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // initialize the map
      list[i].children = []; // initialize the children
    }
    
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parent_id !== null) {
        if (node.parent_id in map) {
          list[map[node.parent_id]].children.push(node);
        }
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  function flatChilds(arr, acc) {
    arr.map((item) => {
      if(item.children.length > 0) {
        acc.push(item)
        flatChilds(item.children, acc)
      } else {
        acc.push(item)
      }
    })
    return acc
  }

  const onMonthChoice = (date) => {
    if(date === null) {
      return
    }
    const ts = new Date(date).getTime();

    setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.fromMillis(ts).endOf('month')]);

    setSelectedMonth(date)
  }

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  const handleChangeDivision = (value) => {
    setSelectedDivision(divisions.find(it => it.id === value.value));
    setDivision(buildDivision(divisions, divisions.find(it => it.id === value.value)))
  }

  const headerData = {
    title: 'divisions_report',
    isWide: true
  };

  if(empty) return (
    <div className='flex h-full w-full items-center justify-center'>{t('no_divisions')}</div>
  )

  const customStyles = {
    control: base => ({
      ...base,
      height: 33,
      minHeight: 33
    })
  };

  return loading ? <Spinner /> : (
    <Layout
      headerData={headerData}
    >
      <div className="grid grid-cols-4 gap-4 md:grid-cols-4 mt-4">
        <div className='col-span-2'>
          <Select 
            classNamePrefix="flow_select"
            className="text-sm"
            options={divisionList} 
            value={divisionList.find(i => i.value === selectedDivision.id)}
            onChange={value => handleChangeDivision(value)}
            isSearchable={true}
            styles={customStyles}
          />
        </div>
        <div>
          <ShiftsInFilter shifts={{value: currentShift, onChange: handleChangeShift, options: shiftList}}/>
        </div>
        <div>
          <MonthDatePicker onChange={onMonthChoice} selectedDate={selectedMonth} />
        </div>
      </div>
      {loading ? <Spinner/> :
        <div className="flex flex-col space-y-6 pb-10 mt-8">
          {division && <DivisionItem division={division} selectedDate={selectedDate} shift={convertedShift} changeDivision={handleChangeDivision}/>}
        </div>
      }
      
    </Layout>
  );
};

export default ReportDivisions;