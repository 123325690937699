import React, { useEffect, useState } from 'react';
import Table from '../../../../../components/form/table';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import {DateTime} from 'luxon';


const ScreenShifts = ({ screen, setScreen }) => {
  const {t} = useTranslation();
  const [shifts, setShifts] = useState([]);
  const [addedShifts, setAddedShifts] = useState([]);
  const [workingShiftId, setWorkingShiftId] = useState(null)

  const [{response: shiftsResponse, isLoading: usersIsLoading, error: usersError}, doFetchShifts] = useFetch(`/directories/working_shifts`);
  const [{response: shiftAddResponse, isLoading: userAddIsLoading, error: userAddError}, doFetchShiftAdd] = useFetch(`/directories/screens/${screen.id}/working_shifts`);
  const [{response: shiftRemoveResponse, isLoading: userRemoveIsLoading, error: userRemoveError}, doFetchShiftRemove] = useFetch(`/directories/screens/${screen.id}/working_shifts/${workingShiftId}`);

  useEffect(() => {
    setAddedShifts(screen?.attributes?.working_shift_relations?.map(ws => {return ws?.working_shift}))
  },[screen])

  useEffect(() => {
    doFetchShifts()
  }, [])

  useEffect(() => {
    if(!shiftsResponse) {
      return;
    } 
    setShifts(
      shiftsResponse.data.map(shift =>       
        { 
          return {
            id: shift.id,
            name: shift.attributes.name,
            started_at: shift.attributes.started_at,
            ended_at: shift.attributes.ended_at,
          }
        }
      )
    );
  },[shiftsResponse]);

  useEffect(() => {
    if(!shiftAddResponse) {return}
    setScreen({...screen, attributes: {...screen.attributes, working_shift_relations: [...screen.attributes.working_shift_relations, {id: shiftAddResponse.data.id, working_shift: shiftAddResponse.data.attributes.working_shift}]}})
  }, [shiftAddResponse])

  useEffect(() => {
    if(!shiftRemoveResponse) {return}
    let updatedWorkingShiftRelations = screen.attributes.working_shift_relations.filter(ws => ws.id != workingShiftId)
    setScreen({...screen, attributes: {...screen.attributes, working_shift_relations: updatedWorkingShiftRelations}})
    setWorkingShiftId(null)
  }, [shiftRemoveResponse])

  function onShiftAdd(id) {
    let selectedShift = shifts.find(s => s.id === id)
    let selectedShiftDiff = DateTime.fromISO(selectedShift.ended_at).diff(DateTime.fromISO(selectedShift.started_at), 'seconds')

    let totalDuration = addedShifts.reduce((acc, shift) => {
      let diff = DateTime.fromISO(shift.ended_at).diff(DateTime.fromISO(shift.started_at), 'seconds')
      if(diff.seconds > 0) {
        return DateTime.fromISO(shift.started_at).toSeconds() <= DateTime.fromISO(selectedShift.started_at).toSeconds() && DateTime.fromISO(selectedShift.started_at).toSeconds() < DateTime.fromISO(shift.ended_at).toSeconds() - 1 ? acc + 1000000 : acc + diff.seconds
      } else {
        return (DateTime.fromISO(shift.ended_at).toSeconds() - 86400) <= DateTime.fromISO(selectedShiftDiff.started_at).toSeconds() && DateTime.fromISO(selectedShiftDiff.started_at).toSeconds() < DateTime.fromISO(shift.started_at).toSeconds() - 1 ? acc + 1000000 : acc + (86400 + diff.seconds)
      }
    }, 0)

    if((totalDuration + (selectedShiftDiff.seconds > 0 ? selectedShiftDiff.seconds : (86400 + selectedShiftDiff.seconds))) <= 86400) {
      doFetchShiftAdd({
        method: 'POST',
        data: {
          data: {working_shift_id: id}
        }
      });
    }
  }

  function onShiftRemove(id) {
    let workingShiftRelation = screen?.attributes?.working_shift_relations.find(ur => ur.working_shift.id == id)
    if(workingShiftRelation.id) {
      setWorkingShiftId(workingShiftRelation.id)
      doFetchShiftRemove({
        method: 'DELETE'
      })
    }
  }

  const columns = [

    {
      label: t('name'),
      prop: "name",
      labelClassName: "w-full lg:w-6/12",
      className: "w-full lg:w-6/12 flex-shrink-0",
      render: ({name}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="font-medium text-gray-900 truncate">
              {name}
            </div>
          </div>
        )
      }
    },
    {
      label: t('started_at'),
      prop: "started_at",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: ({started_at}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="text-gray-900 truncate">
              {DateTime.fromISO(started_at).toFormat("HH:mm")}
            </div>
          </div>
        )
      }
    },
    {
      label: t('ended_at'),
      prop: "ended_at",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: ({ended_at}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="text-gray-900 truncate">
              {DateTime.fromISO(ended_at).toFormat("HH:mm")}
            </div>
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        if(addedShifts.map(s => s.id?.toString()).includes(row.id)) {
          return (
            <div className="flex space-x-2 justify-end">
              <button 
                onClick={() => onShiftRemove(row.id)}
                className="text-red-600 hover:text-red-900 cursor-pointer bg-red-100 hover:bg-red-200 px-2 py-1 flex items-center justify-center rounded ">
                {t('disconnect')}
              </button>
            </div>
          )
        } else {
          return (
            <div className="flex space-x-2 justify-end">
              <button 
                onClick={() => onShiftAdd(row.id)}
                className="text-indigo-600 hover:text-indigo-900 cursor-pointer bg-blue-100 hover:bg-blue-200 px-2 py-1 flex items-center justify-center rounded ">
                {t('connect')}
              </button>
            </div>
          )
        }
      }
    }
  ]

  return ( 
    <Table
      border={true}
      columns={columns}
      data={shifts}
      // onRowClick={(data) => onPersonClick(data.id)}
    />
  );
};

export default ScreenShifts;