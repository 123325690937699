import React from 'react';
import Table from '../../../../../components/form/table';
import Spinner from "../../../../../components/loader";
import { useTranslation } from 'react-i18next';
import {getPerfectDate, getPerfectDateTime} from '../../../../../utils/index'

const ChangesTable = ({ tableData }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        type: tableItem.type,
        at: tableItem.attributes.at,
        description: tableItem.attributes.description,
        division: tableItem.attributes.division_name,
        parent: tableItem.attributes.parent_name,
        performer: tableItem.attributes.performer_name,
        responsible: tableItem.attributes.responsible_name,
        position: tableItem.attributes.position,
        status: tableItem.attributes.status,
        move_status: tableItem.attributes.move_status
      }
    )
  });

  const columns = [
    {
      label: t('at'),
      prop: "at",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex items-center">
            {getPerfectDateTime(row.at)}
          </div>
        )
      }
    },
    {
      label: t('change_type'),
      prop: "at",
      labelClassName: "w-full lg:w-5/12",
      className: "w-full lg:w-5/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex items-center">
            {t(row.type)}
          </div>
        )
      }
    },
    {
      label: t('what_change'),
      prop: "equipment",
      labelClassName: "w-full lg:w-5/12",
      className: "w-full lg:w-5/12 flex-shrink-0",
      render: (row) => { 
        switch (row.type) {
          case "equipment_item_status_changed":
            return (
              <div className="font-medium text-gray-900 truncate">
                {t(row.status)}
              </div>
            )
          case "equipment_item_division_changed":
            return (
              <div className="font-medium text-gray-900 truncate">
                {row.division}
              </div>
            )
          case "equipment_item_parent_changed":
            return (
              <div className="font-medium text-gray-900 truncate">
                {row.parent}
              </div>
            )
          case "equipment_item_position_changed":
            return (
              <div className="text-gray-500 truncate">
                {row.position}
              </div>
            )
          case "equipment_item_responsible_changed":
            return (
              <div className="text-gray-900 truncate">
                {row.responsible ? row.responsible : t('no_selected')}
              </div>
            )
          case "equipment_item_performer_changed":
            return (
              <div className="text-gray-900 truncate">
                {row.performer ? row.performer : t('no_selected')}
              </div>
            )
          case "equipment_item_move_status_changed":
            return (
              <div className="text-gray-900 truncate">
                {row.move_status ? t(`move_${row.move_status}`) : t('no_selected')}
              </div>
            )
          case "sites_equipment_item_changes_watchdogs":
            return (
              <div className="text-gray-900 truncate">
                {row.move_status ? t(`move_${row.move_status}`) : t('no_selected')}
              </div>
            )
          default:
            break;
        }

      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );

};

export default ChangesTable;
