import React, {Fragment, useContext} from 'react';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';

import PrivateRoute from '../auth/private';
import PublicRoute from '../auth/public';
import HomePage from '../pages/home';

import {CurrentUserContext} from "../contexts/current-user";

import DevicesPage from "../pages/devices";
import DevicePage from "../pages/devices/device";

import Directories from '../pages/directories/';
import DirectoriesEdit from '../pages/directories/edit';
import Directory from '../pages/directories/directory';
import UnitsDirectory from '../pages/directories/special/units';

import EquipmentAccounting from '../pages/equipment/accounting';
import EquipmentLocation from '../pages/report/tracking';
import EquipmentEditPage from '../pages/equipment/accounting/editPage';
import EquipmentTypes from '../pages/equipment/types';
import EquipmentTypeEdit from '../pages/equipment/types/type';
import ReportEquipmentPage from '../pages/report/equipment_load';
import EquipmentReportPage from '../pages/report/equipment';
import MaintenancestReportPage from '../pages/report/maintenances';
import ReportStaffPage from '../pages/report/staff_load';
import PersonReportPage from '../pages/report/staff';
import ReportDivisions from '../pages/report/divisions';

import ReportElectrocityPage from '../pages/electricity';
import ElectricityItemPage from '../pages/electricity/device'

import Staff from '../pages/staff/staff';
import StaffEdit from '../pages/staff/staff/edit';

import Posts from '../pages/staff/positions';
import PostEdit from '../pages/staff/positions/position';

import Manufacturers from '../pages/equipment/vendors';
import ManufactureEdit from '../pages/equipment/vendors/manufacture';
import ReportHeatDivisions from '../pages/report/heat_divisions';
import AnalyticPage from '../pages/analytics';



const SiteRoutes = () => {
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <Switch>
      <PrivateRoute path="/" component={HomePage} exact/>
      <PrivateRoute path="/devices" component={DevicesPage} exact/>
      <PrivateRoute path="/devices/:id" component={DevicePage} exact/>

      <PrivateRoute path="/electricities" component={ReportElectrocityPage} exact/>
      <PrivateRoute path="/electricities/:id" component={ElectricityItemPage} exact/>

      <PrivateRoute path="/directories" component={Directories} exact/>
      <PrivateRoute path="/directories/units" component={UnitsDirectory} exact/>
      <PrivateRoute path="/directories/:id" component={DirectoriesEdit} exact/>
      <PrivateRoute path="/directories/users/:slug" component={Directory} exact/>

      <PrivateRoute path="/equipment" component={EquipmentAccounting} exact/>
      <PrivateRoute path="/equipment/:id" component={EquipmentEditPage} exact/>

      <PrivateRoute path="/equipment_map" component={EquipmentLocation} exact/>

      <PrivateRoute path="/report/equipment" component={ReportEquipmentPage} exact/>
      <PrivateRoute path="/report/equipment/:id" component={EquipmentReportPage} exact/>
      <PrivateRoute path="/report/maintenances" component={MaintenancestReportPage} exact/>
      <PrivateRoute path="/report/staff" component={ReportStaffPage} exact/>
      <PrivateRoute path="/report/staff/:id" component={PersonReportPage} exact/>
      <PrivateRoute path="/report/divisions" component={ReportDivisions} exact/>
      {/* <PrivateRoute path="/report/divisions" component={ReportHeatDivisions} exact/> */}
      {/* <PrivateRoute path="/report/divisions/:id" component={PersonReportPage} exact/> */}

      <PrivateRoute path="/manufactures" component={Manufacturers} exact/>
      <PrivateRoute path="/manufactures/:id" component={ManufactureEdit} exact/>

      <PrivateRoute path="/equipment_types" component={EquipmentTypes} exact/>
      <PrivateRoute path="/equipment_types/:id" component={EquipmentTypeEdit} exact/>

      <PrivateRoute path="/staff" component={Staff} exact/>
      <PrivateRoute path="/staff/:id" component={StaffEdit} exact/>
      <PrivateRoute path="/positions" component={Posts} exact/>
      <PrivateRoute path="/positions/:id" component={PostEdit} exact/>

      <PrivateRoute path="/analytic" component={AnalyticPage} exact/>
      {/* <PrivateRoute path="/upload/log" component={UploadPage} exact/>
      <PrivateRoute path="/upload/oazis" component={OazisUploadPage} exact/> */}
    </Switch>
  );
};

export default SiteRoutes;
