import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {CurrentUserContext} from "../../../contexts/current-user";
import useFetch from '../../../hooks/use-fetch';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import MaintenancesTable from './table';
import { useHistory } from "react-router-dom";
import { maintenanceType } from '../../../enums';

import { sortDataList } from '../../../utils';
import Pagination from "../../../components/form/pagination";
import Filters from '../../../components/filters';
import EditEquipmentModal from '../../equipment/accounting/editModal';

const ITEMS_PER_PAGE = 40;

const ReportMaintenancesPage = (props) => { 
  const {t} = useTranslation();
  let history = useHistory();
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [producersList, setProducersList] = useState([]);
  const [modelsList, setModelsList] = useState('');
  const [currentItemId, setCurrentItemId] = useState(null);
  let [isOpen, setIsOpen] = useState(false);

  // FILTERS 
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterOptionsProducers, setFilterOptionsProducers] = useState([]);
  const [filterOptionsTypes, setFilterOptionsTypes] = useState([]);
  const [filterOptionsDivisions, setFilterOptionsDivisions] = useState([]);
  const filterOptionsMaintenanceTypes = maintenanceType.map(type => ({label: t(`maintenance_${type}`), value: type}))

  // SORT
  const [sortColumn, setSortColumn] = useState('id')
  const [sortDirection, setSortDirection] = useState('asc')

  // PAGING
  const [showPaging, setShowPaging] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(40);
  const [paginatedTableData, setPaginatedTableData] = useState([]);

  const [params, setParams] = useState(new URLSearchParams({}))

  const [pagingParams, setPagingParams] = useState(new URLSearchParams({
    'page': currentPage,
    'per': itemsPerPage,
    'sort': sortColumn,
    'direction': sortDirection
  }))

  // sort_by(%w(sites_producers.name sites_equipment_items.name next_maintenance_at working_from_previous_maintenance), 'desc')

  const [{response: producersResponse, isLoading: producersIsLoading, error: producersError}, doFetchProducers] = useFetch('/directories/producers');
  const [{response: equipmentTypesResponse, isLoading: equipmentTypesIsLoading, error: equipmentTypesError}, doFetchEquipmentTypes] = useFetch('/directories/equipment_types');

  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch((`/directories/maintenances?${params.toString()}&${pagingParams.toString()}`).replace(/,/g, ""));

  useEffect(() => {
    doFetchProducers();
    doFetchEquipmentTypes();
  }, []);

  useEffect(() => {
    setLoading(true)
    doFetchTableData();
  },[]);


  useEffect(() => {
    if(!response) {
      return
    }
    setTableData(response.data)
    setLoading(false)
  },[response]);

  useEffect(() => {
    if (!producersResponse || !equipmentTypesResponse) {
      return;
    }
    const newProducersList = [];    
    producersResponse.data.forEach(item => {
      newProducersList.push({label: item.attributes.name, value: item.id})
    });

    const newFilterListProducers = 
    sortDataList(producersResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    const newFilterListTypes = 
    sortDataList(equipmentTypesResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));


    setProducersList(newProducersList);

    setFilterOptionsProducers(newFilterListProducers);
    setFilterOptionsTypes(newFilterListTypes);

  }, [producersResponse, equipmentTypesResponse]);


  const headerData = {
    title: 'maintenances',
    isWide: true
  };

  const filtersData = {
    // search: {
    //   placeholder: t('equipment_search'),
    //   value: search,
    //   onChange: setSearch
    // },
    filtersList: [
      {
        name: 'producer',
        key: 'producer_ids[]',
        placeholder: 'Поиск',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsProducers
      },
      {
        name: 'equipment_type',
        key: 'equipment_type_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsTypes
      },
      {
        name: 'maintenance_types',
        key: 'maintenance_types[]',
        type: 'select',
        option_search: false,
        options: filterOptionsMaintenanceTypes
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  function sorting(key) {
    if(key === sortColumn) {
      let dir = sortDirection === 'asc' ? 'desc' : 'asc'
      setSortDirection(dir)
      let params = new URLSearchParams({
        'page': currentPage,
        'per': itemsPerPage,
        'sort': key,
        'direction': dir
      })
      setPagingParams(params);
      setLoading(true)
      doFetchTableData();
    } else {
      setSortColumn(key)
      setSortDirection('asc')
      let params = new URLSearchParams({
        'page': currentPage,
        'per': itemsPerPage,
        'sort': key,
        'direction': 'asc'
      })
      setPagingParams(params);
      setLoading(true)
      doFetchTableData();
    }
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    // search ? newParams.append('search', search) : newParams.delete('search')
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [search, selectedFilters])
  
  // Paging
  
  useEffect(() => {
    if (!meta) {
      return;
    }
    setTotalItems(meta.total_pages * itemsPerPage);
    if (meta.total_pages > 1) {
      setShowPaging(true);
    } else {
      setShowPaging(false);
    }
  }, [meta]);

  const listPage = (page) => {
    setCurrentPage(page);
    let params = new URLSearchParams({
      'page': page,
      'per': itemsPerPage,
      'sort': sortColumn,
      'direction': sortDirection
    })
    setPagingParams(params);
    setLoading(true)
    doFetchTableData();
  };

  useEffect(() => {
    if (tableData.length <= ITEMS_PER_PAGE) {
      setPaginatedTableData(tableData);
      return;
    }
    const offset = currentPage === 0 ? 0 : currentPage * ITEMS_PER_PAGE;
    const fullTableData = tableData.slice();
    if (currentPage === Math.ceil(tableData.length / ITEMS_PER_PAGE) - 1) {
      setPaginatedTableData(fullTableData.slice(offset));
    } else {
      setPaginatedTableData(fullTableData.slice(offset, offset + ITEMS_PER_PAGE));
    }
  }, [tableData, currentPage]);

  function reloadTable() {
    setLoading(true)
    doFetchTableData();
  }


  function openModal(id) {
    setCurrentItemId(id)
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    reloadTable()
  }


  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      <div className="content pb-10 mt-10">
        {loading ? <Spinner/> : (
          <>
            <MaintenancesTable
              tableData={tableData}
              sortKey={sortColumn}
              direction={sortDirection}
              sorting={sorting}
              onItemEdit={openModal}
            />
            {showPaging && (
              <Pagination layout="prev, pager, next" total={totalItems} pageSize={itemsPerPage} currentPage={currentPage} onCurrentChange={(currentPage) => listPage(currentPage)}/>
            )}
          </>
        )}
      </div>
      {isOpen && <EditEquipmentModal closeModal={closeModal} isOpen={isOpen} currentItemId={currentItemId}/>}
    </Layout>
  );
}

export default ReportMaintenancesPage;