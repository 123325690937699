import React, {useContext, useState, useRef, useEffect} from 'react';
import {CurrentUserContext} from "../../../contexts/current-user";

import PageFilter from '../../../components/page_filter';
import Layout from '../../../components/layout';
import Spinner from "../../../components/loader";

import useFetch from "../../../hooks/use-fetch";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../hooks-store/store";

import Pagination from "../../../components/form/pagination";

import LogsTable from './table';

const ITEMS_PER_PAGE = 40;


const SettingsLogs = (props) => {
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [pageSearch, setPageSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [filtersProducers, setFiltersProducers] = useState([]);
  const [filtersTypes, setFiltersTypes] = useState([]);
  const [filtersDivisions, setFiltersDivisions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPaging, setShowPaging] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(40);
  const [paginatedTableData, setPaginatedTableData] = useState([]);


  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch((`/directories/logs?${pageSearch <= 2 ? '' : 'search=' + pageSearch + '&'}page=${currentPage}&per=${itemsPerPage}&sort=created_at&direction=desc`).replace(/,/g, ""));

  const headerData = {
    title: 'logs',
  };

  useEffect(() => {
    setLoading(true);
    doFetchTableData();
  }, []);

  
  // Paging
  
  useEffect(() => {
    if (!meta) {
      return;
    }
    setTotalItems(meta.total_pages * itemsPerPage);
    if (meta.total_pages > 1) {
      setShowPaging(true);
    } else {
      setShowPaging(false);
    }
  }, [meta]);

  const listPage = (page) => {
    setCurrentPage(page);
    doFetchTableData();
  };



  //TODO Сделать единую функцию сортировки


  useEffect(() => {
    if (!response) {
      return;
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (tableData.length <= ITEMS_PER_PAGE) {
      setPaginatedTableData(tableData);
      return;
    }
    const offset = currentPage === 0 ? 0 : currentPage * ITEMS_PER_PAGE;
    const fullTableData = tableData.slice();
    if (currentPage === Math.ceil(tableData.length / ITEMS_PER_PAGE) - 1) {
      setPaginatedTableData(fullTableData.slice(offset));
    } else {
      setPaginatedTableData(fullTableData.slice(offset, offset + ITEMS_PER_PAGE));
    }
  }, [tableData, currentPage]);


  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className="content pb-10 pt-10">
          {loading ? <Spinner/> : (
            <>
              <LogsTable
                tableData={tableData}
                isLoading={isLoading}
                error={error}
              />
              {showPaging && (
                <Pagination layout="prev, pager, next" total={totalItems} pageSize={itemsPerPage} currentPage={currentPage} onCurrentChange={(currentPage) => listPage(currentPage)}/>
              )}
            </>
          )}
        </div>
    </Layout>
  );
};

export default SettingsLogs;
