import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ResponsivePie } from '@nivo/pie';
import { secondsToTime } from '../../../utils';

const Tooltip = ({data}) => {
  return (
    <div className="bg-white shadow-lg rounded text-sm text-gray-700 px-4 py-4 flex flex-col min-w-min">
      <span className="font-medium whitespace-nowrap">{data?.datum?.label}:</span>
      <span className="font-semibold">{secondsToTime(data?.datum?.value)}</span>
    </div>
  )
}

const DailyWorkWidget = ({loading, worked, stopped, turned_off, count}) => {
  const {t} = useTranslation(['widgets']);
  const [data, setData] = useState([])

  useEffect(() => {
    const newData = [
      {id: 'worked', label: t('all_worked'), value: (worked), color: 'rgba(16, 185, 129)'},
      {id: 'stopped', label: t('all_stopped'), value: (stopped), color: 'rgba(252, 211, 77)'},
      {id: 'turned_off', label: t('all_turned_off'), value: (turned_off), color: 'rgba(220, 220, 220)'}
    ]
    setData(newData)
  },[worked, stopped, turned_off, count])



  const getIndicatorInPercent = (attr) => {
    return Math.round(Math.round(attr[0] / (attr[0] + attr[1] + attr[2]) * 10000, -1) / 100)
  };

  return (
    <div className="grid grid-cols-4 space-x-4">
      <div className="w-full h-full max-h-40">
        <ResponsivePie
          data={data}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={3}
          fit={false}
          enableArcLabels={false}
          colors={{ datum: 'data.color' }}
          enableArcLinkLabels={false}
          motionConfig="slow"
          transitionMode="centerRadius"
          tooltip={(datum) => (<Tooltip data={datum} />)}
        />
      </div>
      <div className="flex items-start">
        <div className="flex">
          <span className="w-2 h-2 rounded bg-green-600 mx-2 my-1.5"></span>
          <div className="flex flex-col space-y-4">
            <div>
              {loading ? <Skeleton /> : <div className="text-sm text-gray-700 font-semibold">{worked ? secondsToTime(worked / count) : ""} ({worked ? getIndicatorInPercent([worked, stopped, turned_off]) : ""}%)</div> }
              <div className="text-sm text-gray-500">{t('avg_worked')}</div>
            </div>
            <div>
              {loading ? <Skeleton /> : <div className="text-sm text-gray-700 font-semibold">{worked ? secondsToTime(worked) : ""} ({worked ? getIndicatorInPercent([worked, stopped, turned_off]) : ""}%)</div> }
              <div className="text-sm text-gray-500">{t('all_worked')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start">
        <div className="flex">
          <span className="w-2 h-2 rounded bg-yellow-400 mx-2 my-1.5"></span>
          <div className="flex flex-col space-y-4">
            <div>
              {loading ? <Skeleton /> :<div className="text-sm text-gray-700 font-semibold">{stopped ? secondsToTime(stopped / count) : ""} ({stopped ? getIndicatorInPercent([stopped, worked, turned_off]) : ""}%)</div>}
              <div className="text-sm text-gray-500">{t('avg_stopped')}</div>
            </div>
            <div>
              {loading ? <Skeleton /> : <div className="text-sm text-gray-700 font-semibold">{stopped ? secondsToTime(stopped) : ""} ({stopped ? getIndicatorInPercent([stopped, worked, turned_off]) : ""}%)</div>}
              <div className="text-sm text-gray-500">{t('all_stopped')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start">
        <div className="flex">
          <span className="w-2 h-2 rounded bg-gray-300 mx-2 my-1.5"></span>
          <div className="flex flex-col space-y-4">
            <div>
              {loading ? <Skeleton /> : <div className="text-sm text-gray-700 font-semibold">{turned_off ? secondsToTime(turned_off / count) : ""}  ({turned_off ? getIndicatorInPercent([turned_off, stopped, worked]) : ""}%)</div> }
              <div className="text-sm text-gray-500">{t('avg_turned_off')}</div>
            </div>
            <div>
              {loading ? <Skeleton /> : <div className="text-sm text-gray-700 font-semibold">{turned_off ? secondsToTime(turned_off) : ""}  ({turned_off ? getIndicatorInPercent([turned_off, stopped, worked]) : ""}%)</div> }
              <div className="text-sm text-gray-500">{t('all_turned_off')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DailyWorkWidget;