import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../../assets/canvasjs.react';
import { convertTimezone } from '../../../../utils';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;


const BruttoWeightChart = ({sensorData}) =>  {
  const [chartDataT, setChartDataT] = useState();
  const [chartDataF, setChartDataF] = useState();
  const [chartDataTeor, setChartDataTeor] = useState();

  useEffect(() => {
    if (!sensorData) {
      return;
    }
    const brT = [];
    const brF = [];
    const brTeor = [];

    sensorData.forEach(item => {
      switch (item.data_key_name) {
        case "mb_br_t":
          item.data.forEach(it => {
            brT.push({ x: new Date(it.at + convertTimezone(it.at)), y: it.v})
          })
          break;
        case "mb_br_f":
          item.data.forEach(it => {
            brF.push({ x: new Date(it.at + convertTimezone(it.at)), y: it.v})
          })
          break;
        case "mb_br_teor":
          item.data.forEach(it => {
            brTeor.push({ x: new Date(it.at + convertTimezone(it.at)), y: it.v})
          })
          break;
        default:
          break;
      }
    });

    setChartDataT(brT);
    setChartDataF(brF);
    setChartDataTeor(brTeor);
  }, [sensorData]);

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 300,
    culture:  "ru",
    axisX:{
      lineColor: '#E7ECF8',
      intervalType: "minutes",
      valueFormatString: 'HH:mm:ss',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
  };

  const options = {
    data: [{
      xValueFormatString: "HH:mm:ss",
      type:"line",
      name: "Given",
      showInLegend: true,
      color: '#363849',
      fillOpacity: .2,
      lineColor: '#363849',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartDataT
    },
    {
      xValueFormatString: "HH:mm:ss",
      type:"line",
      name: "Fact",
      showInLegend: true,
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartDataF
    },
    {
      xValueFormatString: "HH:mm:ss",
      type:"line",
      name: "Teor",
      showInLegend: true,
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartDataTeor
    }
    ]
  };

  return (
    <div className="my-4">
      <div className="flex items-center h-10">
        <span>Brutto weights</span>
      </div>
      <div className="text-sm">
        <CanvasJSChart options = {{...baseOptions, ...options}}/>
      </div>
    </div>
  )
}

export default BruttoWeightChart;
