import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CanvasJSReact from '../../../assets/canvasjs.react';
import { convertTimezone } from '../../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CounterChart = ({data}) =>  {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if(!data) {
      return
    }
    let total = 0;
    const new_data = []
    data.data.map(it => {
      total = it.v ? total + it.v : total + 0
      new_data.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: it.v
      })
    })
    setTotal(total.toFixed(2));
    setChartData(new_data);
    setLoading(false);
  },[data])

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 80,
    culture:  "ru",
    axisX:{
      includeZero: true,
      lineColor: '#E7ECF8',
      intervalType: "hours",
      valueFormatString: 'HH:mm',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      gridThickness: 0,
      tickLength: 0,
      lineThickness: 0,
      labelFormatter: function(){
        return " ";
      }
    },
    tooltip: {
      enabled: false
    }
  };

  const options = {
    data: [{
      yValueFormatString: "####0.##",
      indexLabel: "{y}",
      indexLabelFontFamily: "helvetica, arial",
      indexLabelFontColor: "#2d3436",
      indexLabelPlacement: "inside",
      xValueFormatString: "HH:mm",
      type: "column",
      color: '#55efc4',
      fillOpacity: 1,
      lineColor: '#55efc4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartData
    }]
  };
  

  return (
    <div className=" my-4 border-t">
      <div className="flex flex-row pt-2 w-full justify-between">
        <div className="">{t('counter_of_count')}</div>
        <div className="font-medium">{t('all_day')}: {Number(total).toFixed(2)}</div>
      </div>
      <div className="text-sm mt-4">
        <CanvasJSChart options = {{...baseOptions, ...options}}/>
      </div>
    </div>
  );
}

export default CounterChart;
