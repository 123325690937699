import { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';

import {CurrentUserContext} from '../../../contexts/current-user';

import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Select, {AsyncSelect} from '@atlaskit/select';
import { TimePicker } from '@atlaskit/datetime-picker';

import ErrorsBlock from '../../../components/errors';
import useDebounce from '../../../hooks/useDebounce';

const statuses = [
  {label: "Администратор", value: "root"},
  {label: "Владелец", value: "maintainer"},
  {label: "Пользователь", value: "user"}
]


export default function FormUsers({itemId, closeModal, isOpen, reloadTable, tableData, divisions}) {
  const {t} = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const user = tableData.find(item => item.id === itemId);
  const [formData, setFormData] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [division, setDivision] = useState('');
  const [email, setEmail] = useState('');
  const [personId, setPersonId] = useState('')
  const [personnel, setPersonnel] = useState('')
  const [searchPersonQuery, setSearchPersonQuery] = useState('');
  const [personsOptions, setPersonsOptions] = useState([]);
  const [status, setStatus] = useState('');
  const [errors, setErrors] = useState([]);

  const debouncedSearchTerm = useDebounce(searchPersonQuery, 500);

  const isNewItem = itemId === 'new';

  const [{response: userResponse}, doFetchUser] = useFetch('/users/account', true);
  const [{response: saveResponse, isLoading: saveIsLoading, error: saveError}, doFetchItemSave] = useFetch(`/users`);
  const [{response: updateResponse, isLoading: updateIsLoading, error: updateError}, doFetchItemUpdate] = useFetch(`/users/${itemId}`);

  const [{response: personItemResponse, isLoading: personItemIsLoading, error: personItemError}, doFetchPersonItem] = useFetch(`/directories/personnels/${personId}`);
  const [{response: personsResponse, isLoading: personsIsLoading, error: personsError}, doFetchPersons] = useFetch(`/directories/personnels?search=${searchPersonQuery}`);


  useEffect(() => {
    setFormData(new FormData());
    if (itemId === "new") {
      setFirstName('');
      setLastName('');
      setEmail('');
      return;
    }
    setFirstName(user.attributes.first_name);
    setLastName(user.attributes.last_name);
    setEmail(user.attributes.email);
    setStatus(user.attributes.status);
    setDivision(user.attributes.division_id);

    if(user?.attributes?.personnel_id === null) {
      setPersonId(null)
      setPersonnel(null)
    } else {
      setPersonId(user?.attributes?.personnel_id)
      doFetchPersonItem()
    }
  }, [itemId]);

  useEffect(() => {
    if(!personItemResponse) {
      return;
    }
    setPersonnel({label: `${personItemResponse.data.attributes.last_name} ${personItemResponse.data.attributes.first_name}`, value: personItemResponse.data.id})

  }, [personItemResponse])


  useEffect(() => {
    if(!personsResponse) {
      return;
    }
    const list = []
    personsResponse.data.map(it => {
      list.push({label: `${it.attributes.last_name} ${it.attributes.first_name}`, value: it.id})
    })
    setPersonsOptions(list)
  },[personsResponse]);

  useEffect(() => {
    if(debouncedSearchTerm === "") {
      return;
    }
    doFetchPersons();
  },[debouncedSearchTerm])


  useEffect(() => {
    if (!userResponse) {
      return;
    }
    setCurrentUserState(state => ({
      ...state,
      sites: userResponse.data.attributes.sites,
    }));
  }, [userResponse]);

  useEffect(() => {
    if (!saveResponse) {
      return;
    }
    doFetchUser();
    closeModal();
    reloadTable();
  }, [saveResponse]);

  useEffect(() => {
    if (!updateResponse) {
      return;
    }
    doFetchUser();
    closeModal();
    reloadTable();
  }, [updateResponse]);


  const onSaveClick = (data) => {
    if (isNewItem) {
      doFetchItemSave({
        method: 'POST',
        data: {
          data: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password,
            status: data.status.value,
            division_id: data.division.value != "all" ? data.division.value : null,
            personnel_id: data.personnel.value ? data.personnel.value : null
          }
        }
      });
    } else {
      doFetchItemUpdate({
        method: 'PUT',
        data: {
          data: {
            first_name: data.first_name,
            last_name: data.last_name,
            status: data.status.value,
            division_id: data.division.value != "all" ? data.division.value : null,
            personnel_id: data?.personnel?.value ? data.personnel.value : null
          }
        }
      });
    }
  };

  const searchPerson = (inputValue) => {
    if(inputValue.length < 1) {
      return;
    }
    setSearchPersonQuery(inputValue);
    return personsOptions;
  };




  const loadPersonOptions = (inputValue) => 
    new Promise((resolve) => {
      resolve(searchPerson(inputValue));
    });

  useEffect(() => {
    if(saveError) {
      setErrors(saveError.errors)
    }
    if(updateError) {
      setErrors(updateError.errors)
    }
  }, [saveError, updateError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNewItem ? t('adding_participant') : t('editing_participant')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('participant_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => onSaveClick(data)}>
                {({ formProps }) => (
                  <form {...formProps}>
                    <Field name="first_name" defaultValue={firstName ? firstName : ""} label={t('name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="last_name" defaultValue={lastName ? lastName : ""} label={t('surname')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="email" defaultValue={email ? email : ""} label={t('email')} isRequired >
                      {({ fieldProps }) => isNewItem ? <TextField {...fieldProps}/> : <TextField {...fieldProps} isDisabled/>}
                    </Field>
                    <Field name="status" defaultValue={status ? statuses.find(s => s.value == status) : statuses.find(s => s.value == "owner")} label={t('status')} isRequired>
                      {({ fieldProps }) => <Select {...fieldProps} options={statuses} className="text-sm"/>}
                    </Field>
                    <Field name="division" defaultValue={division ? divisions.find(d => d.value == division) : divisions.find(d => d.value == "all")} label={t('division')}>
                      {({ fieldProps }) => <Select {...fieldProps} options={divisions} className="text-sm"/>}
                    </Field>
                    <Field name="personnel" label={t('personnel')} defaultValue={personnel ? personnel : ""}>
                      {({ fieldProps, error, meta: { valid } }) => 
                        <AsyncSelect {...fieldProps} loadOptions={loadPersonOptions} isClearable defaultOptions={personsOptions} placeholder={t('enter_and_select_placeholder')} className="text-sm"/>
                      }
                    </Field>
                    {isNewItem ? 
                    <Field name="password" defaultValue="" label={t('password')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field> : ""
                    }
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
