import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {DateTime} from 'luxon';
import Spinner from '../../../../components/loader';
import useFetch from '../../../../hooks/use-fetch';
import useMassFetch from '../../../../hooks/use-mass-fetch';
import ReportEquipmentByDay from './by_day';
import ReportEquipmentFull from './full';
import {covertTimeToSeconds} from '../../../../utils';


const ReportEquipmentInfo = ({currentItem, startDate, endDate, convertedShift, sensors, showByDays}) => {
  const tz = DateTime.local().toFormat('ZZ');
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [usageData, setUsageData] = useState([]);
  const [sensorsData, setSensorsData] = useState([]);
  const [startDateRDString, setStartDateRDString] = useState('');
  const [endDateRDString, setEndDateRDString] = useState('');
  const [startDateU, setStartDateU] = useState();
  const [startTime, setStartTime] = useState('');
  const [endDateU, setEndDateU] = useState('');
  const [endTime, setEndTime] = useState('');
  const [shiftId, setShiftId] = useState('');
  const [urls, setUrls] = useState([]);

  const [{response, isLoading, error}, doFetchUsage] = useFetch(`/directories/ng_equipment_items_data/usage_data_by_day?from=${startDateU}&to=${endDateU}&day_time_from=${startTime}&day_time_to=${endTime}&equipment_item_ids[]=${currentItem.id} `);
  

  useEffect(() => {
    if(!endDate) {
      return
    }

    let sti = covertTimeToSeconds(convertedShift.start) * 1000
    let eti = covertTimeToSeconds(convertedShift.end) * 1000
    
    if(convertedShift.id === null) {
      setShiftId('')
      setStartTime(convertedShift.start)
      setEndTime(convertedShift.end)
    } else {
      setShiftId(convertedShift.id)
      setStartTime(convertedShift.start)
      setEndTime(convertedShift.end)
    }

    //TODO НА СЕРВЕРЕ ОШИБКА 
    if(convertedShift.start > convertedShift.end && endDate.startOf('day').ts === DateTime.local().startOf('day').ts) {
      setStartDateU(DateTime.fromMillis(startDate.ts).toISO())
      setEndDateU(DateTime.fromMillis(endDate.ts).plus({days: 1}).toISO())
    } else {
      setStartDateU(DateTime.fromMillis(startDate.ts).toISO())
      setEndDateU(DateTime.fromMillis(endDate.ts).toISO())
    }

    if(convertedShift.start < convertedShift.end) {
      setStartDateRDString(DateTime.fromMillis(startDate.startOf('day').ts + sti).toISO())
      setEndDateRDString(DateTime.fromMillis(endDate.startOf('day').ts + eti).toISO())
    } else {
      setStartDateRDString(DateTime.fromMillis(startDate.startOf('day').ts + sti).toISO())
      setEndDateRDString(DateTime.fromMillis(endDate.endOf('day').ts + eti).toISO())
    }
    
    setLoading(true);
    doFetchUsage();

  },[endDate, convertedShift, sensors])

  useEffect(()=>{
    if(!response) {
      return
    }
    setUsageData(response.data);
    const u = []
    sensors.forEach(s => {
      switch (s.attributes.sensor_type.data_key_name) {
        case 'counter':
          u.push(`/devices/sensors/${s.id}/received_data?from=${startDateRDString}&to=${endDateRDString}&capacity=one_hour`)
          break;
        case 'mb_count':
          u.push(`/devices/sensors/${s.id}/received_data?from=${startDateRDString}&to=${endDateRDString}&capacity=one_hour`)
          break;
        case 'ac_rms':
          u.push(`/devices/sensors/${s.id}/received_data?from=${startDateRDString}&to=${endDateRDString}&capacity=one_minute`)
          u.push(`/directories/equipment_items/${currentItem.id}/power_usage?from=${startDateRDString}&to=${endDateRDString}`)
          break;
        default:
          u.push(`/devices/sensors/${s.id}/received_data?from=${startDateRDString}&to=${endDateRDString}&capacity=one_minute`)
      }

      if(s.attributes.device_serial_number === "A4CF1244E620" && (s.attributes.sensor_type.data_key_name === "wire" || s.attributes.sensor_type.data_key_name === "gas")) {
        u.push(`/devices/sensors/${s.id}/received_data?from=${startDateRDString}&to=${endDateRDString}&capacity=one_minute&scalable_by=sum`)        
      }
    });
    setUrls(u);
    setLoading(false)
  },[response])

  return (
    loading ?       
    <div className="flex items-center justify-center w-full h-80">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
    </div> :
    showByDays ? 
    <ReportEquipmentByDay data={usageData} urls={urls} sensors={sensors} startDate={startDate} endDate={endDate} currentItem={currentItem} convertedShift={convertedShift}/>
    : 
    <ReportEquipmentFull data={usageData} sensors={sensors} startDate={startDate} endDate={endDate} startTime={covertTimeToSeconds(startTime) * 1000} endTime={(covertTimeToSeconds(endTime)) * 1000} currentItem={currentItem}/>
  )
}

export default ReportEquipmentInfo;