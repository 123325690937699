import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMassFetch from '../../../../hooks/use-mass-fetch';

const WeightsWidget = ({mbBruttoSensors, mbNettoSensors, dates, currentSpool}) => {
  let {t} = useTranslation();
  const [data, setData] = useState([]);
  const [bruttoUrls, setBruttoUrls] = useState();
  const [nettoUrls, setNettoUrls] = useState();

  const [{response: bruttoResponse}, doFetchBrutto] = useMassFetch(bruttoUrls)
  const [{response: nettoResponse}, doFetchNetto] = useMassFetch(nettoUrls)

  useEffect(() => {
    if(!dates || !dates[1]) {
      return
    }
    setBruttoUrls(mbBruttoSensors.map(b => `/devices/sensors/${b.id}/received_data?from=${dates[0]}&to=${dates[1]}&capacity=one_hour&scalable_by=sum`))
    setNettoUrls(mbNettoSensors.map(n => `/devices/sensors/${n.id}/received_data?from=${dates[0]}&to=${dates[1]}&capacity=one_hour&scalable_by=sum`))

    doFetchBrutto()
    doFetchNetto()
  }, [dates])

  useEffect(() => {
    if(!bruttoResponse || !bruttoResponse.length || !nettoResponse || !nettoResponse.length) {
      return
    }

    buildWeights()

  },[bruttoResponse, mbBruttoSensors, mbNettoSensors, nettoResponse, currentSpool])


  function buildWeights() {
    let bData = {name: 'mb_br'}
    let nData = {name: 'mb_nt'}

    mbBruttoSensors.map(s => {
      let sd = bruttoResponse.find(i => i.data.id == s.id).data.attributes
      let sdData = []
      if(!currentSpool) {
        sdData = sd.data
      } else {
        sdData = sd.data.filter(i => currentSpool.hours.includes(i.at))
      }
      if(sd.sensor_type.data_key_name === 'mb_br_t') {
        bData = {...bData, spec: (sdData.reduce( function(acc, b){return acc + (b.v ? b.v : 0)}, 0) / 1000).toFixed(2)}
      }
      if(sd.sensor_type.data_key_name === 'mb_br_teor') {
        bData = {...bData, teor: (sdData.reduce( function(acc, b){return acc + (b.v ? b.v : 0)}, 0) / 1000).toFixed(2)}
      }
      if(sd.sensor_type.data_key_name === 'mb_br_f') {
        bData = {...bData, fact: (sdData.reduce( function(acc, b){return acc + (b.v ? b.v : 0)}, 0) / 1000).toFixed(2)}
      }
    })

    mbNettoSensors.map(s => {
      let sd = nettoResponse.find(i => i.data.id == s.id).data.attributes
      let sdData = []
      if(!currentSpool) {
        sdData = sd.data
      } else {
        sdData = sd.data.filter(i => currentSpool.hours.includes(i.at))
      }
      if(sd.sensor_type.data_key_name === 'mb_nt_t') {
        nData = {...nData, spec: (sdData.reduce( function(acc, b){return acc + (b.v ? b.v : 0)}, 0) / 1000).toFixed(2)}
      }
      if(sd.sensor_type.data_key_name === 'mb_nt_f') {
        nData = {...nData, fact: (sdData.reduce( function(acc, b){return acc + (b.v ? b.v : 0)}, 0) / 1000).toFixed(2)}
      }
      if(sd.sensor_type.data_key_name === 'mb_mn_teor') {
        nData = {...nData, teor: (sdData.reduce( function(acc, b){return acc + (b.v ? b.v : 0)}, 0) / 1000).toFixed(2)}
      }
    })
    setData([bData, nData])
  }

  return (
    data.map(it => (
      <div key={it.name} className='grid grid-cols-4 h-8 items-center border-t px-2'>
        <span className='font-medium'>{t(it.name)}</span>
        <div className='text-right'><span className='text-gray-500 mr-2'>{t('actual')}</span><span className='font-medium'>{it.fact} {t('kg')}</span></div>
        <div className='text-right'><span className='text-gray-500 mr-2'>{t('teor')}</span><span className='font-medium'>{it.teor} {t('kg')}</span></div>
        <div className='text-right'><span className='text-gray-500 mr-2'>{t('total')}</span><span className='font-medium'>{Number.parseFloat(it.teor) + Number.parseFloat(it.fact)} {t('kg')}</span></div>
      </div>
    ))
  )
}
export default WeightsWidget;