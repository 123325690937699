import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, RangeField, CheckboxField, Fieldset } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { Checkbox } from '@atlaskit/checkbox';

import useFetch from '../../../hooks/use-fetch';
import CollectionSelectField from './field/CollectionSelectField';
import CollectionMultiSelectField from './field/CollectionMultiSelectField';
import SelectField from './field/SelectField';
import MultiSelectField from './field/MultiSelectField';

import Spinner from '../../loader';

const FieldsConstructor = ({fields, setFields, typeFields}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [fileUuid, setFileUuid] = useState('');

  const [{response: fileUploadResponse, isLoading: fileUploadIsLoading, error: fileUploadError}, doFetchFileUpload] = useFetch(`/uploads`, true);


  useEffect(() => {
    if (!fileUploadResponse) {
      return;
    }
    let newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === fileUuid);
    newFieldTypes[index].data.value = fileUploadResponse;
    setFields(newFieldTypes);
  }, [fileUploadResponse, fileUploadIsLoading]);

  const handleNumericField = (uuid, value) => {
    const newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    newFieldTypes[index] = {...newFieldTypes[index], data: {value: value}}
    setFields(newFieldTypes);
  };

  const handleStringField = (uuid, value) => {
    const newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    newFieldTypes[index] = {...newFieldTypes[index], data: {value: value}}
    setFields(newFieldTypes);
  };

  const handleRangeMinField = (uuid, min) => {
    const newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    const data = newFieldTypes[index].data ? {...newFieldTypes[index].data, min: min} : {min: min}
    newFieldTypes[index] = { ...newFieldTypes[index], data}
    console.log(newFieldTypes[index])
    setFields(newFieldTypes);
  };

  const handleRangeMaxField = (uuid, max) => {
    const newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    const data = newFieldTypes[index].data ? {...newFieldTypes[index].data, max: max} : {max: max}
    newFieldTypes[index] = { ...newFieldTypes[index], data}
    setFields(newFieldTypes);
  };

  const handleCheckboxField = (uuid, value) => {
    const newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    newFieldTypes[index] = {...newFieldTypes[index], data: {value: value}}
    setFields(newFieldTypes);
  };

  const handleSelectField = (uuid, id) => {
    let newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    newFieldTypes[index] = {...newFieldTypes[index], data: {value: id}}
    setFields(newFieldTypes);
  };

  const handleSelectMultipleField = (uuid, values) => {
    const newFieldTypes = fields.slice();
    const index = newFieldTypes.findIndex(it => it.uuid === uuid);
    newFieldTypes[index] = {...newFieldTypes[index], data: {value: values}}
    setFields(newFieldTypes);
  };

  const handleFileField = (uuid, file) => {
    setFileUuid(uuid);
    const formData = new FormData();
    formData.set('attachment', file);
    doFetchFileUpload({
      method: 'POST',
      data: formData
    });
  };

  const getDefaultValue = (fid) => {
    const field = fields.find(field => field.uuid === fid);
    if(field.data && field.data.value !== null) {
      return field.data.value
    } else {
      return field.default_value
    }
  }

  const getDefaultValueSelect = (fid) => {
    const field = fields.find(field => field.uuid === fid);
    if(field.type == 'select' || field.type == 'collection') {
      if(field.data && field.data.value !== null) {
        return field.data.value
      } else {
        return ""
      }
    } else {
      if(field.data && field.data.value !== null) {
        return field.data.value
      } else {
        return []
      }
    }
  }

  const getDefaultRange = (fid, type) => {
    const field = fields.find(field => field.uuid === fid);
    if (type === "min") {
      if(field.data && field.data.min !== null) {
        return field.data.min
      } else {
        let valueArr = [];
        if (field.default_value) {
          valueArr = field.default_value.split('-');
        } else {
          valueArr = ['', '']
        }
        return valueArr[0]
      }
    } else {
      if(field.data && field.data.max !== null) {
        return field.data.max
      } else {
        let valueArr = [];
        if (field.default_value) {
          valueArr = field.default_value.split('-');
        } else {
          valueArr = ['', '']
        }
        return valueArr[1]
      }
    }
  }

  const getField = (field) => {
    switch (field.type) {
      case 'numeric':
        return ( 
          <Field key={field.uuid} name={field.uuid} defaultValue={field.data?.value ? getDefaultValue(field.uuid) : getDefaultValue(field.uuid)} label={field.label} isRequired={field.required}>
            {({ fieldProps }) => 
              <TextField type="number" 
              {...fieldProps} 
              onChange={(e) => handleNumericField(field.uuid, e.target.valueAsNumber)} />
            }
          </Field>
        )
      case "string":
        return ( 
          <Field key={field.uuid} name={field.uuid} defaultValue={field.data?.value ? getDefaultValue(field.uuid) : getDefaultValue(field.uuid)} label={field.label} isRequired={field.required}>
            {({ fieldProps }) => <TextField {...fieldProps} onChange={(evt) => handleStringField(field.uuid, evt.target.value)}/>}
          </Field>
        )
      case "range":
        return ( 
          <div key={field.uuid} className="grid grid-cols-2 gap-4">
            <Field name={`${field.uuid}[data][min]`} label={`${field.label} ${t('range_from')}`} defaultValue={field.data?.min ? getDefaultRange(field.uuid, "min") : getDefaultRange(field.uuid, "min")} isRequired={field.required}>
              {({ fieldProps }) => <TextField {...fieldProps} onChange={(evt) => handleRangeMinField(field.uuid, evt.target.value)}/>}
            </Field>
            <Field name={`${field.uuid}[data][max]`} label={`${field.label} ${t('range_to')}`} defaultValue={field.data?.max ? getDefaultRange(field.uuid, "max") : getDefaultRange(field.uuid, "max")} isRequired={field.required}>
              {({ fieldProps }) => <TextField {...fieldProps} onChange={(evt) => handleRangeMaxField(field.uuid, evt.target.value)}/>}
            </Field>
          </div>
        )
      case "checkbox":
        return ( 
          <div key={field.uuid} className="flex items-center pt-6 text-sm">
            <CheckboxField  className="fi" name={field.uuid} isRequired={field.required } >
              {({ fieldProps }) => <Checkbox className="in" size="medium" {...fieldProps} label={field.label} isChecked={field.data?.value ? getDefaultValue(field.uuid) : getDefaultValue(field.uuid)} onChange={(evt) => handleCheckboxField(field.uuid, evt.target.checked)}/>}
            </CheckboxField>
          </div>
        )
      case 'select':
        return ( 
          <SelectField 
            key={field.uuid} 
            fid={field.uuid}
            label={field.label}
            list={field.options}
            value={field.data?.value ? getDefaultValueSelect(field.uuid) : getDefaultValueSelect(field.uuid)}
            required={field.required} 
            setCurrentSelect={handleSelectField}/>
        )
      case 'select_multiple':
        return (   
          <MultiSelectField 
            key={field.uuid}
            fid={field.uuid}
            label={field.label}
            list={field.options}
            values={field.data?.value ? getDefaultValueSelect(field.uuid) : getDefaultValueSelect(field.uuid)}
            required={field.required} 
            setCurrentSelect={handleSelectMultipleField}/>
        )
      case 'collection':
        return ( 
          <CollectionSelectField 
            key={field.uuid} 
            slug={field.collection_name}
            fid={field.uuid}
            label={field.label}
            value={field.data?.value ? getDefaultValueSelect(field.uuid) : getDefaultValueSelect(field.uuid)}
            required={field.required} 
            setCurrentSelect={handleSelectField}/>
        )
      case 'collection_multiple':
        return ( 
          <CollectionMultiSelectField 
            key={field.uuid} 
            slug={field.collection_name}
            fid={field.uuid}
            label={field.label}
            values={field.data?.value ? getDefaultValueSelect(field.uuid) : getDefaultValueSelect(field.uuid)}
            required={field.required} 
            setCurrentSelect={handleSelectMultipleField}/>
        )
      case "file":
        return ( 
          <div key={field.uuid} className="text-sm w-full mt-2 block">
            <div className="text-gray-500 font-semibold mb-1 leading-5">{field.label}</div>
            <div className="relative">
              <div className="flex flex-row justify-between items-center h-10">
                <div className="text-sm text-gray-600 whitespace-nowrap truncate font-medium">
                  <div>{field.data?.value ? field.data?.value.data.attributes.filename : t('choose_file')}</div>
                </div>
                <div className="bg-gray-100 rounded text-sm text-gray-800 px-4 py-2">
                  <span className="font-medium">{t('upload')}</span>
                </div>
              </div>
              <input
                type="file"
                className="absolute cursor-pointer opacity-0 block w-full h-10 top-0"
                onChange={(evt) => handleFileField(field.uuid, evt.target.files[0])}
              />
            </div>
          </div>
        )     
    }
  }



  const items = typeFields.map(field => {
    if (field.type === 'folder') {
      const childItems = [];
      field.children.map(child => {
        childItems.push(getField(child))
      })
      return (
        <div key={field.uuid} className="col-span-full pt-4 mb-4 pb-4 border-b-2">
          <div className="text-sm font-normal border-b-2 py-2">{field.label}</div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-4">
            {childItems}
          </div>
        </div>
      )
    }
    return (
      getField(field)
    )
  });

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-4">
      {items}
    </div>
  )

};

export default FieldsConstructor;