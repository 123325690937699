const getMedianGraph = (data, tail, threshold) => {

  let output_data = []
  let full_length = data.attributes.data.length
  let full_data = data.attributes.data.slice(1, full_length - 1)
  let before = Math.floor(tail / 2)
  let after = Math.ceil(tail / 2)

  let idle = 0
  let work = 0


  full_data.map((it, idx) => {
    output_data.push({at: it.at, median: calculateMedian(full_data.slice(idx - before, idx + after).map(i => i.v))})
  })

  output_data.map(it => {
    if(it.median > threshold) {
      return work = work + 1
    } else {
      return idle = idle + 1
    }
  })

  // let miadianArray = []

  // output_data.map(it => {
  //   return miadianArray.push(it.median > threshold ? 1 : 0)
  // });

  // miadianArray.map((v, i) => {
  //   let summa = Math.sum(miadianArray.slice(i, i+tail+1))
  //   summa > 0 ? 1 : 0 
  // })

  // console.log(miadianArray)

  return {idle: idle, work: work}
}

function calculateAvg(arr) {
  let sum = arr.reduce((a, b) => a + b, 0);
  let avg = (sum / arr.length) || 0;
  return Math.ceil(avg)
}

function calculateMedian(arr) {
  if (arr.length == 0) {
    return; // 0.
  }
  arr.sort((a, b) => a - b); // 1.
  const midpoint = Math.floor(arr.length / 2); // 2.
  const median = arr.length % 2 === 1 ?
    arr[midpoint] : // 3.1. If odd length, just take midpoint
    (arr[midpoint - 1] + arr[midpoint]) / 2; // 3.2. If even length, take median of midpoints
  return  Math.ceil(median);
}

// часто встречающееся значение
function calculateModa(a) {
  a = a.slice().sort((x, y) => x - y);

  let bestStreak = 1;
  let bestElem = a[0];
  let currentStreak = 1;
  let currentElem = a[0];

  for (let i = 1; i < a.length; i++) {
    if (a[i-1] !== a[i]) {
      if (currentStreak > bestStreak) {
        bestStreak = currentStreak;
        bestElem = currentElem;
      }

      currentStreak = 0;
      currentElem = a[i];
    }

    currentStreak++;
  }

  return currentStreak > bestStreak ? currentElem : bestElem;
};

export {
  getMedianGraph
}