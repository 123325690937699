import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCloudCheckFill, BsCloudSlashFill, BsFillCloudFill } from "react-icons/bs";


import Table from '../../../components/form/table';
import { PencilAltIcon, TrashIcon, LinkIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import Spinner from "../../../components/loader";
import {getPerfectDate} from "../../../utils";

const DevicesTable = ({ tableData, onItemDelete, onItemRecived, onItemUnlink}) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    const itemDate = new Date(tableItem.attributes.initialized_at);

    const st = [];

    tableItem.attributes.sensor_types.forEach(item => {
      st.push(t(item.name));
    });

    return (
      {
        id: tableItem.id,
        serial_number: tableItem.attributes.serial_number,
        name: tableItem.attributes.name,
        model: tableItem.attributes.model,
        types: st,
        sensor_types: tableItem.attributes.sensor_types,
        status: tableItem.attributes.online,
        installation_date: getPerfectDate(itemDate),
        site_id: tableItem.attributes.site_id,
        site_name:  tableItem.attributes.site_name,
        receive_data: tableItem.attributes.receive_data
      }
    )
  });

  const columns = [
    {
      label: `${t('serial_number')} / ${t('model')}`,
      prop: "serial_number",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: ({serial_number, model, name}) => {
        return (
          <div>
            <div className="font-medium text-gray-900">
              {serial_number}
            </div>
            <div className="text-gray-500">
              {model}
            </div>
          </div>
        )
      }
    },
    {
      label: t('data_types'),
      prop: "types",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: ({types}) => {return <div className="flex whitespace-pre-line">{types.join(', ')}</div>}
    },
    {
      label: t('company'),
      prop: "company",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => {
        return (
          <div>
            <div className="font-medium text-gray-900">
              {row.site_name}
            </div>
            <div className="text-gray-500">
              {row.installation_date}
            </div>
            <div className="text-gray-500">
              {row.name}
            </div>
          </div>
        )
      }
    },
    {
      label: t('status'),
      prop: "status",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: ({status}) => {
        return (
          <span className={`${status ? 'text-green-500' : 'text-red-500'} px-2`}
          >
            {status ? <CheckCircleIcon className='w-5 h-5'/> : <ExclamationCircleIcon className='w-5 h-5'/>}
          </span>
        )
      }
    },
    {
      label: t('receive_data'),
      prop: "receive_data",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: ({receive_data}) => {
        return (
          <span className={`${receive_data ? 'text-green-500' : 'text-red-500'} px-2`}
          >
            {receive_data ? <BsCloudCheckFill className='w-5 h-5' /> : <BsCloudSlashFill className='w-5 h-5'/>}
          </span>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "invisible w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            {row.site_id ? <button 
              onClick={() => onItemUnlink(row.id)} 
              className="text-green-600 hover:text-green-900 cursor-pointer w-8 h-8 bg-green-100 hover:bg-green-200 flex items-center justify-center rounded ">
              <LinkIcon className="w-4 h-4"/>
            </button> : "" }
            <button 
              onClick={() => onItemRecived(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <BsFillCloudFill className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    },
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );
};

export default DevicesTable;
