import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import PageFilter from '../../../components/page_filter';
import useFetch from '../../../hooks/use-fetch';
import Layout from '../../../components/layout';
import PostsTable from "./table";
import AddPost from './add';
import Spinner from '../../../components/loader';
import Filters from '../../../components/filters';

const Posts = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [itemId, setItemId] = useState('new');
  const [pageSearch, setPageSearch] = useState('');
  const [params, setParams] = useState(new URLSearchParams({}))
  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error}, doFetchTableData] = useFetch(`/directories/posts?${params.toString()}`);
  const [{response: itemDeleteResponse, isLoading: itemDeleteIsLoading, error: itemDeleteError}, doFetchItemDelete] = useFetch(`/directories/posts/${itemId}`);

  const headerData = {
    title: t('positions'),
    buttonsList: [
      {
        text: t('create_position'),
        action: () => openModal()
      }
    ],
  };

  const filtersData = {
    search: {
      placeholder: 'Поиск',
      value: pageSearch,
      onChange: setPageSearch
    }
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    pageSearch ? newParams.append('search', pageSearch) : newParams.delete('search')

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [pageSearch])

  useEffect(() => {
    if (itemDeleteIsLoading) {
      return;
    }
    doFetchTableData();
  }, [itemDeleteIsLoading]);

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);


  const onItemEdit = (id) => {
    history.push({
      pathname: `/positions/${id}`,
    });
  };

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
    reloadTable();
  };

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    doFetchTableData();
  };

  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      <div className="content pb-10">
        {loading ? <Spinner/> :
          <PostsTable
            tableData={tableData}
            onItemEdit={onItemEdit}
            onItemDelete={onItemDelete}
            isLoading={isLoading}
            error={error}
          />
        }
      </div>
      <AddPost closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable}/>
    </Layout>
  );
};

export default Posts;
