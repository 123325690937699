import { Fragment, useState, useContext, useEffect } from 'react';
import useFetch from '../../hooks/use-fetch';
import { useTranslation } from 'react-i18next';

import { Dialog, Transition } from '@headlessui/react';
import Form, {ErrorMessage, Field, CheckboxField} from "@atlaskit/form";
import { Checkbox } from '@atlaskit/checkbox';
import TextField from '@atlaskit/textfield';

import ErrorsBlock from '../../components/errors';

export default function AddDirectory({itemId, closeModal, isOpen, reloadTable}) {
  const {t} = useTranslation();
  const [name, setName] = useState('');
  const [showInMenu, setShowInMenu] = useState(false);
  const [hierarchic, setHierarchic] = useState(false);

  const [errors, setErrors] = useState([]);
  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/directories/directories`);

  const handleUpdate = (data) => {
    onSave({name: name, show_in_menu: showInMenu, hierarchic: hierarchic})
  }

  const onSave = (data) => {
    doFetchItemSave({
      method: 'POST',
      data: {
        data: data
      }
    });
  };

  useEffect(() => {
    if (itemSaveResponse) {
      closeModal();
    }
    reloadTable();
  }, [itemSaveResponse]);

  useEffect(() => {
    if (itemSaveError) {
      setErrors(itemSaveError.errors)
    }
  }, [itemSaveError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('create_directory')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('directory_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <div className="text-sm">
              <Field name="name" label={t('directory_name')}>
                {({ fieldProps, error }) => (
                  <>
                    <TextField
                      autoComplete="off"
                      {...fieldProps}
                      onChange={(evt) => setName(evt.target.value)}
                      value={name === null ? '' : name}
                    />
                    {error && (
                      <ErrorMessage>
                        This user name is already in use, try another one.
                      </ErrorMessage>
                    )}
                  </>
                )}
              </Field>
              </div>
              <div className="flex flex-row text-sm space-x-2">
                <CheckboxField name="show_in_menu">
                  {({ fieldProps, error }) => (
                    <Checkbox {...fieldProps} 
                      label={t('show_in_menu')}
                      isChecked={showInMenu}
                      onChange={() => setShowInMenu(!showInMenu)}
                    />
                  )}
                </CheckboxField>
                <CheckboxField name="hierarchic" >
                  {({ fieldProps, error }) => (
                    <Checkbox {...fieldProps} 
                      label={t('hierarchic')}
                      isChecked={hierarchic}
                      onChange={() => setHierarchic(!hierarchic)}
                    />
                  )}
                </CheckboxField>
              </div>
              <div className="flex justify-between mt-8">
                <button 
                  type="submit" 
                  onClick={() => handleUpdate()}
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  {t('save')}
                </button>
                <button 
                  onClick={() => closeModal()}
                  type="button" 
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  {t('cancel')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
