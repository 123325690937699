import { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import useFetch from '../../../../hooks/use-fetch';

import {CurrentUserContext} from '../../../../contexts/current-user';

import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Select from '@atlaskit/select';

import ErrorsBlock from '../../../../components/errors';


export default function FormSensor({item, closeModal, isOpen, reloadTable}) {
  const { t } = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [formData, setFormData] = useState('');
  const [name, setName] = useState('');
  const [longName, setLongName] = useState('');
  const [shortName, setShortName] = useState('');
  const [dataKeyName, setDataKeyName] = useState('');
  const [chartType, setChartType] = useState('');
  const [errors, setErrors] = useState([]);

  const isNew = item === '';

  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/administration/directories/sensor_types/${item.id}`, true);
  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/administration/directories/sensor_types`, true);

  useEffect(() => {
    setFormData(new FormData());
    if (!item) {
      setName('');
      setLongName('');
      setShortName('');
      setDataKeyName('');
      setChartType('');
      return;
    }
    setName(item.attributes.name);
    setLongName(item.attributes.long_name);
    setShortName(item.attributes.short_name);
    setDataKeyName(item.attributes.data_key_name);
    setChartType(item.attributes.chart_type);
  }, [item]);


  useEffect(() => {
    if (!itemSaveResponse) {
      return;
    }
    closeModal();
    reloadTable();
  }, [itemSaveResponse]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    closeModal();
    reloadTable();
  }, [itemUpdateResponse]);


  const onSaveClick = (data) => {
    if (isNew) {
      doFetchItemSave({
        method: 'POST',
        data: {
          data: data
        }
      });
    } else {
      doFetchItemUpdate({
        method: 'PUT',
        data: {
          data: data
        }
      });
    }
  };

  useEffect(() => {
    if (itemSaveError) {
      setErrors(itemSaveError.errors)
    }
  }, [itemSaveError]);

  useEffect(() => {
    if (itemUpdateError) {
      setErrors(itemUpdateError.errors)
    }
  }, [itemUpdateError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNew ? t("adding_model") : t("editing_model")}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t("model_form_text")}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => onSaveClick(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="name" defaultValue={name || ""} label={t('title_name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="long_name" defaultValue={longName || ""} label={t('long_name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="short_name" defaultValue={shortName || ""} label={t('short_name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="data_key_name" defaultValue={dataKeyName || ""} label={t('data_key_name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="chart_type" defaultValue={chartType || ""} label={t('chart_type')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
