import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import TextField from '@atlaskit/textfield';
import Form, { Field, CheckboxField, ErrorMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from "../../../../../contexts/current-user";
import useFetch from '../../../../../hooks/use-fetch';
import FieldsConstructor from '../../../../../components/form/fields';

const StaffSettings = ({ currentItem, onUpdate }) => {
  const {t} = useTranslation();
  const today = new Date().toISOString().slice(0, 10);
  const [currentFileField, setCurrentFileField] = useState({});
  const [imageId, setImageId] = useState('');
  const [image, setImage] = useState({});
  const [postFields, setPostFields] = useState([]);
  const [fields, setFields] = useState([]);

  const [{response: imageUploadResponse, isLoading: imageUploadIsLoading, error: imageUploadError}, doFetchImageUpload] = useFetch(`/uploads`, true);

  useEffect(() => {
    setImageId(currentItem.attributes.image.id || '')
    generateFields(currentItem.attributes.fields);
    setPostFields(currentItem.attributes.fields);
  },[])

  const generateFields = (arr) => {
    const newFields = [];
    arr.forEach((item) => {
      newFields.push(item);
      if (item.children) {
        item.children.forEach((childItem) => {
          newFields.push(childItem);
        });
      }
    });
    setFields(newFields);
  };


  const onFileChoice = (file) => {
    const formData = new FormData();
    formData.set('attachment', file);
    doFetchImageUpload({
      method: 'POST',
      data: formData
    });
  };

  useEffect(() => {
    if (!imageUploadResponse) {
      return;
    }
    setImageId(imageUploadResponse.data.id)
    setImage(imageUploadResponse.data)
  }, [imageUploadResponse]);

  const handleUpdate = (formData) => {    
    const newFieldsData = {};
    fields.forEach(it => {
      if(it.data === undefined) {
        return
      }
      newFieldsData[it.uuid] = it.data
    });
    onUpdate(
      {
        first_name: formData.first_name,
        last_name: formData.last_name,
        middle_name: formData.middle_name,
        birth_date: formData.birth_date,
        personnel_number: formData.personnel_number,
        image: image,
        fields: newFieldsData
      }
    )
  }

  return (
    <div className="mt-4">
      <div className="bg-white flex">
        <div className="mb-4 w-32 h-32">
          {imageId === '' ? <Avatar name={`${currentItem.attributes.last_name} ${currentItem.attributes.first_name}`} className="rounded"/> : (
            <img src={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${imageId}`} alt="" className="h-full w-auto rounded object-cover object-center max-w-32"/>
          )}
        </div>
        <div className="ml-10 flex flex-col">
          <span className="text-gray-800 text-xs font-light mb-4">{t('avatar_upload_text')}</span>
          <label className="cursor-pointer">
            <span className="mt-2 leading-normal px-4 py-2 text-blue-900 bg-blue-100 text-xs rounded-full" >{t('avatar_upload')}</span>
            <input
              type="file"
              className="hidden"
              onChange={(evt) => onFileChoice(evt.target.files[0])}
            />
          </label>
          
        </div>
      </div>

      <Form onSubmit={data => handleUpdate(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} >
            <div className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1">
              <Field name="last_name" defaultValue={currentItem.attributes.last_name || ""} label={t('surname')} isRequired>
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field name="first_name" defaultValue={currentItem.attributes.first_name || ""} label={t('name')} isRequired>
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field name="middle_name" defaultValue={currentItem.attributes.middle_name || ""} label={t('middlename')}>
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field name="birth_date" defaultValue={currentItem.attributes.birth_date || today} label={t('birth_date')} className="text-sm">
                {({ fieldProps }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={today}/>}
              </Field>
              <Field name="personnel_number" defaultValue={currentItem.attributes.personnel_number || ""} label={t('personnel_number')} isRequired>
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
            </div>
            {fields.length > 0 ? 
              <>
                <div className="pt-8 pb-2 text-sm font-medium">{t('additional')}</div>  
                <FieldsConstructor fields={fields} setFields={setFields} typeFields={postFields}/>
              </>
              : ""
            }
            <div className="flex justify-between mt-8">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("update")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
    </div>
  )
};

export default StaffSettings;
