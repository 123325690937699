import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { ChartPieIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import moment from 'moment';
import Spinner from "../../../../components/loader";
import { getPerfectDate } from "../../../../utils";
import Table from '../../../../components/form/table';


const EquipmentTrackingTable = ({isLoading, error, data, onItemClick, onItemEditClick }) => {
  const {t} = useTranslation();
  
  return (
    data.map(item => (
      <div key={item.id} className="flex w-full text-gray-800 text-sm py-2 px-2 hover:bg-blue-50 cursor-default border-b items-center" onClick={()=> onItemClick(item)}>
        <div className='flex font-medium py-0.5 col-span-2 flex-col w-full'>
          <div className='flex w-full justify-between items-center'>
            <div>{item.equipment_type_name}, {item.producer_name}, {item.equipment_model_name}, {item.inventory_number}</div>
            <div className='font-normal'>{item.move_status_changed_at ? moment(item.move_status_changed_at).fromNow() : " -"}</div>
          </div>
          <div>
            <div className='flex space-x-4 py-0.5'>
              <div className={`flex flex-row col-span-1 space-x-2 font-medium
                ${item.move_status === 'usage' ? "text-green-600" : ""}
                ${item.move_status === 'storage' ? "text-yellow-700" : ""}
                ${item.move_status === 'moving' ? "text-purple-600" : ""}
                ${item.move_status === 'repair' ? "text-blue-600" : ""}
              `}>
                <div>{t(`move_${item.move_status}`)}</div>
              </div>
              <div className='flex flex-row col-span-1 space-x-2'>
                <div>{item.status ? t(item.status) : " -"}</div>
              </div>
            </div>
            <div className='flex flex-row col-span-2 justify-between'>
              <div>
                {/* <div className='text-gray-600 mr-1'>{t('responsible')}:</div> */}
                <div>{item.responsible ? item.responsible : "-"}</div>
              </div>
              <div className='flex gap-4'>
                <button className='bg-blue-200 text-blue-600 px-3 py-0.5 rounded-md' onClick={()=> onItemEditClick(item)}>Просмотр</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  )
};

export default EquipmentTrackingTable;
