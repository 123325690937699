import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';
import Avatar from 'react-avatar';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { DatePicker } from '@atlaskit/datetime-picker';

import ErrorsBlock from '../../../components/errors';

export default function AddStaff({closeModal, isOpen, reloadTable, postsList, divisionsList}) {
  const {t} = useTranslation();
  const today = new Date().toISOString().slice(0, 10);
  const [imageId, setImageId] = useState('');
  const [image, setImage] = useState('');
  const [errors, setErrors] = useState([]);

  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/directories/personnels`);
  const [{response: imageUploadResponse, isLoading: imageUploadIsLoading, error: imageUploadError}, doFetchImageUpload] = useFetch(`/uploads`, true);

  const onSaveClick = (data) => {
    doFetchItemSave({
      method: 'POST',
      data: {
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          middle_name: data.middle_name,
          birth_date: data.birth_date,
          personnel_number: data.personnel_number,
          image: image
        }
      }
    });
  };

  const onFileChoice = (value) => {
    const formData = new FormData()
    formData.set('attachment', value);
    doFetchImageUpload({
      method: 'POST',
      data: formData
    });
  };

  useEffect(() => {
    if (!imageUploadResponse) {
      return;
    }
    setImageId(imageUploadResponse.data.id)
    setImage(imageUploadResponse.data)
  }, [imageUploadResponse]);


  useEffect(() => {
    if (itemSaveResponse) {
      closeModal();
    }
    reloadTable();
  }, [itemSaveResponse]);

  useEffect(() => {
    if (itemSaveError) {
      setErrors(itemSaveError.errors)
    }
  }, [itemSaveError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full lg:w-1/4 p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('adding_staff')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('adding_staff_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <div className="mt-4">
                <div className="bg-white flex">
                  <div className="mb-4 w-32 h-32">
                    {imageId === '' ? <Avatar name="" /> : (
                      <img src={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${imageId}`} alt="" className="w-auto mx-auto rounded-lg object-cover object-center"/>
                    )}
                  </div>
                  <div className="ml-10 flex flex-col">
                    <span className="text-gray-800 text-xs font-light mb-4">{t('avatar_upload_text')}</span>
                    <label className="cursor-pointer">
                      <span className="mt-2 leading-normal px-4 py-2 text-blue-900 bg-blue-100 text-xs rounded-full" >{t('avatar_upload')}</span>
                      <input
                        type="file"
                        className="hidden"
                        onChange={(evt) => onFileChoice(evt.target.files[0])}
                      />
                    </label>
                    
                  </div>
                </div>
              </div>
              <Form onSubmit={data => onSaveClick(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="last_name" defaultValue="" label={t('surname')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="first_name" defaultValue="" label={t('name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="middle_name" defaultValue="" label={t('middlename')}>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>
                    <Field name="birth_date" defaultValue="" label={t('birth_date')} className="text-sm"  isRequired>
                      {({ fieldProps }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={today}/>}
                    </Field>
                    <Field name="personnel_number" defaultValue="" label={t('personnel_number')}>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>

                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
