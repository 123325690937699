import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import useFetch from '../../../hooks/use-fetch';
import Entries from '../entries';

const Directory = (props) => {
  const [pageTitle, setPageTitle] = useState('');
  const [currentItem, setCurrentItem] = useState('');
  const [loading, setLoading] = useState(true);
  const [slug, setSlug] = useState(props.match.params.slug);

  const [{response, isLoading, error}, doFetchDirectory] = useFetch(`/directories/directories`);

  useEffect(() => {
    doFetchDirectory();
    setSlug(props.match.params.slug);
  }, [props.match.params.slug]);

  useEffect(() => {
    if (!response) {
      return;
    }
    setCurrentItem(response.data.find(item => item.attributes.slug === slug));
    setPageTitle(response.data.find(item => item.attributes.slug === slug).attributes.name);
    setLoading(false);
  }, [response]);

  const headerData = {
    title: pageTitle
  };

  return (
    <>
    {loading ? <Spinner /> :
      <Layout
        headerData={headerData}
        isWide={true}
      >
        <Entries 
          currentItem={currentItem}
          slug={slug}
        /> 
      </Layout> 
    }
    </>
  );
};

export default Directory;
