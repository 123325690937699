import React, {useState, useEffect, useRef, useContext} from 'react';
import { useTranslation } from 'react-i18next';
// import HeatMap from '@uiw/react-heat-map';
import HeatMap from '../../../../components/heatmap';
import Tooltip from '@uiw/react-tooltip';
import {DateTime, Info} from 'luxon';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import useFetch from '../../../../hooks/use-fetch';
import { getPerfectDate } from '../../../../utils';

import TotalByPeriod from './total_by_period';

const today = DateTime.local()

const PersonWorkCalendar = ({person, startDate, endDate, setStartDate, setEndDate, convertedShift}) => {
  const {t} = useTranslation();
  const heatmapRef = useRef()

  const [selectedDate, setSelectedDate] = useState([startDate, endDate])
  const [startTime, setStartTime] = useState(DateTime.fromFormat(convertedShift.start, "HH:mm:ss").toISO())
  const [endTime, setEndTime] = useState(DateTime.fromFormat(convertedShift.end, "HH:mm:ss").toISO())
  const [data, setData] = useState()
  const [intensivity, setIntensivity] = useState([]);
  const [intensivityLoaded, setIntensivityLoaded] = useState(false);
  const [intensivityStartDate, setIntensivityStartDate] = useState(DateTime.local().endOf("month").minus({'year': 1}));
  const [intensivityEndDate, setIntensivityEndDate] = useState(DateTime.local().endOf("month"));

  // const [{response: intensivityResponse, isLoading: intensivityIsLoading, error: intensivityError}, doFetchIntensivity] = useFetch(`/directories/personnels/${person.id}/usage?from=${intensivityStartDate}&to=${intensivityEndDate}&day_time_from=${startTime}&day_time_to=${endTime}`);
  const [{response: intensivityResponse, isLoading: intensivityIsLoading, error: intensivityError}, doFetchIntensivity] = useFetch(`/directories/ng_personnel_data/usage_data_by_day?personnel_ids[]=${person.id}&from=${intensivityStartDate}&to=${intensivityEndDate}&day_time_from=${startTime}&day_time_to=${endTime}`);

  useEffect(()=>{
    setIntensivityLoaded(false)
    doFetchIntensivity()
  },[convertedShift])



  useEffect(()=>{
    setSelectedDate([startDate, endDate])
    setStartTime(DateTime.fromFormat(convertedShift.start, "HH:mm:ss").toISO())
    setEndTime(DateTime.fromFormat(convertedShift.end, "HH:mm:ss").toISO())
  },[startDate, endDate, convertedShift])

  useEffect(() => {
    if (!intensivityResponse || !intensivityResponse.data) {
      return;
    }

    setData(intensivityResponse.data)

    let obj = []
    intensivityResponse.data.map(val => {
      obj.push({date: DateTime.fromSQL(val.at).ts, count: val.worked})
    })

    setIntensivity(obj);
    setIntensivityLoaded(true)
  }, [intensivityResponse]);

  const increaseYear = () => {
    setIntensivityStartDate(intensivityStartDate.minus({year: 1}))
    setIntensivityEndDate(intensivityEndDate.minus({year: 1}))
    doFetchIntensivity()
  }

  const decreaseYear = () => {
    setIntensivityStartDate(intensivityStartDate.plus({year: 1}))
    setIntensivityEndDate(intensivityEndDate.plus({year: 1}))
    doFetchIntensivity()
  }

  function convertHeatMapDate(date) { 
    const parts = date.split('/').map( Number )
    return DateTime.local(parts[0], parts[1], parts[2])
  }

  function handleHeatMapClick(start, end) {
    setStartDate(start)
    setEndDate(end)
    setSelectedDate([start, end])
  }

  useEffect(() => {
    setColors()
  },[convertedShift.duration])

  function setColors() {
    let d = convertedShift.duration
    let obj = {}
    Object.assign(obj, {[d/d]: '#f3f4f6'});
    Object.assign(obj, {[d * 0.1]: '#FFF48E'});
    Object.assign(obj, {[d * 0.25]: '#CAE781'});
    Object.assign(obj, {[d * 0.4]: '#94D973'});
    Object.assign(obj, {[d * 0.5]: '#28BD57'});
    Object.assign(obj, {[d * 0.65]: '#1B9D9B'});
    Object.assign(obj, {[d * 0.8]: '#148DBD'});
    Object.assign(obj, {[d * 0.9]: '#0D7DDE'});
    Object.assign(obj, {[d * 1]: '#3B82F6'});
    return obj
  }

  function legendText() {
    return ( 
      <div className='flex flex-row h-4 w-full pt-2 pl-2 bg-white flex-shrink-0 items-center justify-center'>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#f3f4f6'}}></span>0%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#FFF48E'}}></span>10%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#CAE781'}}></span>25%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#94D973'}}></span>40%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#28BD57'}}></span>50%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#1B9D9B'}}></span>65%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#148DBD'}}></span>80%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#0D7DDE'}}></span>90%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#3B82F6'}}></span>100%</div>
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col border rounded py-4 mt-4 px-2 flex-grow">
        {!intensivityLoaded ? 
          <div className="flex items-center justify-center w-full h-60">
            <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
          </div>
        :
        <div className='flex flex-col h-60'>
          <div className="flex justify-between text-xs mb-4 items-center px-2">
            { (intensivityEndDate.year === today.year) ? 
              <>
              <button className="bg-gray-200 rounded px-2 py-1 flex items-center hover:bg-gray-300 w-16" onClick={() => increaseYear()}>
                <ChevronLeftIcon className="w-3 h-3"/>
                {intensivityEndDate.year - 1}
              </button> 
              <span className="font-medium">{intensivityEndDate.year}</span>
              <div className="w-16"></div>
              </>
              :
              <>
              <button className="bg-gray-200 rounded px-2 py-1 flex items-center hover:bg-gray-300 w-16" onClick={() => increaseYear()}>
                <ChevronLeftIcon className="w-3 h-3"/>  
                {intensivityStartDate.year}
              </button>
              <span className="font-medium">{intensivityEndDate.year}</span>
              <button className="bg-gray-200 rounded px-2 py-1 flex items-center hover:bg-gray-300 w-16" onClick={() => decreaseYear()}>
                {intensivityEndDate.year + 1}
                <ChevronRightIcon className="w-3 h-3" />
              </button>
              </>
            }
          </div>
          <div className='flex flex-row items-start'>
            <div className='flex flex-col h-full w-8 pt-5 pl-2 bg-white flex-shrink-0'>
              {[Info.weekdays('short')[6], Info.weekdays('short').splice(0, 6)].flat().map((el, idx) => {
                return <span key={idx} className='h-4 flex items-center capitalize' style={{fontSize: 9, marginBottom: 7}}>{el}</span>
              })}
            </div>
            <div ref={heatmapRef} className='flex flex-col items-end justify-end overflow-hidden'>
              <HeatMap 
                startDate={intensivityStartDate.toJSDate()}
                endDate={intensivityEndDate.toJSDate()}
                className={"h-44 w-full overflow-hidden"}
                style={{width: (365 / 7 * 24) - 24, fontSize: 9}}
                value={intensivity}
                rectSize={18}
                radius={4}
                space={5}
                legendCellSize={0}
                rectRender={(props, data) => {
                  if (selectedDate.length > 0 && selectedDate[1]) {
                    if (convertHeatMapDate(data.date).startOf('day') >= selectedDate[0].startOf('day') && convertHeatMapDate(data.date).endOf('day') <= selectedDate[1]) {
                      props.fill = "#3728BD"
                    }
                  }
                  return ( 
                    <Tooltip key={props.key} placement="top" content={`${t('date')}: ${getPerfectDate(data.date)}`}>
                    <rect {...props}
                      onClick={() => handleHeatMapClick(convertHeatMapDate(data.date).startOf('day'), convertHeatMapDate(data.date).endOf('day'))}
                    ></rect>
                    </Tooltip>

                  );
                }}
                panelColors={setColors()}
              />
            </div>
          </div>
          {legendText()}
        </div>
        }
      </div>
      {intensivityLoaded && <TotalByPeriod data={data} startDate={startDate} endDate={endDate} duration={convertedShift.duration}/>}
    </>
  )
}
export default PersonWorkCalendar;