import React, {useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { CurrentUserContext } from '../../../contexts/current-user';
import { Disclosure, Transition } from '@headlessui/react'
import { CollectionIcon, ChevronUpIcon, UserGroupIcon, ChipIcon, BookOpenIcon, LightningBoltIcon, PresentationChartBarIcon } from '@heroicons/react/outline';

const SiteNav = ({ applications }) => {
  const [currentUserState, ] = useContext(CurrentUserContext);
  const {t} = useTranslation();
  const [directoriesList, setDirectoriesList] = useState([]);
  
  // console.log(currentUserState.currentSite.user_status)

  useEffect(() => {
    if (currentUserState.directories === null) {
      return;
    }
    const userDirectories = [];
    currentUserState.directories.forEach(item => {
      if (item.attributes.show_in_menu) {
        userDirectories.push({ name: item.attributes.name, link: `directories/users/${item.attributes.slug}`});
      }
    });
    setDirectoriesList(userDirectories);
  }, [currentUserState.directories]);

  const directories = directoriesList.map((item) => (
    <li key={item.name}>
      <NavLink to={`/${item.link}`} exact className="flex items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
        {item.name}
      </NavLink>
    </li>
    )
  );

  return (

      <ul className="py-4 px-4 space-y-2 w-72">
      {applications.equipment_and_materials ? (
        <Disclosure as="li">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <div className="w-4 h-4">
                  <PresentationChartBarIcon className="w-4 h-4"></PresentationChartBarIcon>
                </div>
                <div className="flex w-full justify-between items-center pl-6">
                  <span>{t('reports')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-4 h-4`}
                  />
                </div>

              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
              <Disclosure.Panel as="ul" className="text-sm text-gray-500 pt-2 space-y-1">
                {applications.equipment_and_materials && applications.devices ?
                  <li>
                    <NavLink to="/report/equipment"  className="flex justify-between items-center w-full pl-12 px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      {t('equipment_load_report')}
                    </NavLink>
                  </li> : <></>}
                {applications.tracking ?
                <li>
                  <NavLink to="/equipment_map"  className="flex justify-between items-center w-full pl-12 px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('equipment_tracking')}
                  </NavLink>
                </li> : <></>}
                {applications.staff ?
                  <li>
                    <NavLink to="/report/staff" exact className="flex justify-between items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      {t('staff_report')}
                    </NavLink>
                  </li> : <></>}
                {/* {applications.staff && applications.equipment_and_materials ?
                  <li>
                    <NavLink to="/report/divisions" exact className="flex justify-between items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      {t('divisions_report')}
                    </NavLink>
                  </li> : <></>} */}
                {applications.staff && applications.equipment_and_materials ?
                <li>
                  <NavLink to="/analytic" exact className="flex justify-between items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('analytics')}
                  </NavLink>
                </li> : <></>}
                {applications.equipment_and_materials ?
                  <li>
                    <NavLink to="/report/maintenances"  className="flex justify-between items-center w-full pl-12 px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      {t('maintenances')}
                    </NavLink>
                  </li> : <></>}
              </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        ) : ''}
      {applications.equipment_and_materials ? (
        <Disclosure as="li">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <div className="w-4 h-4">
                  <CollectionIcon className="w-4 h-4"></CollectionIcon>
                </div>
                <div className="flex w-full justify-between items-center pl-6">
                  <span>{t('equipment')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-4 h-4`}
                  />
                </div>

              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
              <Disclosure.Panel as="ul" className="text-sm text-gray-500 pt-2 space-y-1">
                <li>
                  <NavLink to="/equipment"  className="flex justify-between items-center w-full pl-12 px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('equipment_accounting')}
                  </NavLink>
                </li>
                {currentUserState.currentSite.user_status == "user" ? "" :
                  <>
                    <li> 
                      <NavLink to="/manufactures" className="flex justify-between items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                        {t('manufactures')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/equipment_types" exact className="flex justify-between items-center w-full pl-12 px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      {t('equipment_types')}
                      </NavLink>
                    </li>
                  </>
                }
              </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        ) : ''}
      {applications.staff ? (
        <Disclosure as="li">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <div className="w-4 h-4">
                  <UserGroupIcon className="w-4 h-4"></UserGroupIcon>
                </div>
                <div className="flex w-full justify-between items-center pl-6">
                  <span>{t('staff')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-4 h-4 `}
                  />
                </div>

              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
              <Disclosure.Panel as="ul" className="text-sm text-gray-500 pt-2 space-y-1">
                <li>
                  <NavLink to="/staff" exact className="flex justify-between items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('staff')}
                  </NavLink>
                </li>
                {currentUserState.currentSite.user_status == "user" ? "" :
                  <li>
                    <NavLink to="/positions" exact className="flex justify-between items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      {t('positions')}
                    </NavLink>
                  </li> 
                }
              </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ) : ''}
      {applications.electricity && currentUserState.currentSite.user_status != "user" ? ( 
        <li>
          <NavLink to="/electricities" className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
            <div className="w-4 h-4"> 
              <LightningBoltIcon className="w-4 h-4" stroke="currentColor"></LightningBoltIcon>
            </div>
            <span className="pl-6">{t('electricity')}</span>
          </NavLink>
        </li>
      ) : ''}

      {applications.devices && currentUserState.currentSite.user_status != "user" ? (
        <li>
          <NavLink to="/devices" className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
            <div className="w-4 h-4"> 
              <ChipIcon className="w-4 h-4" stroke="currentColor"></ChipIcon>
            </div>
            <span className="pl-6">{t('devices')}</span>
          </NavLink>
        </li>
      ) : ''}

      {applications.directories && currentUserState.currentSite.user_status != "user" ? (
      <Disclosure as="li">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <div className="w-4 h-4"> 
                  <BookOpenIcon className="w-4 h-4 text-gray-600"></BookOpenIcon>
                </div>
                <div className="flex w-full justify-between items-center pl-6">
                  <span>{t('directories')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-4 h-4`}
                  />
                </div>

              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
              <Disclosure.Panel as="ul" className="text-sm text-gray-500 pt-2 space-y-1">
                <li>
                  <NavLink to="/directories" exact className="flex items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('all_directories')}
                  </NavLink>
                </li>
                {directories}
                <li>
                  <NavLink to="/directories/units" exact className="flex items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('units')}
                  </NavLink>
                </li>
              </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
        ) : ''}

        {/* FIX Пока закрытый раздел */}
        {/* <li
          className="slave-nav-item"
          onClick={() => onMainNavItemClick('works')}
        >
          <NavLink to="/works" className="slave-nav-item__link">
            Производство работ
          </NavLink>
        </li> */}

        
        {/*<li*/}
        {/*  className="slave-nav-item"*/}
        {/*  onClick={() => onMainNavItemClick('certifications')}*/}
        {/*>*/}
        {/*  <NavLink to="/certifications" className="slave-nav-item__link">*/}
        {/*    Аттестации*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
      </ul>

  );
};

export default SiteNav;
