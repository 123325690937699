import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { convertTime, convertTimeHHMM, getJustDate, secondsToTime } from '../../../../../utils';

const Tooltip = ({data}) => {
  const {t} = useTranslation(['widgets']);
  return (
    <div className="bg-white shadow-lg rounded text-sm text-gray-700 px-4 py-4 flex flex-col min-w-min">
      <span className="font-medium whitespace-nowrap">{DateTime.fromMillis(data?.data?.at).toFormat("dd.MM.yyyy")}</span>
      <span className="font-semibold">{t('staff_worked')}: {secondsToTime(data?.data?.worked)}</span>
      <span className="font-semibold">{t('staff_stopped')}: {secondsToTime(data?.data?.stopped)}</span>
      {/* <span className="font-semibold">{t('staff_turned_off')}: {secondsToTime(data?.data?.turned_off)}</span> */}
    </div>
  )
}

const PersonLoadWidget = ({dailyData, duration, onBarClick}) => {
  const {t} = useTranslation(['widgets']);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [days, setDays] = useState(1);
  const [realDays, setRealDays] = useState(1);
  const [tickValues, setTickValues] = useState([]);

  useEffect(() => {
    if(dailyData.length === 0) {
      setDays(1)
      setRealDays(1)
      return;
    }
    setLoading(true)
    setDays(dailyData.length)
    setRealDays(DateTime.local().month === DateTime.fromSQL(dailyData[0].at).month ? DateTime.local().day : DateTime.fromSQL(dailyData[0].at).daysInMonth)
    convertData(dailyData)
  },[dailyData])

  useEffect(() => {
    if(duration === 86400) {
      setTickValues([0, 28800, 57600, 86400])
    } else {
      let part = duration / 4
      setTickValues([0, part, part * 2, part * 3, duration])
    }
  },[duration])


  function convertData(dailyData) {
    const data = []
    dailyData.map(it => {
      data.push({
        at: DateTime.fromSQL(it.at).ts,
        worked: it.worked,
        workedColor: 'rgba(16, 185, 129)',
        stopped: it.stopped,
        stoppedColor: 'rgba(252, 211, 77)'
        // no_card: it.no_card < 0 ? 0 : it.no_card,
        // no_cardColor: 'rgba(220, 38, 38)'
      })
    })
    setChartData(data);
    setLoading(false);
  }

  function percent(type) {
    const one = (duration * realDays) / 100

    const initialValue = 0;
    const sumWithInitial = dailyData.reduce(
      (acc, v) => acc + v[type],
      initialValue,
    );

    switch (type) {
      case 'worked':
        return `${Math.round(sumWithInitial / one)}%`

      case 'stopped':
        return `${Math.round(sumWithInitial / one)}%`

      // case 'no_card':
      //   return `${Math.round(sumWithInitial / one)}%`
    
      default:
        return t('no_data')
    }
  }

  function total(type) {
    const initialValue = 0;
    const sumWithInitial = dailyData.reduce(
      (acc, v) => acc + v[type],
      initialValue,
    );

    switch (type) {
      case 'worked':
        return sumWithInitial

      case 'stopped':
        return sumWithInitial

      // case 'no_card':
      //   return sumWithInitial
    
      default:
        return 0
    }
  }

  return (
    <>
      {loading ? t('loading') :
      <div className="w-full">
        <div className="h-24 mt-4">
          <div className="grid grid-cols-12 pt-2">
            <div className="flex flex-col col-start-1 col-end-3">
              <span className="text-gray-500">{t('staff_used_in_period')}</span>
              <span className="font-medium">{secondsToTime(total('worked'))} ({percent('worked')})</span>
              {/* <div className="font-medium">{t('avg_in_period')}: {secondsToTime(generalData.period_worked / days)}</div> */}
            </div>
            <div className="flex flex-col col-start-4 col-end-6">
              <span className="text-gray-500">{t('staff_stopped_in_period')}</span>
              <span className="font-medium">{secondsToTime(total('stopped'))} ({percent('stopped')})</span>
              {/* <div className="font-medium">{t('avg_in_period')}: {secondsToTime(generalData.period_stopped / days)}</div> */}
            </div>
            {/* <div className="flex flex-col col-start-7 col-end-9">
              <span className="text-gray-500">{t('staff_no_card_in_period')}</span>
              <span className="font-medium">{secondsToTime(total('no_card'))} ({percent('no_card')})</span>
            </div> */}
            {/* <div className="flex flex-col col-start-7 col-end-9">
              <span className="text-gray-500">{t('staff_common_time_in_period')}</span>
              <div className="font-medium">{secondsToTime(duration * realDays)}</div>
              <div className="font-medium">{t('avg_in_period')}: {secondsToTime(total('no_card') / days)}</div>
            </div> */}
          </div>
        </div>
        <div className="h-56">
        <ResponsiveBarCanvas
          data={chartData}
          keys={['worked', 'stopped']}
          indexBy="at"
          margin={{ top: 30, right: 130, bottom: 55, left: 70 }}
          enableGridY={true}
          enableGridX={false}
          gridYValues={7}
          maxValue={duration}
          groupMode="grouped"
          padding={0.4}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          valueFormat={value => 
            `${convertTime(value)}`
          }
          enableLabel={false}
          colors={({ id, data }) => String(data[`${id}Color`])}
          borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t('days'),
              legendPosition: 'middle',
              legendOffset: 37,
              format: value => getJustDate(value),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: tickValues,
            legend: t('duration'),
            legendPosition: 'middle',
            legendOffset: -65,
            format: value => `${convertTimeHHMM(value)}`,
          }}
          tooltip={(data) => (<Tooltip data={data} />)}
          onClick={({data}) => onBarClick(data.at)}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          legendLabel={datum => t(`staff_${datum.id}`)}
          legends={[
              {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 16,
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]}
        />
        </div>
      </div>}
    </>
  )
}

export default PersonLoadWidget;