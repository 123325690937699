import { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';

import { Dialog, Transition } from '@headlessui/react';
import Form, { Field, ErrorMessage, CheckboxField } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Select from '@atlaskit/select';

import ErrorsBlock from '../../../../../components/errors';
import FieldsConstructor from '../../../../../components/form/fields';

export default function FormModel({modelId, closeModal, isOpen, producer, equipmentTypes, reloadTable}) {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [typeFields, setTypeFields] = useState([]);
  const [fields, setFields] = useState([]);

  const [errors, setErrors] = useState([]);

  const isNewItem = modelId === 'new';

  const [{response: modelResponse, isLoading: modelIsLoading, error: modelError}, doFetchModel] = useFetch(`/directories/equipment_models/${modelId}`);
  const [{response: modelSaveResponse, isLoading: modelSaveIsLoading, error: modelSaveError}, doFetchModelSave] = useFetch(`/directories/equipment_models?producer_id=${producer}`);
  const [{response: modelUpdateResponse, isLoading: modelUpdateIsLoading, error: modelUpdateError}, doFetchModelUpdate] = useFetch(`/directories/equipment_models/${modelId}`);

  const [{response: equipmentTypeResponse, isLoading: equipmentTypeIsLoading, error: equipmentTyperror}, doFetchEquipmentType] = useFetch(`/directories/equipment_types/${type}`);

  useEffect(() => {
    const list = [] 
    equipmentTypes.map(it => {
      list.push({label: it.attributes.name, value: it.id})
    })
    setTypes(list)
  }, []);

  useEffect(() => {
    if(isNewItem) {
      setFields([])
      setName('');
      setType('');
    } else {
      doFetchModel()
    }
  },[modelId]);

  useEffect(() => {
    if(!modelResponse) {
      return
    }
    setName(modelResponse.data.attributes.name)
    setType(modelResponse.data.attributes.equipment_type_id);
    generateFields(modelResponse.data.attributes.fields);
    setTypeFields(modelResponse.data.attributes.fields);
  },[modelResponse]);

  useEffect(() => {
    if(!equipmentTypeResponse) {
      return
    }
    setTypeFields(equipmentTypeResponse.data.attributes.field_types);
    generateFields(equipmentTypeResponse.data.attributes.field_types);
  },[equipmentTypeResponse]);

  const handleTypeChange = (value) => {
    if(type !== value && isNewItem) {
      setType(value)
      doFetchEquipmentType()
    } else if (type !== value && !isNewItem) {
      setType(value)
      doFetchEquipmentType()
    } else {
      return
    }
  };

  const generateFields = (arr) => {
    const newFields = [];
    arr.forEach((item) => {
      newFields.push(item);
      if (item.children) {
        item.children.forEach((childItem) => {
          newFields.push(childItem);
        });
      }
    });
    setFields(newFields);
  };

  const onSave = (data) => {
    const newFieldsData = {};
    fields.forEach(it => {
      if(it.data === undefined) {
        return
      }
      newFieldsData[it.uuid] = it.data
    });

    if (isNewItem) {
      doFetchModelSave({
        method: 'POST',
        data: {
          data: {
            name: data.name,
            producer_id: producer,
            equipment_type_id: data.equipment_type.value,
            fields: newFieldsData
          }
        }
      });
    } else {
      doFetchModelUpdate({
        method: 'PUT',
        data: {
          data: {
            name: data.name,
            producer_id: producer,
            equipment_type_id: data.equipment_type.value,
            fields: newFieldsData
          }
        }
      });
    }
  };

  const handleCloseModal = () => {
    closeModal();
    reloadTable();
  };

  useEffect(()=> {
    if(isOpen) {
      return
    }
    setTimeout(() => {
      setFields([])
      setName('');
      setType('');
      setErrors([]);
    }, 300);
  },[isOpen]);

  useEffect(() => {
    if (!modelSaveResponse) {
      return
    }
    handleCloseModal()
  }, [modelSaveResponse]);

  useEffect(() => {
    if (!modelUpdateResponse) {
      return
    }
    handleCloseModal()
  }, [modelUpdateResponse]);


  useEffect(() => {
    if (modelSaveError || modelUpdateError) {
      setErrors(modelSaveError.errors)
    }
  }, [modelSaveError, modelUpdateError]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full lg:w-1/2 p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNewItem ? t('adding_model') : t('editing_model')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('adding_model_form_text')}
              </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => onSave(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <div className="grid md:grid-cols-2 gap-4">
                      <Field name="name" defaultValue={name} label={t('title_name')} isRequired>
                        {({ fieldProps }) => <TextField {...fieldProps}/>}
                      </Field>
                      <Field name="equipment_type" defaultValue={types.find(t => t.value == type)} label={t('equipment_type')} isRequired>
                        {({ fieldProps }) => <Select {...fieldProps} options={types} onChange={(type) => handleTypeChange(type.value)} className="text-sm" placeholder={t('select_placeholder')}/>}
                      </Field>
                    </div>
                    {fields.length > 0 ? 
                      <>
                        <div className="pt-4 pb-2 text-sm font-medium">{t('specifications')}</div>  
                        <FieldsConstructor fields={fields} setFields={setFields} typeFields={typeFields}/>
                      </>
                      : ""
                    }
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => handleCloseModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
