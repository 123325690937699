import OazisUploadPage from ".";
import Logo from '../../components/logo';
const ExternalOazisUpload = () => {
  return (
    <>
    <header className="flex h-16 w-full">
      <div className="flex w-full justify-center items-center bg-white border-b-2 border-gray-100">
        <Logo lang={"ru"}/> 
      </div>
    </header>
    <OazisUploadPage />
    </>
  )
}

export default ExternalOazisUpload;