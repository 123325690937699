import {useState, useEffect, useContext, Fragment} from 'react';
import {useStore} from "../../hooks-store/store";
import {CurrentUserContext} from "../../contexts/current-user";
import useFetch from "../../hooks/use-fetch";
import GlobalMenu from "./globalNav";
import { SkeletonContent } from './skeletonContent';
import { Redirect, Route, Switch } from "react-router";
import AdminRoutes from '../../routes/admin';
import SiteRoutes from '../../routes/site';
import UploadRoutes from '../../routes/upload';
import AccountRoutes from '../../routes/account';
import SettingsRoutes from '../../routes/settings';
import SiteNav from "./site";
import AdminMenu from "./admin/sidebar";
import AccountMenu from "./account/sidebar";
import SettingsMenu from './settings/sidebar';
import Spinner from '../loader';


const Navigation = ({initLoading}) => {
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [{response: responseAppsList, isLoading: isLoadingAppsList, error: errorAppsList}, doFetchAppsList] = useFetch('/applications');
  const [{response: responseDirectories, isLoading: isLoadingDirectories, error: errorDirectories}, doFetchDirectories] = useFetch('/directories/directories');
  const [{response: responseShifts, isLoading: isLoadingShifts, error: errorShifts}, doFetchShifts] = useFetch('/directories/working_shifts');

  const [currentItem, setCurrentItem] = useState('home');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (initLoading || currentUserState.currentSite == undefined) {
      return;
    }
    doFetchAppsList();
    doFetchDirectories();
    doFetchShifts();
  }, [currentUserState.currentSite, initLoading]);

  useEffect(() => {
    if (!responseAppsList || !responseDirectories || !responseShifts) {
      return;
    }
    const currentApplications = {};
    responseAppsList.data.forEach(application => {
      currentApplications[application.id] = application.attributes.enabled;
    });
    setCurrentUserState(state => ({
      ...state,
      applications: currentApplications,
      directories: responseDirectories.data,
      shifts: responseShifts.data
    }));
    // setApplications(currentApplications);
    setLoading(false);
  }, [responseAppsList, responseDirectories, responseShifts]);

  const mainNav = () => (
    <GlobalMenu
      currentUserState={currentUserState}
      applications={currentUserState.applications}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );

  return currentUserState?.isLoggedIn ? (
    <>
      {loading ? <Spinner /> : 
      <>
        <nav className="fixed w-full h-16 z-10 top-0 inset-x-0">
          {mainNav()}
        </nav>
        <Switch>
          <Route path="/admin">
            <aside className="fixed h-full w-72 hidden pt-16 inset-y-0 overflow-x-hidden overflow-y-auto xl:block">
              <div className="flex h-full text-sm py-4 border-r-2 border-solid border-gray-100 bg-gray-50"> 
                <AdminMenu />
              </div>
            </aside>
            <AdminRoutes/>
          </Route>
          <Route path="/account">
            <aside className="fixed h-full w-72 hidden pt-16 inset-y-0 overflow-x-hidden overflow-y-auto xl:block">
              <div className="flex h-full text-sm py-4 border-r-2 border-solid border-gray-100 bg-gray-50"> 
                <AccountMenu />
              </div>
            </aside>
            <AccountRoutes/>
          </Route>
          <Route path="/settings">
            <aside className="fixed h-full w-72 hidden pt-16 inset-y-0 overflow-x-hidden overflow-y-auto xl:block">
              <div className="flex h-full text-sm py-4 border-r-2 border-solid border-gray-100 bg-gray-50"> 
                <SettingsMenu />
              </div>
            </aside>
            <SettingsRoutes/>
          </Route>
          <Route path="/upload">
            <UploadRoutes/>
          </Route>
          <Route path="/">
            <aside className="fixed h-full hidden w-72 pt-16 inset-y-0 overflow-x-hidden overflow-y-auto xl:block">
              <div className="flex h-full text-sm py-4 border-r-2 border-solid border-gray-100 bg-gray-50"> 
                <SiteNav
                  applications={currentUserState.applications}
                  currentUserState={currentUserState}
                />
              </div>
            </aside>
            <SiteRoutes/>
          </Route>
        </Switch>
      </>}
    </>
  ) : ""
};

export default Navigation;