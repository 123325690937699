import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import LazyLoad from 'react-lazyload';

import StaffMonthLoadWidget from '../../../components/widgets/staff/month_load';

const ReportItem = ({person, selectedDate, convertedShift}) => {
  const {t} = useTranslation();
  let history = useHistory();

  const onItemClick = (id) => {
    history.push({
      pathname: `/report/staff/${id}`,
    });
  };

  const onBarClick = (id, date) => {
    history.push({
      pathname: `/report/staff/${id}`,
      search: `?date=${date}`
    });
  };
  
  return (
    <LazyLoad height={300} offset={100} once>
      <div className="flex h-full border border-gray-100 rounded-sm px-4 py-4 text-sm">
        <div className="w-full h-full flex flex-col">
          <div className="flex justify-between h-8 items-center">
            <div className="flex space-x-1 cursor-pointer">
              <span className="font-medium">{person.attributes.last_name}</span><span>{person.attributes.first_name}</span><span>{person.attributes.middle_name}</span>
            </div>
            <button onClick={() => onItemClick(person.id)} className="px-2 py-1 bg-blue-100 rounded text-blue-700 font-medium text-sm">{t('more_details')}</button>
          </div>
          <div className="flex space-x-1 itmes-center h-6 items-center">
            <span className="font-medium text-gray-700">{person.attributes.division_name} / </span><span className="font-medium text-gray-500">{person.attributes.post_name}</span>
          </div>
          <div className="flex w-full h-80">
            <StaffMonthLoadWidget person={person} selectedDate={selectedDate} convertedShift={convertedShift} onBarClick={onBarClick}/>
          </div>
        </div>
      </div>
    </LazyLoad>
  )
}

export default ReportItem;