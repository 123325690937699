import { useEffect, useState } from "react"
import useFetch from "../../../hooks/use-fetch";
import Select from '@atlaskit/select';
import { useTranslation } from "react-i18next";
import {Interval, DateTime} from 'luxon';
import useAsyncFetch from "../../../hooks/useAsyncFetch";
import { convertTimeHHMM, covertTimeToSeconds } from "../../../utils";
import IdelTimelineWidget from "../../../components/widgets/idle_timeline";
import Spinner from "../../../components/loader";

const intervals = [
  // {label: "Секунда", value: "raw"},
  {label: "Минута", value: "one_minute"},
  {label: "Десять минут", value: "ten_minute"},
  {label: "Час", value: "one_hour"},
]

const formulas = [
  {label: "Среднее", value: "avg"},
  {label: "Максимальное", value: "max"},
  {label: "Минимальное", value: "min"},
  {label: "Сумма", value: "sum"},
  {label: "Разница макс/мин", value: "diff"},
]

export default function EquipmentBaseAnalytic({selectedDate, convertedShift}) {
  const {t} = useTranslation();
  const [dates, setDates] = useState();
  const [currentResource, setCurrentResource] = useState(null)
  const [equipmentList, setEquipmentList] = useState([])
  const [sensorsList, setSensorsList] = useState([])
  const [currentSensor, setCurrentSensor] = useState(null)
  const [timeInterval, setTimeInterval] = useState(intervals[1])
  const [formula, setFormula] = useState(formulas[0])
  const [params, setParams] = useState(new URLSearchParams({'with_sensors': true}))
  const [loading, setLoading] = useState(false)
  const [timeMarkers, setTimeMarkers] = useState([0, 21600, 43200, 64800, 86400])
  const [url, setUrl] = useState([])
  const [data, setData] = useState([]);

  const [{response, isLoading, error}, doFetchEquipment] = useFetch(`/directories/equipment_items?${params.toString()}`);
  const [{response: sensorsData, isLoading: sensorsIsLoading, error: errorSensors}, doFetchEquipmentSensors] = useFetch(`/directories/equipment_items/${currentResource?.id}/sensors`);
  
  const [{response: dataResponse, isLoading: dataIsLoading}, doFetchData] = useFetch(url)

  useEffect(() => {
    doFetchEquipment()
  }, [])

  useEffect(() => {
    setUrl(null)
    setData(null)

    const shiftStart = covertTimeToSeconds(convertedShift.start);
    const shiftEnded = covertTimeToSeconds(convertedShift.end);

    setTimeMarkers([
      shiftStart, 
      shiftStart + (convertedShift.duration / 4) > 86400 ? (shiftStart + (convertedShift.duration / 4)) - 86400 : shiftStart + (convertedShift.duration / 4), 
      shiftStart + (convertedShift.duration / 2) > 86400 ? (shiftStart + (convertedShift.duration / 2)) - 86400 : shiftStart + (convertedShift.duration / 2), 
      shiftStart + (convertedShift.duration / 4) * 3 > 86400 ? (shiftStart + (convertedShift.duration / 4) * 3) - 86400 : shiftStart + (convertedShift.duration / 4) * 3, 
      shiftEnded
    ])

    setDates(Interval.fromDateTimes(selectedDate[0], selectedDate[1]).splitBy({ day: 1 }))
  }, [selectedDate, convertedShift])

  useEffect(() => {
    setUrl(null)
    setData(null)
  },[currentResource])

  useEffect(() => {
    if(!response?.data) {
      return
    }
    setEquipmentList(response.data)
  }, [response?.data])

  useEffect(() => {
    if(!sensorsData?.data) {
      return
    }
    setSensorsList(sensorsData.data.filter(sensor => !sensor.attributes.sensor_type.data_key_name.startsWith("rfid")))
  }, [sensorsData?.data])

  function generate() {
    if(!currentResource && !currentSensor && !timeInterval && !formula) {return}
    let path = `/devices/sensors/${currentSensor.id}/received_data?from=${selectedDate[0]}&to=${selectedDate[1]}&capacity=${timeInterval.value}&scalable_by=${formula.value}`
    setUrl(path)
    console.log(path)
    doFetchData()
  }

  useEffect(() => {
    if(!dataResponse || !dataResponse.length === 0) {return}
    if(!url) {return}
    // let dataByDays = []
    // dataResponse.map((d, index) => dataByDays.push({date: dates[index].start, data: d.data}))

    setData(dataResponse)
  }, [dataResponse])

  useEffect(() => {
    if(dataIsLoading) {return}
  }, [dataResponse])

  function handleChangeResource(data) {
    setCurrentResource(data)
    setCurrentSensor(null)
    doFetchEquipmentSensors()
  }

  function handleChangeSensor(data) {
    setCurrentSensor(data)
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="w-96">
          <Select 
            getOptionLabel={(o) => o ? `${o.attributes.equipment_type_name} ${o.attributes.producer_name} ${o.attributes.equipment_model_name}` : "Оборудование не выбрано"}
            getOptionValue={(o) => o ? o.id : null}
            options={equipmentList} 
            onChange={handleChangeResource}
            value={currentResource} 
            placeholder={t('select_equipment_placeholder')} 
            className="text-sm"
          />
        </div>
        <div className="w-96">
          <Select 
            getOptionLabel={(o) => o ? `${o.attributes.sensor_type.name}` : "Датчик не выбрано"}
            getOptionValue={(o) => o ? o.id : null}
            options={sensorsList} 
            onChange={handleChangeSensor}
            value={currentSensor} 
            placeholder={t('select_equipment_placeholder')} 
            className="text-sm"
          />
        </div>
        <div className="w-48">
          <Select 
            options={intervals} 
            onChange={setTimeInterval}
            value={timeInterval}
            placeholder={t('select_equipment_placeholder')} 
            className="text-sm"
          />
        </div>
        <div className="w-48">
          <Select 
            options={formulas} 
            onChange={setFormula}
            value={formula}
            placeholder={t('select_equipment_placeholder')} 
            className="text-sm"
          />
        </div>
      </div>
      <div className="py-10 w-full h-full min-h-96">

        <div className="flex flex-col w-full h-full items-center justify-center">
          <span className="text-gray-600 py-8">Выберите оборудование и сформируйте аналитический отчет</span>
          <button className="bg-blue-600 text-white py-2 px-8 rounded-sm font-medium text-sm" onClick={() => generate()}>Сформировать</button>
        </div>

      </div>
    </>
  )
}