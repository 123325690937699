import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Transition } from '@headlessui/react'
import { FilterIcon, XIcon } from '@heroicons/react/outline';
import { FilterIcon as SolidFilterIcon } from '@heroicons/react/solid';

function FilterPopover(props) {
  const {t} = useTranslation();
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
              className={`
                ${open ? 'bg-white text-blue-600' : 'text-gray-800 bg-white ring-gray-200'}
                group inline-flex items-center ring rounded-sm text-sm px-2 py-1.5 font-normal text-gray-600 hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              { open ? <SolidFilterIcon width={16}/> : <FilterIcon width={16}/> }
              <span className='mx-2'>{t('filters')}</span>
          </Popover.Button>
          <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-10 mt-3 w-80 bg-white max-w-sm transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-sm shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative flex flex-col mb-3">
                    <div className='flex flex-row px-6 py-4 justify-between items-center'>
                      <div className='font-medium'>{t('filters')}</div>
                      {props.selected.length > 0 ? <div className='font-normal text-gray-400 text-xs' onClick={() => props.clearAll()}>{t('clear')}</div> : <></>}
                    </div>
                    {props.children}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
        </>
      )}
    </Popover>
  )
}

export default FilterPopover;