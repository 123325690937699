import React from 'react';

import Header from '../header';
import { useStore } from '../../hooks-store/store';

const Layout = ({headerData, isWide, children}) => {
  const state = useStore()[0];

  return (
    <main className={`pt-16 xl:pl-72 ${isWide ? "" : "2xl:pr-72"}`}>
      <div className="w-full px-4 xl:px-8">
          {headerData ? <Header
            headerData={headerData}
          /> : ""}
          {children}
      </div>
    </main>
  )
};

export default Layout;
