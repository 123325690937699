import React from 'react';
import Table from '../../../../../components/form/table';
import Spinner from "../../../../../components/loader";
import { useTranslation } from 'react-i18next';
import {getPerfectDate} from '../../../../../utils/index'
import { TrashIcon } from '@heroicons/react/outline';

const DevicesTable = ({ tableData, onItemDelete }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        at: tableItem.attributes.equipment_item_connected_at,
        device_serial_number: tableItem.attributes.device_serial_number,
        slot_name: tableItem.attributes.slot_name,
        sensor_name: t(tableItem.attributes.sensor_type.data_key_name),
        factor: tableItem.attributes.factor,
        idle_level: tableItem.attributes.idle_level,
        status: tableItem.attributes.online
      }
    )
  });

  const columns = [
    {
      label: t('at'),
      prop: "at",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex items-center">
            {getPerfectDate(row.at)}
          </div>
        )
      }
    },
    {
      label: t('device'),
      prop: "device",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => { 
        return (
          <>
            <div className="font-medium text-gray-900 truncate">
              {row.device_serial_number}
            </div>
            <div className="text-gray-500 truncate">
              {row.slot_name}
            </div>
          </>
        )
      }
    },
    {
      label: t('sensor'),
      prop: "sensor",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => { 
        return (
          <>
            <div className="font-medium text-gray-900 truncate">
              {row.sensor_name}
            </div>
            <div className="text-gray-500 truncate">
              {t('factor')}: {row.factor}
            </div>
          </>
        )
      }
    },
    {
      label: t('idle_level'),
      prop: "idle_level",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => { 
        return (
          <div className="flex items-center">
            {t(row.idle_level)}
          </div>
        )
      }
    },
    {
      label: t('status'),
      prop: "status",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => { 
        return (
          <div className="flex items-center">
            {t(row.status)}
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );

};

export default DevicesTable;
