import React, { useState, useEffect } from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import {DateTime} from 'luxon';
import Avatar from 'react-avatar';
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper';
import useFetch from '../../hooks/use-fetch';
import {MenuIcon, SearchIcon, BellIcon, CogIcon, UploadIcon} from '@heroicons/react/outline';
import AccountNav from './account';
import SitesNav from './sites';
import Logo from '../logo';
import Sidebar from './sidebar';

import SiteNav from "./site";
import AccountMenu from "./account/sidebar";
import SettingsMenu from './settings/sidebar';
import UploadsMenu from './uploads';


const GlobalMenu = ({currentUserState, isOpen, setIsOpen}) => {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  const [currentSiteDateTime, setCurrentSiteDateTime] = useState(DateTime.local())


  useEffect(() => {
    setInterval(() => setCurrentSiteDateTime(DateTime.local()), 1000);
  }, []);

  return currentUserState === undefined ? '' : (
    <div className="relative bg-white border-b-2 border-gray-100">
      <div className="mx-auto xl:px-4 px-2">
        <div className="grid md:grid-cols-3 grid-cols-2 h-16 items-center">
          <div className="flex items-center">
            <div className="xl:hidden items-center flex">
              <Popover className="relative px-2">
                {({ open }) => (
                  <>
                    <div className="flex mr-2">
                      <Popover.Button><MenuIcon className="w-6 h-6 text-gray-500 hover:text-gray-900 cursor-pointer"/></Popover.Button>
                    </div>
                    <Popover.Overlay
                      className={`${
                        open ? 'opacity-30 fixed inset-0' : 'opacity-0'
                      } bg-black`}
                    />
                    <Popover.Panel         
                      className="flex absolute z-10 mt-8 bg-white shadow rounded">
                      <SiteNav
                        applications={currentUserState.applications}
                        currentUserState={currentUserState}
                      />
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            </div>
            <Link to="/">
              <div className="flex items-center">
                <div className="w-8 h-8 mr-4">
                    {currentUserState?.currentSite?.image?.id === undefined ? <Avatar name={currentUserState?.currentSite?.name} size="32" className="text-xs rounded-full font-medium"/> : (
                      <img className="rounded" src={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${currentUserState?.currentSite?.image?.id}`} alt=""/>
                    )}      
                </div>
                <div className="flex flex-col">
                  <div className="text-sm font-medium truncate">
                    {currentUserState?.currentSite?.name}
                  </div>
                  {/* <div className="text-sm font-medium truncate">
                    {currentSiteDateTime.toFormat('HH:mm:ss')} / {new Date().toLocaleTimeString()}
                  </div> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="mx-auto hidden md:flex">
            <Logo lang={currentUserState?.currentUser?.attributes?.locale}/>
          </div>
          <div className="flex justify-end">
            <div className="flex items-center justify-end lg:flex-1 lg:w-0">
              <div className="xl:hidden items-center flex">
                <Popover  className="h-16 px-2 py-5">
                  <Popover.Button ref={setReferenceElement}><CogIcon className="h-6 w-6 mr-6 text-gray-500 hover:text-gray-900 cursor-pointer"/></Popover.Button>
                  <Popover.Panel 
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="flex absolute z-10 mt-8 bg-white shadow rounded">
                    <SettingsMenu />
                  </Popover.Panel>
                </Popover>
              </div>
              {currentUserState?.currentSite?.user_status === "root" || currentUserState?.admin ? 
              <Link to="/settings" className="hidden xl:flex">
                <CogIcon className="h-6 w-6 mr-6 text-gray-500 hover:text-gray-900 cursor-pointer"/>
              </Link> : "" }
              <UploadsMenu />
              {/* <SitesNav/> */}
              <AccountNav/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalMenu;
