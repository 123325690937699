import React, {useState, useEffect} from 'react';

import CanvasJSReact from '../../assets/canvasjs.react';
import Spinner from "../loader";
import {getPerfectDateTime, convertTimezone} from "../../utils";


const CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJSReact.CanvasJS.addCultureInfo("ru",
  {
    months: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"]
  });

const ChartRfid = ({loading, responseData, baseOptions, timeZone}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!responseData.attributes) {
      return;
    }
    const itemData = [];
    responseData.attributes.data.forEach(it => {
      itemData.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: 1,
        personnel: it.personnel,
        rfid: it.rfid
      })
    });
    if (itemData.length) {
      itemData[0].markerType = 'none';
      //itemData[0].toolTipContent = null;
      itemData[itemData.length - 1].markerType = 'none';
    }
    setChartData(itemData);
  }, [responseData]);

  // console.log(responseData);

  const options = {
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: 'IBMPlexSansMedium',
      labelFontColor: '#596890',
      labelFontSize: 10,
      interval: 1,
      maximum: 1.9
    },

    toolTip: {
      shared: true,
      contentFormatter: function (e) {

        var content = " ";
        for (var i = 0; i < e.entries.length; i++) {
          if (e.entries[i].dataPoint.markerType === 'none'){
            continue;
          }
          if (e.entries[i].dataPoint.personnel) {
            content += 'Персонал: ' + e.entries[i].dataPoint.personnel.first_name + ' ' + e.entries[i].dataPoint.personnel.middle_name + ' ' + e.entries[i].dataPoint.personnel.last_name;
          } else {
            content += 'Персонал: неизвесный';
          }
          content += '</br>Номер карты: ' + e.entries[i].dataPoint.rfid;
          content += '</br>Время: ' + getPerfectDateTime(e.entries[i].dataPoint.x);
        }
        return content;
      }
    },
    data: [{
      xValueFormatString: "HH:mm:ss",
      // type: "stepLine",
      type: "scatter",
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 6,
      dataPoints: chartData
    }]
  };

  return (
    <div className="device-details-history-item__chart">
      {loading ?       <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div>  : <CanvasJSChart options={{...baseOptions, ...options}}/>}
    </div>
  )
};

export default ChartRfid;
