import { ArrowsExpandIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ElectricityDayWidget from '../../../../components/widgets/electricity_day';
import { DateTime } from 'luxon';
import { getPerfectDate } from '../../../../utils';
import FullInfo from './details';
import ElectricitySensor from './sensor';

const ElectricityDay = ({item}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true)

  const [totalPh1, setTotalPh1] = useState('');
  const [totalPh2, setTotalPh2] = useState('');
  const [totalPh3, setTotalPh3] = useState('');
  const [totalRp, setTotalRp] = useState('');

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    setTotalPh1(getTotal(item.ph1))
    setTotalPh2(getTotal(item.ph2))
    setTotalPh3(getTotal(item.ph3))
    setTotalRp(getTotal(item.rp))
    setLoading(false)
  }, [])

  function getTotal(arr) {
    let total = 0;
    arr.map(h => {
      total = h.v ? total + h.v : total
    })
    return total / 1000
  }

  return (
    loading ?     
    <div className="flex items-center justify-center w-full h-80">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
    </div> 
    :
    <>
      <div className="w-full p-4 border rounded-sm mb-4">
        <div className="flex capitalize justify-between">
          {DateTime.fromMillis(item.day).weekdayLong}, {DateTime.fromMillis(item.day).toFormat("dd.MM.yyyy")}
          <ArrowsExpandIcon className="w-4 h-4 text-gray-500 hover:text-blue-500" onClick={openModal}/>
        </div>
        <ElectricityDayWidget 
          at={item.day}
          ph1={totalPh1}
          ph2={totalPh2}
          ph3={totalPh3}
          rp={totalRp}
        />
        <ElectricitySensor 
          sensor={item}
        />
      </div>
      {isOpen ? <FullInfo closeModal={closeModal} isOpen={isOpen} item={item}/> : ""}
    </>
  )
}

export default ElectricityDay;