import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/use-fetch";
import AlgorithmWire from "../../../../components/algorithms/wire";
import AlgorithmIdleForCycle from "../../../../components/algorithms/idle_for_cycle";

const EquipmentAlgorithms = ({currentItem, startDate, endDate, convertedShift, sensors}) => {
  const [equipmentType, setEquipmentType] = useState('')
  const [tags, setTags] = useState([])

  const [{response: equipmentTypeResponse, isLoading: equipmentTypeIsLoading, error: equipmentTypeError}, doFetchEquipmentType] = useFetch(`/directories/equipment_types/${equipmentType}`);

  useEffect(() => {
    setEquipmentType(currentItem.attributes.equipment_type_id)
    doFetchEquipmentType()
  },[])

  useEffect(() => {
    if(!equipmentTypeResponse) {
      return
    }
    setTags(equipmentTypeResponse.data.attributes.tags)
  },[equipmentTypeResponse])

  const wireAlgorithm = sensors.map((sensor, index) => {
    if(sensor.attributes.sensor_type.data_key_name == "i" && tags.includes('welding_wire')) {
      return <AlgorithmWire 
        key={`${index}_welding_wire`}
        sensor={sensor}
        startDate={startDate}
        endDate={endDate}
        shift={convertedShift}
      />
    } else {
      return ""
    }
  });

  const idleAlgorithm = sensors.map((sensor, index) => {
    if(sensor.attributes.sensor_type.data_key_name === "ac_rms") {
      return <AlgorithmIdleForCycle
        key={`${index}_welding_wire`}
        sensor={sensor}
        startDate={startDate}
        endDate={endDate}
        shift={convertedShift}
      />
    } else {
      return ""
    }
  });
  
  return (
    <>
      {idleAlgorithm}
      {wireAlgorithm}
    </>
  )

}

export default EquipmentAlgorithms;