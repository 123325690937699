import { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {createPortal} from 'react-dom';
import { CurrentUserContext } from "../../../../contexts/current-user";
import { YMaps, Map, Placemark, Polyline, Clusterer } from "react-yandex-maps";
import { getPerfectDate, getPerfectDateTime } from '../../../../utils';

const EquipmentsOnMap = ({data, currentItem, onItemEditClick}) => {
  const {t} = useTranslation();
  const mapRef = useRef(null)
  const [currentUserState, ] = useContext(CurrentUserContext);
  const [coords, setCoords] = useState([59.9342802, 30.3350986])
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  useEffect(() => {
    let new_coords = data.find(i => i.id === currentItem.id)?.coordinates
    if(!new_coords || !new_coords[0] || !new_coords[1] || data.length === 0) {
      return
    }
    setCoords(new_coords)
  }, [currentItem, data])

  return (
    <>
      <YMaps 
        query={{ lang: currentUserState?.currentUser?.attributes?.locale || 'ru_RU'}}
      >
        <div className={`w-full h-full flex rounded overflow-hidden bg-white py-4`}>
          <Map instanceRef={mapRef} width='100%' height='480px' state={{ center: data.length > 0 ? coords : coords, zoom: 5, controls: ['zoomControl', 'fullscreenControl'] }} modules={['control.ZoomControl', 'control.FullscreenControl']}>
            <Clusterer options={{
              preset: 'islands#invertedBlueClusterIcons',
              groupByCoordinates: false
            }}>
              {data.map((place, index) => 
                <Placemark 
                  key={index} 
                  geometry={place.coordinates} 
                  options={
                    {
                      preset: 'islands#blueStretchyIcon', // список темплейтов на сайте яндекса
                      iconColor: 'blue', // цвет иконки, можно также задавать в hex
                    } }
                    properties={
                      {
                      iconContent: `<div>${place.inventory_number} </div>`, // пару символов помещается
                      hintContent: `<div>${getPerfectDate(place.date)} ${t(place.status)}</div>`,
                      // создаём пустой элемент с заданными размерами
                      // balloonContent: '<div id="driver-2" class="driver-card"></div>',
                    }	}
                    onClick={ () => { 
                    // ставим в очередь промисов, чтобы сработало после отрисовки балуна
                    setTimeout(() => { onItemEditClick(place)}, 0)
                    } }
                  // properties={{
                  //   iconContent: `
                  //     <div class="flex flex-col" >
                  //       <button>Knopka<button>
                  //       <div>${getPerfectDate(place.date)} ${t(place.status)}</div>
                  //     </div>
                  //   `
                  // }} 
                  // options={{
                  //   preset: "islands#blueStretchyIcon",
                  //   // Disabling the close balloon button.
                  //   balloonCloseButton: false,
                  //   // The balloon will open and close when the placemark icon is clicked.
                  //   hideIconOnBalloonOpen: false
                  // }}
                />)
              }
            </Clusterer>
          </Map>
        </div>
      </YMaps>
    </>
  )
}

export default EquipmentsOnMap;


