import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { UploadIcon} from '@heroicons/react/outline';

const UploadsMenu = () => {
  const { t } = useTranslation();

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block">
        <div>
          <Menu.Button className="inline-flex justify-center w-full px-2 py-2 lg:mr-2 text-sm font-medium text-gray-700 rounded-md">
            <UploadIcon className="h-6 w-6 mr-6 text-gray-500 hover:text-gray-900 cursor-pointer"/>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items as="ul" className="absolute right-0 mt-2 origin-top-right w-48 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
            <Menu.Item>
              {({ active }) => (
                <li className={`${active && 'bg-blue-500 text-white'} flex justify-end`}>
                  <Link
                    className="px-4 py-2 cursor-pointer w-full"
                    to="/upload/log" 
                  >
                    {t('upload_log')}
                  </Link>
                </li>

              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <li className={`${active && 'bg-blue-500 text-white'} flex justify-end`}>
                <Link
                  className="px-4 py-2 cursor-pointer w-full"
                  to="/upload/oazis"
                >
                  {t('upload_oazis')}
                </Link>
                </li>

              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
};

export default UploadsMenu;