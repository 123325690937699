import React, {useState, useEffect, useRef, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useTranslation } from 'react-i18next';

import useFetch from '../../hooks/use-fetch';
import {CurrentUserContext} from "../../contexts/current-user";
import Layout from '../../components/layout';
import Spinner from '../../components/loader';
import DirectorySettings from './settings';

import Entries from './entries';


const DirectoriesEdit = (props) => {
  const {t} = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [itemId, setItemId] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [pageSearch, setPageSearch] = useState('');
  const [currentItem, setCurrentItem] = useState('')

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/directories/directories/${itemId}`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/directories/${itemId}`);

  const headerData = {
    title: currentItem?.attributes?.name || ""
  };

  useEffect(() => {
    doFetchItem();
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }
    setCurrentItem(response.data);
    setLoading(false)
  }, [response]);

  const onUpdate = (data) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: data
      }
    });
  };

  useEffect(()=>{
    if(!itemUpdateResponse) {
      return
    }
    setCurrentItem(itemUpdateResponse.data);

    setCurrentUserState(state => ({
      ...state,
      directories: 
        state.directories.map((directory, index) => {
          if(directory.id === itemUpdateResponse.data.id) {
            return itemUpdateResponse.data
          } else {
            return directory;
          }
        })
    }));
  },[itemUpdateResponse]);

  return (
    <Layout
    headerData={headerData}
    isWide={true}
  >
    {loading ? <Spinner/> : (
      <Tabs className="text-gray-800 font-medium text-sm mt-4" id="default">
        <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
          <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
            {t('entries')}
          </Tab>
          <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
            {t('settings')}
          </Tab>
        </TabList>
        <TabPanel>
          <Entries currentItem={currentItem} slug={currentItem.attributes.slug}/>
        </TabPanel>
        <TabPanel>
          <DirectorySettings 
            currentItem={currentItem}
            onUpdate={onUpdate}
          />
        </TabPanel>
      </Tabs>
    )}
  </Layout>
  )
};

export default DirectoriesEdit;
