import React, {useContext, useState, useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {CurrentUserContext} from "../../../contexts/current-user";
import { sortDataList, sortSelectList } from '../../../utils';
import { staffStatuses } from '../../../enums';
import PageFilter from '../../../components/page_filter';
import Layout from '../../../components/layout';
import Spinner from "../../../components/loader";

import useFetch from "../../../hooks/use-fetch";
import { useHistory } from "react-router-dom";

import Pagination from "../../../components/form/pagination";

import StaffTable from "./table";
import AddStaff from './add';
import Filters from '../../../components/filters';


const ITEMS_PER_PAGE = 40;


const Staff = (props) => {
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const {t} = useTranslation();


  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemId, setItemId] = useState('new');
  
  const [postsList, setPostsList] = useState([]);
  const [divisionsList, setDivisionsList] = useState([]);

  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterOptionsPosts, setFilterOptionsPosts] = useState([]);
  const [filterOptionsDivisions, setFilterOptionsDivisions] = useState([]);
  const filterOptionsStatuses = staffStatuses.map(status => ({label: t(`staff_${status}`), value: status}))

  const [showPaging, setShowPaging] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(40);
  const [paginatedTableData, setPaginatedTableData] = useState([]);

  const [params, setParams] = useState(new URLSearchParams({}))
  const [pagingParams, setPagingParams] = useState(new URLSearchParams({
    'page': currentPage,
    'per': itemsPerPage
  }))


  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  const [{response: postsResponse, isLoading: postsIsLoading, error: postsError}, doFetchPosts] = useFetch('/directories/posts');
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError}, doFetchDivisions] = useFetch('/directories/divisions');
  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch((`/directories/personnels?${params.toString()}&${pagingParams.toString()}`).replace(/,/g, ""));  
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/personnels/${itemId}`);



  useEffect(() => {    
    doFetchPosts();
    doFetchDivisions();
  }, []);

  useEffect(() => {
    if (!postsResponse || !divisionsResponse) {
      return;
    }
    const newPostsList = [];    
    postsResponse.data.forEach(item => {
      newPostsList.push({label: item.attributes.name, value: item.id})
    });

    const newDivisionsList = [];    
    divisionsResponse.data.forEach(item => {
      newDivisionsList.push({label: item.attributes.name, value: item.id})
    });

    setPostsList(sortSelectList(newPostsList));
    setDivisionsList(sortSelectList(newDivisionsList));

    setFilterOptionsPosts(sortDataList(postsResponse.data).map(item => ({label: item.attributes.name, value: item.id})));
    setFilterOptionsDivisions(sortDataList(divisionsResponse.data).map(item => ({label: item.attributes.name, value: item.id})));
  }, [postsResponse, divisionsResponse]);


  useEffect(() => {
    if (!response) {
      return;
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (!meta) {
      return;
    }
    setTotalItems(meta.total_pages * itemsPerPage);
    if (meta.total_pages > 1) {
      setShowPaging(true);
    } else {
      setShowPaging(false);
    }
  }, [meta]);

  const listPage = (page) => {
    setCurrentPage(page);
    let params = new URLSearchParams({
      'page': page,
      'per': itemsPerPage
    })
    setPagingParams(params);
    setLoading(true)
    doFetchTableData();
  };

  useEffect(() => {
    if (tableData.length <= ITEMS_PER_PAGE) {
      setPaginatedTableData(tableData);
      return;
    }
    const offset = currentPage === 0 ? 0 : currentPage * ITEMS_PER_PAGE;
    const fullTableData = tableData.slice();
    if (currentPage === Math.ceil(tableData.length / ITEMS_PER_PAGE) - 1) {
      setPaginatedTableData(fullTableData.slice(offset));
    } else {
      setPaginatedTableData(fullTableData.slice(offset, offset + ITEMS_PER_PAGE));
    }
  }, [tableData, currentPage]);

  const headerData = {
    title: 'staff',
    buttonsList: [
      {
        text: 'create_staff',
        action: () => onAddClick()
      }
    ],
  };

  const filtersData = {
    search: {
      placeholder: t('staff_search'),
      value: search,
      onChange: setSearch
    },
    filtersList: [
      {
        name: 'post',
        key: 'post_ids[]',
        placeholder: 'Поиск',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsPosts
      },
      {
        name: 'division',
        key: 'division_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsDivisions
      },
      {
        name: 'status',
        key: 'statuses[]',
        type: 'multiselect',
        option_search: false,
        options: filterOptionsStatuses
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    search ? newParams.append('search', search) : newParams.delete('search')
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [search, selectedFilters])

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    if (deleteIsLoading) {
      return;
    }
    reloadTable();
  }, [deleteIsLoading]);


  const onAddClick = () => {
    setItemId('new');
    openModal()
  };

  const onItemEdit = (id) => {
    history.push({
      pathname: `/staff/${id}`,
    });
  };

  const onItemReport = (id) => {
    history.push({
      pathname: `/report/staff/${id}`,
    });
  };

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  const reloadTable = () => {
    doFetchTableData();
  };


  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      <div className="content pb-10">
        {loading ? <Spinner/> : (
          <>
            <StaffTable
              tableData={tableData}
              onItemEdit={onItemEdit}
              onItemReport={onItemReport}
              onItemDelete={onItemDelete}
              isLoading={isLoading}
              error={error}
            />
            {showPaging && (
              <Pagination layout="prev, pager, next" total={totalItems} pageSize={itemsPerPage} currentPage={currentPage} onCurrentChange={(currentPage) => listPage(currentPage)}/>
            )}
          </>
        )}
      </div>
      <AddStaff closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} postsList={postsList} divisionsList={divisionsList}/>
    </Layout>
  );
};

export default Staff;
