import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/use-fetch";
import CeCharts from "./full";

const ClassEngineering = ({sensor, startDate, endDate, startTime, endTime, shiftDepths, byDays}) => {
  const {t} = useTranslation();
  const [sensors, setSensors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [sensorsData, setSensorsData] = useState([]);
  const [urls, setUrls] = useState([]);
  const [mbCountSensor, setMbCountSensor] = useState({})
  const [footageSensor, setFootageSensor] = useState({})
  const [spoolWeigthSensor, setSpoolWeigthSensor] = useState({})
  const [mbBruttoSensors, setMbBruttoSensors] = useState([])
  const [mbNettoSensors, setMbNettoSensors] = useState([])

  const [{response, isLoading, error, meta}, doFetchDevice] = useFetch((`/devices/devices/${sensor.attributes.device_id}`).replace(/,/g, ""));

  useEffect(() => {
    if(!sensor) {
      return
    }
    setLoading(true)
    doFetchDevice()
  },[sensor]);

  useEffect(() => {
    if(!response) {
      return
    }
    setDeviceData(response.data);
    setLoading(false)
  },[response])

  useEffect(() => {
    if(!startDate || !endDate || deviceData.length === 0) {
      return
    }

    let brutto = []
    let netto = []

    deviceData.attributes.slots[0].sensors.map(sensor => {
      if (sensor.sensor_type.data_key_name.startsWith('mb_')) {
        switch (sensor.sensor_type.data_key_name) {
          case 'mb_count':
            setMbCountSensor(sensor)
            break;
          case 'mb_lenf':
            setFootageSensor(sensor)
            setSpoolWeigthSensor(sensor)
            break;
          // case 'mb_weight':
          //   setSpoolWeigthSensor(sensor)
          //   break;
          case 'mb_nt_t':
            netto.push(sensor)
            break;
          case 'mb_nt_f':
            netto.push(sensor)
            break;
          case 'mb_mn_teor':
            netto.push(sensor)
            break;
          case 'mb_br_t':
            brutto.push(sensor)
            break;
          case 'mb_br_f':
            brutto.push(sensor)
            break;
          case 'mb_br_teor':
            brutto.push(sensor)
            break;
          default:
            break;
        }
      } else {
        return
      }
    })

    setMbBruttoSensors(brutto)
    setMbNettoSensors(netto)

  },[startDate, endDate, shiftDepths, deviceData])

  return (
    loading ?       
    <div className="flex items-center justify-center w-full h-80">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
    </div> 
    : 
    <>
      {mbCountSensor && 
      mbBruttoSensors.length && 
      mbNettoSensors.length && 
      footageSensor &&
      <CeCharts 
        mbCountSensor={mbCountSensor} 
        footageSensor={footageSensor}
        spoolWeigthSensor={spoolWeigthSensor}
        mbBruttoSensors={mbBruttoSensors} 
        mbNettoSensors={mbNettoSensors} 
        startDate={startDate} 
        startTime={startTime} 
        endDate={endDate} 
        endTime={endTime} 
        shiftDepths={shiftDepths}
      />}
    </>
  )
}

export default ClassEngineering;