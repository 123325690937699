import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilAltIcon } from '@heroicons/react/outline';

import Spinner from "../../../components/loader";

const OrganizationsTable = ({ tableData, onItemEdit, isLoading, error, changeOrg, currentSiteId }) => {
  const { t } = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      <tr
        key={tableItem.id}
        className="hover:bg-gray-50"
      >
        <td className="px-6 py-4 whitespace-nowrap w-32">
          { currentSiteId == tableItem.id ? 
            <div className="inline-flex px-2 py-1 rounded text-sm bg-green-300 text-green-700">{t('active')}</div> : 
            <div onClick={()=>changeOrg(tableItem.id)} className="inline-flex px-2 py-1 rounded text-sm bg-blue-100 text-blue-700 cursor-pointer hover:bg-blue-600 hover:text-white">{t('go_to')}</div> }
        </td>
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{tableItem.attributes.name}</div></td>
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{tableItem.attributes.subdomain}</div></td>
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{tableItem.attributes.user_status}</div></td>
        <td 
          className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end"
        >
          <span onClick={() => onItemEdit(tableItem.id)} className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded "><PencilAltIcon className="w-4 h-4"/></span>
        </td>
      </tr>
    )
  });

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr
          className=""
        >
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-32"></th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('organization_name')}</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('domen')}</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('role')}</th>
          <th scope="col" className="relative px-6 py-3"><span className="sr-only">{t('actions')}</span></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
      {isLoading ? (
        <tr>
          <td colSpan="7">
            <Spinner/>
          </td>
        </tr>
      ) : error ? (
        <tr>
          <td colSpan="7">
            <div className="table__error">{t('loading_error')}</div>
          </td>
        </tr>
      ) : tableData.length === 0 ? (
        <tr>
          <td colSpan="7">
            <div className="table__empty">{t('no_data')}</div>
          </td>
        </tr>
      ) : items}
      </tbody>
    </table>
  );
};

export default OrganizationsTable;
