import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field, CheckboxField } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Checkbox from '@atlaskit/checkbox';
import Select from '@atlaskit/select';
import { ValidationState } from '@atlaskit/select/types';
import { useTranslation } from "react-i18next";

import ErrorsBlock from '../../../../../components/errors';

const PostOptionForm = ({itemId, closeModal, isOpen, fieldTypes, unitsList, directoriesList, parent, onSave}) => {
  const {t} = useTranslation();
  const [currentItem, setCurrentItem] = useState({})
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [required, setRequired] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [unitId, setUnitId] = useState('');
  const [parentId, setParentId] = useState(parent);
  const [childrens, setChildrens] = useState([]);
  const [depth, setDepth] = useState(0);
  const [errors, setErrors] = useState([]);

  const isNewItem = itemId === 'new';

  const typeSelectList = [
    {label: t('numeric'), value: 'numeric'},
    {label: t('string'), value: 'string'},
    {label: t('range'), value: 'range'},
    {label: t('select'), value: 'select'},
    {label: t('select_multiple'), value: 'select_multiple'},
    {label: t('checkbox'), value: 'checkbox'},
    {label: t('collection'), value: 'collection'},
    {label: t('collection_multiple'), value: 'collection_multiple'},
    {label: t('folder'), value: 'folder'},
    {label: t('file'), value: 'file'},
  ];

  useEffect(() => {
    if (itemId === 'new' && parent !== null) {
      const parentOption = fieldTypes.find(item => item.id === parent);
      setCurrentItem({
        label: "",
        type: "",
        required: false,
        default_value: null,
        collection_name: "",
        unit_id: "",
        children: [],
        options: []
      })
      return;
    } 

    if (itemId === 'new' && parent === null) {
      setCurrentItem({
        label: "",
        type: "",
        required: false,
        default_value: null,
        collection_name: "",
        unit_id: "",
        children: [],
        options: []
      })
      return;
    } 

    let item = {}
    if(parent !== null) {
      fieldTypes?.map(it => {
        if(it.uuid === parent) {
          item = it.children.find(child => child.uuid === itemId);
        }
      })   
    } else {
      item = fieldTypes?.find(it => it.uuid === itemId);
    }

    setCurrentItem({
      label: item.label,
      type: item.type,
      required: item.required,
      default_value: item.default_value,
      collection_name: item.collection_name,
      unit_id: item.unit_id,
      children: item.children,
      options: item.options ? item.options : []
    })
  }, [itemId]);

  const validateType = (value) => (!value ? 'EMPTY' : undefined);

  const getValidationState = (error, valid) => {
    if (!error && !valid) {
      return 'default';
    }
    if (valid === true) {
      return 'success';
    }
    return 'error';
  };

  function defaultValueField(type) {
    switch (type) {
      case 'numeric':
        return (
          <Field name="default_value" label={t('default_value_for_number')} defaultValue={currentItem.default_value ? currentItem.default_value : ""}>
            {({ fieldProps }) => 
              <TextField {...fieldProps} type="number"/>}
          </Field>
        )
      case 'string':
        return (
          <Field name="default_value" label={t('default_value_for_string')} defaultValue={currentItem.default_value ? currentItem.default_value : ""}>
            {({ fieldProps }) =>  <TextField {...fieldProps} />}
          </Field>
        )
      case 'range':
        return (         
          <Field name="default_value" label={t('default_value_for_range')} defaultValue={currentItem.default_value ? currentItem.default_value : ""}>
            {({ fieldProps }) =>  <TextField {...fieldProps} />}
          </Field>
        )
      case 'select':
        return (    
          <Field name="default_value" label={t('default_value_for_select')} defaultValue={currentItem.default_value ? currentItem.default_value : ""}>
            {({ fieldProps }) =>  <TextField {...fieldProps} />}
          </Field>
        )
      case 'select_multiple':
        return (         
          <Field name="default_value" label={t('default_value_for_select_multiple')} defaultValue={currentItem.default_value ? currentItem.default_value : ""}>
            {({ fieldProps }) =>  <TextField {...fieldProps} />}
          </Field>
        )
      case 'checkbox':
        return (         
          <CheckboxField name="default_value" defaultValue={currentItem.default_value ? currentItem.default_value : false}>
            {({ fieldProps }) => <Checkbox {...fieldProps} label={t('default_value_for_checkbox')}/>}
          </CheckboxField>
        )
      case 'collection':
        return (         
          <Field name="collection_name" label={t('default_value_for_collection')} defaultValue={directoriesList.filter(item => item.value === currentItem.collection_name)}>
            {({ fieldProps }) => 
              <Select {...fieldProps} options={directoriesList} className="text-sm"/>
            }
          </Field>
        )
      case 'collection_multiple':
        return (         
          <Field name="collection_name" label={t('default_value_for_collection_multiple')} defaultValue={directoriesList.filter(item => item.value === currentItem.collection_name)}>
            {({ fieldProps }) => 
              <Select {...fieldProps} options={directoriesList} className="text-sm"/>
            }
          </Field>
        )
      case 'folder':
        return 
      case 'file':
        return 
    }
  }

  const genFieldTypesItem = (item) => {
    const baseItem = isNewItem ? {
      "type": item.type.value,
      "label": item.label,
      "unit_id": item.unit?.value,
      "required": item.required,
      "children": []
    } : {
      "uuid": itemId,
      "type": item.type.value,
      "label": item.label,
      "unit_id": item.unit?.value,
      "required": item.required,
      "children": []
    };
    switch (item.type.value) {
      case 'checkbox':
        return {
          ...baseItem,
          "default_value": item.default_value
        };
      case 'collection':
        return {
          ...baseItem,
          "default_value": item.collection_name.label,
          "collection_name": item.collection_name.value
        };
      case 'collection_multiple':
        return {
          ...baseItem,
          "default_value": item.collection_name.label,
          "collection_name": item.collection_name.value
        };
      case 'select':
        const currentOneOptions = currentItem.options;
        const newSelectOneOptions = [];
        if (item.default_value) {
          const OneOptions = item.default_value.split(',');
          const selectOneOptions = [];
          OneOptions.forEach((option) => {
            selectOneOptions.push(option.trim())
          });

          const filteredSelectOneOptions = selectOneOptions.filter(function(value, index, self) { 
            return self.indexOf(value) === index;
          })

          filteredSelectOneOptions.forEach((option) => {
            newSelectOneOptions.push({"label": option, "uuid": currentOneOptions.find(it => it.label === option.trim()) ? currentOneOptions.find(it => it.label === option.trim()).uuid : ''});
          });
        }
        return {
          ...baseItem,
          "default_value": item.default_value,
          "options": newSelectOneOptions
        };
      case 'select_multiple':
        const currentMultipleOptions = currentItem.options;
        const newSelectMultipleOptions = [];
        if (item.default_value) {
          const multipleOptions = item.default_value.split(',');
          const selectMultipleOptions = [];
          multipleOptions.forEach((option) => {
            selectMultipleOptions.push(option.trim())
          });

          const filteredSelectMultipleOptions = selectMultipleOptions.filter(function(value, index, self) { 
            return self.indexOf(value) === index;
          })

          filteredSelectMultipleOptions.forEach((option) => {
            newSelectMultipleOptions.push({"label": option, "uuid": currentMultipleOptions.find(it => it.label === option.trim()) ? currentMultipleOptions.find(it => it.label === option.trim()).uuid : ''});
          });
        }
        return {
          ...baseItem,
          "default_value": item.default_value,
          "options": newSelectMultipleOptions
        };
      case 'folder':
        return {
          "type": item.type.value,
          "label": item.label,
          "children": []
        };
      case 'file':
        return {
          "type": item.type.value,
          "label": item.label,
          "required": item.required,
        };
      default:
        return {
          ...baseItem,
          "default_value": item.default_value,
        };
    }
  };


  const handleUpdate = (data) => {
    const item = genFieldTypesItem(data);
    const newFieldTypes = fieldTypes;
    
    if (parent === null) {
      if(isNewItem) {
        newFieldTypes.push(item)
      } else {
        const old = newFieldTypes.findIndex(it => it.uuid === itemId);
        newFieldTypes[old] = item
      }
    } else {
      if(isNewItem) {
        const parentItemIndex = newFieldTypes.findIndex(it => it.uuid === parent);
        newFieldTypes[parentItemIndex].children.push(item)
      } else {
        const parentItemIndex = newFieldTypes.findIndex(it => it.uuid === parent);
        const old = newFieldTypes[parentItemIndex].children.findIndex(it => it.uuid === itemId);
        newFieldTypes[parentItemIndex].children[old] = item
      }
    }
    onSave(newFieldTypes);
    closeModal();
  }

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
        setCurrentItem({
          label: "",
          type: "",
          required: false,
          default_value: null,
          collection_name: "",
          unit_id: "",
          children: [],
          options: []
        })
      }, 300);
    }
  },[isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNewItem ? t('adding_equipment_option') : t('editing_equipment_option')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('equipment_option_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => handleUpdate(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="label" label={t('title_name')} defaultValue={currentItem.label} validate={validateType} isRequired>
                      {({ fieldProps, error, meta: { valid } }) => <TextField {...fieldProps}/>}
                    </Field>
                    <Field name="type" label={t('type')} defaultValue={typeSelectList.find(item => item.value === currentItem.type)} isRequired validate={validateType}>
                      {({ fieldProps, error, meta: { valid } }) => 
                        <Select {...fieldProps} placeholder={t('select_placeholder')} options={typeSelectList} onChange={(type) => setCurrentItem({...currentItem, type: type.value})} className="text-sm" validationState={getValidationState(error, valid)}/>
                      }
                    </Field>
                    {defaultValueField(currentItem.type)}
                    {currentItem.type === "folder" || currentItem.type === "file" ? "" :
                      <Field name="unit" label={t('unit')} defaultValue={unitsList.find(item => item.value === currentItem.unit_id)} isRequired validate={validateType}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <Select {...fieldProps} placeholder={t('select_placeholder')} options={unitsList} className="text-sm" validationState={getValidationState(error, valid)}/>
                        }
                      </Field>
                    }
                    <div className="text-sm">
                      <CheckboxField name="required" defaultValue={currentItem.required}>
                        {({ fieldProps }) => <Checkbox {...fieldProps} label={t('required_field')}/>}
                      </CheckboxField>
                    </div>
                    <div className="flex justify-between mt-8">
                      <button 
                        // onClick={() => handleUpdate()}
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
export default PostOptionForm;