import { Fragment, useState, useEffect } from 'react';
import useFetch from '../../../hooks/use-fetch';

import { Dialog, Transition } from '@headlessui/react';
import { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import TextArea from '@atlaskit/textarea';
import { useTranslation } from "react-i18next";

import ErrorsBlock from '../../../components/errors';

const Form = ({slug, itemId, closeModal, isOpen, reloadTable, tableData, parent}) => {
  const {t} = useTranslation();
  const [name, setName] = useState('');
  const [abbr, setAbbr] = useState('');
  const [description, setDescription] = useState('');
  const [parentId, setParentId] = useState(parent);
  const [depth, setDepth] = useState(0);
  const [errors, setErrors] = useState([]);

  const isNewItem = itemId === 'new';

  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/directories/${slug}`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/${slug}/${itemId}`);

  useEffect(() => {
    if (itemId === 'new' && parent !== null) {
      const parentEntry = tableData.find(item => item.id === parent);
      setName('');
      setAbbr('');
      setDescription('');
      setDepth(parentEntry?.depth + 1);
      setParentId(parent)
      return;
    } 

    if (itemId === 'new' && parent === null) {
      setName('');
      setAbbr('');
      setDescription('');
      setDepth(0);
      setParentId(parent)
      return;
    } 

    const entry = tableData.find(item => item.id === itemId);
    setName(entry.attributes.name);
    setAbbr(entry.attributes.abbr);
    setDescription(entry.attributes.description);
    setDepth(entry.attributes.depth);
    setParentId(entry.attributes.parent_id)
  }, [itemId, isOpen]);

  const onSave = () => {
    if (isNewItem) {
      doFetchItemSave({
        method: 'POST',
        data: {
          data: {
            name: name,
            abbr: abbr,
            description: description,
            parent_id: parentId,
            depth: depth
          }
        }
      });
    } else {
      doFetchItemUpdate({
        method: 'PUT',
        data: {
          data: {
            name: name,
            abbr: abbr,
            description: description,
            parent_id: parentId,
            depth: depth
          }
        }
      });
    }
  };

  useEffect(() => {
    closeModal();
    reloadTable();
  },[itemSaveResponse, itemUpdateResponse])

  useEffect(() => {
    if(itemSaveError) {
      setErrors(itemSaveError.errors)
    }
    if(itemUpdateError) {
      setErrors(itemUpdateError.errors)
    }
  }, [itemSaveError, itemUpdateError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNewItem ? t('adding_entry') : t('editing_entry')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('entry_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <div className="text-sm">
              <Field name="name" label={t('name')} isRequired>
                {({ fieldProps }) => 
                  <TextField 
                    {...fieldProps} 
                    onChange={(evt) => setName(evt.target.value)}
                    value={name === null ? '' : name}
                />}
              </Field>
              <Field name="abbr" label={t('abbreviation')}>
                {({ fieldProps }) => 
                  <TextField 
                    {...fieldProps} 
                    onChange={(evt) => setAbbr(evt.target.value)}
                    value={abbr === null ? '' : abbr}
                />}
              </Field>
              <Field name="description" label={t('description')}>
                {({ fieldProps }) => 
                  <TextArea 
                    {...fieldProps} 
                    onChange={(evt) => setDescription(evt.target.value)}
                    value={description === null ? '' : description}
                />}
              </Field>
              </div>
              <div className="flex justify-between mt-8">
                <button 
                  onClick={() => onSave()}
                  type="submit" 
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  {t('save')}
                </button>
                <button 
                  onClick={() => closeModal()}
                  type="button" 
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  {t('cancel')}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
export default Form;