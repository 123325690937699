import React from 'react';
import Table from '../../../../../components/form/table';
import Spinner from "../../../../../components/loader";
import { useTranslation } from 'react-i18next';
import {getPerfectDate} from '../../../../../utils/index'

const RelatedTable = ({ tableData }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        at: tableItem.attributes.parent_changed_at,
        inventory_number: tableItem.attributes.inventory_number,
        division_changed_at: tableItem.attributes.division_changed_at,
        division_name: tableItem.attributes.division_name,
        equipment_model_name: tableItem.attributes.equipment_model_name,
        equipment_type_name: tableItem.attributes.equipment_type_name,
        factory_number: tableItem.attributes.factory_number,
        name: tableItem.attributes.name,
        position: tableItem.attributes.position,
        producer_name: tableItem.attributes.producer_name,
        status: tableItem.attributes.status,
        status_changed_at: tableItem.attributes.status_changed_at        
      }
    )
  });

  const columns = [
    {
      label: t('name'),
      prop: "name",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <>
            <div className="font-medium text-gray-800 truncate">
              {row.name}
            </div>
            <div className="flex text-gray-500 items-center">
              {t('from_date')}: {getPerfectDate(row.at)}
            </div>
            <div className="text-gray-500 truncate flex">
              {row.inventory_number}
            </div>
          </>
        )
      }
    },
    {
      label: t('equipment'),
      prop: "inventory_naumber",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: (row) => { 
        return (
          <>
            <div className="font-medium text-gray-900 truncate">
              {row.equipment_model_name}
            </div>
            <div className="font-medium text-gray-500 truncate">
              {row.producer_name}
            </div>
            <div className="text-gray-500 truncate flex">
              {row.factory_number}
            </div>
          </>
        )
      }
    },
    {
      label: t('division'),
      prop: "division",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: (row) => { 
        return (
        <>
        {row.division_name !== null ? 
          <>
            <div className="font-medium text-gray-900 truncate">
              {row.division_name}
            </div>
            <div className="text-gray-500 truncate flex">
            {t('from_date')}: {getPerfectDate(row.division_changed_at)}
            </div>
          </>
        : "" }
        </>
        )}
    },
    {
      label: t('status'),
      prop: "status",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => { 
        return (
          <>
            {row.status !== null ? 
            <>
              <div className="font-medium text-gray-900 truncate">
                {t(row.status)}
              </div>
              <div className="text-gray-500 truncate flex">
              {t('from_date')}: {getPerfectDate(row.status_changed_at)}
              </div>
            </>
            : "" }
          </>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  );

};

export default RelatedTable;
