import { Duration } from "luxon"

function calculateMedian(arr) {
  if (arr.length == 0) {
    return; // 0.
  }
  arr.sort((a, b) => a - b); // 1.
  const midpoint = Math.floor(arr.length / 2); // 2.
  const median = arr.length % 2 === 1 ?
    arr[midpoint] : // 3.1. If odd length, just take midpoint
    (arr[midpoint - 1] + arr[midpoint]) / 2; // 3.2. If even length, take median of midpoints
  return  Math.ceil(median);
}

function calculateModa(a) {
  a = a.slice().sort((x, y) => x - y);

  let bestStreak = 1;
  let bestElem = a[0];
  let currentStreak = 1;
  let currentElem = a[0];

  for (let i = 1; i < a.length; i++) {
    if (a[i-1] !== a[i]) {
      if (currentStreak > bestStreak) {
        bestStreak = currentStreak;
        bestElem = currentElem;
      }

      currentStreak = 0;
      currentElem = a[i];
    }

    currentStreak++;
  }

  return currentStreak > bestStreak ? currentElem : bestElem;
};

export default function BottelNeckItem({item}) {
  let width = item.worked / ((item.worked + item.stopped + item.no_data) / 100)
  let works = item.data.filter(i => i.state === "worked")
  let worksNoSecond = item.data.filter(i => i.state === "worked" && i.duration > 1)
  let medianWorks = calculateMedian(worksNoSecond.map(i => i?.duration))
  let modaWorks = calculateModa(worksNoSecond.map(i => i?.duration))
  let worksTime = Duration.fromMillis(item.worked * 1000).normalize().toFormat("hh:mm:ss")

  let idel = item.data.filter(i => i.state === "stopped")
  let stops = item.data.filter(i => i.state === "stopped" && i.duration > 1)
  let medianStops = calculateMedian(stops.map(i => i?.duration))
  let modaStops = calculateModa(stops.map(i => i?.duration))
  let idleTime = Duration.fromMillis(item.stopped * 1000).normalize().toFormat("hh:mm:ss")
  let turnedOffTime = Duration.fromMillis(item.no_data * 1000).normalize().toFormat("hh:mm:ss")

  return (
    <div className="w-full space-y-2">
      <div className="text-sm font-medium">{item.equipment}</div>
      <div className="relative flex items-center">
        <div className="h-5 rounded bg-emerald-400 justify-start flex items-center text-sm text-emerald-900" style={{width: width + "%"}}>
          <div className="px-2 whitespace-nowrap text-sm font-medium">{width.toFixed(2)} %</div>
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="p-2 bg-emerald-300 min-w-[140px]">
          <div className="text-sm font-semibold">{worksTime}</div>
          <div className="text-xs">Время работ</div>
        </div>
        <div className="p-2 bg-amber-300 min-w-[140px]">
          <div className="text-sm font-semibold">{idleTime}</div>
          <div className="text-xs">Время простоев</div>
        </div>
        <div className="p-2 bg-rose-300 min-w-[140px]">
          <div className="text-sm font-semibold">{turnedOffTime}</div>
          <div className="text-xs">Время отключен</div>
        </div>
        <div className="p-2 bg-emerald-200 min-w-[140px]">
          <div className="text-sm font-semibold">{works.length}</div>
          <div className="text-xs">Работы за месяц</div>
        </div>
        <div className="p-2 bg-amber-200 min-w-[140px]">
          <div className="text-sm font-semibold">{idel.length}</div>
          <div className="text-xs">Простои за месяц</div>
        </div>
        <div className="p-2 bg-cyan-200 min-w-[140px]">
          <div className="text-sm font-semibold">{medianWorks ? Duration.fromMillis(medianWorks * 1000).normalize().toFormat("hh:mm:ss") : "-"}</div>
          <div className="text-xs">medianWorks</div>
        </div>
        <div className="p-2 bg-cyan-200 min-w-[140px]">
          <div className="text-sm font-semibold">{modaWorks ? Duration.fromMillis(modaWorks * 1000).normalize().toFormat("hh:mm:ss") : "-"}</div>
          <div className="text-xs">modaWorks</div>
        </div>
        <div className="p-2 bg-cyan-200 min-w-[140px]">
          <div className="text-sm font-semibold">{medianStops ? Duration.fromMillis(medianStops * 1000).normalize().toFormat("hh:mm:ss") : "-"}</div>
          <div className="text-xs">medianStops</div>
        </div>
        <div className="p-2 bg-cyan-200 min-w-[140px]">
          <div className="text-sm font-semibold">{modaStops ? Duration.fromMillis(modaStops * 1000).normalize().toFormat("hh:mm:ss") : "-"}</div>
          <div className="text-xs">modaStops</div>
        </div>
      </div>
    </div>
  )
}