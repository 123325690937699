import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';


export const useScreenStore = create(
  immer((set) => ({
    screen: null,
    screenAvailable: false,
    screenIsLoading: true,
    equipments: null,
    shifts: null,
    currentShift: null,
    screenWidth: 1280,
    screenHeight: 1920,
    pagesCount: 1,
    equipmentCount: 0,
    screenData: {},
    setScreenAvailable: (value) => set((state) => {state.screenAvailable = value}),
    setScreenIsLoading: (value) => set((state) => {state.screenIsLoading = value}),
    setScreen: (screen) => set((state) => {state.screen = screen}),
    setEquipmentCount: (count) => set((state) => {state.equipmentCount = count}),
    setPagesCount: (count) => set((state) => {state.pagesCount = count}),
    setEquipment: (equipments) => set((state) => {state.equipments = equipments}), 
    setShifts: (shifts) => set((state) => {state.shifts = shifts}),
    setCurrentShift: (shift) => set((state) => {state.currentShift = shift}),
    setScreenWidth: (width) => set((state) => {state.screenWidth = width}),
    setScreenHeight: (height) => set((state) => {state.screenHeight = height}),
    setScreenData: (data) => set((state) => {state.screenData = data}),
    addEquipmentData: (id, data) => set((state) => {state.screenData[id] = data}),
    updateEquipmentData: (id, data) => set((state) => {state.screenData[id] = data}),
    updateEquipmentStartDateTime: (id, data) => set((state) => {state.screenData[id].startDateTime = data}),
    updateEquipmentEndDateTime: (id, data) => set((state) => {state.screenData[id].endDateTime = data}),
    updateEquipmentTimeline: (id, data) => set((state) => {state.screenData[id].timeline = data}),
  }))
)