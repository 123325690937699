import React, { useState, useEffect, useContext, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useFetch from '../../../hooks/use-fetch';
import Select from '@atlaskit/select';
import Checkbox from '@atlaskit/checkbox';
import {DateTime} from "luxon";


import { convertTimezone } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';

import {CurrentUserContext} from "../../../contexts/current-user";

import ReportEquipmentInfo from './info'
// import ReportEquipmentStaff from './staff';
import EquipmentAlgorithms from './algorithms';
import DateRangePicker from '../../../components/form/calendar/dateRangePicker';
import EquipmentIndicators from './indicators';
import Interaction from './interaction';


const EquipmentReportPage = (props) => {
  const {t} = useTranslation();
  let history = useHistory();
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [itemId, ] = useState(props.match.params.id);
  const [currentItem, setCurrentItem] = useState();
  const [slotsList, setSlotsList] = useState([]);
  const [slot, setSlot] = useState({});
  const [allSensors, setAllSensors] = useState([]);
  const [commonSensors, setCommonSensors] = useState([]);
  const [rfidSensors, setRfidSensors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(DateTime.local().startOf('day'));
  const [endDate, setEndDate] = useState(DateTime.local().endOf('day'));
  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" });
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59"});
  const [showByDays, setShowByDays] = useState(false)

  const dateRangePickerRef = useRef(null);

  const [{response, isLoading, error}, doFetchEquipment] = useFetch(`/directories/equipment_items/${itemId}`);
  const [{response: sensorsResponse, isLoading: sensorsIsLoading, error: sensorsError}, doFetchSensors] = useFetch(`/directories/equipment_items/${itemId}/sensors`);

  function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
        props.location.search.substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }

  useEffect(() => {
    setLoading(true)

    const innerDate = DateTime.fromMillis(Number.parseInt(findGetParameter('date')))

    if(innerDate && !innerDate.invalid) {
      setStartDate(innerDate.startOf('day'))
      setEndDate(innerDate.endOf('day'))
    }

    doFetchEquipment();
    doFetchSensors();

    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]
    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat("HH:mm:ss"), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat("HH:mm:ss")})
      })
    }
    setShiftList(shifts);
  },[])


  useEffect(() => {
    if(!response || !sensorsResponse) {
      return
    }
    setCurrentItem(response.data)

    const list = [{label: "Все", value: null, sensors: sensorsResponse.data}]
    const groupedByslots = groupBy(sensorsResponse.data, slot => slot.attributes.slot_name);

    groupedByslots.forEach((slots) => {
      list.push({label: slots[0].attributes.equipment_item_name, value: slots[0].attributes.slot_name, sensors: slots})
    })
    
    setSlotsList(list);
    setSlot(list[0]);
    setAllSensors(list[0].sensors)
    sliceSensors(list[0].sensors);
    setLoading(false)
  },[response, sensorsResponse]);

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
         if (!collection) {
            map.set(key, [item]);
         } else {
            collection.push(item);
         }
    });
    return map;
  }

  const sliceSensors = (items) => {
    const common = []
    const rfid = []
    items.map(it => {
      if(it.attributes.sensor_type.data_key_name === "rfid_hold" || it.attributes.sensor_type.data_key_name === "rfid") {
        rfid.push(it)
      } else {
        common.push(it)
      }
    })
    setCommonSensors(common);
    setRfidSensors(rfid);
  }

  const inEquipmentEdit = () => {
    history.push({
      pathname: `/equipment/${itemId}`,
    });
  }

  const handleChangeSlot = (slot) => {
    setSlot(slot);
    sliceSensors(slot.sensors);
  };

  const onDateChoice = (dateRange) => {
    if (dateRange === null) {
      return;
    } else if (dateRange[1] === null) {
      const start = DateTime.local(dateRange[0].getFullYear(), dateRange[0].getMonth() + 1, dateRange[0].getDate())
      setStartDate(start);
      setEndDate(null);
    } else {
      const start = DateTime.local(dateRange[0].getFullYear(), dateRange[0].getMonth() + 1, dateRange[0].getDate())
      const end = DateTime.local(dateRange[1].getFullYear(), dateRange[1].getMonth() + 1, dateRange[1].getDate())
      
      setStartDate(start);
      setEndDate(end.endOf('day'));
    }
  };

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at})
    } else {
      setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at})
    }
  }

  const headerData = {
    title: 'equipment_report',
    isWide: true
  };

  return loading ? <Spinner /> : (
    <Layout
      headerData={headerData}
    >
      <div className="grid grid-cols-3 p-4 border bg-gray-50 rounded mt-6 mb-6">
        <div className="flex flex-col text-sm space-y-1">
          <span>{t('equipment')}: {currentItem?.attributes.name}</span>
          <span>{t('equipment_type_name')}: {currentItem?.attributes.equipment_type_name}</span>
          <span>{t('inventory_number')}: {currentItem?.attributes.inventory_number}</span>
        </div>
        <div className="flex flex-col text-sm space-y-1">
          <span>{t('vendor')}: {currentItem?.attributes.producer_name}</span>
          <span>{t('model')}: {currentItem?.attributes.equipment_model_name}</span>
          <span>{t('factory_number')}: {currentItem?.attributes.factory_number}</span>
        </div>
        <div className="flex justify-end">
          <button className="bg-blue-100 text-blue-600 rounded px-2 py-1 h-8 flex items-center text-sm font-medium" onClick={() => inEquipmentEdit()}>{t('equipment_card')}</button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 mt-4">
        {slotsList.length > 2 ?
        <div>
          <Select 
            className="text-sm justify-start"
            options={slotsList} 
            value={slot}
            onChange={value => handleChangeSlot(value)}
          />
        </div> : ""}
        <div>
          <Select 
            className="text-sm justify-start"
            options={shiftList} 
            value={currentShift.value === null ? shiftList.find(it => it.value === null) : shiftList.find(it => it.value === currentShift.value)}
            onChange={value => handleChangeShift(value)}
          />
        </div>
        <div className='flex items-center text-sm'>
          <Checkbox  
            label={t('show_by_days')}
            isChecked={showByDays}
            onChange={() => setShowByDays(!showByDays)}
          />
        </div>
        <div className="lg:col-end-5 col-span-1">
          <DateRangePicker startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} onChange={onDateChoice} />
        </div>
      </div>
      {loading ? <Spinner/> : 
      <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200 mb-4">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('data')}
            </Tab>
            {currentUserState?.applications?.staff ? 
              <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
                {t('who_use_equipment')}
              </Tab> : ""
            }
            {/* <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('indicators')}
            </Tab> */}
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('algorithms')}
            </Tab>
          </TabList>
          <TabPanel>
            <ReportEquipmentInfo 
              showByDays={showByDays}
              currentItem={currentItem}
              startDate={startDate}
              endDate={endDate}
              convertedShift={convertedShift}
              sensors={commonSensors}
            />
          </TabPanel>
          {currentUserState?.applications?.staff ? 
            <TabPanel>
              <Interaction 
                showByDays={showByDays}
                currentItem={currentItem}
                startDate={startDate}
                endDate={endDate}
                convertedShift={convertedShift}
                sensors={commonSensors}
              />
            </TabPanel> : ""}
          {/* <TabPanel>
            <EquipmentIndicators 
              currentItem={currentItem}
              startDate={startDate}
              endDate={endDate}
              convertedShift={convertedShift}
              sensors={allSensors}
            />
          </TabPanel> */}
          <TabPanel>
            <EquipmentAlgorithms 
              currentItem={currentItem}
              startDate={startDate}
              endDate={endDate}
              convertedShift={convertedShift}
              sensors={allSensors}
            />
          </TabPanel>
        </Tabs>
      }
    </Layout>
  );
};

export default EquipmentReportPage;