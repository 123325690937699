import {DocumentReportIcon, ExclamationIcon} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import {getPerfectDateTime, getPerfectTime} from '../../../../../utils';
import { useEffect, useState } from 'react';

import InteractionGraph from './grpah';
import useFetch from '../../../../../hooks/use-fetch';

let ignoreSensors = ['rfid', 'rfid_hold']

const InteractionItem = ({item, showByDays}) => {
  const {t} = useTranslation();
  const [openGraph, setOpenGraph] = useState(false)
  const [sensors, setSensors] = useState();

  const [{response, isLoading, error}, doFetchSensors] = useFetch(`/directories/equipment_items/${item.equipment_item_id}/sensors`);

  useEffect(() => {
    if(!response || !response?.data || !response?.data?.length) {
      return
    }
    setSensors(response?.data?.filter(s => !ignoreSensors.includes(s.attributes.sensor_type.data_key_name)))
  },[response])

  function handleLoadData() {
    if(!openGraph) {
      doFetchSensors()
    }
    setOpenGraph(!openGraph)
  }
  
  return (
    <>
    <div className='flex flex-row h-full py-2 border-b'>
      <div className='flex w-4 items-center justify-center mx-4'>
        {item.start && item.finish ? <DocumentReportIcon onClick={() => handleLoadData(item)} className='text-green-500 cursor-pointer' width={16} height={16} /> : <ExclamationIcon className='text-yellow-600' width={16} height={16}/>}
      </div>
      <div key={item.id} className='flex flex-row w-full text-sm pr-4 justify-between'>
        <div className='flex flex-row'>
          <div className='font-medium mr-2'>{item.start ? showByDays ? getPerfectTime(item.start) : getPerfectDateTime(item.start) : t('no_data')}</div> -
          <div className='font-medium ml-2'>{item.finish ? showByDays ? getPerfectTime(item.finish) : getPerfectDateTime(item.finish) : t('no_data')}</div>
        </div>
        <div className='font-normal text-gray-700 truncate'>{item.equipment_item.producer_name} / {item.equipment_item.model_name} / {item.equipment_item.name}</div>
      </div>
    </div>
    {openGraph && 
      sensors?.map((sensor) => (
        <InteractionGraph key={sensor.id} currentItem={item} currentSensor={sensor}/>
      ))
    }
    </>
  )
}

export default InteractionItem;