import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../hooks/use-fetch';

import {CurrentUserContext} from "../../../../contexts/current-user";

import Spinner from '../../../../components/loader';
import Layout from '../../../../components/layout';
import SensorsTable from "./table";
import FormSensor from './form';


const SensorsDirectory = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [item, setItem] = useState('');
  const [itemId, setItemId] = useState('');


  let [isOpen, setIsOpen] = useState(false)

  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch('/administration/directories/sensor_types', true);
  const [{response: deleteResponse, isLoading: deleteIsLoading}, doFetchDelete] = useFetch(`/administration/directories/sensor_types/${itemId}`, true)


  const headerData = {
    title: 'sensors',
    buttonsList: [
      {
        text: 'add_sensor',
        action: () => openModal()
      }
    ],
  };

  useEffect(() => {
    setLoading(true);
    doFetchTableData();
  }, []);

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);

  const onItemEdit = (id) => {
    setItem(tableData.find(it => it.id === id));
    openModal();
  }

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchDelete({
      method: 'DELETE'
    });
  }

  useEffect(() => {
    if(deleteIsLoading) {
      return
    }
    reloadTable()
  },[deleteIsLoading])


  function closeModal() {
    setTimeout(() => {
      setItem('');
    }, 300);
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    setLoading(true);
    doFetchTableData();
  };


  return (
    <Layout
      isWide={true}
      headerData={headerData}
    >
      {loading ? <Spinner/> : (
        <div className="mt-8 pb-10">
          <SensorsTable
            tableData={tableData}
            isLoading={isLoading}
            onItemEdit={onItemEdit}
            onItemDelete={onItemDelete}
          />
        </div>
      )}
      <FormSensor closeModal={closeModal} isOpen={isOpen} item={item} reloadTable={reloadTable}/>
    </Layout>
  );
};

export default SensorsDirectory;
