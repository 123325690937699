import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import useFetch from '../../../../../hooks/use-fetch';
import { covertTimeToSeconds, convertTimeHHMM, secondsToTime} from '../../../../../utils';
import TimelineWidget from '../../../../../components/widgets/timeline';

function Box({ children }) {
  return (
      <div className='flex w-full h-4 items-center 
      bg-gradient-to-r 
      from-green-400 
      to-red-600 
      via-yellow-200
      animate-gradient-x'
      >
          {children}
      </div>
  )
}

const TimelineData = ({equipment, selectedDate, currentShift}) => {
  const {t} = useTranslation();
  const tz = DateTime.local().toFormat('ZZ');
  const [loading, setLoading] = useState(true);
  const [equipmentTimeline, setEquipmentTimeline] = useState([])
  const [startDate, setStartDate] = useState(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T${currentShift.started_at}.000${tz}`);
  const [endDate, setEndDate] = useState(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T${currentShift.ended_at}.999${tz}`);
  const [timeMarkers, setTimeMarkers] = useState([0, 21600, 43200, 64800, 86400])

  const [{response, isLoading, error}, doFetchTimeline] = useFetch(`/directories/equipment_items/${equipment.id}/timelines?from=${startDate}&to=${endDate}`);

  useEffect(() => {
    if(covertTimeToSeconds(currentShift.started_at) > covertTimeToSeconds(currentShift.ended_at)) {
      setStartDate(`${selectedDate.toFormat("yyyy-MM-dd")}T${currentShift.started_at}.000${tz}`)

      let nextEndDay = selectedDate.plus({day: 1}).toFormat("yyyy-MM-dd")
      setEndDate(`${nextEndDay}T${currentShift.ended_at}.000${tz}`)

    } else {
      setStartDate(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T${currentShift.started_at}.000${tz}`);
      setEndDate(`${selectedDate.year}-${selectedDate.month}-${selectedDate.day}T${currentShift.ended_at}.999${tz}`);
    }


    const shiftStart = covertTimeToSeconds(currentShift.started_at);
    const shiftEnded = covertTimeToSeconds(currentShift.ended_at);

    setTimeMarkers([
      shiftStart, 
      shiftStart + (currentShift.duration / 4) > 86400 ? (shiftStart + (currentShift.duration / 4)) - 86400 : shiftStart + (currentShift.duration / 4), 
      shiftStart + (currentShift.duration / 2) > 86400 ? (shiftStart + (currentShift.duration / 2)) - 86400 : shiftStart + (currentShift.duration / 2), 
      shiftStart + (currentShift.duration / 4) * 3 > 86400 ? (shiftStart + (currentShift.duration / 4) * 3) - 86400 : shiftStart + (currentShift.duration / 4) * 3, 
      shiftEnded
    ])

    setLoading(true)
    doFetchTimeline()
  },[selectedDate, currentShift])

  useEffect(() => {
    if(!response) {
      return
    }
    setEquipmentTimeline(equipment.attributes.stop_threshold > 0 ? transformTimelineByStopThreshold(response.data.attributes.timeline) : response.data.attributes.timeline)
    setLoading(false)
  },[response])

  const getLoadPercent = (arr) => {
    let turnedOffCount = 0;
    let stoppedCount = 0;
    let workedCount = 0;

    arr.forEach(lineItem => {
      switch (lineItem.state) {
        case 'turned_off':
          turnedOffCount = turnedOffCount + lineItem.seconds;
          break;
        case 'stopped':
          stoppedCount = stoppedCount + lineItem.seconds;
          break;
        case 'worked':
          workedCount = workedCount + lineItem.seconds;
          break;
        default: 
          break;
      }
    });


    if(workedCount > 0) {
      return <div className='text-xs px-2 py-1'>{t('work_percent')}: {Math.round(workedCount / (stoppedCount + turnedOffCount + workedCount) * 10000 , -2) / 100} %</div>
    } else {
      return <></>
    }
  };

  const getIntensivity = (arr) => {
    let turnedOffCount = 0;
    let stoppedCount = 0;
    let workedCount = 0;
    let operationsCount = 0;
    let idleCount = 0;

    arr.forEach(lineItem => {
      switch (lineItem.state) {
        case 'turned_off':
          turnedOffCount = turnedOffCount + lineItem.seconds;
          break;
        case 'stopped':
          idleCount = idleCount + 1;
          stoppedCount = stoppedCount + lineItem.seconds;
          break;
        case 'worked':
          operationsCount = operationsCount + 1;
          workedCount = workedCount + lineItem.seconds;
          break;
        default: 
          break;
      }
    });

    const avgOperationTime = operationsCount > 0 ? Math.round(workedCount / operationsCount) : 0;

    if(avgOperationTime > 0) {
      return (
        <>
          <div className='text-xs px-2 py-1'>{t('count_of_operations')}: {operationsCount}</div>
          <div className='text-xs px-2 py-1'>{t('avg_operation_time')}: {secondsToTime(avgOperationTime)}</div>
        </>
      )
    } else {
      return <></>
    }
  };

  function transformTimelineByStopThreshold(timeline) {
    let newT = [...timeline]
    let res = []
    newT.forEach(function(item){
      if(item.state === 'stopped' && item.seconds <= equipment.attributes.stop_threshold && res.length > 0){
        if(res[res.length - 1].state === 'worked') {
          res[res.length - 1] = {...res[res.length - 1], seconds: res[res.length - 1].seconds + item.seconds}
        } else {
          res.push({...item, state: 'worked'});
        }
      } else {
        res.push(item);
      }
    })
    return res;
  }

  return (
    <div>
      <div className="grid grid-cols-4 text-xs text-gray-500">
        <div className="flex pl-4">{convertTimeHHMM(timeMarkers[0])}</div>
        <div className="flex pl-2">{convertTimeHHMM(timeMarkers[1])}</div>
        <div>{convertTimeHHMM(timeMarkers[2])}</div>
        <div className="flex justify-between -ml-2"><span>{convertTimeHHMM(timeMarkers[3])}</span></div>
      </div>
      <div className="flex overflow-hidden w-full h-5 px-4 py-1 mb-3"> 
        {
          loading ? 
          <Box /> 
          : <div className='flex w-full h-full'><TimelineWidget data={ equipmentTimeline } duration={currentShift.duration}/></div>
        }
      </div> 
      
      {loading ? <Skeleton height={22} borderRadius={0} baseColor="#DCDCDC"/> :
        <div className='grid grid-cols-6 gap-4 border-t bg-gray-100 px-2 text-gray-700'>
          {getLoadPercent(equipmentTimeline)}
          {getIntensivity(equipmentTimeline)}
        </div>
      }
      
    </div>
  )
}

export default TimelineData;