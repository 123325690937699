import React, {useState, useEffect, useRef, useContext} from 'react';
import { useLocation } from "react-router-dom";
import HeatMap from '../../../components/heatmap';
import Tooltip from '@uiw/react-tooltip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import {DateTime, Info} from 'luxon';

import useFetch from '../../../hooks/use-fetch';
import Spinner from '../../../components/loader';
import Layout from '../../../components/layout';

import DeviceData from './data';
import DeviceSettings from './settings';
import DeviceConstants from './constants';
import { getPerfectDate } from '../../../utils';

// const today = DateTime.fromJSDate(new Date());

const today = DateTime.local()


const DevicePage = (props) => {
  const location = useLocation();
  const {t} = useTranslation();
  const [itemId, ] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState('');
  const [sensorTypes, setSensorTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState([DateTime.local().startOf('day'), DateTime.local()])
  const [intensivity, setIntensivity] = useState([]);
  const [intensivityLoaded, setIntensivityLoaded] = useState(false);
  const [intensivityStartDate, setIntensivityStartDate] = useState(DateTime.local().endOf("month").minus({'year': 1}));
  const [intensivityEndDate, setIntensivityEndDate] = useState(DateTime.local().endOf('month'));

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/devices/devices/${itemId}`);
  const [{response: intensivityResponse, isLoading: intensivityLoading, error: intensivityError}, doFetchIntensivity] = useFetch(`/devices/devices/${itemId}/daily_usage?from=${intensivityStartDate}&to=${intensivityEndDate}`)
  const [{response: itemUpdateResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemUpdate] = useFetch(`/devices/devices/${itemId}`);


  const headerData = {
    title: currentItem === '' ? '' : `${t('device')}: ${currentItem.attributes.serial_number}`,
    isWide: false
  };

  useEffect(() => {
    setSensorTypes(location.state.types)
  },[])

  useEffect(() => {
    doFetchIntensivity()
    doFetchItem();
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }
    setCurrentItem(response.data);
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    setCurrentItem(itemUpdateResponse.data);
  }, [itemUpdateResponse]);

  useEffect(() => {
    if (!intensivityResponse) {
      return;
    }
    intensivityResponse.data.map(val => {
      delete Object.assign(val, {['date']: val['at'] })['at'];
      delete Object.assign(val, {['count']: val['online'] })['online'];
    })
    setIntensivity(intensivityResponse.data);
    setIntensivityLoaded(true)
  }, [intensivityResponse]);

  const increaseYear = () => {
    setIntensivityStartDate(intensivityStartDate.minus({year: 1}))
    setIntensivityEndDate(intensivityEndDate.minus({year: 1}))
    doFetchIntensivity()
  }

  const decreaseYear = () => {
    setIntensivityStartDate(intensivityStartDate.plus({year: 1}))
    setIntensivityEndDate(intensivityEndDate.plus({year: 1}))
    doFetchIntensivity()
  }

  const onUpdate = (name) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: {
          name: name
        }
      }
    });
  };

  const reloadTable = () =>{
    doFetchItem();
  }


  function convertHeatMapDate(date) { 
    const parts = date.split('/').map( Number )
    return DateTime.local(parts[0], parts[1], parts[2])
  }

  function setColors() {
    let d = 86400
    let obj = {}
    Object.assign(obj, {[d/d]: '#f3f4f6'});
    Object.assign(obj, {[d * 0.1]: '#FFF48E'});
    Object.assign(obj, {[d * 0.25]: '#CAE781'});
    Object.assign(obj, {[d * 0.4]: '#94D973'});
    Object.assign(obj, {[d * 0.5]: '#28BD57'});
    Object.assign(obj, {[d * 0.65]: '#1B9D9B'});
    Object.assign(obj, {[d * 0.8]: '#148DBD'});
    Object.assign(obj, {[d * 0.9]: '#0D7DDE'});
    Object.assign(obj, {[d * 1]: '#3B82F6'});
    return obj
  }

  function legendText() {
    return ( 
      <div className='flex flex-row h-4 w-full pt-2 pl-2 bg-white flex-shrink-0 items-center justify-center'>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#f3f4f6'}}></span>0%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#FFF48E'}}></span>10%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#CAE781'}}></span>25%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#94D973'}}></span>40%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#28BD57'}}></span>50%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#1B9D9B'}}></span>65%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#148DBD'}}></span>80%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#0D7DDE'}}></span>90%</div>
        <div className='flex items-center mr-3' style={{fontSize: 9}}><span className='block w-2 h-2 mr-2' style={{backgroundColor: '#3B82F6'}}></span>100%</div>
      </div>
    )
  }

  return (
    <Layout
      isWide={false}
      headerData={headerData}
    >
      <div className="flex flex-col border rounded py-4 mt-4 px-2 flex-grow">
        {!intensivityLoaded ? 
          <div className="flex items-center justify-center w-full h-60">
            <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
          </div>
        :
        <div className='flex flex-col h-60'>
          <div className="flex justify-between text-xs mb-4 items-center px-2">
            { (intensivityEndDate.year === today.year) ? 
              <>
              <button className="bg-gray-200 rounded px-2 py-1 flex items-center hover:bg-gray-300 w-16" onClick={() => increaseYear()}>
                <ChevronLeftIcon className="w-3 h-3"/>
                {intensivityEndDate.year - 1}
              </button> 
              <span className="font-medium">{intensivityEndDate.year}</span>
              <div className="w-16"></div>
              </>
              :
              <>
              <button className="bg-gray-200 rounded px-2 py-1 flex items-center hover:bg-gray-300 w-16" onClick={() => increaseYear()}>
                <ChevronLeftIcon className="w-3 h-3"/>  
                {intensivityStartDate.year}
              </button>
              <span className="font-medium">{intensivityEndDate.year}</span>
              <button className="bg-gray-200 rounded px-2 py-1 flex items-center hover:bg-gray-300 w-16" onClick={() => decreaseYear()}>
                {intensivityEndDate.year + 1}
                <ChevronRightIcon className="w-3 h-3" />
              </button>
              </>
            }
          </div>
          <div className='flex flex-row items-start'>
            <div className='flex flex-col h-full w-8 pt-5 pl-2 bg-white flex-shrink-0'>
              {[Info.weekdays('short')[6], Info.weekdays('short').splice(0, 6)].flat().map((el, idx) => {
                return <span key={idx} className='h-4 flex items-center capitalize' style={{fontSize: 9, marginBottom: 7}}>{el}</span>
              })}
            </div>
            <div className='flex flex-col items-end justify-end overflow-hidden'>
              <HeatMap 
                startDate={intensivityStartDate.toJSDate()}
                endDate={intensivityEndDate.toJSDate()}
                className={"h-44 w-full overflow-hidden"}
                style={{width: (365 / 7 * 24) - 24, fontSize: 9}}
                value={intensivity}
                rectSize={18}
                radius={4}
                space={5}
                legendCellSize={0}
                rectRender={(props, data) => {
                  if (selectedDate.length > 0 && selectedDate[1]) {
                    if (convertHeatMapDate(data.date).startOf('day') >= selectedDate[0].startOf('day') && convertHeatMapDate(data.date).endOf('day') <= selectedDate[1]) {
                      props.fill = "#3728BD"
                    }
                  }
                  return ( 
                    <Tooltip key={props.key} placement="top" content={`${t('date')}: ${getPerfectDate(data.date)}`}>
                    <rect {...props}
                      onClick={() => setSelectedDate([convertHeatMapDate(data.date).startOf('day'), convertHeatMapDate(data.date).endOf('day')])}
                    ></rect>
                    </Tooltip>

                  );
                }}
                panelColors={setColors()}
              />
            </div>
          </div>
          {legendText()}
        </div>
        }
      </div>
      {loading ? <Spinner/> : (
        <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('data')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('constants')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('settings')}
            </Tab>
          </TabList>
          <TabPanel>
            <DeviceData 
              currentItem={currentItem}
              sensorTypes={sensorTypes}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </TabPanel>
          <TabPanel>
            <DeviceConstants 
              sensorTypes={sensorTypes}
              tableData={currentItem.attributes.slots}
              reloadTable={reloadTable}
            />
          </TabPanel>
          <TabPanel>
            <DeviceSettings 
              currentItem={currentItem}
              onUpdate={onUpdate}
            />
          </TabPanel>
        </Tabs>
      )}
    </Layout>
  )
};

export default DevicePage;
