import { Fragment, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { CurrentUserContext } from "../../../../../contexts/current-user";
import { YMaps, Map, Placemark, Polyline, Clusterer } from "react-yandex-maps";
import { getPerfectDateTime } from '../../../../../utils';

const EquipmentOnMap = ({history, type, position}) => {
  const {t} = useTranslation();
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <YMaps 
      query={{ lang: currentUserState?.currentUser?.attributes?.locale || 'ru_RU' }}
    >
      <div className='w-full h-[480px] flex rounded mt-4 overflow-hidden'>
        <Map width='100%' height={480} defaultState={{ center: position.coordinates, zoom: 12, controls: ['zoomControl', 'fullscreenControl'] }} modules={['control.ZoomControl', 'control.FullscreenControl']}>
          {type === 'current' && 
            <Placemark 
              properties={{
                iconContent: `<div class="flex flex-col"><div>${getPerfectDateTime(position.date)} ${t(position.status)}</div></div>`
              }} 
              geometry={position.coordinates} 
              options={{
                preset: "islands#blueStretchyIcon",
                // Disabling the close balloon button.
                balloonCloseButton: false,
                // The balloon will open and close when the placemark icon is clicked.
                hideIconOnBalloonOpen: false
              }}
            />
          }
          {type === 'history' && 
            <Clusterer options={{
              preset: 'islands#invertedVioletClusterIcons',
              groupByCoordinates: false
            }}>
              {history.map((place, index) => 
                <Placemark 
                  key={index} 
                  properties={{
                    iconContent: `<div class="flex flex-col"><div>${getPerfectDateTime(place.date)} ${t(place.status)}</div></div>`
                  }} 
                  geometry={place.coordinates} 
                  options={{
                    preset: "islands#blueStretchyIcon",
                    // Disabling the close balloon button.
                    balloonCloseButton: false,
                    // The balloon will open and close when the placemark icon is clicked.
                    hideIconOnBalloonOpen: false
                  }}
                />)
              }
            </Clusterer>
          }
        </Map>
      </div>
    </YMaps>
  )
}

export default EquipmentOnMap;