import React, { useState, useEffect, forwardRef, useContext } from 'react';
import {DateTime} from "luxon";
import {CurrentUserContext} from "../../../contexts/current-user";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {ChevronLeftIcon, ChevronRightIcon, CalendarIcon} from '@heroicons/react/outline';
import {convertTimezone, getPerfectDate} from "../../../utils";
import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';
import { enGB, ru } from 'date-fns/locale';

registerLocale("ru", ru)
registerLocale("en", enGB)


const WidgetItem = ({ item, current }) => {
  const {t} = useTranslation(['widgets']);
  const date = DateTime.fromSeconds(item.at);

  const data = [
    {id: 'worked', label: t('total_worked'), value: item.worked, color: 'rgba(16, 185, 129)'},
    {id: 'stopped', label: t('total_stopped'), value: item.stopped, color: 'rgba(252, 211, 77)'},
    {id: 'turned_off', label: t('total_turned_off'), value: item.turned_off, color: 'rgba(220, 38, 38)'}
  ]

  return (
    <div className={current ? 'flex items-center justify-center w-7 h-7 relative bg-gray-700 rounded-full' : 'flex items-center justify-center w-7 h-7 relative'}>
      <ResponsivePie
        data={data}
        innerRadius={0.8}
        fit={false}
        enableArcLabels={false}
        colors={{ datum: 'data.color' }}
        enableArcLinkLabels={false}
        motionConfig="slow"
        transitionMode="centerRadius"
        isInteractive={false}
        // tooltip={(datum) => (<Tooltip data={datum} />)}
      />
      <div className={current ? "absolute text-xs font-medium text-white" : "absolute text-xs font-medium text-gray-800"}>{date.day}</div>
    </div>
  )
}

const IntensiveCalendar = ({selectedDate, setSelectedDate, calendarData, loadMonthData}) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);
  
  const [previousVisibleMonth, setPreviousVisibleMonth] = useState(selectedDate.month - 1);
  const [currentVisibleMonth, setCurrentVisibleMonth] = useState(selectedDate.month);
  const [nextVisibleMonth, setNextVisibleMonth] = useState(selectedDate.month + 1);
  const [currentVisibleYear, setCurrentVisibleYear] = useState(selectedDate.year);

  setDefaultLocale(currentUserState.currentUser.attributes.locale)

  useEffect(() => {
    loadMonthData(selectedDate.month, selectedDate.year);
    setCurrentVisibleMonth(selectedDate.month);
    setCurrentVisibleYear(selectedDate.year);
  }, [selectedDate]);

  useEffect(() => {
    if (currentVisibleMonth === 1) {
      setPreviousVisibleMonth(12);
      setNextVisibleMonth(2);
    } else {
      if (currentVisibleMonth === 12) {
        setPreviousVisibleMonth(11);
        setNextVisibleMonth(1);
      } else {
        setPreviousVisibleMonth(currentVisibleMonth - 1);
        setNextVisibleMonth(currentVisibleMonth + 1);
      }
    }
    loadMonthData(currentVisibleMonth, currentVisibleYear);
  }, [currentVisibleMonth]);

  const monthDecrease = () => {
    if (currentVisibleMonth === 1) {
      setCurrentVisibleMonth(12);
      setCurrentVisibleYear(currentVisibleYear - 1);
    } else {
      setCurrentVisibleMonth(currentVisibleMonth - 1);
    }
  };

  const monthIncrease = () => {
    if (currentVisibleMonth === 12) {
      setCurrentVisibleMonth(1);
      setCurrentVisibleYear(currentVisibleYear + 1);
    } else {
      setCurrentVisibleMonth(currentVisibleMonth + 1);
    }
  };

  const CalendarButton = forwardRef(({ value, onClick }, ref) => (
    <button className="h-8 bg-white ring ring-gray-200 focus:ring-blue-300 rounded-sm text-gray-800 text-sm w-full flex items-center px-2" onClick={onClick} ref={ref}>
      <CalendarIcon className="w-4 h-4 mr-2"/>
      <span className="capitalize">{DateTime.fromFormat(value, "MM/dd/yyyy").weekdayLong}, {DateTime.fromFormat(value, "MM/dd/yyyy").toFormat("dd.MM.yyyy")}</span>
    </button>
  ));

  const renderDayContents = (day, date) => {
    let isCurrent = false;
    if (date.getFullYear() === selectedDate.yaer) {
      if (date.getMonth() === selectedDate.month) {
        if (date.getDate() === selectedDate.day) {
          isCurrent = true;
        }
      }
    }

    const ts = date.getTime()

    const item = calendarData.find(it => it.at === (ts - convertTimezone(ts)) / 1000)

    if(item === undefined) {
      return <span className="text-xs text-gray-400">{date.getDate()}</span>;
    }
    return <WidgetItem item={item} current={isCurrent}/>;
  };

  const renderCustomHeader = ({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }) => {

    const handelDecrease = () => {
      monthDecrease()
      decreaseMonth()
    }

    const handelIncrease = () => {
      monthIncrease()
      increaseMonth()
    }

    return (
      <div className="flex justify-between font-medium text-gray-800 mb-4 text-sm items-center">
        <div className="flex items-center space-x-2 text-xs capitalize">
          <div
              className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center"
              onClick={handelDecrease}
            >
            <ChevronLeftIcon className="w-4 h-4"/>
          </div>
        </div>
        <div className="capitalize text-xs">
            {monthDate.toLocaleString(t("locale"), {
              month: "long",
              year: "numeric",
            })}
        </div>
        <div className="flex items-center space-x-2 text-xs capitalize">
          <div
            className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center"
            onClick={handelIncrease}
          >
            <ChevronRightIcon className="w-4 h-4"/>
          </div>
        </div>
      </div>
    )
  }


  const onDateChoice = (date) => {
    const selected = date.getTime() - convertTimezone(date.getTime())
    setSelectedDate(DateTime.fromMillis(selected))
  };

  return (
    <DatePicker 
      calendarClassName="intensive"
      selected={new Date(selectedDate.toMillis() + convertTimezone(selectedDate.toMillis()))}
      onChange={(date) => onDateChoice(date)}
      renderCustomHeader={(data) => renderCustomHeader(data)}
      renderDayContents={renderDayContents}
      customInput={<CalendarButton />}
      popperPlacement="bottom-end"
      maxDate={DateTime.local().toJSDate}
      calendarStartDay={1}
    />
  )


}
export default IntensiveCalendar;