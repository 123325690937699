import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorsBlock = ({errors}) => {
  const {t} = useTranslation(); 

  return (
    <ul className="w-full h-full flex flex-col text-xs font-medium mt-4 space-y-2">
      {errors.map((error, index) => {
        return (
          <li key={index} className="text-red-700 w-full">{t('error')}: {t(error.title) || t(error.detail)}</li>
        )
      })}
    </ul>
  )
};

export default ErrorsBlock;
