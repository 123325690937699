import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SettingsMenu = () => {
  const { t } = useTranslation();

  return (
    <ul className="py-4 px-4 md:space-y-2 w-72">
      <li className="flex justify-between items-center w-full  text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings" exact className="w-full h-full px-2 py-2 rounded">
          {t('company_settings')}
        </NavLink>
      </li>
      <li className="flex justify-between items-center w-full  text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/divisions"  className="w-full h-full px-2 py-2 rounded">
          {t('divisions')}
        </NavLink>
      </li>
      <li className="flex justify-between items-center w-full  text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/shifts" exact className="w-full h-full px-2 py-2 rounded">
          {t('shifts')}
        </NavLink>
      </li>
      <li className="flex justify-between items-center w-full text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/users" exact className="w-full h-full px-2 py-2 rounded">
          {t('participants')}
        </NavLink>
      </li>
      <li className="flex justify-between items-center w-full  text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/screens"  className="w-full h-full px-2 py-2 rounded">
          {t('screens')}
        </NavLink>
      </li>
      <li className="flex justify-between items-center w-full text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/apps" exact className="w-full h-full px-2 py-2 rounded">
          {t('apps')}
        </NavLink>
      </li>
      {/* <li className="flex justify-between items-center w-full text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/notifications" exact className="w-full h-full px-2 py-2 rounded">
          Oповещения
        </NavLink>
      </li> */}
      <li className="flex justify-between items-center w-full text-sm font-medium text-left rounded text-gray-600 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75 cursor-pointer">
        <NavLink to="/settings/logs" exact className="w-full h-full px-2 py-2 rounded">
          Журнал
        </NavLink>
      </li>
    </ul>
  )
};

export default SettingsMenu;