import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';
import {DateTime} from 'luxon';
import { sortDataList } from '../../../utils';
import { covertTimeToSeconds } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import {CurrentUserContext} from "../../../contexts/current-user";
import Filters from '../../../components/filters';
import ReportItem from './item';


const ReportEquipmentPage = (props) => {
  const {t} = useTranslation();
  
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(false);
  const [equipment, setEquipment] = useState([]);

  const [divisionList, setDivisionList] = useState([]);
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [params, setParams] = useState(new URLSearchParams({'with_sensors': true}))

  const [selectedDate, setSelectedDate] = useState([DateTime.local(), DateTime.local()]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(DateTime.local().toMillis()));
  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59", duration: 86400});
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400})

  const [{response, isLoading, error}, doFetchEquipment] = useFetch(`/directories/equipment_items?${params.toString()}`);
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsDataError}, doFetchDivisions] = useFetch('/directories/divisions');
  const [{response: equipmentTypesResponse, isLoading: equipmentTypesIsLoading, error: equipmentTypesDataError}, doFetchEquipmentTypes] = useFetch('/directories/equipment_types');


  useEffect(() => {
    setLoading(true)
    doFetchEquipment();
    doFetchDivisions();
    doFetchEquipmentTypes();

    
    setSelectedDate([DateTime.local().startOf('month'), DateTime.local().endOf('month')]);

    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]

    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat('HH:mm:ss'), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat('HH:mm:ss')})
      })
    }
    setShiftList(shifts);
  },[])

  useEffect(() => {
    if(isLoading) {
      setEquipment([])
    }
  }, [isLoading])

  useEffect(() => {
    if(!response) {
      return
    }

    setEquipment(response.data)
    setLoading(false)
  },[response])

  useEffect(() => {
    if(!divisionsResponse) {
      return
    }
    // const options = [{label: t('all_divisions'), value: null}]
    const options = []
    sortDataList(divisionsResponse.data).map(it => {
      options.push({label: it.attributes.name, value: it.id})
    })
    setDivisionList(options)
  },[divisionsResponse]);

  useEffect(() => {
    if(!equipmentTypesResponse) {
      return
    }
    // const options = [{label: t('all_equipment_types'), value: null}]
    const options = []
    sortDataList(equipmentTypesResponse.data).map(it => {
      options.push({label: it.attributes.name, value: it.id})
    })
    setEquipmentTypeList(options)
  },[equipmentTypesResponse])


  const items = equipment.map((item, index) => {      
    return (
      <ReportItem key={item.id} equipment={item} selectedDate={selectedDate} convertedShift={convertedShift}/> 
    )
  })

  const onMonthChoice = (date) => {
    if(date === null) {
      return
    }
    const ts = new Date(date).getTime();

    setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.fromMillis(ts).endOf('month')]);

    setSelectedMonth(date)
  }

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  const headerData = {
    title: 'equipment_load_report',
    isWide: true
  };

  const filtersData = {
    calendar: {
      type: 'month',
      selectedDate: selectedMonth,
      onChange: onMonthChoice
    },
    shifts: {
      options: shiftList,
      value: currentShift,
      onChange: handleChangeShift
    },
    filtersList: [
      {
        name: 'equipment_type',
        key: 'equipment_type_ids[]',
        type: 'multiselect',
        option_search: true,
        options: equipmentTypeList
      },
      {
        name: 'division',
        key: 'division_ids[]',
        type: 'multiselect',
        option_search: true,
        options: divisionList
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})
    newParams.append('with_sensors', true)
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchEquipment();

  }, [selectedFilters])


  return (
    <Layout
      headerData={headerData}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      {loading ? <Spinner/> :
        <div className="flex flex-col space-y-6 pb-10 mt-8">
          {equipment.length > 0 ? items : ""}
        </div>
      }
      
    </Layout>
  );
};

export default ReportEquipmentPage;