import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field, CheckboxField } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Checkbox from '@atlaskit/checkbox';
import { DatePicker } from '@atlaskit/datetime-picker';
import Select, {AsyncSelect} from '@atlaskit/select';
import { useTranslation } from "react-i18next";

import useFetch from '../../../../../hooks/use-fetch';

import ErrorsBlock from '../../../../../components/errors';

const ChangesForm = ({currentItem, closeModal, isOpen, statuses, reloadData}) => {
  const {t} = useTranslation();
  const [at, setAt] = useState(new Date().toISOString().slice(0, 10));
  const [status, setStatus] = useState(currentItem.attributes.status || "");
  const [leader, setLeader] = useState(false);
  // const [orderNumber, setOrderNumber] = useState(currentItem.attributes.order_number)
  // const [order, setOrder] = useState(currentItem.attributes.image);
  // const [orderId, setOrderId] = useState('');
  const [divisionId, setDivisionId] = useState('');
  const [division, setDivision] = useState('');
  const [post, setPost] = useState('');
  const [postId, setPostId] = useState('');
  const [searchPostsQuery, setSearchPostsQuery] = useState('');
  const [searchDivisionsQuery, setSearchDivisionsQuery] = useState('');
  const [postsOptions, setPostsOptions] = useState([]);
  const [divisionsOptions, setDivisionsOptions] = useState([]);

  const [errors, setErrors] = useState([]);

  const [{response: saveResponse, isLoading: saveIsLoading, error: saveError}, doFetchItemSave] = useFetch(`/directories/personnels/${currentItem.id}/changes`);
  const [{response: fileUploadResponse, isLoading: fileUploadIsLoading, error: fileUploadError}, doFetchFileUpload] = useFetch(`/uploads`, true);

  const [{response: postsResponse, isLoading: postsIsLoading, error: postsError}, doFetchPosts] = useFetch(`/directories/posts?search=${searchPostsQuery}`);
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError}, doFetchDivisions] = useFetch(`/directories/divisions?search=${searchDivisionsQuery}`);


  useEffect(() => {
    if(currentItem.attributes.post_id === null) {
      return;
    }
    setPostId(currentItem.attributes.post_id);
    setPost({label: currentItem.attributes.post_name, value: currentItem.attributes.post_id})
  },[]);

  useEffect(() => {
    if(currentItem.attributes.leader === null) {
      return;
    }
    setLeader(currentItem.attributes.leader)
  },[]);

  useEffect(() => {
    if(currentItem.attributes.division_id === null) {
      return;
    }
    setDivisionId(currentItem.attributes.division_id);
    setDivision({label: currentItem.attributes.division_name, value: currentItem.attributes.division_id});
  },[]);


  useEffect(() => {
    if(searchPostsQuery === "") {
      return;
    } else {}
    doFetchPosts();
  },[searchPostsQuery])

  useEffect(() => {
    if(searchDivisionsQuery === "") {
      return;
    } else {}
    doFetchDivisions();
  },[searchDivisionsQuery])

  useEffect(() => {
    if(!postsResponse) {
      return;
    }
    const list = []
    postsResponse.data.map(it => {
      list.push({label: `${it.attributes.name}`, value: it.id})
    })
    setPostsOptions(list)
  },[postsResponse]);

  useEffect(() => {
    if(!divisionsResponse) {
      return;
    }
    const list = []
    divisionsResponse.data.map(it => {
      list.push({label: it.attributes.name, value: it.id})
    })
    setDivisionsOptions(list)
  },[divisionsResponse]);

  const searchPosts = (inputValue) => {
    if(inputValue.length < 1) {
      return;
    }
    setSearchPostsQuery(inputValue);
    return postsOptions;
  };

  const loadPostsOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchPosts(inputValue));
      }, 1000);
  });

  const searchDivision = (inputValue) => {
    if(inputValue.length < 1) {
      return;
    }
    setSearchDivisionsQuery(inputValue);
    return divisionsOptions;
  };

  const loadDivisionsOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchDivision(inputValue));
      }, 1000);
  });

  useEffect(() => {
    closeModal();
    reloadData();
  },[saveResponse])

  useEffect(() => {
    if(!saveError) {
      return;
    }
    setErrors(saveError.errors)
  }, [saveError])

  const handleUpdate = (data) => {
    doFetchItemSave({
      method: 'POST',
      data: {
        data: {
          status: data.status.value || "",
          post_id: Number(data.post.value) || null,
          at: data.at || new Date(),
          division_id: Number(data.division.value) || null,
          leader: leader
        }
      }
    });
  };


  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('adding_staff_change')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('staff_chnages_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => handleUpdate(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <div className="text-sm">
                      <Field name="status" label={t('status')} defaultValue={statuses.find(it => it.value == status) || ""}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <Select {...fieldProps} options={statuses} placeholder={t('select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <Field name="post" label={t('post')} defaultValue={post ? post : ""}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <AsyncSelect {...fieldProps} loadOptions={loadPostsOptions} cacheOptions defaultOptions placeholder={t('enter_and_select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <Field name="division" label={t('division')} defaultValue={division ? division : ""}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <AsyncSelect {...fieldProps} loadOptions={loadDivisionsOptions} cacheOptions defaultOptions placeholder={t('enter_and_select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <div className="my-4">
                        <CheckboxField name="leader" >
                          {({ fieldProps, error }) => (
                            <Checkbox {...fieldProps} 
                              label={t('leader')}
                              isChecked={leader}
                              onChange={() => setLeader(!leader)}
                            />
                          )}
                        </CheckboxField>
                      </div>
                      <Field name="at" label={t('at')} defaultValue={at || ""} isRequired>
                        {({ fieldProps, error, meta: { valid } }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={at}/>}
                      </Field>
                      {/* <Field name="order_number" label={t('order_number')} defaultValue={orderNumber ? orderNumber : ""}>
                        {({ fieldProps, error, meta: { valid } }) => <TextField {...fieldProps} />}
                      </Field> */}
                      {/* <div className="field mt-4">
                        <div className="relative">
                          <div className="flex flex-row justify-between items-center h-10">
                            {order.data ? 
                              <a className="text-sm text-blue-600 whitespace-nowrap truncate font-medium flex z-10" href={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${order.data.id}`} target="_blank">{order.data.attributes.filename}</a> : 
                              <div className="text-sm text-gray-600 whitespace-nowrap truncate font-medium">{t('upload_order')}</div>
                            }
                            <div className="bg-gray-100 rounded text-sm text-gray-800 px-4 py-2">
                              <span className="font-medium">{t('upload')}</span>
                            </div>
                          </div>
                          <input
                            name="attachment"
                            type="file"
                            className="absolute cursor-pointer opacity-0 block w-full h-10 top-0"
                            onChange={(evt) => handleFileField(evt.target.files[0])}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
export default ChangesForm;