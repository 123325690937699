import { useEffect, useState } from "react"
import _ from "lodash"
import useFetch from "../../../hooks/use-fetch";
import Select from '@atlaskit/select';
import { useTranslation } from "react-i18next";
import {Interval, DateTime} from 'luxon';
import useAsyncFetch from "../../../hooks/useAsyncFetch";
import { convertTimeHHMM, covertTimeToSeconds, objectMap } from "../../../utils";
import IdelTimelineWidget from "./timeline";
import Spinner from "../../../components/loader";

export default function EquipmentIdleHeatMap({selectedDate, convertedShift}) {
  const {t} = useTranslation();
  const tz = DateTime.local().toFormat('ZZ');
  const [dates, setDates] = useState();
  const [currentResource, setCurrentResource] = useState(null)
  const [equipmentList, setEquipmentList] = useState([])
  const [params, setParams] = useState(new URLSearchParams({'with_sensors': true}))
  const [usageParams, setUsageParams] = useState(new URLSearchParams({}))
  const [timeMarkers, setTimeMarkers] = useState([0, 21600, 43200, 64800, 86400])
  const [data, setData] = useState([]);

  const [{response, isLoading, error}, doFetchEquipment] = useFetch(`/directories/equipment_items?${params.toString()}`);
  const [{response: dataResponse, isLoading: dataIsLoading}, doFetchData] = useFetch(`/directories/ng_equipment_items_data/timeline?${usageParams.toString()}`)

  useEffect(() => {
    doFetchEquipment()
  }, [])

  useEffect(() => {
    setData(null)

    const shiftStart = covertTimeToSeconds(convertedShift.start);
    const shiftEnded = covertTimeToSeconds(convertedShift.end);

    setTimeMarkers([
      shiftStart, 
      shiftStart + (convertedShift.duration / 4) > 86400 ? (shiftStart + (convertedShift.duration / 4)) - 86400 : shiftStart + (convertedShift.duration / 4), 
      shiftStart + (convertedShift.duration / 2) > 86400 ? (shiftStart + (convertedShift.duration / 2)) - 86400 : shiftStart + (convertedShift.duration / 2), 
      shiftStart + (convertedShift.duration / 4) * 3 > 86400 ? (shiftStart + (convertedShift.duration / 4) * 3) - 86400 : shiftStart + (convertedShift.duration / 4) * 3, 
      shiftEnded
    ])

    setDates(Interval.fromDateTimes(selectedDate[0], selectedDate[1]).splitBy({ day: 1 }))
  }, [selectedDate, convertedShift])

  useEffect(() => {
    if(!response?.data) {
      return
    }
    setEquipmentList(response.data)
  }, [response?.data])

  useEffect(() => {
    setData(null)
  }, [currentResource?.id])


  function generate() {
    if(!currentResource) {return}

    const start = selectedDate[0].startOf('day').toFormat("yyyy-MM-dd")
    const end = selectedDate[1].endOf('day').toFormat("yyyy-MM-dd")

    let startDateString
    let endDateString

    if(covertTimeToSeconds(convertedShift.started_at) > covertTimeToSeconds(convertedShift.ended_at)) {
      startDateString = `${start}T${convertedShift.start}.000${tz}`

      let nextEndDayString = selectedDate.endOf('month').plus({day: 1}).toFormat("yyyy-MM-dd")
      endDateString = `${nextEndDayString}T${convertedShift.end}.000${tz}`
    } else {
      startDateString  = `${start}T${convertedShift.start}.000${tz}`
      endDateString = `${end}T${convertedShift.end}.000${tz}`
    }

    let newUsageParams = new URLSearchParams({})
    newUsageParams.append('from', startDateString)
    newUsageParams.append('to', endDateString)
    newUsageParams.append('day_time_from', convertedShift.start)
    newUsageParams.append('day_time_to', convertedShift.end)
    newUsageParams.append('equipment_item_ids[]', currentResource.id)

    setUsageParams(newUsageParams.toString())
    doFetchData();
  }

  useEffect(() => {
    if(!dataResponse?.data || !dataResponse.data?.length === 0) {return}
    let dataByDays = []
    objectMap(_.groupBy(dataResponse.data, "at"), (v, k) => dataByDays.push({
      date: DateTime.fromSQL(k, { setZone: false }), 
      data: (v.map(i => ({at: DateTime.fromSQL(i.start, { setZone: false }).ts / 1000, state: i.state, seconds: i.duration})))
    }))
    setData(dataByDays)
  }, [dataResponse])

  useEffect(() => {
    if(dataIsLoading) {return}
  }, [dataResponse])

  return (
    <>
      <div className="flex justify-between">
        <div className="w-96">
          <Select 
            getOptionLabel={(o) => o ? `${o.attributes.equipment_type_name} ${o.attributes.producer_name} ${o.attributes.equipment_model_name}` : "Оборудование не выбрано"}
            getOptionValue={(o) => o ? o.id : null}
            options={equipmentList} 
            onChange={setCurrentResource}
            value={currentResource} 
            placeholder={t('select_equipment_placeholder')} 
            className="text-sm"
          />
        </div>
      </div>
      <div className="py-10 w-full h-full min-h-96">
        {dataIsLoading ? <Spinner /> : data ?
        <>
          <div className="pl-10 grid grid-cols-4 text-xs text-gray-500 pb-2">
            <div className="flex">{convertTimeHHMM(timeMarkers[0])}</div>
            <div className="flex">{convertTimeHHMM(timeMarkers[1])}</div>
            <div>{convertTimeHHMM(timeMarkers[2])}</div>
            <div className="flex justify-between"><span>{convertTimeHHMM(timeMarkers[3])}</span></div>
          </div>
          {data.map((day, i) => {
            return (
              <div key={i} className="flex w-full h-full border-b items-center">
                <span className="w-10 text-xs text-gray-500">{day.date.day}</span>
                <IdelTimelineWidget key={i} data={day.data} duration={convertedShift.duration}/>
              </div>
            )
          })}
        </>
        :
        <div className="flex flex-col w-full h-full items-center justify-center">
          <span className="text-gray-600 py-8">Выберите оборудование и сформируйте аналитический отчет</span>
          <button className="bg-blue-600 text-white py-2 px-8 rounded-sm font-medium text-sm" onClick={() => generate()}>Сформировать</button>
        </div>
        }
      </div>
    </>
  )
}