import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import useFetch from '../../hooks/use-fetch';
import useLocalStorage from '../../hooks/use-local-storage';

import TextField from "@atlaskit/textfield";
import Form, {ErrorMessage, Field} from "@atlaskit/form";
import ErrorsBlock from '../../components/errors';


const Signin = (props) => {
  const {t} = useTranslation();
  const [, setToken] = useLocalStorage('weld-jwt');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [errors, setErrors] = useState([]);
  const [{response, isLoading, error: loginError}, doFetchLogin] = useFetch('/users/sessions', true);

  useEffect(() => {
    if (!response) {
      return;
    }
    setToken(JSON.stringify(response));
    setRedirectToReferrer(true)
  }, [response]);

  useEffect(() => {
    if (loginError) {
      setErrors(loginError.errors)
    }
  }, [loginError]);


  const clickSubmit = (data) => {
    doFetchLogin({
      method: 'POST',
      data: data
    });
  };

  const redirectUser = () => {
    if (redirectToReferrer) {
      return <Redirect to="/"/>
    }
  };

  return (
    <section className="mt-6">
      {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
      <Form onSubmit={data => clickSubmit(data)}>
        {({ formProps }) => (
          <form {...formProps}>
            <Field name="email" defaultValue="" label={t('email')} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field name="password" defaultValue="" label={t('password')} isRequired>
              {({ fieldProps }) => <TextField type="password" {...fieldProps} />}
            </Field>
            <div className="flex justify-between mt-8">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t('login')}
              </button>
            </div>
          </form>
        )}
      </Form>

      {redirectUser()}
    </section>
  );
};

export default Signin;
