import React, {useContext, useState, useRef, useEffect} from 'react';
import {CurrentUserContext} from "../../../contexts/current-user";
import { useTranslation } from 'react-i18next';
import { sortDataList } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from "../../../components/loader";

import useFetch from "../../../hooks/use-fetch";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../hooks-store/store";

import Pagination from "../../../components/form/pagination";

import EquipmentAccountingTable from "./table";
import AddEquipment from './add';
import { equipmentStatuses } from '../../../enums';
import Filters from '../../../components/filters';
import EditEquipmentModal from './editModal';

const ITEMS_PER_PAGE = 40;


const EquipmentAccounting = (props) => {
  const {t} = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);

  // DATA
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemId, setItemId] = useState('new');
  const [producersList, setProducersList] = useState([]);
  const [modelsList, setModelsList] = useState('');

  // FILTERS 
  const [search, setSearch] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterOptionsProducers, setFilterOptionsProducers] = useState([]);
  const [filterOptionsTypes, setFilterOptionsTypes] = useState([]);
  const [filterOptionsDivisions, setFilterOptionsDivisions] = useState([]);
  const filterOptionsStatuses = equipmentStatuses.map(status => ({label: t(status), value: status}))
  const filterOtpionsWithSensors = [{label: t('sensors_connected'), value: true}, {label: t('sensors_disconnected'), value: false}]

  // PAGING
  const [showPaging, setShowPaging] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(40);
  const [paginatedTableData, setPaginatedTableData] = useState([]);

  const [params, setParams] = useState(new URLSearchParams({}))

  const [pagingParams, setPagingParams] = useState(new URLSearchParams({
    'page': currentPage,
    'per': itemsPerPage,
    'sort': 'inventory_number',
    'direction': 'asc'
  }))

  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  let [currentItemId, setCurrentItemId] = useState(null)
  let [isEditOpen, setIsEditOpen] = useState(false)

  const [{response: producersResponse, isLoading: producersIsLoading, error: producersError}, doFetchProducers] = useFetch('/directories/producers');
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError}, doFetchDivisions] = useFetch('/directories/divisions');
  const [{response: equipmentTypesResponse, isLoading: equipmentTypesIsLoading, error: equipmentTypesError}, doFetchEquipmentTypes] = useFetch('/directories/equipment_types');
  
  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch((`/directories/equipment_items?${params.toString()}&${pagingParams.toString()}`).replace(/,/g, ""));

  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/equipment_items/${itemId}`);

  useEffect(() => {
    doFetchProducers();
    doFetchDivisions();
    doFetchEquipmentTypes();
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (!producersResponse || !divisionsResponse || !equipmentTypesResponse) {
      return;
    }
    const newProducersList = [];    
    producersResponse.data.forEach(item => {
      newProducersList.push({label: item.attributes.name, value: item.id})
    });

    const newFilterListProducers = 
    sortDataList(producersResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    const newFilterListTypes = 
    sortDataList(equipmentTypesResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    const newFilterListDivisions = 
    sortDataList(divisionsResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    setProducersList(newProducersList);

    setFilterOptionsProducers(newFilterListProducers);
    setFilterOptionsTypes(newFilterListTypes);
    setFilterOptionsDivisions(newFilterListDivisions);
  }, [producersResponse, divisionsResponse, equipmentTypesResponse]);

  const headerData = {
    title: 'equipment',
    buttonsList: [
      {
        text: 'create_equipment',
        action: () => onAddClick()
      }
    ],
  };

  const filtersData = {
    search: {
      placeholder: t('equipment_search'),
      value: search,
      onChange: setSearch
    },
    filtersList: [
      {
        name: 'producer',
        key: 'producer_ids[]',
        placeholder: 'Поиск',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsProducers
      },
      {
        name: 'equipment_type',
        key: 'equipment_type_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsTypes
      },
      {
        name: 'division',
        key: 'division_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsDivisions
      },
      {
        name: 'status',
        key: 'statuses[]',
        type: 'multiselect',
        option_search: false,
        options: filterOptionsStatuses
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  if(currentUserState.applications.devices) {
    filtersData.filtersList.push(
      {
        name: 'with_sensors',
        key: 'with_sensors',
        type: 'select',
        option_search: false,
        options: filterOtpionsWithSensors
      }
    )
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    search ? newParams.append('search', search) : newParams.delete('search')
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [search, selectedFilters])
  
  // Paging
  
  useEffect(() => {
    if (!meta) {
      return;
    }
    setTotalItems(meta.total_pages * itemsPerPage);
    if (meta.total_pages > 1) {
      setShowPaging(true);
    } else {
      setShowPaging(false);
    }
  }, [meta]);

  const listPage = (page) => {
    setCurrentPage(page);
    let params = new URLSearchParams({
      'page': page,
      'per': itemsPerPage,
      'sort': 'inventory_number',
      'direction': 'asc'
    })
    setPagingParams(params);
    setLoading(true)
    doFetchTableData();
  };

  useEffect(() => {
    if (tableData.length <= ITEMS_PER_PAGE) {
      setPaginatedTableData(tableData);
      return;
    }
    const offset = currentPage === 0 ? 0 : currentPage * ITEMS_PER_PAGE;
    const fullTableData = tableData.slice();
    if (currentPage === Math.ceil(tableData.length / ITEMS_PER_PAGE) - 1) {
      setPaginatedTableData(fullTableData.slice(offset));
    } else {
      setPaginatedTableData(fullTableData.slice(offset, offset + ITEMS_PER_PAGE));
    }
  }, [tableData, currentPage]);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeEditModal() {
    setIsEditOpen(false)
    setCurrentItemId(null)
    window.history.replaceState(null, "New Page Title", `/equipment`)
  }

  function openEditModal() {
    setIsEditOpen(true)
  }

  useEffect(() => {
    if (deleteIsLoading) {
      return;
    }
    reloadTable();
  }, [deleteIsLoading]);

  const onAddClick = () => {
    setItemId('new');
    openModal()
  };

  const onItemEdit = (id) => {
    setCurrentItemId(id)
    openEditModal()
    window.history.replaceState(null, "New Page Title", `/equipment/${id}`)
    // history.replace({
    //   pathname: `/equipment/${id}`,
    // });
  };

  const onItemReport = (id) => {
    history.push({
      pathname: `/report/equipment/${id}`,
    });
  };

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  const reloadTable = () => {
    doFetchTableData();
  };

  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      <div className="content pb-10">
        {loading ? <Spinner/> : (
          <>
            <EquipmentAccountingTable
              tableData={tableData}
              onItemEdit={onItemEdit}
              onItemReport={onItemReport}
              onItemDelete={onItemDelete}
              isLoading={isLoading}
              error={error}
            />
            {showPaging && (
              <Pagination layout="prev, pager, next" total={totalItems} pageSize={itemsPerPage} currentPage={currentPage} onCurrentChange={(currentPage) => listPage(currentPage)}/>
            )}
          </>
        )}
      </div>
      <AddEquipment closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} producersList={producersList} modelsList={modelsList}/>
      {isEditOpen && <EditEquipmentModal closeModal={closeEditModal} isOpen={isEditOpen} currentItemId={currentItemId}/>}
    </Layout>
  );
};

export default EquipmentAccounting;
