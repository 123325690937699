import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { enGB, ru, de } from 'date-fns/locale';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import {CalendarIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import {CurrentUserContext} from "../../../contexts/current-user";

registerLocale('ru', ru);
registerLocale('en', enGB);
registerLocale('de', de);

const MonthDatePicker = ({selectedDate, onChange}) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);

  setDefaultLocale(currentUserState.currentUser.attributes.locale)

  const CalendarButton = forwardRef(({ value, onClick }, ref) => (
    <button className="h-8 bg-white ring ring-gray-200 focus:ring-blue-300 rounded-sm text-gray-800 text-sm w-full flex items-center px-2" onClick={onClick} ref={ref}>
      <CalendarIcon className="w-4 h-4 mr-2"/>
      <span className="capitalize">{new Date(value).toLocaleDateString(t('locale'), {month: "long", year: "numeric"})}</span>
    </button>
  ));


  const renderCustomHeader = ({monthDate, decreaseYear, increaseYear}) => {
    return (
      <div className="flex justify-between font-medium text-gray-800 mb-4 text-sm items-center">
        <div className="flex items-center space-x-2 text-xs capitalize">
          <div
              className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center"
              onClick={decreaseYear}
            >
            <ChevronLeftIcon className="w-4 h-4"/>
          </div>
        </div>
        <div className="capitalize text-xs">
            {monthDate.toLocaleString(t("locale"), {
              year: "numeric",
            })}
        </div>
        <div className="flex items-center space-x-2 text-xs capitalize">
          <div
            className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center"
            onClick={increaseYear}
          >
            <ChevronRightIcon className="w-4 h-4"/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <DatePicker 
      calendarClassName="only_month"
      selected={selectedDate}
      onChange={date => onChange(date)}
      popperPlacement="bottom-end"
      showMonthYearPicker
      showFullMonthYearPicker
      customInput={<CalendarButton />}
      renderCustomHeader={renderCustomHeader}
    />
  )
}

export default MonthDatePicker;