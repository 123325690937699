import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({lang}) => {

  return (
    <Link
      to="/"
    >
      <svg width="110" height="20" viewBox="0 0 130 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M130 2.18182L121.352 31H117.71L125.995 3.18182H118.165V0H129.909V2.18182H130Z" fill="#3C4257"/>
      <path d="M121.352 31H0L0.910364 27.5454H122.126L121.352 31Z" fill="#3C4257"/>
      <path d="M29.0435 19.6374H25.22L28.3152 3.54644H22.762L19.6668 19.6374H15.8433L19.5758 0.273712H32.776L29.0435 19.6374Z" fill="#3C4257"/>
      <path d="M41.6067 19.9091C39.877 19.9091 38.5115 19.2727 37.6011 18.2727C36.5997 17.1818 36.1445 15.7273 36.0535 13.7273C36.0535 13.0909 36.0535 12.3636 36.1445 11.7273L36.6907 8.54545C36.9638 6.81818 37.5101 5.27273 38.2383 4C38.9666 2.72727 39.968 1.63636 41.0605 1C42.1529 0.363636 43.5185 0 44.884 0C46.6137 0 47.9792 0.636364 48.8896 1.72727C49.8 2.81818 50.3462 4.45455 50.3462 6.36364C50.3462 7.09091 50.2551 7.81818 50.1641 8.45455L49.7089 11.4545C49.2537 14.0909 48.3434 16.1818 46.8868 17.6364C45.5213 19.2727 43.7916 20 41.6067 19.9091ZM46.4316 8.45455C46.5227 7.54546 46.6137 6.81818 46.6137 6.36364C46.6137 4.36364 45.8854 3.36364 44.5199 3.27273C43.5185 3.18182 42.6081 3.63636 41.9708 4.45455C41.3336 5.27273 40.7874 6.63636 40.5143 8.45455L39.968 11.7273C39.877 12.6364 39.786 13.2727 39.786 13.8182C39.786 15.7273 40.5143 16.6364 41.9708 16.7273C42.9722 16.7273 43.7916 16.3636 44.5199 15.6364C45.2481 14.9091 45.7033 13.5455 45.9764 11.8182L46.4316 8.45455Z" fill="#3C4257"/>
      <path d="M67.9162 3.54546H63.2733L60.1781 19.6364H56.3545L59.4498 3.54546H54.8979L55.5352 0.272736H68.6444L67.9162 3.54546Z" fill="#3C4257"/>
      <path d="M76.2004 19.9091C74.4707 19.9091 73.1962 19.2727 72.1948 18.2727C71.1934 17.1818 70.7383 15.7273 70.6472 13.7273C70.6472 13.0909 70.6472 12.3636 70.7383 11.7273L71.2845 8.54545C71.5576 6.81818 72.1038 5.27273 72.8321 4C73.5604 2.63636 74.5618 1.63636 75.6542 1C76.7467 0.363636 78.1122 0 79.4778 0C81.2074 0 82.573 0.636364 83.4834 1.72727C84.4848 2.90909 84.9399 4.45455 84.9399 6.36364C84.9399 7.09091 84.8489 7.81818 84.7579 8.45455L84.3027 11.4545C83.8475 14.0909 82.9371 16.1818 81.4806 17.6364C80.115 19.2727 78.3853 20 76.2004 19.9091ZM81.0254 8.45455C81.1164 7.54546 81.2074 6.81818 81.2074 6.36364C81.2074 4.36364 80.4792 3.36364 79.1136 3.27273C78.1122 3.18182 77.2018 3.63636 76.5646 4.45455C75.9273 5.27273 75.3811 6.63636 75.108 8.45455L74.5618 11.7273C74.4707 12.6364 74.3797 13.2727 74.3797 13.8182C74.3797 15.7273 75.108 16.6364 76.5646 16.7273C77.566 16.7273 78.3853 16.3636 79.1136 15.6364C79.8419 14.9091 80.2971 13.5455 80.5702 11.8182L81.0254 8.45455Z" fill="#3C4257"/>
      <path d="M95.4092 11.7273H93.6795L92.1319 19.6364H88.3083L92.0408 0.272736H95.8644L94.4078 8.00001H95.5913L100.325 0.272736H105.15L98.6865 9.45455L101.782 19.6364H97.412L95.4092 11.7273Z" fill="#3C4257"/>
      </svg>
    </Link>
  );
};

export default Logo;
