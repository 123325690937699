import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { convertTime, convertTimeHHMM, getJustDate, secondsToTime } from '../../../utils';
import ChartRPMonth from '../charts/chartRPMonth';


const ElectricityLoadWidget = ({device, selectedDate}) => {
  const {t} = useTranslation(['widgets']);
  const [loading, setLoading] = useState(true);
  const [days, setDays] = useState(1);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if(device.dailyData) {
      return
    }

    // if(device.daily_data.length === 0) {
    //   setDays(1)
    //   return;
    // }
    setChartData(sliceByDay(device.daily_data))
    setLoading(false)
  },[device.daily_data])


  function sliceByDay(daily_data) {
    let start = selectedDate[0].ts

    const daysData = []
    let totalDays = 0

    while (start <= selectedDate[1].endOf('day').ts) { 
      let item = daily_data.find(it => it.at * 1000 >= start && it.at * 1000 < start + 86400000)

      if(item) {
        daysData.push({at: start, sum: item.sum, max: item.max, min: item.min, seconds: item.seconds_count})
      } else {
        daysData.push({at: start, sum: 0, max: 0, min: 0, seconds: 0})
      }

      totalDays = totalDays + 1;
      start = start + 86400000;
    }
    setDays(totalDays)

    return daysData
  }

  return (
    <>
      {loading ? t('loading') :
      <div className="w-full">
        <div className="h-24 mt-4">
          <div className="grid grid-cols-12 pt-2">
            <div className="flex flex-col col-start-1 col-end-3">
              <div className='text-gray-600'>Общее потребление за месяц</div>
              <div className='mt-8'>
                <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(((device.sum / device.seconds_count / 1000) * (device.seconds_count / 3600)))}</span>
                <span className='text-gray-400 font-medium'>.{(((device.sum / device.seconds_count / 1000) * (device.seconds_count / 3600)) % 1).toFixed(2).substring(2)}</span>
                <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
              </div>
            </div>
            <div className="flex flex-col col-start-4 col-end-6">
            <div className='text-gray-600'>Среднее потребление в день</div>
              <div className='mt-8'>
                <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(((device.sum / device.seconds_count / 1000) * (device.seconds_count / 3600)) / device.daily_data.length)}</span>
                <span className='text-gray-400 font-medium'>.{(((device.sum / device.seconds_count / 1000) * (device.seconds_count / 3600)) / device.daily_data.length % 1).toFixed(2).substring(2)}</span>
                <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
              </div>
            </div>
            <div className="flex flex-col col-start-7 col-end-9">
            <div className='text-gray-600'>Пиковое значение за период</div>
              <div className='mt-8'>
                <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(device.max / 1000)}</span>
                <span className='text-gray-400 font-medium'>.{(((device.sum / device.seconds_count / 1000) * (device.seconds_count / 3600)) / device.daily_data.length % 1).toFixed(2).substring(2)}</span>
                <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
              </div>
            </div>
            
          </div>
        </div>
        <div className="h-64">
          <ChartRPMonth data={chartData} />
        </div>
      </div>}
    </>
  )
}

export default ElectricityLoadWidget;