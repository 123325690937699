import {DocumentReportIcon, ExclamationIcon} from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import {getPerfectDateTime, getPerfectTime} from '../../../../../utils';
import { useEffect, useState } from 'react';

import InteractionGraph from './grpah';
import { DateTime } from 'luxon';

let ignoreSensors = ['rfid', 'rfid_hold']

const InteractionItem = ({item, sensors, showByDays}) => {
  const {t} = useTranslation();
  const [openGraph, setOpenGraph] = useState(false)
  const [currentSensors, setCurrentSensors] = useState();


  useEffect(() => {
    if(!sensors || !sensors.length) {
      return
    }

    let res = []
    sensors.map(s => ( res.push({id: s.id, data_key_name: s.attributes.sensor_type.data_key_name})))

    setCurrentSensors(res.filter(s => !ignoreSensors.includes(s.data_key_name)))
  },[sensors])

  function handleLoadData() {
    setOpenGraph(!openGraph)
  }
  
  return (
    <>
    <div className='flex flex-row h-full py-2 border-b'>
      <div className='flex w-4 items-center justify-center mx-4'>
        {item ? <DocumentReportIcon onClick={() => handleLoadData(item)} className='text-green-500 cursor-pointer' width={16} height={16} /> : <ExclamationIcon className='text-yellow-600' width={16} height={16}/>}
      </div>
      <div key={item.id} className='flex-col w-full text-sm pr-4'>
        <div className='flex flex-row justify-between'>
          <div className='font-medium'>{item.personnel.last_name} {item.personnel.first_name} {item.personnel.middle_name}</div>
          <div className='font-normal text-gray-700'>{item.personnel.division_name} / {item.personnel.post_name}</div>
        </div>
        <div className='flex flex-row'>
          <div className='font-medium mr-2'>{item ? showByDays ? DateTime.fromSQL(item.start).toISOTime({ suppressMilliseconds: true, includeOffset: false }) : item.start : t('no_data')}</div> -
          <div className='font-medium ml-2'>{item ? showByDays ? DateTime.fromSQL(item.finish).toISOTime({ suppressMilliseconds: true, includeOffset: false }) : item.finish : t('no_data')}</div>
        </div>
      </div>
    </div>
    
    {openGraph && 
      currentSensors.map((sens) => (
        <InteractionGraph key={sens.id} currentItem={item} currentSensor={sens}/>
      ))
    }
    </>
  )
}

export default InteractionItem;