import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../hooks/use-fetch';
import {DateTime} from "luxon";
import {getPerfectDate} from '../../../../utils';

import InteractionItem from './item';

const Interaction = ({currentItem, startDate, endDate, convertedShift, showByDays, sensors}) => {
  const {t} = useTranslation();
  const [startTime, setStartTime] = useState(DateTime.fromFormat(convertedShift.start, "HH:mm:ss").toISO())
  const [endTime, setEndTime] = useState(DateTime.fromFormat(convertedShift.end, "HH:mm:ss").toISO())
  const [interactionLoaded, setInteractionLoaded] = useState(false)
  const [interactionList, setInteractionList] = useState()
  const [interactionGroups, setInteractionGroups] = useState()

  // const [{response, isLoading, error}, doFetchInteraction] = useFetch(`/directories/equipment_items/${currentItem.id}/personnel_usage?from=${startDate}&to=${endDate}&day_time_from=${startTime}&day_time_to=${endTime}`);
  const [{response, isLoading, error}, doFetchInteraction] = useFetch(`/directories/ng_equipment_items_data/iteractions?equipment_item_ids[]=${currentItem.id}&from=${startDate}&to=${endDate}&day_time_from=${startTime}&day_time_to=${endTime}`);


  useEffect(()=>{
    if(!endDate) { return }
    setStartTime(DateTime.fromFormat(convertedShift.start, "HH:mm:ss").toISO())
    setEndTime(DateTime.fromFormat(convertedShift.end, "HH:mm:ss").toISO())
    setInteractionLoaded(false)
    doFetchInteraction()
  },[convertedShift, startDate, endDate])

  useEffect(()=>{
    if(!response || !response?.data) {
      return
    }

    setInteractionList(_.uniqBy(response.data.map(i => ({...i, uniq: `${i.total_start}_${i.total_finish}`})), 'uniq' ).map(i => ({...i, start: i.total_start, finish: i.total_finish})).reverse())

    const groups = _.groupBy(response.data.reverse(), 'at')
    const groupInteractions = Object.keys(groups).map((date) => {
      return {
        date,
        interactions: groups[date]
      };
    })

    setInteractionGroups(groupInteractions)

    setInteractionLoaded(true)
  },[response])

  return (
  <div className='py-6'>
    {interactionLoaded && interactionList.length > 0 ?
      <div className='flex flex-col w-full rounded-sm border'>
        {showByDays ? 
          interactionGroups.map((group, idx) => (
            <div key={idx} className='flex flex-col w-full'>
              <span className='flex items-center justify-center h-10 bg-gray-100'>{getPerfectDate(group.date)} / {convertedShift.start} - {convertedShift.end}</span>
              {group.interactions.map((it, idx) => (
                <InteractionItem key={idx} item={it} showByDays={showByDays} sensors={sensors}/>
              ))}
            </div>
          ))
        : interactionList.map((it, idx) => (
            <InteractionItem key={idx} item={it} showByDays={showByDays} sensors={sensors}/>
          ))
        }
      </div>
      :
      isLoading ? 
        <div className="flex items-center justify-center w-full h-80">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
        </div> 
        : 
        <div className='flex flex-col w-full mt-4 p-4 h-48 items-center justify-center rounded-sm border'>{t('no_intercation_at_this_day')}</div>
    }
  </div>)
  
}
export default Interaction;