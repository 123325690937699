import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { CurrentUserContext } from '../../../contexts/current-user';

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import useFetch from '../../../hooks/use-fetch';
import FormOrganization from './form';
import OrganizationsTable from "./table";

const Organizations = () => {
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [currentSiteId, setCurrentSiteId] = useState('');
  const [userSites, setUserSites] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [itemId, setItemId] = useState('new');
  const [loading, setLoading] = useState(true);
  const [goToHome, setGoToHome] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error}, doFetchTableData] = useFetch('/users/sites', true);
  const [{response: responseAppsList}, doFetchAppsList] = useFetch('/applications');

  useEffect(() => {
    setLoading(true)
    doFetchTableData()
  },[])

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
    setLoading(false)
  }, [response]);

  useEffect(() => {
    if (!currentUserState.sites) {
      return
    }
    setCurrentSiteId(currentUserState.currentSite.id)
    setUserSites(currentUserState.sites);
  },[currentUserState])

  const headerData = {
    title: 'my_organizations',
    buttonsList: [
      {
        text: 'create_organization',
        action: () => onAdd()
      }
    ],
  };

  const onAdd = () => {
    setItemId('new');
    openModal();
  };

  const onEdit = (id) => {
    setItemId(id);
    openModal();
  };

  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setItemId('new')
    }, 300);
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    doFetchTableData();
  };

  const onOrganizationChoice = (id) => {
    const organization = userSites.find(site => site.id == id);
    setCurrentUserState(state => ({
      ...state,
      currentSite: organization,
    }));
    setGoToHome(true);
    doFetchAppsList();
  };

  if (goToHome) {
    return (
      <Redirect to="/" />
    )
  }



  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      {loading ? <Spinner /> : 
      
        <div className="flex flex-col mt-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
                <OrganizationsTable
                  // sortedBy={sortedBy}
                  // sortedDown={sortedDown}
                  // onSortClick={onSortClick}
                  currentSiteId = {currentSiteId}
                  changeOrg = {onOrganizationChoice}
                  tableData={tableData}
                  onItemEdit={onEdit}
                  isLoading={isLoading}
                  error={error}
                />
              </div>
            </div>
          </div>
        </div>
      }
      <FormOrganization itemId={itemId} closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} tableData={tableData}/>
    </Layout>
  );
};

export default Organizations;
