import React from 'react';
import Table from '../../../../../components/form/table';
import Spinner from "../../../../../components/loader";
import { useTranslation } from 'react-i18next';
import {getPerfectDate} from '../../../../../utils/index'
import { PlusCircleIcon, PencilAltIcon, TrashIcon, FolderIcon, FolderOpenIcon } from '@heroicons/react/outline';

const МaintenancesTable = ({ tableData, onItemEdit, onItemDelete }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        name: tableItem.attributes.description,
        type: tableItem.attributes.maintenance_type,
        maintenance_description: tableItem.attributes.maintenance_description,
        previous_maintenance_at: tableItem.attributes.previous_maintenance_at,
        maintenance_time: tableItem.attributes.maintenance_time,
        next_maintenance_at: tableItem.attributes.next_maintenance_at,
        next_maintenance_after: tableItem.attributes.next_maintenance_after,
      }
    )
  });

  const columns = [
    {
      // label: t('name'),
      prop: "name",
      // labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-6/12 flex-shrink-0 flex items-start",
      // renderHeader: () => {
      //   return (
      //     <div className='flex flex-col'>
      //       <span>{t('name')}</span>
      //       <span>{t('maintenance_type')}</span>
      //       <span>{t('description')}</span>
      //     </div>
      //   )
      // },
      render: (row) => { 
        return (
          <div className=''>
            <div className={`inline-flex px-2 py-0.5 rounded-sm text-xs text-white ${row.type === 'service' ? 'bg-green-800' : 'bg-yellow-800'}`}>
              {t(`maintenance_${row.type}`)}
            </div>
            <div className="flex">
              {row.name}
            </div>
            <div className="flex text-gray-500">
              {row.maintenance_description}
            </div>
          </div>
        )
      }
    },
    {
      // label: t('previous_maintenance_at'),
      prop: "previous_maintenance_at",
      // labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      // renderHeader: () => {
      //   return (
      //     <div className='flex flex-col'>
      //       <span>{t('next_maintenance_at')}</span>
      //       <span>{t('previous_maintenance')}</span>
      //     </div>
      //   )
      // },
      render: (row) => {
        return (
          <>
            <div className="flex flex-col">
              <span>{t('next_maintenance_at')}</span>
              {getPerfectDate(row.next_maintenance_at)}
            </div>
            <div className="flex flex-col text-gray-400 text-xs">
              <span>{t('previous_maintenance_at')}</span>
              {getPerfectDate(row.previous_maintenance_at)}
            </div>
          </>
        )
      }
    },
    {
      // label: t('maintenance_time'),
      prop: "maintenance_time",
      // labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      // renderHeader: () => {
      //   return (
      //     <div className='flex flex-col'>
      //       <span>{t('next_maintenance_after')}</span>
      //       <span>{t('maintenance_time')}</span>
      //     </div>
      //   )
      // },
      render: (row) => { 
        return (
          <>
            <div className="flex flex-col">
              <span>{t('next_maintenance_after')}</span>
              {row.maintenance_time}
            </div>
            <div className="flex flex-col text-gray-400 text-xs">
              <span>{t('maintenance_time')}</span>
              {row.next_maintenance_after}
            </div>
          </>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-1/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onItemEdit(row.id)} 
              className="text-blue-600 hover:text-blue-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded-sm">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    items.length ? 
    <Table
      showHeader={false}
      border={true}
      columns={columns}
      data={items}
    /> : <Spinner />
  );

};

export default МaintenancesTable;
