import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import useFetch from '../../../../hooks/use-fetch';

const ColectionSelectField = ({fid, slug, label, value, required, setCurrentSelect}) => {
  const {t} = useTranslation();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState('');

  const [{response}, doFetchTableData] = useFetch(`/directories/${slug}`);

  useEffect(() => {
    if(slug) {
      doFetchTableData()
    }
  },[slug])

  useEffect(() => {
    if (!response) {
      return;
    }
    const list = [];
    response.data.forEach(it => {
      list.push({
        label: it.attributes.name,
        value: it.id,
        uuid: fid
      })
    });
    setOptions(list);
  }, [response]);

  useEffect(() => {
    if (value === null || value === undefined || options.length === 0) {
      return;
    } else {
      setSelected(options.find(it => it.value == value))
    }
  }, [options, value]);

  const onItemClick = (uuid, id) => {
    setCurrentSelect(uuid, id);
  };

  return ( 
    <Field name={fid} defaultValue={selected === null ? "" : selected} label={label} isRequired={required} >
      {({ fieldProps }) => <Select className="text-sm" options={options} {...fieldProps} onChange={value => onItemClick(value.uuid, value.value)} placeholder={t('select_placeholder')}/>}
    </Field>
  )
}

export default ColectionSelectField;