import React, {useState, useEffect, Fragment, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {CurrentUserContext} from "../../../../contexts/current-user";
import {DateTime, Info} from 'luxon';

import useFetch from "../../../../hooks/use-fetch";
import {convertTime, secondsToTime} from "../../../../utils";
import ChartI from "../../../../components/charts/chartI";
import ChartU from "../../../../components/charts/chartU";
import ChartT from "../../../../components/charts/chartT";
import ChartAng from "../../../../components/charts/chartAng";
import ChartTAir from "../../../../components/charts/chartTair";
import ChartRfidHold from "../../../../components/charts/chartRfidHold";
import ChartRfid from "../../../../components/charts/chartRfid";
import ChartGas from "../../../../components/charts/chartGas";
import ChartWire from "../../../../components/charts/chartWire";
import CanvasJSReact from '../../../../assets/canvasjs.react';
import ChartUniversal from '../../../../components/charts/chartUniversal';
import ChartRP from '../../../../components/widgets/charts/special/chartRP';



const DeviceDataItem = ({ currentItem, currentSensor, slotsCount, filteredDate, isOnline }) => {
  const [currentUserState, _] = useContext(CurrentUserContext);

  const {t} = useTranslation();
  const [autoReload, setAutoReload] = useState(true);
  const [defaultPeriod, setDefaultPeriod] = useState(true);
  const [zoomed, setZoomed] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(true);
  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');
  const [endTime, setEndTime] = useState(DateTime.local());
  const [currentIntervalType, setCurrentIntervalType] = useState('hours');

  const [{response, isLoading, error}, doFetchData] = useFetch(`/devices/sensors/${currentSensor.id}/received_data?from=${startDateString}&to=${endDateString}`);


  useEffect(() => {
    if(!currentUserState || !currentUserState.currentSite) {
      return
    }

    if(!filteredDate[1]) {
      return
    }

    setStartDateString(filteredDate[0]);
    setEndDateString(filteredDate[1]);

    setLoading(true);
    doFetchData();

    if (filteredDate.length > 1) {
      setCurrentIntervalType(calculateIntervalType(new Date(filteredDate[0]).getTime(), new Date(filteredDate[1]).getTime()));
    } else {
      setCurrentIntervalType(calculateIntervalType(Math.floor((new Date().getTime() / (1000 * 60 * 60 * 24))) * (1000 * 60 * 60 * 24), new Date().getTime()));
    }
  },[filteredDate, endTime, currentUserState])



  useEffect(() => {
    setLoading(true);
    doFetchData();
  }, [currentSensor]);


  useEffect(() => {
    if (!response) {
      return;
    }
    setResponseData(response.data);
    setLoading(false);

    CanvasJSReact.CanvasJS.addCultureInfo ("ru", 
    {      
      decimalSeparator: ",",// Observe ToolTip Number Format
      digitGroupSeparator: ".", // Observe axisY labels
      days: ["понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскреснье"],
      shortDays: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
      months: ["январь", "февраль", "март", "аперль", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
      shortMonths: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
      
    });

    const lengthData = response.data.attributes.data.length - 1;
    const first = response.data.attributes.data[0];
    const last = response.data.attributes.data[lengthData];

    if (filteredDate.length > 1) {
      setCurrentIntervalType(calculateIntervalType(first.at, last.at));
    } else {
      setCurrentIntervalType(calculateIntervalType(Math.floor((new Date().getTime() / (1000 * 60 * 60 * 24))) * (1000 * 60 * 60 * 24), new Date().getTime()));
    }
    if (isOnline) {
      if (zoomed) {
        return;
      }
      setTimeout(() => {
        if (filteredDate.length === 1) {
          const currentTime = new Date().getTime();
          setEndTime(`${new Date(currentTime).getHours()}:${new Date(currentTime).getMinutes()}:${new Date(currentTime).getSeconds()}`);
        }}, 30000);
    }
  }, [response]);

  const calculateIntervalType = (min, max) => {
    const interval = Math.floor(max / 1000) - Math.floor(min / 1000);
    if (interval <= 3600) {
      return 'minutes';
    }
    if (interval > 3600 && interval <= 172800) {
      return 'hours';
    }
    if (interval > 172800 && interval <= 2678400) {
      return 'days';
    }
    if (interval > 2678400) {
      return 'month';
    }
  };


  const rangeChanged = (attr) => {
    const tz = DateTime.local().toFormat('ZZ');

    if (attr.trigger === 'reset') {

      setStartDateString(filteredDate[0].startOf('day'));

      if (filteredDate.length > 1 && filteredDate[1].toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
        setEndDateString(filteredDate[1].endOf('day'));
      } else {
        setEndDateString(DateTime.local());
      }
      setZoomed(false);
    } else {
      setDefaultPeriod(false);
      const min = attr.axisX[0].viewportMinimum;
      const max = attr.axisX[0].viewportMaximum;


      const newStartTime = `${new Date(min).getHours()}:${new Date(min).getMinutes()}:${new Date(min).getSeconds()}`;
      const newEndTime = `${new Date(max).getHours()}:${new Date(max).getMinutes()}:${new Date(max).getSeconds()}`;

      setEndTime(endTime);

      setStartDateString(`${new Date(min).getFullYear()}-${new Date(min).getMonth() + 1}-${new Date(min).getDate()}T${newStartTime}.000${tz}`);
      setEndDateString(`${new Date(max).getFullYear()}-${new Date(max).getMonth() + 1}-${new Date(max).getDate()}T${newEndTime}.999${tz}`);

      setZoomed(true);
    }
    doFetchData();
  };

  const getItemContent = () => {
    switch (currentSensor.sensor_type.data_key_name) {
      case 'i':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={DateTime.local().offset * 60000}
            />
          </Fragment>
        );
      case 'ac_avg':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={DateTime.local().offset * 60000}
            />
          </Fragment>
        );
      case 'ac_rms':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={DateTime.local().offset * 60000}
            />
          </Fragment>
        );
      case 'ac_max':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={DateTime.local().offset * 60000}
            />
          </Fragment>
        );
      case 'ac_min':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
            />
          </Fragment>
        );
      case 'u':
        return (
          <ChartU
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 't':
        return (
          <Fragment>
            <ChartT
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={0}
            />
          </Fragment>
        );
      case 'ang':
        return (
          <ChartAng
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 't_air':
        return (
          <Fragment>
            <ChartTAir
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={0}
            />
          </Fragment>
        );
      case 'rfid_hold':
        return (
          <ChartRfidHold
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 'rfid':
        return (
          <ChartRfid
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 'gas':
        return (
          <ChartGas
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 'gas1':
        return (
          <ChartGas
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 'gas2':
        return (
          <ChartGas
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      case 'wire':
        return (
          <ChartWire
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
      default:
        return (
          <ChartUniversal
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={0}
          />
        );
    }
  };

  const baseOptions = {
    animationEnabled: false,
    zoomEnabled: true,
    height: 200,
    culture: DateTime.local().locale,
    axisX:{
      lineColor: '#E7ECF8',
      // intervalType: 'hour',
      // valueFormatString: 'HH:mm:ss',
      intervalType: currentIntervalType,
      valueFormatString: currentIntervalType === 'minutes' ? 'HH:mm:ss' : currentIntervalType === 'hours' ? 'HH:mm' : currentIntervalType === 'days' ? 'DD MMM' : 'MMM YYYY',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontSize: 10,
      // labelFormatter: function (e) {

      //   if (e.axis.intervalType === 'year' || e.axis.intervalType === 'month'|| e.axis.intervalType === 'days'){
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "MM.DD.YY");
      //   } else {
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "HH:mm:ss");
      //   }


      // },
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontSize: 10,
    },
    rangeChanged: rangeChanged
  };

  return (
    <div className="p-4 border rounded">
      <div className="mb-4">{t(currentSensor.sensor_type.data_key_name)}{slotsCount > 1 ? ' | ' : ''}{slotsCount > 1 ? (<span>{t('slot')} {currentItem.number}</span>) : ''}</div>
      {getItemContent()}
    </div>
  )
};

export default DeviceDataItem;
