import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field, CheckboxField} from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { DatePicker } from '@atlaskit/datetime-picker';
import Select, {AsyncSelect} from '@atlaskit/select';
import { Checkbox } from '@atlaskit/checkbox';
import { useTranslation } from "react-i18next";

import useFetch from '../../../../../hooks/use-fetch';

import ErrorsBlock from '../../../../../components/errors';

const DeviceForm = ({currentItem, closeModal, isOpen, reloadData}) => {
  const {t} = useTranslation();
  const [at, setAt] = useState('');
  const [idleLevel, setIdleLevel] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [device, setDevice] = useState('');
  const [sensor, setSensor] = useState('');
  // const [status, setStatus] = useState(false);
  const [devicesList, setDevicesList] = useState([]);
  const [sensorsList, setSensorsList] = useState([]);


  const [errors, setErrors] = useState([]);

  const [{response: devicesResponse, isLoading: devicesIsLoading, error: devicesError}, doFetchDevices] = useFetch(`/devices/devices`);
  const [{response: sensorsResponse, isLoading: sensorsIsLoading, error: sensorsError}, doFetchSensors] = useFetch(`/devices/devices/${device.value}`);
  const [{response: saveResponse, isLoading: saveIsLoading, error: saveError}, doFetchItemSave] = useFetch(`/directories/equipment_items/${currentItem.id}/sensors`);


  useEffect(() => {
    setAt(new Date().toISOString().slice(0, 10))
  },[])

  useEffect(() => {
    doFetchDevices()
  },[])

  useEffect(() => {
    if(!devicesResponse) {
      return;
    }
    const list = []
    devicesResponse.data.map(it => {
      list.push({label: it.attributes.serial_number, value: it.id})
    })
    setDevicesList(list)

  },[devicesResponse])

  const handleDeviceSelect = (data) => {
    setDeviceId(data.value)
    setDevice(data)
    doFetchSensors()
  }

  useEffect(() => {
    if (!sensorsResponse) {
      return;
    }
    const newSlotsList = [];
    const slotsList = sensorsResponse.data.attributes.slots;
    slotsList.forEach((slotItem) => {
      slotItem.sensors.forEach(sensorItem => {
        if (sensorItem.equipment_item_id === null) {
          newSlotsList.push({
            value: sensorItem.id,
            label: slotsList.length > 1 ? `${t(sensorItem.sensor_type.data_key_name)} | ${t("slot")} ${slotItem.number}` : `${t(sensorItem.sensor_type.data_key_name)}`,
            data_key_name: sensorItem.sensor_type.data_key_name
          })
        }
      });
    });
    
    setSensorsList(newSlotsList);
  }, [sensorsResponse]);


  useEffect(() => {
    closeModal();
    reloadData();
  },[saveResponse])

  useEffect(() => {
    if(!saveError) {
      return;
    }
    setErrors(saveError.errors)
  }, [saveError])

  const handleSave = (data) => {
    doFetchItemSave({
      method: 'POST',
      data: {
        data: {
          sensor_id: sensor === null ? null : Number(data.sensor.value),
          equipment_item_connected_at: `${new Date(data.at).getFullYear()}-${new Date(data.at).getMonth() + 1}-${new Date(data.at).getDate()} 01:00:00 +0300`,
          idle_level: data.idle_level === '' ? '0' : data.idle_level,
          // status: data.sensor.data_key_name === 'i' || data.sensor.data_key_name === 'ac_rms' ? true : status
        }
      }
    });
  };


  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
        setDevice('')
        setSensor('')
        setIdleLevel('')
        setSensorsList([])
        setAt(new Date().toISOString().slice(0, 10));
        // setStatus(false)
      }, 300);
    }
  },[isOpen])

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {t('adding_equipment_device_sensor')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('adding_equipment_device_sensor_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => handleSave(data)}>
                {({ formProps }) => (
                  <form {...formProps}>
                    <div className="text-sm">
                      <Field name="device" label={t('device')} defaultValue={device ? devicesList.find(it => it.value === device.value) : ""}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <Select {...fieldProps} options={devicesList} placeholder={t('select_placeholder')} className="text-sm" onChange={(e) => handleDeviceSelect(e)}/>
                        }
                      </Field>
                      <Field name="sensor" label={t('sensor')} defaultValue={sensor ? sensorsList.find(it => it.value === sensor.value) : ""}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <Select {...fieldProps} options={sensorsList} placeholder={t('select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <Field name="idle_level" label={t('idle_level')} defaultValue={idleLevel || ""}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <TextField {...fieldProps} type="number" placeholder={t('select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <Field name="at" label={t('at')} defaultValue={at || ""} isRequired>
                        {({ fieldProps, error, meta: { valid } }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={at}/>}
                      </Field>
                      {/* <CheckboxField name="status">
                        {({ fieldProps, error }) => (
                          <div className='pt-4'>
                            <Checkbox {...fieldProps} 
                              label={t('use_sensor_for_load_calculating')}
                              isChecked={status}
                              onChange={() => setStatus(!status)}
                            />
                          </div>
                        )}
                      </CheckboxField> */}
                    </div>
                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
export default DeviceForm;