import React, { useState, useContext, useEffect } from 'react';
import TextField from '@atlaskit/textfield';
import Form, { Field, CheckboxField, ErrorMessage } from '@atlaskit/form';
import Select, {OptionType, FormatOptionLabelMeta} from '@atlaskit/select';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from "../../../../../contexts/current-user";

const options = [
  {
    label: 'Показатели для сварочного модуля',
    options: [
      {label: "Расчетное значение расхода проволки", value: "welding_wire", description: 'Расчитывается на основе показаний датчика тока для сварочного оборудования.', },
      {label: "Расчетное значение расхода газа", value: "welding_eq", description: 'Расчитывается на основе показаний датчика тока для сварочного оборудования.',},
      {label: "Расчетное значение расхода элетродов", value: "welding_electrod", description: 'Расчитывается на основе показаний датчика тока для сварочного оборудования.',},
    ],
  },
];

const formatOptionLabel = (
  option: OptionType,
  { context }: FormatOptionLabelMeta<OptionType>,
) => {
  if (context === 'menu') {
    return (
      <div
        className="flex flex-col"
      >
        <div>{option.label}</div>
        {option.description ? (
          <div
            className="text-xs italic text-gray-600 font-light"
          >
            {option.description}
          </div>
        ) : null}
      </div>
    );
  }
  return option.label;
};


const PostSettings = ({ currentItem, onUpdate }) => {
  const [currentUserState, _] = useContext(CurrentUserContext);
  const {t} = useTranslation();
  const [name, setName] = useState(currentItem?.attributes?.name);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    let curTags = []
    currentItem?.attributes?.tags.map(tag => {
      options.map(group => {
        curTags.push(group.options.filter(item => item.value === tag))
      })
    })
    setTags(curTags.flat())
  },[])

  const handleUpdate = (data) => {
    let selectedTags = []
    data?.tags?.map(tag => {
      selectedTags.push(tag.value)
    });
    
    onUpdate({name: data.name, tags: selectedTags})
  }

  return (
    <div className="mt-4">
      <Form onSubmit={data => handleUpdate(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1 text-sm">
            <Field name="name" defaultValue={name} label={t("post_name")} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            {currentUserState.applications.welding && (
              <>
                <Field name="tags" label={t("еstimated_indicators")} defaultValue={tags}> 
                  {({ fieldProps }) => <Select {...fieldProps} placeholder={t('select_placholder')} formatOptionLabel={formatOptionLabel} options={options} className="text-sm" isMulti />}
                </Field>
              </>
            )}
            <div className="flex justify-between mt-8 col-span-full">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("update")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
    </div>
  )
};

export default PostSettings;
