import React, {useEffect, useRef, useState, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from 'react-i18next';

import Layout from '../../../../components/layout';
import useFetch from '../../../../hooks/use-fetch';
import Spinner from '../../../../components/loader';

import EquipmentTypeSettings from './settings';
import EquipmentTypeOption from './options';



const EquipmentTypeEdit = (props) => {
  const {t} = useTranslation();
  const [itemId, ] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [currentType, setCurrentType] = useState('');

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/directories/equipment_types/${itemId}`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/equipment_types/${itemId}`);


  useEffect(() => {
    doFetchItem();
  },[])

  useEffect(() => {
    if(!response) {
      return;
    }
    setCurrentType(response.data);
    setLoading(false);
  },[response]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    setCurrentType(itemUpdateResponse.data);
  }, [itemUpdateResponse]);

  const onUpdateSettings = (data) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: data
      }
    });
  };

  const onUpdateOptions = (data) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: {
          field_types: data
        }
      }
    });
  };

  const headerData = {
    title: currentType?.attributes?.name ? `${currentType?.attributes?.name}` : "...",
  };

  return (
    <Layout 
      headerData={headerData}
      isWide={true}
    >
      {loading ? <Spinner/> : (
        <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('equipment_options')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('settings')}
            </Tab>
          </TabList>
          <TabPanel>
            <EquipmentTypeOption 
              onUpdate={onUpdateOptions}
              currentItem={currentType}
            />
          </TabPanel>
          <TabPanel>
            <EquipmentTypeSettings 
              onUpdate={onUpdateSettings}
              currentItem={currentType}
            />
          </TabPanel>
        </Tabs>
      )}
    </Layout>
  );
};

export default EquipmentTypeEdit;
