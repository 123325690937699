import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import {Settings} from 'luxon';

import './app.css';

import Navigation from '../components/navigation';
import EnterPage from '../pages/enter';
import CurrentUserChecker from '../utils/current-user-checker';
import { CurrentUserContext } from "../contexts/current-user";
import PublicRoute from '../auth/public';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../locales/en/translation.json";
import translationRU from "../locales/ru/translation.json";
import widgetsEN from "../locales/en/widgets.json";
import widgetsRU from "../locales/ru/widgets.json";
import logsEN from "../locales/en/logs.json";
import logsRU from "../locales/ru/logs.json";
import ExternalOazisUpload from '../pages/oazis/external';
import ScreenPage from '../pages/screen';
import { ScreenProvider } from '../contexts/screenContext';
import ScreenRouter from '../auth/screen';

const resources = {
  en: {
    translation: translationEN,
    widgets: widgetsEN,
    logs: logsEN
  },
  ru: {
    translation: translationRU,
    widgets: widgetsRU,
    logs: logsRU
  }
};

const App = () => {
  const [currentUserState, ] = useContext(CurrentUserContext);
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    if (currentUserState.currentSite === null || currentUserState.currentUser === null || currentUserState === null)  {
      return;
    }
    i18n.use(initReactI18next).init({
      resources,
      lng: currentUserState?.currentUser?.attributes?.locale || "", 
      fallbackLng: "en"
    });

    // Luxon Settings

    Settings.defaultLocale = currentUserState?.currentUser?.attributes?.locale || "ru";
    Settings.defaultZone = currentUserState?.currentSite?.timezone ? currentUserState?.currentSite?.timezone : "Europe/Moscow";



    setInitLoading(false);
  }, [currentUserState]);

  // console.warn('run');

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted={true} component={EnterPage} path="/login" exact />
          <PublicRoute restricted={true} component={ExternalOazisUpload} path="/oazis" exact />
          <ScreenRouter restricted={true} path="/screen/:site/:id" exact/>
          <CurrentUserChecker>
            <div className="app h-screen" >
              <Navigation initLoading={initLoading}/>
            </div>
          </CurrentUserChecker>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
