import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ResponsiveBarCanvas } from '@nivo/bar';
import { convertTime, convertTimeHHMM, getJustDate, secondsToTime } from '../../../../../utils';

const Tooltip = ({data}) => {
  const {t} = useTranslation(['widgets']);
  return (
    <div className="bg-white shadow-lg rounded text-sm text-gray-700 px-4 py-4 flex flex-col min-w-min">
      <span className="font-medium whitespace-nowrap">{DateTime.fromMillis(data?.data?.at).toFormat("dd.MM.yyyy")}</span>
      <span className="font-semibold">{t('worked')}: {secondsToTime(data?.data?.worked)}</span>
      <span className="font-semibold">{t('stopped')}: {secondsToTime(data?.data?.stopped)}</span>
      <span className="font-semibold">{t('turned_off')}: {secondsToTime(data?.data?.turned_off)}</span>
    </div>
  )
}

const EquipmentLoadWidget = ({dailyData, generalData, duration, onBarClick}) => {
  const {t} = useTranslation(['widgets']);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [days, setDays] = useState(1);
  const [tickValues, setTickValues] = useState([]);

  useEffect(() => {
    if(dailyData.length === 0) {
      setDays(1)
      return;
    }
    setLoading(true)
    setDays(dailyData.length)
    convertData(dailyData)
  },[dailyData])

  useEffect(() => {
    if(duration === 86400) {
      setTickValues([0, 14400, 28800, 43200, 57600, 72000, 86400])
    } else {
      let part = duration / 6
      setTickValues([0, part, part * 2, part * 3, part * 4, part * 5, duration])
    }
  },[duration])


  function convertData(dailyData) {
    const data = []
    dailyData.map(it => {
      data.push({
        at: DateTime.fromFormat(it.at, "yyyy-MM-dd").toMillis(),
        worked: it.worked,
        workedColor: 'rgba(16, 185, 129)',
        stopped: it.stopped,
        stoppedColor: 'rgba(252, 211, 77)',
        turned_off: it.turned_off < 0 ? 0 : it.turned_off,
        turned_offColor: 'rgba(220, 220, 220)'
      })
    })
    setChartData(data);
    setLoading(false);
  }

  function percent(type) {
    const one = (generalData.period_worked + generalData.period_stopped + generalData.period_turned_off)/ 100
    switch (type) {
      case 'worked':
        return `${Math.round(generalData.period_worked / one)}%`

      case 'stopped':
        return `${Math.round(generalData.period_stopped / one)}%`

      case 'turned_off':
        return `${Math.round(generalData.period_turned_off / one)}%`
    
      default:
        return t('no_data')
    }
  }

  return (
    <>
      {loading ? t('loading') :
      <div className="w-full">
        <div className="h-24 mt-4">
          <div className="grid grid-cols-12 pt-2">
            <div className="flex flex-col col-start-1 col-end-3">
              <span className="text-gray-500">{t('equipment_used_in_period')}</span>
              <span className="font-medium">{secondsToTime(generalData.period_worked)} ({percent('worked')})</span>
              <div className="font-medium">{t('avg_in_period')}: {secondsToTime(generalData.period_worked / days)}</div>
            </div>
            <div className="flex flex-col col-start-4 col-end-6">
              <span className="text-gray-500">{t('equipment_stopped_in_period')}</span>
              <span className="font-medium">{secondsToTime(generalData.period_stopped)} ({percent('stopped')})</span>
              <div className="font-medium">{t('avg_in_period')}: {secondsToTime(generalData.period_stopped / days)}</div>
            </div>
            <div className="flex flex-col col-start-7 col-end-9">
              <span className="text-gray-500">{t('equipment_turned_off_in_period')}</span>
              <div className="font-medium">{secondsToTime(generalData.period_turned_off)} ({percent('turned_off')})</div>
              <div className="font-medium">{t('avg_in_period')}: {secondsToTime(generalData.period_turned_off / days)}</div>
            </div>
          </div>
        </div>
        <div className="h-56">
        <ResponsiveBarCanvas
          data={chartData}
          keys={['worked', 'stopped', 'turned_off']}
          indexBy="at"
          margin={{ top: 30, right: 130, bottom: 55, left: 70 }}
          enableGridY={false}
          enableGridX={false}
          gridYValues={7}
          maxValue={duration}
          groupMode="grouped"
          padding={0.4}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          valueFormat={value => 
            `${convertTime(value)}`
          }
          enableLabel={false}
          colors={({ id, data }) => String(data[`${id}Color`])}
          borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: t('days'),
              legendPosition: 'middle',
              legendOffset: 37,
              format: value => getJustDate(value),
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: tickValues,
            legend: t('duration'),
            legendPosition: 'middle',
            legendOffset: -65,
            format: value => `${convertTimeHHMM(value)}`,
          }}
          tooltip={(data) => (<Tooltip data={data} />)}
          onClick={({data}) => onBarClick(data.at)}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
          legendLabel={datum => `${t(datum.id)}`}
          legends={[
              {
                  dataFrom: 'keys',
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 0.85,
                  symbolSize: 16,
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemOpacity: 1
                          }
                      }
                  ]
              }
          ]}
        />
        </div>
      </div>}
    </>
  )
}

export default EquipmentLoadWidget;