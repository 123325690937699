import React, { useState, useEffect } from 'react';
import {DateTime} from 'luxon'
import CanvasJSReact from '../../../../assets/canvasjs.react';

import {convertTimezone} from '../../../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;


const ChartRP = ({loading, data, shiftMarkers, idleLevel, rangeChanged, currentIntervalType, reset}) => {
  const [chartRef, setChartRef] = useState([])
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!data.attributes) {
      return;
    }
    const itemData = [];

    data.attributes.data
    .forEach(it => {
      itemData.push({
        x: new Date(it.at + convertTimezone(it.at)),
        // x: new Date(it.at),
        y: it.v ? (it.v > 0 ? it.v : 0) : null  
      })
    });

    setChartData(itemData);
  }, [data]);

  useEffect(() => {
    if(!reset) {
      return
    }
    chartRef.axisX[0].set("viewportMinimum", null, false);
    chartRef.axisX[0].set("viewportMaximum", null);

  }, [reset])

  const baseOptions = {
    animationEnabled: false,
    zoomEnabled: true,
    height: 200,
    culture: DateTime.local().locale,
    axisX:{
      stripLines: shiftMarkers,
      lineColor: '#E7ECF8',
      // intervalType: 'hour',
      // valueFormatString: 'HH:mm:ss',
      intervalType: currentIntervalType,
      valueFormatString: currentIntervalType === 'minutes' ? 'HH:mm:ss' : currentIntervalType === 'hours' ? 'HH:mm' : currentIntervalType === 'days' ? 'DD MMM' : 'MMM YYYY',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      // labelFormatter: function (e) {

      //   console.log(e);
      //   if (e.axis.intervalType === 'year' || e.axis.intervalType === 'month'|| e.axis.intervalType === 'days'){
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "MM.DD.YY");
      //   } else {
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "HH:mm:ss");
      //   }


      // },
    },
    axisY: {
      stripLines:[
        {                
          startValue:0,
          endValue: idleLevel,                
          color:"#d8d8d8"                
        }
      ],
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      labelPlacement:"inside"
    },
    rangeChanged: rangeChanged
  };

  const options = {
    data: [{
      xValueFormatString: "HH:mm:ss",
      type: "stepArea",
      // type: "area",
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartData
      
    }]
  };

  return (
    <div className="text-sm">
      {loading ?     
      <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div>  : 
        <CanvasJSChart options = {{...options, ...baseOptions}} onRef={ref => setChartRef(ref)}/>
      }
    </div>
  )
};

export default ChartRP;
