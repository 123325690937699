import React, {useContext} from 'react';
import {Switch} from 'react-router-dom';
import {CurrentUserContext} from "../contexts/current-user";
import PrivateRoute from '../auth/private';



// import SettingsPage from "../pages/settings/settings-page/settings-page";
// import SettingsAppsPage from "../pages/settings/apps-page/apps-page";
// import SettingsLogsPage from "../pages/settings/logs-page/logs-page";
// import SettingsNotificationPage from "../pages/settings/notification-page/notification-page";
// import SettingsUsersPage from "../pages/settings/users-page/users-page";
// import SettingsWidgetsPage from "../pages/settings/widgets-page/widgets-page";
import Divisions from '../pages/settings/divisions';
import DivisionEdit from '../pages/settings/divisions/division';
import OrganizationEdit from '../pages/settings/edit';
import SettingsShifts from "../pages/settings/shifts";
import SettingsApps from "../pages/settings/apps";
import SettingsLogs from "../pages/settings/logs";
import SettingsUsers from "../pages/settings/users";
import SettingsNotifications from "../pages/settings/notifications";
import ScreenEdit from '../pages/settings/screens/screen';
import Screens from '../pages/settings/screens';

const SettingsRoutes = () => {
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <Switch>
      <PrivateRoute path="/settings" component={OrganizationEdit} exact/>
      <PrivateRoute path="/settings/divisions" component={Divisions} exact/>
      <PrivateRoute path="/settings/divisions/:id" component={DivisionEdit} exact/>
      <PrivateRoute path="/settings/shifts" component={SettingsShifts} exact/>
      <PrivateRoute path="/settings/screens" component={Screens} exact/>
      <PrivateRoute path="/settings/screens/:id" component={ScreenEdit} exact/>
      <PrivateRoute path="/settings/apps" component={SettingsApps} exact/>
      <PrivateRoute path="/settings/logs" component={SettingsLogs} exact/>
      <PrivateRoute path="/settings/users" component={SettingsUsers} exact/>
      <PrivateRoute path="/settings/notifications" component={SettingsNotifications} exact/>
    </Switch>
  )
};

export default SettingsRoutes;