import React, { useState, useEffect } from 'react';
import TextField from '@atlaskit/textfield';
import Form, { Field, CheckboxField, ErrorMessage } from '@atlaskit/form';
import Select, {OptionType, FormatOptionLabelMeta} from '@atlaskit/select';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from "../../../../../contexts/current-user";


const ManufactureSettings = ({ currentItem, countries, onUpdate }) => {
  const {t} = useTranslation();

  const handleUpdate = (data) => {    
    onUpdate({name: data.name, country_id: data.country.value})
  }

  return (
    <div className="mt-4">
      <Form onSubmit={data => handleUpdate(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1 text-sm">
            <Field name="name" defaultValue={currentItem.attributes.name} label={t("title_name")} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field name="country" defaultValue={currentItem.relationships.country.data.id ? countries.filter(country => country.value === currentItem.relationships.country.data.id) : ""} label={t('country')} isRequired>
              {({ fieldProps }) => <Select {...fieldProps} options={countries} className="text-sm" placeholder={t('select_placeholder')}/>}
            </Field>
            <div className="flex justify-between mt-8">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("update")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
    </div>
  )
};

export default ManufactureSettings;
