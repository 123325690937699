import { XIcon } from '@heroicons/react/outline';
import React from 'react';

const SelectedFilters = ({ tags, removeFilter }) => {
  const items = tags.map((item, index) => {
    return (
      <div
        key={index}
        className="relative text-xs font-semibold flex rounded-md bg-blue-100 text-blue-600 pl-2 pr-8 py-1 my-1 items-center mr-2"
      >
        <span>{item.label}</span>
        <div
          className="absolute right-2 cursor-pointer"
          onClick={() => removeFilter({label: item.label, value: item.value}, {name: item.name, key: item.key, type: item.type})}
        >
          <XIcon className="w-3 h-3"/>
        </div>
      </div>
    );
  });

  return (
    <div className="flex flex-row flex-wrap w-full my-3">
      {items}
    </div>
  );
};

export default SelectedFilters;
