import React, { createContext, useState, useContext, useEffect } from 'react';
import { useScreenStore } from '../store/screenStore';
import { CurrentUserContext } from './current-user';
import useFetch from '../hooks/use-fetch';
import {DateTime} from 'luxon';
import { useTranslation } from 'react-i18next';
import Spinner from '../components/loader';

export const ScreenContext = createContext([{}, () => {}]);

export const ScreenProvider = (props) => {
  const {t} = useTranslation();
  const {children, match, auth} = props
  const site = match.params.site
  const id = match.params.id
  const fullDayShift = {id: null, label: t('full_day'), start: DateTime.fromISO("2000-01-01T00:00:00.000+03:00",), end: DateTime.fromISO("2000-01-01T23:59:59.999+03:00"), started_at: "00:00:00", ended_at: "23:59:59", duration: 86400}
  const screenStore = useScreenStore()
  const {equipments, screenAvailable, screenIsLoading} = screenStore
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [updateDateTime, setUpdateDateTime] = useState(DateTime.local().plus({minute: 2}))
  const [from, setFrom] = useState(DateTime.local().startOf('day'))
  const [to, setTo] = useState(DateTime.local().endOf('day'))
  const [{response, isLoading, error}, doFetchScreen] = useFetch(`/screens/${id}`);
  const [{response: screenData, isLoading: dataLoading, error: dataError}, doFetchData] = useFetch(`/screens/${id}/data?from=${from}&to=${to}`);

  useEffect(() => {
    if (auth) {return} 
    setCurrentUserState(state => ({
      ...state,
      currentSite: {subdomain: site},
    }));

  }, [site, auth]);

  useEffect(() => {
    if(currentUserState?.currentSite?.subdomain && id) {
      doFetchScreen()
    }
  },[id, currentUserState?.currentSite?.subdomain])

  useEffect(() => {
    if(response?.data) {
      screenStore.setScreen(response.data)
      screenStore.setEquipmentCount(response.data.attributes.equipment_item_relations.length)
      screenStore.setEquipment(response.data.attributes.equipment_item_relations.map(eir => eir.equipment_item))
      screenStore.setPagesCount(response.data.attributes.equipment_item_relations.length > 0 ? Math.ceil(response.data?.attributes?.equipment_item_relations?.length / 10) : 1)
      screenStore.setShifts(response.data?.attributes?.working_shift_relations?.length > 0 ? shiftsConvertor(response.data?.attributes?.working_shift_relations) : [])
    } else {
      screenStore.setScreenAvailable(false)
    }
  },[response])

  useEffect(() => {
    if(!error) {return}
    screenStore.setScreenIsLoading(false)
    screenStore.setScreenAvailable(false)
  }, [error])

  useEffect(() => {
    if(!screenStore.shifts) {return}
    detectCurrentShift(screenStore.shifts)
  }, [screenStore.shifts])

  useEffect(() => {
    if(!screenStore.currentShift) {return}
    setFrom(DateTime.now().set({day: screenStore.currentShift.start > screenStore.currentShift.end ? DateTime.now().hour < 24 && DateTime.now().hour > screenStore.currentShift.start.hour ? DateTime.now().day : DateTime.now().minus({day: 1}).day : DateTime.now().day, hour: screenStore.currentShift.start.hour, minute: screenStore.currentShift.start.minute, second: 0, millisecond: 0 }))
    setTo(DateTime.now().set({ minute: DateTime.now().minute - 1, second: 0, millisecond: 0}))
    setUpdateDateTime(DateTime.local().plus({minute: 1}))
    screenStore.setScreenIsLoading(true)
    screenStore.setScreenAvailable(false)
    doFetchData()
  }, [screenStore.currentShift])

  useEffect(() => {
    if(!screenData) {return}
    screenStore.setScreenData(screenData.data.attributes.data)
    screenStore.setScreenIsLoading(false)
    screenStore.setScreenAvailable(true)
  }, [screenData])


  function shiftsConvertor(working_shifts) {
    let shifts = []
    working_shifts.map(shift => {
      let diff = DateTime.fromISO(shift.working_shift.ended_at).diff(DateTime.fromISO(shift.working_shift.started_at), 'seconds').seconds
      shifts.push({
        id: shift.working_shift.id,
        label: shift.working_shift.name, 
        start: DateTime.fromISO(shift.working_shift.started_at), 
        end: DateTime.fromISO(shift.working_shift.ended_at),
        started_at: DateTime.fromISO(shift.working_shift.started_at).toFormat("HH:mm:ss"), 
        ended_at: DateTime.fromISO(shift.working_shift.ended_at).toFormat("HH:mm:ss"), 
        duration: diff > 0 ? diff : diff * -1,
      })
    })
    return shifts
  }

  function detectCurrentShift(shifts) {
    let cs = shifts.filter(s => 
        DateTime.local().set({
          day: 
            DateTime.local().set({hour: s.start.hour, minute: s.start.minute}) > DateTime.local().set({hour: s.end.hour, minute: s.end.minute}) ? 
            DateTime.local() < DateTime.local().endOf('day') && DateTime.local().hour > s.start.hour ? 
              DateTime.local().day : DateTime.local().minus({day: 1}).day 
            : DateTime.local().day, 
          hour: s.start.hour, 
          minute: s.start.minute}) <= updateDateTime.minus({minute: 1}) 
      &&  
        DateTime.local().set({
          day: 
            DateTime.local().set({hour: s.start.hour, minute: s.start.minute}) > DateTime.local().set({hour: s.end.hour, minute: s.end.minute}) ? 
            DateTime.local().set({hour: s.end.hour, minute: s.end.minute}) <= DateTime.local() && DateTime.local() <= DateTime.local().endOf('day') ? 
              DateTime.local().day : DateTime.local().plus({day: 1}).day 
            : DateTime.local().day, 
          hour: s.end.hour, 
          minute: s.end.minute}) >= updateDateTime.minus({minute: 1})
    )
    if(cs.length > 0) {
      if(screenStore?.currentShift?.id !== cs[0].id) {
        screenStore.setCurrentShift(cs[0])
      }
    } else {
      if(screenStore?.currentShift?.id !== null) {
        screenStore.setCurrentShift(fullDayShift)
      }
    }
  } 

  // console.log(screenStore)
  // Запрос экрана
  // Проверка доступности экрана
  // Вычисляем пропоции экрана
  // Вычисляем количество страниц
  // Получаем список оборудования для экрана

  let value = {...screenStore, updateDateTime, setUpdateDateTime, detectCurrentShift}

  if(screenAvailable === false && screenIsLoading === false) return <div className="w-full h-full flex items-center justify-center">Экрана не существует</div>

  return (
    screenAvailable && !screenIsLoading ? <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider> : <Spinner />
  )
};
