import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartPieIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';

import Spinner from "../../../components/loader";
import { getPerfectDate } from "../../../utils";
import Table from '../../../components/form/table';


const EquipmentAccountingTable = ({ onItemEdit, onItemDelete, onItemReport, isLoading, error, tableData }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        parent_id: tableItem.attributes.parent_id,
        childs: tableItem.attributes.children_ids,
        inventory_number: tableItem.attributes.inventory_number,
        name: tableItem.attributes.name,
        equipment_model_name: tableItem.attributes.equipment_model_name,
        equipment_type_name: tableItem.attributes.equipment_type_name,
        producer_name: tableItem.attributes.producer_name,
        factory_number: tableItem.attributes.factory_number,
        division_name: tableItem.attributes.division_name,
        with_sensors: tableItem.attributes.with_sensors,
        parent_changed_at: tableItem.attributes.parent_changed_at,
        status_changed_at: tableItem.attributes.status_changed_at,
        division_changed_at: tableItem.attributes.division_changed_at
      }
    )
  });

  const columns = [
    {
      label: t('equipment'),
      prop: "name",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {row.equipment_type_name}, {row.producer_name}, {row.equipment_model_name}
            </div>
            <div className="text-gray-500 truncate">
              {row.name}
            </div>
            
          </div>
        )
      }
    },
    {
      label: `${t('inventory_number')} / ${t('factory_number')}`,
      prop: "producer",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: (row) => {
        return (
          <div>
            <div className="font-medium text-gray-900 truncate">
              {row.inventory_number} / {row.factory_number}
            </div>
            <div className="text-gray-500 truncate">
              {row.qrcode ? t('with_qr_code') : <></>}
            </div>
          </div>
        )
      }
    },
    {
      label: t('division'),
      prop: "division_name",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
    },

    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "invisible w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            {row.with_sensors ? <button 
              onClick={() => onItemReport(row.id)} 
              className="text-green-600 hover:text-green-900 cursor-pointer w-8 h-8 bg-green-100 hover:bg-green-200 flex items-center justify-center rounded ">
              <ChartPieIcon className="w-4 h-4"/>
            </button> : "" }
            <button 
              onClick={() => onItemEdit(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    },
  ]


  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  )
};

export default EquipmentAccountingTable;
