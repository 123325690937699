import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field, HelperMessage } from '@atlaskit/form';
import TextArea from '@atlaskit/textarea';
import TextField from '@atlaskit/textfield';
import { DatePicker } from '@atlaskit/datetime-picker';
import Select from '@atlaskit/select';
import { useTranslation } from "react-i18next";

import useFetch from '../../../../../hooks/use-fetch';

import ErrorsBlock from '../../../../../components/errors';

const ExplForm = ({isLoading, maintenance, maintenanceTypes, closeModal, isOpen, onSave, errors}) => {
  const {t} = useTranslation();
  const [at, setAt] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [time, setTime] = useState('');
  const [maintenanceDescription, setMaintenanceDescription] = useState('');



  useEffect(() => {
    if(!maintenance) {
      return
    }
    setAt(maintenance?.attributes.previous_maintenance_at ? maintenance.attributes.previous_maintenance_at : new Date().toISOString().slice(0, 10))
    setDescription(maintenance?.attributes.description ? maintenance?.attributes.description : "")
    setTime(maintenance?.attributes.maintenance_time ? maintenance?.attributes.maintenance_time : "")
    setType(maintenance?.attributes.maintenance_type ? maintenanceTypes.find(t => t.value === maintenance?.attributes.maintenance_type) : maintenanceTypes.find(t => t.value === 'service'))
    setMaintenanceDescription(maintenance?.attributes.maintenance_description ? maintenance?.attributes.maintenance_description : "")
  },[maintenance])

  const saveMaintenance = (data) => {
    onSave(data)
  };

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setAt('');
        setDescription('')
        setTime('')
        setType('')
        setMaintenanceDescription('')
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {maintenance?.id ? t('editing_equipment_maintenance') :  t('adding_equipment_maintenance')}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('equipment_maintenance_form_text')}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => saveMaintenance(data)}>
                {({ formProps }) => (
                  <form {...formProps} className={`text-sm`}>
                    <div className="text-sm">
                      <Field name="description" label={t('name')} defaultValue={description} isRequired>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <TextField {...fieldProps} placeholder={t('select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <Field name="type" label={t('maintenance_type')} defaultValue={type} isRequired>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <Select {...fieldProps} options={maintenanceTypes} placeholder={t('select_placeholder')} className="text-sm"/>
                        }
                      </Field>
                      <div className='grid grid-cols-2 w-full space-x-4'>
                        <Field name="time" label={t('maintenance_time')} defaultValue={time} isRequired>
                          {({ fieldProps, error, meta: { valid } }) => 
                            <TextField {...fieldProps} type='number' placeholder={t('select_placeholder')} className="text-sm"/>
                          }
                        </Field>
                        <Field name="at" label={t('previous_maintenance_at')} defaultValue={at || ""} isRequired>
                          {({ fieldProps, error, meta: { valid } }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={at}/>}
                        </Field>
                      </div>
                      <Field name="maintenance_description" label={t('maintenance_description')} defaultValue={maintenanceDescription}>
                        {({ fieldProps, error, meta: { valid } }) => 
                          <>
                            <TextArea {...fieldProps} className="text-sm"/>
                            <HelperMessage>{t('maintenance_description_help_text')}</HelperMessage>
                          </>
                        }
                      </Field>
                    </div>
                    <div className="flex justify-between mt-8">
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                      <button 
                        disabled={isLoading}
                        type="submit" 
                        className="disabled:opacity-20  inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
export default ExplForm;