import React, { useState, useEffect, useContext, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { enGB, ru } from 'date-fns/locale';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import DatePickerSuggs from './datePickerSuggs';
import {DateTime} from 'luxon';
import { getPerfectDate } from '../../../utils';
import {CurrentUserContext} from "../../../contexts/current-user";

registerLocale("ru", ru)
registerLocale("en", enGB)

const CalendarButton = forwardRef(({ value, onClick, startDate, endDate, setStartDate, setEndDate, onChange }, ref) => (
  <div className='h-10 bg-gray-100 rounded text-sm w-full flex items-center justify-between px-2'>
    <button className="text-sm w-full flex items-center" onClick={onClick} ref={ref}>
      <CalendarIcon className="w-4 h-4 mr-2"/>
      <span className="capitalize">{startDate ? startDate.toLocaleString(DateTime.DATE_SHORT) : ""} - {endDate ? endDate.toLocaleString(DateTime.DATE_SHORT) : ""} </span>
    </button>
    <DatePickerSuggs onChange={onChange} value={value} setStartDate={setStartDate} setEndDate={setEndDate}/>
  </div>
));

const DateRangePicker = ({startDate, endDate, setStartDate, setEndDate, onChange}) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);

  setDefaultLocale(currentUserState.currentUser.attributes.locale)

  const renderCustomHeader = (data) => {
    if(data.customHeaderCount === 0) {
      return (
        <div className="flex font-medium text-gray-800 mb-4 text-sm items-center">
          <div className="flex items-center space-x-2 text-xs capitalize">
            <div
                className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center mr-4"
                onClick={data.decreaseMonth}
              >
              <ChevronLeftIcon className="w-4 h-4"/>
            </div>
          </div>
          <div className="capitalize text-xs">
            <span>
              {data.monthDate.toLocaleString(t("locale"), {
                month: "long",
              })}
            </span>
            <span className='px-2'>{data.monthDate.getFullYear()}</span>
          </div>
          <div>
            {}
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex justify-end font-medium text-gray-800 mb-4 text-sm items-center">
          <div className="capitalize text-xs">
            <span className='px-2'>
              {data.monthDate.toLocaleString(t("locale"), {
                month: "long",
              })}
            </span>
            <span>{data.monthDate.getFullYear()}</span>
          </div>
          <div className="flex items-center space-x-2 text-xs capitalize">
            <div
              className="bg-gray-100 rounded items-center w-7 h-7 flex justify-center ml-4"
              onClick={data.increaseMonth}
            >
              <ChevronRightIcon className="w-4 h-4"/>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <DatePicker 
      calendarClassName="date_range"
      selected={new Date(startDate.toFormat('yyyy/MM/dd'))}
      onChange={(dates) => onChange(dates)}
      popperPlacement="bottom-end"
      startDate={new Date(startDate.toFormat('yyyy/MM/dd'))}
      endDate={endDate ? new Date(endDate.toFormat('yyyy/MM/dd')) : null}
      customInput={<CalendarButton startDate={startDate} endDate={endDate} onChange={onChange} setStartDate={setStartDate} setEndDate={setEndDate}/>}
      calendarStartDay={1}
      selectsRange
      monthsShown={2}
      renderCustomHeader={renderCustomHeader}
    />
  )

}

export default DateRangePicker;