import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import {CurrentUserContext} from "../../../../../contexts/current-user";
import useFetch from '../../../../../hooks/use-fetch';
import PostOptionsTable from './table';
import PostOptionForm from './form';

const PostOption = ({ currentItem, onUpdate, onDelete }) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [itemId, setItemId] = useState("new");
  const [parent, setParent] = useState(null);
  const [unitsList, setUnitsList] = useState([]);
  const [directoriesList, setDirectoriesList] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);

  const [{response: unitsListResponse, isLoading: unitsListIsLoading, error: unitsListError}, doFetchUnitsList] = useFetch('/directories/units');
  const [{response: directoriesListResponse, isLoading: directoriesListIsLoading, error: directoriesListError}, doFetchDirectoriesList] = useFetch('/directories/directories');

  useEffect(() => {
    doFetchUnitsList();
    doFetchDirectoriesList();
  }, []);

  useEffect(() => {
    if (!unitsListResponse || !directoriesListResponse) {
      return;
    }

    const newUnitsList = [];
    unitsListResponse.data.map(item => {
      newUnitsList.push({label: item.attributes.name, value: item.id})
    })

    const newDirectoriesList = [];
    directoriesListResponse.data.map(item => {
      newDirectoriesList.push({label: item.attributes.name, value: item.attributes.slug})
    })
    setUnitsList(newUnitsList);
    setDirectoriesList(newDirectoriesList);

    const newFieldTypes = [];
    currentItem.attributes.field_types.forEach((item) => { 
      newFieldTypes.push(item);
    })
    setFieldTypes(newFieldTypes);
  }, [unitsListResponse, directoriesListResponse, currentItem]);


  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setParent(null)
      setItemId("new")
    }, 300);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    setItemId('new');
    setParent(null)
    openModal();
  };

  const onAddChild = (id) => {
    setItemId('new');
    setParent(id)
    openModal();
  };

  const onEdit = (id, parent) => {
    setParent(parent)
    setItemId(id);
    openModal();
  };

  const handleDelete = (id, parent) => {
    if (parent === null) {
      const newFieldTypes = fieldTypes.filter(item => item.uuid !== id)
      onUpdate(newFieldTypes);
    } else {
      const parentIndex = fieldTypes.findIndex(item => item.uuid === parent);
      const newChilds = fieldTypes[parentIndex].children.filter(item => item.uuid !== id)
      fieldTypes[parentIndex].children = newChilds
      onUpdate(fieldTypes);
    }
  };

  const handleUpdate = (data) => {
    onUpdate(data);
  }

  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('equipment_options')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('equipment_options_text')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('add_option')}
        </button>
      </div>
      <PostOptionsTable
        tableData={fieldTypes}
        onItemEdit={onEdit}
        onItemDelete={handleDelete}
        onAddChild={onAddChild}
        unitsList={unitsList}
      />
      <PostOptionForm 
        itemId={itemId} 
        closeModal={closeModal}
        onSave={handleUpdate}
        fieldTypes={fieldTypes} 
        isOpen={isOpen} 
        parent={parent} 
        unitsList={unitsList}
        directoriesList={directoriesList}
      />
    </div>
  );
};

export default PostOption;
