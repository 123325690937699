import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Table from '../../components/form/table';
import { PencilAltIcon } from '@heroicons/react/outline';
import Spinner from "../../components/loader";
import {getPerfectDate} from "../../utils";

const DevicesTable = ({ tableData, onItemDelete, onItemEdit, onShow, sortedBy, sortedDown, onSortClick, isLoading, error }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    const itemDate = new Date(tableItem.attributes.initialized_at);

    const st = [];

    tableItem.attributes.sensor_types.forEach(item => {
      st.push(t(item.data_key_name));
    });

    return (
      {
        id: tableItem.id,
        serial_number: tableItem.attributes.serial_number,
        model: tableItem.attributes.model,
        types: st,
        sensor_types: tableItem.attributes.sensor_types,
        status: tableItem.attributes.online,
        installation_date: getPerfectDate(itemDate)
      }
    )
  });

  const columns = [
    {
      label: `${t('serial_number')} / ${t('model')}`,
      prop: "serial_number",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: ({serial_number, model}) => {
        return (
          <div>
            <div className="font-medium text-gray-900">
              {serial_number}
            </div>
            <div className="text-gray-500">
              {model}
            </div>
          </div>
        )
      }
    },
    {
      label: t('data_types'),
      prop: "types",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: ({types}) => {return types.join(', ')}
    },
    {
      label: t('status'),
      prop: "status",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: ({status}) => {
        return (
          <span className={`${status ? 'bg-green-100 text-green-800' : ' bg-red-100 text-red-800'} px-2 inline-flex text-xs leading-5 font-semibold rounded-full`}
          >
            {status ? t('active') : t('no_active')}
          </span>
        )
      }
    },
    {
      label: t('installation_date'),
      prop: "installation_date",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
      onRowClick={(data) => onShow(data.id, data.sensor_types, data.serial_number)}
    />
  );
};

export default DevicesTable;
