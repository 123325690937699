import { TrashIcon, PencilAltIcon } from '@heroicons/react/outline';
import React, {useContext, useState, useEffect} from 'react';
import Spinner from '../../../components/loader';
import {getPerfectHours} from '../../../utils/index';

const AppsTable = ({tableData, isLoading, onItemEdit, onItemDelete, error}) => {
  const items = tableData.map(tableItem => {
    return (
      <tr
        key={tableItem.id}
        className="hover:bg-gray-50"
      >
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{tableItem.attributes.name}</div></td>
        <td
          className="px-6 py-4 whitespace-nowrap"
        ><div className="text-sm text-gray-900">{tableItem.attributes.description}</div></td>
        <td 
          className="px-6 py-4 whitespace-nowrap text-sm font-medium flex justify-end space-x-2"
        >
          <button 
            onClick={() => onItemEdit(tableItem.id, !tableItem.attributes.enabled)} 
            className={`${tableItem.attributes.enabled === true ? "bg-green-100 hover:bg-green-200 text-green-600 hover:text-green-700" : "text-indigo-600 hover:text-indigo-900 bg-blue-100  hover:bg-blue-200"} font-medium px-2 py-2 flex items-center justify-center rounded cursor-pointer`}
          >
            {tableItem.attributes.enabled === true ? "Отключить" : "Подключитъ"}
          </button>
        </td>
      </tr>
    )
  });

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr
          className=""
        >
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-48">Модуль</th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Описание</th>
          <th scope="col" className="relative px-6 py-3"><span className="sr-only">Действия</span></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
      {isLoading ? (
        <tr>
          <td colSpan="7">
            <Spinner/>
          </td>
        </tr>
      ) : error ? (
        <tr>
          <td colSpan="7">
            <div className="text-center text-xs py-8 text-gray-600">Ошибка загрузки</div>
          </td>
        </tr>
      ) : tableData.length === 0 ? (
        <tr>
          <td colSpan="7">
            <div className="text-center text-xs py-8 text-gray-600">Данные отсутствуют</div>
          </td>
        </tr>
      ) : items}
      </tbody>
    </table>
  )
};

export default AppsTable;