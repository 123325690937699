import React, {useState, useEffect, Fragment} from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import useFetch from "../../../../../hooks/use-fetch";
import {secondsToTime} from "../../../../../utils";
import ChartI from "../../../../../components/charts/chartI";
import ChartU from "../../../../../components/charts/chartU";
import ChartT from "../../../../../components/charts/chartT";
import ChartAng from "../../../../../components/charts/chartAng";
import ChartTAir from "../../../../../components/charts/chartTair";
import ChartRfidHold from "../../../../../components/charts/chartRfidHold";
import ChartRfid from "../../../../../components/charts/chartRfid";
import ChartGas from "../../../../../components/charts/chartGas";
import ChartWire from "../../../../../components/charts/chartWire";
import ChartUniversal from "../../../../../components/charts/chartUniversal";
import CanvasJSReact from '../../../../../assets/canvasjs.react';

const timeZone = 0;

const ChartItem = ({ date, currentSensorId}) => {
  const {t} = useTranslation();
  const [defaultPeriod, setDefaultPeriod] = useState(true);
  const [zoomed, setZoomed] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(true);
  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');

  const [{response, isLoading, error}, doFetchData] = useFetch(`/devices/sensors/${currentSensorId}/received_data?from=${startDateString}&to=${endDateString}`);

  useEffect(() => {
    const start = DateTime.fromMillis(date).startOf('day')
    const end = DateTime.fromMillis(date).endOf('day')

    setStartDateString(start)
    setEndDateString(end)

    setLoading(true);
    doFetchData();
  },[])


  useEffect(() => {
    if (!response) {
      return;
    }
    setResponseData(response.data);
    setLoading(false);

    CanvasJSReact.CanvasJS.addCultureInfo ("ru", 
    {      
      decimalSeparator: ",",// Observe ToolTip Number Format
      digitGroupSeparator: ".", // Observe axisY labels                   
      days: ["понедельник", "вторник", "среда", "четверг", "пятница", "суббота", "воскреснье"],
      shortDays: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
      months: ["январь", "февраль", "март", "аперль", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
      shortMonths: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
      
    });
  }, [response]);

  const rangeChanged = (attr) => {
    const tz = DateTime.local().toFormat('ZZ');
    if (attr.trigger === 'reset') {
      const start = DateTime.fromMillis(date).startOf('day')
      const end = DateTime.fromMillis(date).endOf('day')

      setStartDateString(start)
      setEndDateString(end)
      setZoomed(false);
    } else {
      setDefaultPeriod(false);
      const min = attr.axisX[0].viewportMinimum;
      const max = attr.axisX[0].viewportMaximum;

      const newStartTime = `${new Date(min).getHours()}:${new Date(min).getMinutes()}:${new Date(min).getSeconds()}`;
      const newEndTime = `${new Date(max).getHours()}:${new Date(max).getMinutes()}:${new Date(max).getSeconds()}`;

      setStartDateString(`${new Date(min).getFullYear()}-${new Date(min).getMonth() + 1}-${new Date(min).getDate()}T${newStartTime}.000${tz}`);
      setEndDateString(`${new Date(max).getFullYear()}-${new Date(max).getMonth() + 1}-${new Date(max).getDate()}T${newEndTime}.999${tz}`);

      setZoomed(true);
    }
    doFetchData();
  };

  const getItemContent = () => {
    switch (responseData.attributes.sensor_type.data_key_name) {
      case 'i':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'ac_avg':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'ac_rms':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'ac_max':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'ac_min':
        return (
          <Fragment>
            {responseData.attributes ? (
              <div className="flex items-center text-gray-600 mb-4">
                <div className="flex mr-4">          
                  <div className="font-normal">{t('worked_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.work_time)}</div>
                </div>
                <div className="flex">
                  <div className="font-normal">{t('turned_off_time')}:</div>
                  <div className="px-2 font-medium">{secondsToTime(responseData.attributes.general.idle_time)}</div>
                </div>
              </div>
            ) : ''}
            <ChartI
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'u':
        return (
          <ChartU
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 't':
        return (
          <Fragment>
            <ChartT
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'ang':
        return (
          <ChartAng
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 't_air':
        return (
          <Fragment>
            <ChartTAir
              loading={loading}
              responseData={responseData}
              baseOptions={baseOptions}
              timeZone={timeZone}
            />
          </Fragment>
        );
      case 'rfid_hold':
        return (
          <ChartRfidHold
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 'rfid':
        return (
          <ChartRfid
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 'gas':
        return (
          <ChartGas
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 'gas1':
        return (
          <ChartGas
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 'gas2':
        return (
          <ChartGas
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      case 'wire':
        return (
          <ChartWire
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
      default:
        return (
          <ChartUniversal
            loading={loading}
            responseData={responseData}
            baseOptions={baseOptions}
            timeZone={timeZone}
          />
        );
    }
  };

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 300,
    culture:  "ru",
    axisX:{
      lineColor: '#E7ECF8',
      // intervalType: 'hour',
      // valueFormatString: 'HH:mm:ss',
      intervalType: "minutes",
      valueFormatString: 'HH:mm:ss',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontSize: 10,
      // labelFormatter: function (e) {

      //   if (e.axis.intervalType === 'year' || e.axis.intervalType === 'month'|| e.axis.intervalType === 'days'){
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "MM.DD.YY");
      //   } else {
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "HH:mm:ss");
      //   }


      // },
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontColor: '#596890',
      labelFontSize: 10,
    },
    rangeChanged: rangeChanged
  };

  return (
    loading ? 
    <div className="flex items-center justify-center w-full h-80">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
    </div> 
    :
    <div className="p-4 border rounded bg-white max-w-7xl mx-auto">
      <div className="mb-4">{t(responseData.attributes.sensor_type.data_key_name)}</div>
      {getItemContent()}
    </div>
  )
};

export default ChartItem;
