import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollWithShadow } from "../../../hooks/useScrollWithShadow";
import {ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import {RiCheckboxBlankLine, RiCheckboxFill} from 'react-icons/ri';

export default function MultiSelectFilter({filter, selected, addFilter, removeFilter}) {
  const { t } = useTranslation();
  const { boxShadow, onScrollHandler } = useScrollWithShadow();
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState(filter.options)
  const [searchValue, setSearchValue] = useState('')

  const onItemClick = (value) => {
    if(selected.some(i => i.value === value.value)) {
      removeFilter(value, filter)
    } else {
      addFilter(value, filter);
    }
  };

  function handleSearch(value) {
    setSearchValue(value)

    let filteredOptions = []

    for (let i = 0; i < filter.options.length; i++) {
      filter.options[i].label.substr(0, value.length) === value && filteredOptions.push(filter.options[i])
    }
    setOptions(filteredOptions)
  }


  return (
    <div className='flex flex-col w-full'>
      <div className='flex flex-row w-full items-center justify-between px-6 py-3 text-sm text-gray-800 hover:bg-blue-100 cursor-pointer' onClick={() => setShow(!show)}>
        <span>{t(filter.name)}</span>
        {show ? <ChevronUpIcon width={20}/> : <ChevronDownIcon width={20}/> }
      </div>
      {show ? 
        <div onScroll={onScrollHandler} className='bg-gray-100 px-4 py-2 flex flex-col max-h-60 overflow-auto' style={{boxShadow}}>
          {filter.option_search &&
            <div className='px-2 pb-4 py-2'>
              <input
                type="text"
                className="text-gray-800 w-full text-sm bg-white ring ring-gray-200 font-normal px-2.5 py-1.5 rounded-sm focus:outline-none focus:bg-white focus:ring-blue-300 placeholder-gray-500"
                placeholder={t('search')}
                onChange={(evt) => handleSearch(evt.target.value)}
                value={searchValue}
              />
            </div> 
          }
          {options.length ? 
            options.map(item => (
              <div key={item.value} className='flex text-sm py-1.5 px-2 rounded-sm hover:bg-blue-200 hover:text-blue-600 hover:ring-blue-200 cursor-pointer' onClick={() => onItemClick(item)}>
                <div className='flex-none'>{!!selected.find(i => i.value === item.value) ? <RiCheckboxFill size={20} className='text-blue-600'/> : <RiCheckboxBlankLine size={20}/>}</div>
                <span className='px-2 grow'>{item.label}</span>
              </div>
            ))
            : <div className='text-sm flex w-full px-2 text-gray-400 py-2'>{t('no_options')}</div>
          }
        </div>

      : <></>}
    </div>
  );
}