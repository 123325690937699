import { Fragment, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import useFetch from '../../../../hooks/use-fetch';

import {CurrentUserContext} from '../../../../contexts/current-user';

import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import Select from '@atlaskit/select';

import ErrorsBlock from '../../../../components/errors';


export default function FormModel({item, closeModal, isOpen, reloadTable}) {
  const { t } = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [formData, setFormData] = useState('');
  const [sensors, setSensors] = useState([]);
  const [name, setName] = useState('');
  const [slotOne, setSlotOne] = useState({number: 1, sensors: []});
  const [slotTwo, setSlotTwo] = useState({number: 2, sensors: []});
  const [slotThree, setSlotThree] = useState({number: 3, sensors: []});
  const [slotFour, setSlotFour] = useState({number: 4, sensors: []});
  const [errors, setErrors] = useState([]);

  const isNew = item === '';

  const [{response: sensorsResponse, isLoading: sensorsIsLoading, error: sensorsError, meta}, doFetchSensorsData] = useFetch('/administration/directories/sensor_types', true);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/administration/directories/device_models/${item.id}`, true);
  const [{response: itemSaveResponse, isLoading: itemSaveIsLoading, error: itemSaveError}, doFetchItemSave] = useFetch(`/administration/directories/device_models`, true);


  useEffect(() => {
    doFetchSensorsData()
  },[])

  useEffect(() => {
    setFormData(new FormData());
    if (!item) {
      setName('');
      return;
    }
    setName(item.attributes.name);


    item.attributes.structure?.slots.filter(slot => {
      if(slot.number == 1) {
        setSlotOne(slot)
      } else if(slot.number == 2) {
        setSlotTwo(slot)
      } else if(slot.number == 3) {
        setSlotThree(slot)
      } else if(slot.number == 4) {
        setSlotFour(slot)
      }
    })
  }, [item]);


  useEffect(() => {
    if(!sensorsResponse) {
      return
    }
    const sensorsList  = []
    sensorsResponse.data.map(it => {
      sensorsList.push({label: it.attributes.long_name, value: it.attributes.data_key_name})
    })
    setSensors(sensorsList)
  },[sensorsResponse])


  useEffect(() => {
    if (!itemSaveResponse) {
      return;
    }

    closeModal();
    reloadTable();
  }, [itemSaveResponse]);

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }

    closeModal();
    reloadTable();
  }, [itemUpdateResponse]);

  function onChangeSensors(data, number) {
    switch (number) {
      case 1:
        setSlotOne({number: 1, sensors: transformSensors(data)})
        break;
      case 2:
        setSlotTwo({number: 2, sensors: transformSensors(data)})
        break;
      case 3:
        setSlotThree({number: 3, sensors: transformSensors(data)})
        break;
      case 4:
        setSlotFour({number: 4, sensors: transformSensors(data)})
        break;
      default:
        break;
    }
  }

  function transformSensors(sensors) {
    let sensors_keys = []
    sensors.map(s => {
      sensors_keys.push(s.value)
    })

    return sensors_keys
  }


  const onSaveClick = (data) => {    
    let slots = []
    if(slotOne.sensors.length) {
      slots.push(slotOne)
    }
    if(slotTwo.sensors.length) {
      slots.push(slotTwo)
    }
    if(slotThree.sensors.length) {
      slots.push(slotThree)
    }
    if(slotFour.sensors.length) {
      slots.push(slotFour)
    }

    if (isNew) {
      doFetchItemSave({
        method: 'POST',
        data: {
          data: {
            name: data.name,
            structure: {
              slots: slots
            }
          }
        }
      });
    } else {
      doFetchItemUpdate({
        method: 'PUT',
        data: {
          data: {
            name: data.name,
            structure: {
              slots: slots
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    if (itemSaveError) {
      setErrors(itemSaveError.errors)
    }
  }, [itemSaveError]);

  useEffect(() => {
    if (itemUpdateError) {
      setErrors(itemUpdateError.errors)
    }
  }, [itemUpdateError]);

  useEffect(() => {
    if(!isOpen) {
      setTimeout(() => {
        setErrors([])
        setSlotOne({number: 1, sensors: []})
        setSlotTwo({number: 2, sensors: []})
        setSlotThree({number: 3, sensors: []})
        setSlotFour({number: 4, sensors: []})
      }, 300);
    }
  },[isOpen])


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen px-4 text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isNew ? t("adding_model") : t("editing_model")}
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t("model_form_text")}
                </p>
              </div>
              {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
              <Form onSubmit={data => onSaveClick(data)}>
                {({ formProps }) => (
                  <form {...formProps} className="text-sm">
                    <Field name="name" defaultValue={name || ""} label={t('title_name')} isRequired>
                      {({ fieldProps }) => <TextField {...fieldProps} />}
                    </Field>

                    <Field key={0} name='slot_one' label={`${t('sensors_for_slot')} 1`}>
                      {({ fieldProps }) => 
                      <>
                        <Select 
                          {...fieldProps}
                          options={sensors.length ? sensors : []}  
                          value={slotOne.sensors.length ? sensors.filter(s => slotOne.sensors.includes(s.value)) : []}s
                          isMulti 
                          onChange={e => onChangeSensors(e, 1)}
                        />
                      </>
                      }
                    </Field>

                    <Field key={1} name='slot_two' label={`${t('sensors_for_slot')} 2`}>
                      {({ fieldProps }) => 
                      <>
                        <Select 
                          {...fieldProps}
                          options={sensors.length ? sensors : []}  
                          isMulti 
                          value={slotTwo.sensors.length ? sensors.filter(s => slotTwo.sensors.includes(s.value)) : []}
                          onChange={e => onChangeSensors(e, 2)}
                        />
                      </>
                      }
                    </Field>

                    <Field key={2} name='slot_three' label={`${t('sensors_for_slot')} 3`}>
                      {({ fieldProps }) => 
                      <>
                        <Select 
                          {...fieldProps}
                          options={sensors.length ? sensors : []}  
                          isMulti 
                          value={slotThree.sensors.length ? sensors.filter(s => slotThree.sensors.includes(s.value)) : []}
                          onChange={e => onChangeSensors(e, 3)}
                        />
                      </>
                      }
                    </Field>

                    <Field key={3} name='slot_four' label={`${t('sensors_for_slot')} 4`}>
                      {({ fieldProps }) => 
                      <>
                        <Select 
                          {...fieldProps}
                          options={sensors.length ? sensors : []}  
                          isMulti 
                          value={slotFour.sensors.length ? sensors.filter(s => slotFour.sensors.includes(s.value)) : []}
                          onChange={e => onChangeSensors(e, 4)}
                        />
                      </>
                      }
                    </Field>

                    <div className="flex justify-between mt-8">
                      <button 
                        type="submit" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('save')}
                      </button>
                      <button 
                        onClick={() => closeModal()}
                        type="button" 
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      >
                        {t('cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
