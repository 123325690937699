import { useTranslation } from 'react-i18next';
import {RiCheckboxBlankLine, RiCheckboxFill} from 'react-icons/ri';

export default function CheckboxFilter({filter, addFilter, removeFilter}) {
  const { t } = useTranslation();
  const selected = filter.selected ? filter.selected : null
  
  const onItemClick = () => {
    if(selected) {
      removeFilter({label: t(filter.name), value: !selected}, filter)
    } else {
      addFilter({label: t(filter.name), value: !selected}, filter);
    }
  };


  return (
    <div className='flex flex-col w-full'>
      <div className='flex flex-row w-full items-center justify-between px-6 py-3 text-sm text-gray-800 hover:bg-blue-100 cursor-pointer' onClick={() => onItemClick()}>
        <span>{t(filter.name)}</span>
        {selected ? <RiCheckboxFill size={20} className='text-blue-600'/> : <RiCheckboxBlankLine size={20}/> }
      </div>
    </div>
  );
}