import {useState, useEffect} from "react";
import useFetch from '../../../hooks/use-fetch';
import { useTranslation } from "react-i18next";

import DirectoryHierarchicTable from "./tables/hierarchic";
import DirectoryCommonTable from "./tables/common";
import Form from "./form";

const Entries = ({currentItem, slug}) => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [itemId, setItemId] = useState("new");
  const [parent, setParent] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [{response, isLoading, error}, doFetchTableData] = useFetch(`/directories/${slug}`);
  const [{response: itemDeleteResponse, isLoading: itemDeleteIsLoading, error: itemDeleteError}, doFetchItemDelete] = useFetch(`/directories/${slug}/${itemId}`);

  useEffect(() => {
    doFetchTableData();
  }, [itemDeleteResponse, slug]);

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
  }, [response]);

  const reloadTable = () => {
    doFetchTableData();
  };

  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setParent(null)
      setItemId("new")
    }, 300);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    setItemId('new');
    setParent(null)
    openModal();
  };

  const onAddChild = (id) => {
    setItemId('new');
    setParent(id)
    openModal();
  };

  const onEdit = (id) => {
    setItemId(id);
    openModal();
  };

  const onDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  useEffect(() => {
    if (itemDeleteIsLoading) {
      return;
    }
    setItemId("new");
    reloadTable();
  }, [itemDeleteIsLoading]);


  return (
    <div className="pt-8">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('entries')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('list_of_entries')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('add_entry')}
        </button>
      </div>
      {currentItem?.attributes?.slug === '' ? '' : currentItem?.attributes?.hierarchic ? (
        <DirectoryHierarchicTable
          tableData={tableData}
          onAddChild={onAddChild}
          onEdit={onEdit}
          onDelete={onDelete}
          isLoading={isLoading}
          error={error}
        />
      ) : (
        <DirectoryCommonTable
          tableData={tableData}
          onEdit={onEdit}
          onDelete={onDelete}
          isLoading={isLoading}
          error={error}
        />
      )}
      <Form slug={slug} itemId={itemId} closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} tableData={tableData} parent={parent}/>
    </div>
  )
}

export default Entries;