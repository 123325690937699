import React, { useEffect, useRef, useState } from 'react';
import useFetch from "../../../../hooks/use-fetch";
import _ from "lodash"
import CanvasJSReact from '../../../../assets/canvasjs.react';
import { convertTimezone, secondsToTime } from '../../../../utils';
import {DateTime, Interval} from "luxon";
import { useTranslation } from 'react-i18next';
import WeightsWidget from '../widgets/weightsWdget';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CeCharts = ({mbCountSensor, spoolWeigthSensor, footageSensor, mbBruttoSensors, mbNettoSensors, startDate, endDate, startTime, endTime, shiftDepths}) =>  {
  let [chartRef, setChartRef] = useState()
  let {t} = useTranslation()
  const [loading, setLoading] = useState(true);
  const [loadingWeights, setLoadingWeights] = useState(true);
  const [rollsResponse, setRollsResponse] = useState()
  const [totalRolls, setTotalRolls] = useState();
  const [chartData, setChartData] = useState([]);
  const [footageResp, setFootageResp] = useState();
  const [footage, setFootage] = useState(0);
  const [currentSpoolWeight, setCurrentSpoolWeight] = useState(null)
  const [spoolsList, setSpoolList] = useState([])
  const [spoolsListReady, setSpoolsListReady] = useState(false)

  const [defaultPeriod, setDefaultPeriod] = useState(true);
  const [zoomed, setZoomed] = useState(false);

  
  const [startDateFG, setStartDateFG] = useState(startDate.toISO());
  const [endDateFG, setEndDateFG] = useState(endDate ? endDate.endOf('day').toISO() : null);
  const [datesFG, setDatesFG] = useState([startDate.toISO(), endDate ? endDate.endOf('day').toISO() : null])

  const [startTimeFG, setStartTimeFG] = useState((secondsToTime(startTime / 1000)))
  const [endTimeFG, setEndTimeFG] = useState(DateTime.fromFormat((secondsToTime(endTime / 1000)), "hh:mm:ss") > DateTime.local().toISO() ? DateTime.local().toFormat("hh:mm:ss") : secondsToTime(endTime / 1000))
  const [currentIntervalType, setCurrentIntervalType] = useState('hours');
  const [shiftMarkers, setShiftMarkers] = useState()


  const [{response, isLoading, error}, doFetchData] = useFetch(`/devices/sensors/${mbCountSensor.id}/received_data?from=${startDateFG}&to=${endDateFG}&capacity=one_hour&scalable_by=diff`);
  const [{response: footageResponse, isLoading: footageIsLoadiing, error: errorFootage}, doFetchFootageData] = useFetch(`/devices/sensors/${footageSensor.id}/received_data?from=${startDateFG}&to=${endDateFG}&capacity=one_hour&scalable_by=sum`);
  const [{response: spoolWeightResponse, isLoading: spoolWeight, error: spoolError}, doFetchSpoolWeightData] = useFetch(`/devices/sensors/${spoolWeigthSensor.id}/received_data?from=${startDateFG}&to=${endDateFG}&capacity=one_hour`);


  useEffect(() => {
    if(!endDate) {
      return
    }

    setStartDateFG(startDate.toISO());
    if (startDate && endDate && endDate.toFormat("yyyy/MM/dd") !== DateTime.local().toFormat("yyyy/MM/dd")) {
      setEndDateFG(endDate.endOf('day').toISO());
      setDatesFG([startDate.toISO(), endDate.endOf('day').toISO()])
    } else {
      setEndDateFG(DateTime.local().endOf('day').toISO());
      setDatesFG([startDate.toISO(), DateTime.local().endOf('day').toISO()])
    }

    setLoading(true);
    doFetchData();
    doFetchFootageData();
    doFetchSpoolWeightData();
    
    if (startDate && endDate) {
      setCurrentIntervalType(calculateIntervalType(startDate.ts, endDate.ts));
    } else {
      setCurrentIntervalType(calculateIntervalType(Math.floor((new Date().getTime() / (1000 * 60 * 60 * 24))) * (1000 * 60 * 60 * 24), new Date().getTime()));
    }
  },[endDate])


  useEffect(() => {
    if(!response) {
      return
    }
    setRollsResponse(response)
    buildRolls(DateTime.fromISO(startDateFG).ts, DateTime.fromISO(endDateFG).ts, response.data.attributes.data)
    setLoading(false);
  },[response])

  useEffect(() => {
    if(!footageResponse) {
      return
    }
    setFootageResp(footageResponse)
    buildFootage(footageResponse.data.attributes.data)
  }, [footageResponse])

  useEffect(() => {
    if(!spoolWeightResponse) {
      return
    }
    const groups = spoolWeightResponse.data.attributes.data.reduce((groups, item) => {
      const value = item.v;
      if (value && !groups[value]) {
        groups[value] = [];
      }
      !!value && groups[value].push(item)
      return groups;
    }, {});

    setSpoolList(Object.keys(groups).map((key) => ({name: key, hours: groups[key].map(it => it.at)})))
    setSpoolsListReady(true)
  }, [spoolWeightResponse])

  function handleCahngeSpool(spool) {
    setCurrentSpoolWeight(spool)
    if(spool === null) {
      buildRolls(DateTime.fromISO(startDateFG).ts, DateTime.fromISO(endDateFG).ts, rollsResponse.data.attributes.data)
      buildFootage(footageResp.data.attributes.data)
    } else{
      buildRolls(DateTime.fromISO(startDateFG).ts, DateTime.fromISO(endDateFG).ts, rollsResponse.data.attributes.data.filter(i => spool.hours.includes(i.at)))
      buildFootage(footageResp.data.attributes.data.filter(i => spool.hours.includes(i.at)))
    }
  }

  function buildFootage(data) {
    let footage = 0;
    data.map(it => {
      footage = footage + (it.v ? it.v : 0)
    })
    setFootage(footage)
  }
  
  function buildRolls(sd, ed, data) {
    let total = Number()
    const new_data = []
    calculateMbCount(sd, ed, data)
    .map(it => {
      total = total + (it.v ? it.v : 0)
      new_data.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: it.v,
        click: (e) => it.type ? onClickColumn(e, it.type) : ""
      })
    })
    setTotalRolls(total);
    setChartData(new_data);
  }

  const calculateIntervalType = (min, max) => {
    const interval = Math.floor(max / 1000) - Math.floor(min / 1000);
    if (interval <= 3600) {
      return 'hours';
    }
    if (interval > 3600 && interval <= 172800) {
      return 'hours';
    }
    if (interval > 172800 && interval <= 2678400) {
     return 'days';
    }
    if (interval > 2678400) {
      return 'month';
    }
  };

  function onClickColumn(e, type){
    if(type === 'day') {
      let min = DateTime.fromJSDate(e.dataPoint.x).startOf('day')
      let max = DateTime.fromJSDate(e.dataPoint.x).endOf('day')
      setCurrentIntervalType(calculateIntervalType(min.ts, max.ts));
      setStartDateFG(min.toISO());
      setEndDateFG(max.toISO());
      setDatesFG([min.toISO(), max.toISO()])
      doFetchData();
      doFetchFootageData();
      setZoomed(true)
    } else if(type === 'month') {
      let min = DateTime.fromJSDate(e.dataPoint.x).startOf('month')
      let max = DateTime.fromJSDate(e.dataPoint.x).endOf('month')
      setCurrentIntervalType(calculateIntervalType(min.ts, max.ts));
      setStartDateFG(min.toISO());
      setEndDateFG(max.toISO());
      setDatesFG([min.toISO(), max.toISO()])
      doFetchData();
      doFetchFootageData();
      setZoomed(true)
    }  
  }

  const calculateMbCount = (min, max, mcd) => {
    let data = mcd.filter(i => 'v' in i).map(i => ({at: i.at, v: i.v ? i.v : 0}))

    const count = Interval.fromDateTimes(DateTime.fromMillis(Math.floor(min)), DateTime.fromMillis(Math.floor(max))).count('days')
    if (count === 0) {
      return data.filter(h => DateTime.fromMillis(min).startOf('hour').ts <= h.at && DateTime.fromMillis(max).endOf('hour').ts > h.at)
    }
    if (count > 0 && count <= 3) {
      return data.filter(h => DateTime.fromMillis(min).startOf('hour').ts <= h.at && DateTime.fromMillis(max).endOf('hour').ts > h.at)
    }
    if (count > 3 && count <= 90) {
      const groups = data.reduce((groups, item) => {
        const date = DateTime.fromMillis(item.at).startOf('day');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
  
      const days = Object.keys(groups).map((date) => {
        const sum = groups[date].reduce((accumulator, object) => {
          return accumulator + object.v;
        }, 0);

        return {
          at: DateTime.fromISO(date).ts + 43200000,
          v: sum,
          d: groups[date],
          count: groups[date].length,
          type: "day"
        };
      })
      return days.filter(m => m.at >= DateTime.fromMillis(min).ts && m.at < DateTime.fromMillis(max).ts)
    }
    if (count > 90) {
      const groups = data.reduce((groups, item) => {
        const date = DateTime.fromMillis(item.at).startOf('month');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
  
      const months = Object.keys(groups).map((date) => {
        const sum = groups[date].reduce((accumulator, object) => {
          return accumulator + object.v;
        }, 0);

        return {
          at: DateTime.fromISO(date).ts + 43200000,
          v: sum,
          d: groups[date],
          count: groups[date].length,
          type: "month"
        };
      })
      return months.filter(m => m.at >= startDate.ts && m.at < endDate.ts)
    }
  }

  const baseOptions = {
    animationEnabled: true,
    animationDuration: 2000,
    zoomEnabled: false,
    zoomType: "x",
    height: 200,
    culture: DateTime.local().locale,
    axisX:{
      lineColor: '#E7ECF8',
      intervalType: currentIntervalType,
      valueFormatString: currentIntervalType === 'minutes' ? 'HH:mm' : currentIntervalType === 'hours' ? 'HH:mm' : currentIntervalType === 'days' ? 'DD MMM' : 'MMM YYYY',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      labelPlacement:"inside"
    }
  };

  const options = {
    height: 160,
    data: [{
      yValueFormatString: "####0.##",
      indexLabel: "{y}",
      indexLabelFontFamily: "helvetica, arial",
      indexLabelFontColor: "#023436",
      xValueFormatString: currentIntervalType === 'minutes' ? 'HH:mm:ss' : currentIntervalType === 'hours' ? 'HH:mm' : currentIntervalType === 'days' ? 'DD MMM' : 'MMM YYYY',
      type: "column",
      color: '#00BFB3',
      fillOpacity: 1,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartData
    }]
  };

  function handleReset() {
    setStartDateFG(startDate.toISO());
    setEndDateFG(endDate.toISO());
    setDatesFG([startDate.toISO(), endDate.toISO()])
    setCurrentIntervalType(calculateIntervalType(startDate.ts, endDate.ts));
    doFetchData();
    doFetchFootageData();
    setZoomed(false)
  }


  return (
    <>
      <div className="flex items-center h-6 px-2 text-xs uppercase justify-end text-gray-500 border-b">
        <span>{t('ce_rolls')}</span>
      </div>
      <div className='flex h-full min-h-10 w-full items-top justify-between mb-4 px-2 py-2 border-b'>
        <div className='flex flex-row items-center'>
          <span className='block w-24 whitespace-nowrap'>{t('mb_lenf')}:</span>
          <div className='flex flex-wrap flex-row gap-2 items-center px-6'>
            
            <button type='button' className={`text-xs font-medium px-2 rounded py-1 ${!currentSpoolWeight ? "bg-blue-600 text-white" : "bg-gray-300 text-gray-800"}`}  onClick={() => handleCahngeSpool(null)}>{t('all')}</button>
            {
              spoolsListReady && spoolsList.map((it, idx) => (
                <button key={idx} type='button' className={`text-xs font-medium px-2 rounded py-1 ${currentSpoolWeight && currentSpoolWeight.name === it.name ? "bg-blue-600 text-white" : "bg-gray-300 text-gray-800"}`} onClick={() => handleCahngeSpool(it)}>{it.name}</button>
              ))
            }
          </div> 
        </div>
        {zoomed && <button className="block w-24 max-h-6 text-xs font-medium px-2 rounded py-1 bg-gray-300 text-gray-800" onClick={() => handleReset()}>{t('reset')}</button>}
      </div>
      <CanvasJSChart options = {{...baseOptions, ...options}} onRef={ref => setChartRef(ref)}/>
      <div className='flex flex-col py-2 font-normal'>
        <div key={'total_rolls_by_period'} className='grid grid-cols-2 h-8 items-center border-t px-2'>
          <span className='font-medium'>{t('total_rolls_by_period')}</span>
          <div className='text-right'><span className='font-medium'>{totalRolls}</span></div>
        </div>
        <div key={'total_footage_by_period'} className='grid grid-cols-2 h-8 items-center border-t px-2'>
          <span className='font-medium'>{t('total_footage_by_period')}</span>
          <div className='text-right'><span className='font-medium'>{footage}</span> {t('meter')}</div>
        </div>
        <WeightsWidget mbBruttoSensors={mbBruttoSensors} mbNettoSensors={mbNettoSensors} dates={datesFG} currentSpool={currentSpoolWeight}/>
      </div>
    </>
  );
}

export default CeCharts;