import { useEffect, useContext, useState } from "react";
import { ScreenContext } from "../../contexts/screenContext";
import Logo from "../../components/logo";
import LazyLoad from 'react-lazyload';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Spinner from "../../components/loader";
import {DateTime} from 'luxon';
import ScreenEquipmentData from "./item";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";




// import Swiper core and required modules
import SwiperCore, {
  Autoplay
} from 'swiper/core';
import useFetch from "../../hooks/use-fetch";

// install Swiper modules
SwiperCore.use([Autoplay]);


const ScreenPage = (props) => {
  const {screen, shifts, screenAvailable, screenIsLoading, currentShift, setScreenWidth, setScreenHeight, equipments, equipmentCount, updateDateTime, setUpdateDateTime, screenData, updateEquipmentData, detectCurrentShift} = useContext(ScreenContext)
  const {width, height} = useWindowDimensions()
  const [currentDateTime, setCurrentDateTime] = useState(DateTime.local())
  const chunkSize = 10;
  const [eqSections, setEqSections] = useState([])
  const [{response: updatedScreenData, isLoading: dataLoading, error: dataError}, doFetchData] = useFetch(`/screens/${screen.attributes.slug}/data?from=${currentDateTime.minus({minute: 2}).set({second: 0, millisecond: 0})}&to=${currentDateTime.minus({minute: 1}).set({second: 0, millisecond: 0})}`);


  SwiperCore.use([Autoplay])

  useEffect(() => {
    if(width && height) {
      setScreenWidth(width - 82)
      setScreenHeight(height)
    } else {
      return
    }
  },[width, height])

  useEffect(() => {
    if(!equipments || equipments.length === 0) {return}

    let chuncks = []
    for (let i = 0; i < equipments.length; i += chunkSize) {
      chuncks.push(equipments.slice(i, i + chunkSize))
    }
    setEqSections(chuncks)
  }, [equipments])

  useEffect(() => {
    if(currentDateTime > updateDateTime) {
      doFetchData()
      setUpdateDateTime(currentDateTime.plus({minute: 1}))
    }
    detectCurrentShift(shifts)
    const interval = setInterval(() => {
      setCurrentDateTime(DateTime.local());
    }, 1000);

    return () => clearInterval(interval);
  }, [currentDateTime]);

  useEffect(() => {
    if(!updatedScreenData) {return}
    if(equipments.length === 0) {window.location.reload(false)}
    if(equipments.length !== Object.keys(updatedScreenData.data.attributes.data).length) {window.location.reload(true)}
    equipments.map(eq => {
      let oldEqData = screenData[eq.id]
      let eqNewData = updatedScreenData.data.attributes.data[eq.id]
      let updatedTimeline = []
      if(oldEqData.timeline[oldEqData.timeline.length - 1]?.state === eqNewData.timeline[0]?.state) {
        let beforeT = [...oldEqData.timeline]
        let afterT = eqNewData.timeline.length === 1 ? [] : eqNewData.timeline.slice(0)
        beforeT[oldEqData.timeline.length - 1] = {
          state: oldEqData.timeline[oldEqData.timeline.length - 1].state,
          at: oldEqData.timeline[oldEqData.timeline.length - 1].at,
          seconds: oldEqData.timeline[oldEqData.timeline.length - 1].seconds + eqNewData.timeline[0].seconds
        }
        updatedTimeline = [...beforeT, ...afterT]
      } else {
        updatedTimeline = [...oldEqData.timeline, ...eqNewData.timeline]
      }

      // let updatedTimeline = [...oldEqData.timeline, ...eqNewData.timeline]
      updateEquipmentData(eq.id, {timeline: updatedTimeline, current_personnel: eqNewData.current_personnel})
      console.log(eq.id, "UPDATED", updatedTimeline) 
    })
  }, [updatedScreenData])

  function mergeTimelines(ot, nt) {
    return ot.map(oti => ({...oti, ...nt.find(nti => nti.at === oti.at)}))
  }

  if(screenAvailable === false && screenIsLoading === false) return <div className="w-full h-full flex items-center justify-center">Экрана не существует</div>
  
  return (
    <section className="w-screen h-screen flex flex-col">
      <div className="h-12 flex-none flex justify-between pl-6 pr-3 items-center">
        <div className="font-semibold">{currentDateTime.minus({minute: 1}).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>
        {/* {screenWidth} x {screenHeight} */}
        <Logo />
      </div>
      <div className="w-full flex justify-between px-6 h-8">
        <span className="text-xl font-bold text-gray-800">{currentShift && currentShift.label}</span>
        <span className="text-xl font-semibold text-gray-600">{screen ? screen.attributes.name : "Без названия"}</span>
      </div>
      <div className="flex-1">
      {equipments && eqSections && eqSections.length > 0 && equipmentCount > 0 && 
        <Swiper 
          spaceBetween={30} 
          centeredSlides={true} 
          autoplay={{
            "delay": 30000,
            "disableOnInteraction": false
          }} 
          className="mySwiper"
        >
          {eqSections.map((section, idx) => (
            <SwiperSlide key={idx} className="px-6 h-full">
              {section.map(equipment => (
                <LazyLoad key={equipment.id} height={(height - 100) / chunkSize} offset={10} once>
                  <div className={`flex flex-col border-b`} style={{height: (height - 100) / chunkSize}}>
                    <ScreenEquipmentData equipment={equipment} />
                  </div>
                </LazyLoad>
              ))}
            </SwiperSlide>
          ))}
        </Swiper>
      }
      </div>

    </section>
  );
};

export default ScreenPage;