import React, { useState, useEffect, useContext } from 'react';
import TextField from '@atlaskit/textfield';
import Form, { Field, CheckboxField, ErrorMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from "../../../../../contexts/current-user";
import useFetch from '../../../../../hooks/use-fetch';
import StatusForm from './changes/status';
import DivisionForm from './changes/division';
import MoveStatusForm from './changes/move_status';
import ResponsibleForm from './changes/responsible';
import GroupForm from './changes/group';
import { equipmentStatuses, moveStatuses } from '../../../../../enums';


const EquipmentSettings = ({ currentItem, personsList, producersList, modelsList, producerId, setProducerId, onUpdate, onChangeItem }) => {
  const {t} = useTranslation();
  const [currentUserState, ] = useContext(CurrentUserContext);
  const today = new Date().toISOString().slice(0, 10);
  const [currentFileField, setCurrentFileField] = useState({});
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [passport, setPassport] = useState({});
  const [certificate, setCertificate] = useState({});
  const [parent, setParent] = useState(null)
  const [{response: fileUploadResponse, isLoading: fileUploadIsLoading, error: fileUploadError}, doFetchFileUpload] = useFetch(`/uploads`, true);

  const [{response: equipmentItemResponse, isLoading: equipmentItemIsLoading, error: equipmentItemError}, doFetchEquipmentItem] = useFetch(`/directories/equipment_items/${currentItem.attributes.parent_id}`);

  const statuses = equipmentStatuses.map(status => ({label: t(status), value: status}))
  const statusesMove = moveStatuses.map(status => ({label: t(`move_${status}`), value: status}))

  useEffect(() => {
    setPassport(currentItem.attributes.passport)
    setCertificate(currentItem.attributes.certificate)
  },[])

  useEffect(() => {
    if (!fileUploadResponse) {
      return;
    }
    if (currentFileField === 'passport') {
      setPassport({ ...fileUploadResponse });
    }
    if (currentFileField === 'certificate') {
      setCertificate({ ...fileUploadResponse });
    }
  }, [fileUploadResponse, fileUploadIsLoading]);

  useEffect(() => {
    if(!currentItem.attributes.parent_id) {
      return
    }
    doFetchEquipmentItem()
  }, [currentItem.attributes.parent_id])

  useEffect(() => {
    if(!equipmentItemResponse) {
      return
    }
    setParent(equipmentItemResponse.data.attributes.name)
  }, [equipmentItemResponse])

  const handleFileField = (type, file) => {
    setCurrentFileField(type);
    const formData = new FormData();
    formData.set('attachment', file);
    doFetchFileUpload({
      method: 'POST',
      data: formData
    });
  };

  const handleUpdate = (formData) => {    
    onUpdate(
      {
        name: formData.name,
        factory_number: formData.factory_number,
        inventory_number: formData.inventory_number,
        equipment_model_id: formData.model.value,
        balance_date: formData.balance_date,
        release_date: formData.release_date,
        service_interval: formData.service_interval,
        producer_id: formData.producer.value,
        qrcode: formData.qrcode,
        stop_threshold: formData.stop_threshold,
        certificate: certificate,
        passport: passport,
      }
    )
  }

  function closeModal() {
    setModalType("")
    setOpen(false);
  };

  function openModal(type) {
    setModalType(type)
    setOpen(true)
  };

  function getModalContent(type) {
    switch (type) {
      case "status":
        return <StatusForm open={open} currentItem={currentItem} onChangeItem={onChangeItem} closeModal={closeModal} statuses={statuses}/>
      case "move_status":
        return <MoveStatusForm open={open} currentItem={currentItem} onChangeItem={onChangeItem} closeModal={closeModal} statuses={statusesMove}/>
      case "responsible":
        return <ResponsibleForm open={open} currentItem={currentItem} onChangeItem={onChangeItem} closeModal={closeModal} persons={personsList}/>
      case "division":
        return <DivisionForm open={open} currentItem={currentItem} onChangeItem={onChangeItem} closeModal={closeModal}/>
      case "group":
        return <GroupForm open={open} currentItem={currentItem} onChangeItem={onChangeItem} closeModal={closeModal}/>
      default:
        break;
    }
  } 

  return (
    <div className="mt-4">
      <Form onSubmit={data => handleUpdate(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} >
            <div className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1">
              <Field name="name" defaultValue={currentItem.attributes.name || ""} label={t('equipment_name')} isRequired>
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4'>
                <Field name="inventory_number" defaultValue={currentItem.attributes.inventory_number || ""} label={t('equipment_inventory_number')} isRequired>
                  {({ fieldProps }) => <TextField {...fieldProps} />}
                </Field>
                <Field name="factory_number" defaultValue={currentItem.attributes.factory_number || ""} label={t('equipment_factory_number')} isRequired>
                  {({ fieldProps }) => <TextField {...fieldProps} />}
                </Field>
              </div>
              <Field name="producer" defaultValue={producersList.find(it => it.value == producerId) || ""} label={t('equipment_producer')} isRequired>
                {({ fieldProps }) => <Select {...fieldProps} className="text-sm" options={producersList} onChange={(e) => setProducerId(e.value)} />}
              </Field>
              <Field name="model" defaultValue={modelsList.find(it => it.value == currentItem.attributes.equipment_model_id) || ""} label={t('equipment_model')} isRequired>
                {({ fieldProps }) => <Select {...fieldProps} className="text-sm" options={modelsList}/>}
              </Field>
              <div className="flex flex-col mt-2">
                <label className='text-xs font-semibold text-gray-500 mb-2'>{t('condition_status')}</label>
                <div className='flex h-8 justify-between items-center'>
                  <div>{currentItem.attributes.status ? statuses.find(it => it.value == currentItem.attributes.status).label : t('no_condition_status')}</div>
                  <button type='button' className='rounded-sm bg-gray-100 px-4 text-gray-600 h-full font-medium' onClick={() => openModal("status")}>Изменить</button>      
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <label className='text-xs font-semibold text-gray-500 mb-2'>{t('division')}</label>
                <div className='flex h-8 justify-between items-center'>
                  <div>{currentItem.attributes.division_name ? currentItem.attributes.division_name : t('no_division')}</div>
                  <button type='button' className='rounded-sm bg-gray-100 px-4 text-gray-600 h-full font-medium' onClick={() => openModal("division")}>Изменить</button>  
                </div>
              </div>
              {currentUserState.applications.tracking ? <></> :
                <>
                  <div className="flex flex-col mt-2">
                    <label className='text-xs font-semibold text-gray-500 mb-2'>{t('move_status')}</label>
                    <div className='flex h-8 justify-between items-center'>
                      <div>{currentItem.attributes.move_status ? t(`move_${currentItem.attributes.move_status}`) : t('no_move_status')}</div>
                      <button type='button' className='rounded-sm bg-gray-100 px-4 text-gray-600 h-full font-medium' onClick={() => openModal("move_status")}>Изменить</button>  
                    </div>
                  </div>
                  <div className="flex flex-col mt-2">
                    <label className='text-xs font-semibold text-gray-500 mb-2'>{t('responsible')}</label>
                    <div className='flex h-8 justify-between items-center'>
                      <div>{currentItem.attributes.responsible_name ? currentItem.attributes.responsible_name : t('no_responsible')}</div>
                      <button type='button' className='rounded-sm bg-gray-100 px-4 text-gray-600 h-full font-medium' onClick={() => openModal("responsible")}>Изменить</button>  
                    </div>
                  </div>
                </>
              }
              <Field name="stop_threshold" defaultValue={currentItem.attributes.stop_threshold} label={t('stop_threshold')}>
                {({ fieldProps }) => <TextField type='number' {...fieldProps} />}
              </Field>
              <div className="flex flex-col mt-2">
                <label className='text-xs font-semibold text-gray-500 mb-2'>{t('in_parent_equipment')}</label>
                <div className='flex h-8 justify-between items-center'>
                  <div>{parent ? t(parent) : t('no_related_equipment')}</div>
                  <button type='button' className='rounded-sm bg-gray-100 px-4 text-gray-600 h-full font-medium' onClick={() => openModal("group")}>Изменить</button>  
                </div>
              </div>
              {/* <div className='grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4'> */}
                <Field name="balance_date" defaultValue={currentItem.attributes.balance_date || ""} label={t('balance_date')}>
                  {({ fieldProps }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={today}/>}
                </Field>
                <Field name="release_date" defaultValue={currentItem.attributes.release_date || ""} label={t('release_date')}>
                  {({ fieldProps }) => <DatePicker {...fieldProps} locale={t('locale_for_time')} weekStartDay={1} placeholder={today}/>}
                </Field>
              {/* </div> */}
              <div className="field mt-4">
                <div className="relative">
                  <div className="flex flex-row justify-between items-center h-10">
                    {passport.data ? 
                      <a className="text-sm text-blue-600 whitespace-nowrap truncate font-medium flex z-10" href={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${passport.data.id}`} target="_blank">{passport.data.attributes.filename}</a> : 
                      <div className="text-sm text-gray-600 whitespace-nowrap truncate font-medium">{t('upload_passport')}</div>
                    }
                    <div className="bg-gray-100 rounded text-sm text-gray-600 px-4 py-2">
                      <span className="font-medium">{t('upload')}</span>
                    </div>
                  </div>
                  <input
                    name="passport"
                    type="file"
                    className="absolute cursor-pointer opacity-0 block w-full h-10 top-0"
                    onChange={(evt) => handleFileField('passport', evt.target.files[0])}
                  />
                </div>
              </div>
              <div className="field mt-4">
                <div className="relative">
                  <div className="flex flex-row justify-between items-center h-10">
                    {certificate.data ? 
                      <a className="text-sm text-blue-600 whitespace-nowrap truncate font-medium flex z-10" href={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${certificate.data.id}`} target="_blank">{certificate.data.attributes.filename}</a> : 
                      <div className="text-sm text-gray-600 whitespace-nowrap truncate font-medium">{t('upload_certificate')}</div>
                    }
                    <div className="bg-gray-100 rounded text-sm text-gray-600 px-4 py-2">
                      <span className="font-medium">{t('upload')}</span>
                    </div>
                  </div>
                  <input
                    name="certificate"
                    type="file"
                    className="absolute cursor-pointer opacity-0 block w-full h-10 top-0"
                    onChange={(evt) => handleFileField('certificate', evt.target.files[0])}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-8">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("update")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
      {open && getModalContent(modalType)}
    </div>
  )
};

export default EquipmentSettings;
