import LazyLoad from 'react-lazyload';
import { useHistory } from "react-router-dom";


import TimelineData from './timeline';

const TimelineItem = ({equipment, selectedDate, currentShift, timeMarkers}) => {
  let history = useHistory();

  const onItemClick = (id) => {
    history.push({
      pathname: `/report/equipment/${id}`,
      search: `?date=${selectedDate.ts}`
    });
  };

  return (
    <LazyLoad height={100} offset={10} once>
      <div className={`flex flex-col border border-gray-100 rounded hover:bg-blue-50 hover:shadow-md cursor-pointer ${equipment.attributes.depth === 0 ? "": "bg-gray-100"}`} onClick={()=>onItemClick(equipment.id)}>
        <div className="flex items-center px-4 py-2 mt-2">
          <div className="mr-4">
            {equipment.attributes.depth === 0 ? (
              <svg className="w-4 h-4" width="16" height="14" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0117 11.5V4.01172H1.98828V11.5H14.0117ZM14.0117 2.5C14.4102 2.5 14.75 2.65234 15.0312 2.95703C15.3359 3.26172 15.4883 3.61328 15.4883 4.01172V11.5C15.4883 11.8984 15.3359 12.25 15.0312 12.5547C14.75 12.8594 14.4102 13.0117 14.0117 13.0117H1.98828C1.58984 13.0117 1.23828 12.8594 0.933594 12.5547C0.652344 12.25 0.511719 11.8984 0.511719 11.5V2.5C0.511719 2.10156 0.652344 1.75 0.933594 1.44531C1.23828 1.14062 1.58984 0.988281 1.98828 0.988281H6.48828L8 2.5H14.0117Z"/>
              </svg>
            ) : (
              <svg className="w-4 h-4" width="11" height="12" viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6562 8L6.65625 12L5.71875 11.0625L8.125 8.65625H0.65625V0.65625H2V7.34375H8.125L5.71875 4.9375L6.65625 4L10.6562 8Z"/>
              </svg>
            )}
          </div>
          <div className="text-sm font-medium text-gray-500 truncate">{equipment.attributes.name}</div>
        </div>
        <TimelineData equipment={equipment} selectedDate={selectedDate} currentShift={currentShift}/>
      </div>
    </LazyLoad>
  )
}

export default TimelineItem;