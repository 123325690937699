import React, { useEffect, useState, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from './index';
import { ScreenProvider } from '../contexts/screenContext';
import ScreenPage from '../pages/screen';
import CurrentUserChecker from '../utils/current-user-checker';
import CurrentSiteChecker from '../utils/current-site-checker';

const ScreenRouter = ({...rest}) => {
  return (
    <Route {...rest} render={props => (
      isAuthenticated() ? 
        <CurrentUserChecker>
          <div className="app h-screen" >
            <ScreenProvider auth={true} {...props}>
              <ScreenPage/>
            </ScreenProvider> 
          </div>
        </CurrentUserChecker>
        : 
        <ScreenProvider auth={false} {...props}>
          <ScreenPage/>
        </ScreenProvider> 
    )} />
  );
};

export default ScreenRouter;