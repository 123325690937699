const diameters = [
  {label: "0.4", value: 0.4, w: 0.001},
  {label: "0.45", value: 0.45, w: 0.001},
  {label: "0.5", value: 0.5, w: 0.002},
  {label: "0.6", value: 0.6, w: 0.002},
  {label: "0.65", value: 0.65, w: 0.003},
  {label: "0.7", value: 0.7, w: 0.003},
  {label: "0.75", value: 0.75, w: 0.003},
  {label: "0.8", value: 0.8, w: 0.004},
  {label: "0.85", value: 0.85, w: 0.004},
  {label: "0.9", value: 0.9, w: 0.005},
  {label: "0.95", value: 0.95, w: 0.006},
  {label: "1", value: 1, w: 0.006},
  {label: "1.1", value: 1.1, w: 0.007},
  {label: "1.2", value: 1.2, w: 0.009},
  {label: "1.3", value: 1.3, w: 0.01},
  {label: "1.4", value: 1.4, w: 0.012},
  {label: "1.5", value: 1.5, w: 0.014},
  {label: "1.6", value: 1.6, w: 0.016},
  {label: "1.7", value: 1.7, w: 0.018},
  {label: "1.8", value: 1.8, w: 0.02},
  {label: "1.9", value: 1.9, w: 0.022},
  {label: "2", value: 2, w: 0.025},
  {label: "2.1", value: 2.1, w: 0.027},
  {label: "2.2", value: 2.2, w: 0.03},
  {label: "2.3", value: 2.3, w: 0.033},
  {label: "2.4", value: 2.4, w: 0.036},
  {label: "2.5", value: 2.5, w: 0.039},
  {label: "2.6", value: 2.6, w: 0.042},
  {label: "2.7", value: 2.7, w: 0.045},
  {label: "2.8", value: 2.8, w: 0.048},
  {label: "2.9", value: 2.9, w: 0.051},
  {label: "3", value: 3, w: 0.055},
]

export default diameters;