import React, {useEffect, useRef, useState, useContext} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {useTranslation} from 'react-i18next';

import Layout from '../../../../components/layout';
import useFetch from '../../../../hooks/use-fetch';
import Spinner from '../../../../components/loader';

import ManufactureSettings from './settings';
import Models from './models';

const ManufactureEdit = (props) => {
  const {t} = useTranslation();
  const [itemId, ] = useState(props.match.params.id);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState('');
  const [itemModels, setItemModels] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [countries, setCountries] = useState([]);

  const [{response, isLoading, error}, doFetchItem] = useFetch(`/directories/producers/${itemId}`);
  const [{response: itemUpdateResponse, isLoading: itemUpdateIsLoading, error: itemUpdateError}, doFetchItemUpdate] = useFetch(`/directories/producers/${itemId}`);

  const [{response: responseModels, isLoading: isLoadingModels, error: errorModels}, doFetchModels] = useFetch(`/directories/equipment_models?producer_id=${itemId}`);

  const [{response: countryListResponse, isLoading: countryListIsLoading, error: countryListError}, doFetchCountryList] = useFetch(`/directories/countries`);
  const [{response: equipmentTypesResponse, isLoading: equipmentTypesIsLoading, error: equipmentTypesError}, doFetchEquipmentList] = useFetch(`/directories/equipment_types`);


  useEffect(() => {
    setLoading(true);
    doFetchItem();
    doFetchEquipmentList();
    doFetchCountryList();
    doFetchModels();
  },[])

  useEffect(() => {
    if(!response) {
      return;
    } 
    setCurrentItem(response.data);
  },[response]);

  useEffect(() => {
    if(!equipmentTypesResponse) {
      return;
    }
    setEquipmentTypes(equipmentTypesResponse.data)
  },[equipmentTypesResponse]);

  useEffect(() => {
    if(!responseModels) {
      return;
    } 
    setItemModels(responseModels.data);
  },[responseModels]);

  useEffect(() => {
    if(!response || !responseModels || !equipmentTypesResponse) {
      return;
    } 
    setLoading(false);
  },[response, responseModels, equipmentTypesResponse]);
  
  useEffect(() => {
    if(!countryListResponse) {
      return
    }
    const countriesOptions =[]

    countryListResponse.data.map(country => {
      countriesOptions.push({label: country.attributes.name, value: country.id})
    })
    setCountries(countriesOptions);
  },[countryListResponse])

  useEffect(() => {
    if (!itemUpdateResponse) {
      return;
    }
    setCurrentItem(itemUpdateResponse.data);
  }, [itemUpdateResponse]);

  const onUpdate = (data) => {
    doFetchItemUpdate({
      method: 'PUT',
      data: {
        data: data
      }
    });
  };

  const reloadModels = () => {
    doFetchModels();
  };

  const headerData = {
    title: currentItem?.attributes?.name ? `${currentItem?.attributes?.name}` : "..."
  };

  return (
    <Layout 
      headerData={headerData}
      isWide={true}
    >
      {loading ? <Spinner/> : (
        <Tabs
          className="text-gray-800 font-medium text-sm mt-4"
          id="default"
        >
          <TabList className="flex items-center space-x-4 border-b-2 border-gray-200">
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('models')}
            </Tab>
            <Tab className="px-2 py-2 -border-b-2 border-gray-200 cursor-pointer" selectedClassName="text-blue-600 border-blue-600">
              {t('settings')}
            </Tab>
          </TabList>
          <TabPanel>
            <Models 
              itemModels = {itemModels}
              equipmentTypes = {equipmentTypes}
              producer = {currentItem.id}
              reloadTable = {reloadModels}
            />
          </TabPanel>
          <TabPanel>
            <ManufactureSettings 
              currentItem = {currentItem}
              countries = {countries}
              onUpdate = {onUpdate}
            />
          </TabPanel>
        </Tabs>
      )}
    </Layout>
  );
};

export default ManufactureEdit;
