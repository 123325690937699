import { useEffect, useState } from "react";
import {DateTime} from 'luxon';
import { useTranslation } from "react-i18next";
import useFetch from "../../../hooks/use-fetch";
import { covertTimeToSeconds, convertTimeHHMM } from "../../../utils";
import { getMedianGraph } from "./algo"
import Form, { Field } from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Select from '@atlaskit/select';


const AlgorithmIdleForCycle = ({sensor, startDate, endDate, shift}) => {
  const {t} = useTranslation();
  const [tail, setTail] = useState(15)
  const threshold = sensor.attributes.idle_level 
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [result, setResult] = useState({idle: 0, work: 0});
  const [startDateString, setStartDateString] = useState('')
  const [endDateString, setEndDateString] = useState('')
  
  const [{response, isLoading, error}, doFetchData] = useFetch(`/devices/sensors/${sensor.id}/received_data?from=${startDateString}&to=${endDateString}&capacity=raw`);

  useEffect(() => {
    if(!endDate) {
      return
    }
    
    const startTime = covertTimeToSeconds(shift.start) * 1000
    const endTime = covertTimeToSeconds(shift.end) * 1000


    const start = DateTime.fromMillis(startDate.startOf('day').toMillis() + startTime)
    const end = startTime < endTime ? DateTime.fromMillis(endDate.startOf('day').toMillis() + endTime) : DateTime.fromMillis(endDate.endOf('day').toMillis() + 1000 + endTime);

    setStartDateString(start)
    setEndDateString(end)
  },[startDate, endDate, shift])

  useEffect(() => {
    if(!response) {
      return
    }
    setData(response.data)
    setResult(getMedianGraph(response.data, tail, threshold))
    setLoading(false);
  },[response])

  function onComputedClick(params) {
    setTail(params.tail)
    setLoading(true);
    doFetchData();
  }

  return (
    <div className="p-4 border rounded bg-white max-w-7xl mx-auto">
      <div className="mb-4">Расчет простоя с учетом циклов за период: {startDate.toFormat("dd.MM.yyyy")} - { endDate ? endDate.toFormat("dd.MM.yyyy") : ""} </div>
      {loading ? 
      <div className="flex items-center justify-center w-full h-24">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div> : 
      <>
        <div className="grid grid-cols-3 gap-x-8 items-center text-gray-600 mb-4">
          <Form onSubmit={params => onComputedClick(params)}>
            {({ formProps }) => (
              <form {...formProps} className="text-sm grid grid-cols-2 items-end gap-x-4 w-full">
                <Field name="tail" defaultValue={tail} label={t('cycle_tail')} isRequired>
                  {({ fieldProps }) => 
                    <Textfield
                      placeholder={t('cycle_tail')}
                      {...fieldProps}
                    />
                  }
                </Field>
                <button 
                    type="submit" 
                    className="inline-flex justify-center px-4 py-2 h-10 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    {t('calculate')}
                </button>
              </form>
            )}
          </Form>
          <div className="flex flex-col items-center"><span className="text-xs">Простой:</span><span className="h-10 text-xl pt-4 font-medium">{result.idle ? convertTimeHHMM(result.idle) : "..."}</span></div>
          <div className="flex flex-col items-center"><span className="text-xs">Работа:</span><span className="h-10 text-xl pt-4 font-medium">{result.work ? convertTimeHHMM(result.work) : "..."}</span></div>
        </div>
      </>
      }
      
    </div>

  )
}

export default AlgorithmIdleForCycle;