import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartPieIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';

import Spinner from "../../../components/loader";
import { getPerfectDate } from "../../../utils";
import Table from '../../../components/form/table';


const StaffTable = ({ onItemEdit, onItemDelete, onItemReport, isLoading, error, tableData }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        first_name: tableItem.attributes.first_name,
        middle_name: tableItem.attributes.middle_name,
        last_name: tableItem.attributes.last_name,
        access_token: tableItem.attributes.access_token_id,
        division_name: tableItem.attributes.division_name,
        post_name: tableItem.attributes.post_name,
        leader: tableItem.attributes.leader,
        status: tableItem.attributes.status,
      }
    )
  });

  const columns = [
    {
      label: t('initials'),
      prop: "initials",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {row.last_name} {row.first_name} {row.middle_name}
            </div>
            <div className="font-medium text-gray-500 truncate">
              {row.post_name != null ? row.post_name : ""} 
            </div>
          </div>
        )
      }
    },
    {
      label: t('division'),
      prop: "division_name",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {row.division_name}
            </div>
            <div className="font-medium text-gray-500 truncate">
              {row.leader ? t('division_leader') : ""} 
            </div>
          </div>
        )
      }
    },
    {
      label: t('status'),
      prop: "status",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => {
        switch (row.status) {
          case 'accepted':
            return (
              <div className="inline-flex font-medium text-xs truncate bg-green-600 text-white px-1 py-1 rounded">{t('staff_accepted')}</div>
            )
          case 'transferred':
            return (
              <div className="inline-flex font-medium text-xs truncate bg-blue-600 text-white px-1 py-1 rounded">{t('staff_transferred')}</div>
            )
          case 'vacation':
            return (
              <div className="inline-flex font-medium text-xs truncate bg-yellow-200 text-yellow-700 px-1 py-1 rounded">{t('staff_vacation')}</div>
            )
          case 'fired':
            return (
              <div className="inline-flex font-medium text-xs truncate bg-red-200 text-red-700 px-1 py-1 rounded">{t('staff_fired')}</div>
            )
          default:
            break;
        }
      }
    },
    {
      label: t('access_token'),
      prop: "access_token",
      labelClassName: "w-full lg:w-1/12",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: (row) => {
        return (
          <div>
            <div className="font-medium text-gray-900 truncate">
              {row.access_token}
            </div>
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "invisible w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            {row.access_token ? <button 
              onClick={() => onItemReport(row.id)} 
              className="text-green-600 hover:text-green-900 cursor-pointer w-8 h-8 bg-green-100 hover:bg-green-200 flex items-center justify-center rounded ">
              <ChartPieIcon className="w-4 h-4"/>
            </button> : "" }
            <button 
              onClick={() => onItemEdit(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    },
  ]


  return (
    <Table
      border={true}
      columns={columns}
      data={items}
    />
  )
};

export default StaffTable;
