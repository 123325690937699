import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import useFetch from '../../../hooks/use-fetch';
import PageFilter from '../../../components/page_filter';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import Filters from '../../../components/filters';

import AddDivision from './add';
import DivisionsTable from './table';

const Divisions = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [itemId, setItemId] = useState('new');
  const [parentId, setParentId] = useState("");
  const [pageSearch, setPageSearch] = useState('');
  const [params, setParams] = useState(new URLSearchParams({}))

  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  const [{response, isLoading, error}, doFetchTableData] = useFetch(`/directories/divisions?${params.toString()}`);
  const [{response: itemDeleteResponse, isLoading: itemDeleteIsLoading, error: itemDeleteError}, doFetchItemDelete] = useFetch(`/directories/divisions/${itemId}`);


  useEffect(() => {
    if (itemDeleteIsLoading) {
      return;
    }
    setItemId('new');
    reloadTable();
  }, [itemDeleteIsLoading]);

  useEffect(() => {
    if (!response) {
      return
    }
    setTableData(response.data);
    setLoading(false);
  }, [response]);

  const onChildAdd = (id) => {
    setItemId('new');
    setParentId(id);
    openModal();
  }

  const onItemEdit = (id) => {
    history.push({
      pathname: `/settings/divisions/${id}`,
    });
  };

  const onItemDelete = (id) => {
    setItemId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  };

  const headerData = {
    title: t('divisions'),
    buttonsList: [
      {
        text: t('create_division'),
        action: () => openModal()
      }
    ],
  };

  const filtersData = {
    search: {
      placeholder: 'Поиск',
      value: pageSearch,
      onChange: setPageSearch
    }
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    pageSearch ? newParams.append('search', pageSearch) : newParams.delete('search')

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [pageSearch])

  function closeModal() {
    setItemId('new');
    setParentId("");
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    doFetchTableData();
  };

  return (
    <Layout
      headerData={headerData}
      isWide={true}
    >
      
      <>
        <div className='my-6'>
          <Filters filtersData={filtersData}/>
        </div>
        {loading ? <Spinner/> :
          <DivisionsTable 
            tableData = {tableData}
            onChildAdd={onChildAdd}
            onItemEdit={onItemEdit}
            onItemDelete={onItemDelete}
            isLoading={isLoading}
          />
        }
        <AddDivision closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} tableData={tableData} parentId={parentId}/>
      </>
      
    </Layout>
  );

}

export default Divisions;