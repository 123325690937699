import React, { useEffect, useState } from 'react';
import {DateTime} from 'luxon';
import { useTranslation } from 'react-i18next';
import CanvasJSReact from '../../../../assets/canvasjs.react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { convertTimezone } from '../../../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartACPower = ({loading, data, powerTotals, currentIntervalType, reset}) =>  {
  const [chartRef, setChartRef] = useState([])
  const {t} = useTranslation();
  const {t: tw} = useTranslation('widgets');

  useEffect(() => {
    if(!reset) {
      return
    }
    chartRef.axisX[0].set("viewportMinimum", null, false);
    chartRef.axisX[0].set("viewportMaximum", null);

  }, [reset])

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: false,
    height: 200,
    culture: DateTime.local().locale,
    axisX:{
      // stripLines: shiftMarkers,
      lineColor: '#E7ECF8',
      // intervalType: 'hour',
      // valueFormatString: 'HH:mm:ss',
      intervalType: currentIntervalType,
      valueFormatString: currentIntervalType === 'minutes' ? 'HH:mm:ss' : currentIntervalType === 'hours' ? 'HH:mm' : currentIntervalType === 'days' ? 'DD MMM' : 'MMM YYYY',
      tickLength: 10,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      // labelFormatter: function (e) {

      //   console.log(e);
      //   if (e.axis.intervalType === 'year' || e.axis.intervalType === 'month'|| e.axis.intervalType === 'days'){
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "MM.DD.YY");
      //   } else {
      //     return CanvasJSReact.CanvasJS.formatDate(e.value, "HH:mm:ss");
      //   }


      // },
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      tickLength: 8,
      tickColor: 'transparent',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      labelPlacement:"inside"
    }
  };

  const options = {
    height: 160,
    data: [{
      yValueFormatString: "####0.##",
      indexLabel: "{y}",
      indexLabelFontFamily: "helvetica, arial",
      indexLabelFontColor: "#545863",
      indexLabelPlacement: "inside",
      xValueFormatString: "HH:mm",
      type: "stackedColumn",
      color: '#F96E46',
      fillOpacity: 1,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: data.worked
    },
    {
      yValueFormatString: "####0.##",
      indexLabel: "{y}",
      indexLabelFontFamily: "helvetica, arial",
      indexLabelFontColor: "#fb8500",
      indexLabelPlacement: "inside",
      xValueFormatString: "HH:mm",
      type: "stackedColumn",
      color: '#F9C846',
      fillOpacity: 1,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: data.idle
    }
  ]
  };
  

  return (
    <>
      <div className="flex items-center h-6 px-2 text-xs uppercase justify-end text-gray-500">
        <span>{t('electricity_consumption')}</span>
      </div>
      <div className="border-t">
        <div className="text-sm">
          <div className='grid grid-cols-4 h-16 px-2 items-center'>
            <div className='flex flex-col'>
              <span className='font-normal text-gray-600'>{t('ac_all_period')}:</span>
              <span className='font-medium text-gray-800'>{!loading ? Number(powerTotals.total).toFixed(2) : <Skeleton width={100} height={16}/>}</span>
            </div>
            <div className='flex flex-col'>
              <span className='font-normal text-gray-600'>{t('ac_worked')}:</span>
              <span className='font-medium text-gray-800'>{!loading ? Number(powerTotals.total_worked).toFixed(2) : <Skeleton width={100} height={16}/>}</span>
            </div>
            <div className='flex flex-col'>
              <span className='font-normal text-gray-600'>{t('ac_idle')}:</span>
              <span className='font-medium text-gray-800'>{!loading ? Number(powerTotals.total_idle).toFixed(2) : <Skeleton width={100} height={16}/>}</span>
            </div>
          </div>
        </div>
      </div>
      <CanvasJSChart options = {{...baseOptions, ...options}} onRef={ref => setChartRef(ref)}/>
    </>
  );
}

export default ChartACPower;
