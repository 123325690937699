import diameters from "./data";

const getMeters = (data, d) => {
  let length = 0;
  const w = diameters.find(it => it.value == d);
  data.attributes.data.map(it => {
    if (it.v == null || it.v == undefined) { return }
    if (it.v <= 50 && it.v > 30) { return length = length  + (Math.fround(1 * d))/60 }
    if (it.v <= 75 && it.v > 50) { return length = length + (Math.fround(1.334 * d))/60 }
    if (it.v <= 100 && it.v > 75) { return length = length + (Math.fround(1.834 * d))/60 }
    if (it.v <= 125 && it.v > 100) { return length = length + (Math.fround(2.417 * d))/60 }
    if (it.v <= 150 && it.v > 125) { return length = length + (Math.fround(3 * d))/60 }
    if (it.v <= 175 && it.v > 150) { return length = length + (Math.fround(3.834 * d))/60 }
    if (it.v <= 200 && it.v > 175) { return length = length + (Math.fround(4.75 * d))/60 }
    if (it.v <= 225 && it.v > 200) { return length = length + (Math.fround(5.667 * d))/60 }
    if (it.v <= 250 && it.v > 225) { return length = length + (Math.fround(6.417 * d))/60 }
    if (it.v <= 275 && it.v > 250) { return length = length + (Math.fround(7.084 * d))/60 }
    if (it.v <= 300 && it.v > 275) { return length = length + (Math.fround(8.084 * d))/60 }
    if (it.v <= 325 && it.v > 300) { return length = length + (Math.fround(9 * d))/60 }
    if (it.v <= 350 && it.v > 325) { return length = length + (Math.fround(9.834 * d))/60 }
    if (it.v <= 375 && it.v > 350) { return length = length + (Math.fround(10.834 * d))/60 }
    if (it.v <= 400 && it.v > 375) { return length = length + (Math.fround(12.25 * d))/60 }
    if (it.v <= 440 && it.v > 400) { return length = length + (Math.fround(14.5 * d))/60 }
  })

  return {length: length, weight: length * w.w}
}

export {
  getMeters
}