import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DailyWorkWidget from '../../../../../components/widgets/daily_work';
import Item from '../item';
import {DateTime, Duration} from 'luxon';
import ClassEngineering from '../../../../../components/vendors/classengineering';

const ReportEquipmentFull = ({data, sensors, startDate, endDate, startTime, endTime, currentItem}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true)
  const [periodData, setPeriodData] = useState({worked: 0, stopped: 0, turned_off: 0, count: 1})
  const [shiftDepths, setShiftDepths] = useState([]);
  const [vendorCE, setVendorCE] = useState(false);
  const [commonSensors, setCommonSensors] = useState();
  const [vendorCESensors, setVendorCESensors] = useState();

  useEffect(() => {
    if(!startDate || !endDate || !(typeof startTime === 'number') || !(typeof endTime === 'number') || !data) {
      return
    }

    let res = [];
    let i = startDate;
    if(startTime < endTime) {
      while (i <= endDate) {
        res.push([i.plus(startTime).ts, i.plus(endTime).ts]);
        i = i.plus(86400000);
      }
    } else if(startTime > endTime) {
      while (i <= endDate) {
        res.push([i.plus(startTime).ts, i.plus(86400000 + endTime) >= endDate ? endDate.plus(endTime).ts : i.plus(86400000 + endTime).ts]);
        i = i.plus(86400000);
      }
    }

    setShiftDepths(res)

    let worked = 0;
    let stopped = 0;
    let turned_off = 0;
    let days = data.length;

    data.map(it => {
      worked = worked + it.worked
      stopped = stopped + it.stopped
      turned_off = turned_off + it.turned_off
    })
    setPeriodData({
      worked: worked, 
      stopped: stopped, 
      turned_off: turned_off, 
      count: days
    })
    setLoading(false)
  },[startDate, endDate, startTime, endTime, data])

  useEffect(() => {
    let cs = []
    let vCE = []
    sensors.map(s => {
      if(s.attributes.sensor_type.data_key_name === 'mb_count') {
        setVendorCE(true)
        vCE.push(s)
      } else {
        cs.push(s)
      }
    });
    setCommonSensors(cs)
    setVendorCESensors(vCE)
  },[sensors])


  return (
    <div className="flex flex-col space-y-4 pb-10">
      <div className="">
        <div className="flex items-center w-full h-full">
          <div className='flex flex-col relative border border-gray-200 rounded-sm px-4 py-4 w-full h-full'>
            <div className="font-medium mb-6">{t('data_by_period')}: {startDate.toFormat('dd.MM.yyyy')} - {endDate ? endDate.toFormat('dd.MM.yyyy') : ""} / {Duration.fromMillis(startTime).toFormat('hh:mm:ss')} - {Duration.fromMillis(endTime).toFormat('hh:mm:ss')}</div>
            <DailyWorkWidget worked={periodData.worked} stopped={periodData.stopped} turned_off={periodData.turned_off} count={periodData.count}/>
          </div>
        </div>
      </div>
      {loading ? <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div> : 
      <>
        {vendorCE &&  
        <div className="p-4 border rounded">
          <ClassEngineering
            sensor={vendorCESensors[0]}
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            shiftDepths={shiftDepths}
            byDays={false}
          />
        </div>}
        <div className="p-4 border rounded">
          {commonSensors.map(s => (
            <Item key={s.id} currentSensor={s} startDate={startDate} endDate={endDate} startTime={startTime} endTime={endTime} shiftDepths={shiftDepths} currentItem={currentItem}/>
          ))} 
        </div>
      </>
      }
    </div>
  )
}

export default ReportEquipmentFull;