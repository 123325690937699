import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CurrentUserContext } from '../../../contexts/current-user';
import { Disclosure, Transition } from '@headlessui/react';
import { CollectionIcon, ChevronUpIcon, UserGroupIcon, ChipIcon, BookOpenIcon } from '@heroicons/react/outline';

const AdminMenu = () => {
  const { t } = useTranslation();
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <ul className="py-4 px-4 md:space-y-2 w-72">
      <li>
        <NavLink to="/admin/devices" className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
          <div className="w-4 h-4"> 
            <ChipIcon className="w-4 h-4" stroke="currentColor"></ChipIcon>
          </div>
          <div className="flex w-full justify-between items-center pl-6">
            <span>{t('devices')}</span>
          </div>
        </NavLink>
      </li>
      <Disclosure as="li">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center w-full px-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                <div className="w-4 h-4"> 
                  <BookOpenIcon className="w-4 h-4 text-gray-600"></BookOpenIcon>
                </div>
                <div className="flex w-full justify-between items-center pl-6">
                  <span>{t('directories')}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-4 h-4`}
                  />
                </div>

              </Disclosure.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
              <Disclosure.Panel as="ul" className="text-sm text-gray-500 pt-2 space-y-1">
                <li>
                  <NavLink to="/admin/directories/models" exact className="flex items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('models')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin/directories/sensors" exact className="flex items-center w-full pl-12 pr-2 py-2 text-sm font-medium text-left rounded text-gray-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                    {t('sensors')}
                  </NavLink>
                </li>
              </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
    </ul>
  )
};

export default AdminMenu;