import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChartRPDay from '../../../../components/widgets/charts/chartRPDay';
import ChartPhaseDay from '../../../../components/widgets/charts/chartPhaseDay';

const ElectricitySensor = ({sensor, loading}) => {
  const {t} = useTranslation();

  return (
    loading ? 
      <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div> : 
      <>
        <ChartRPDay data={sensor.rp}/>
        <ChartPhaseDay data={sensor}/>
      </>
  )
}

export default ElectricitySensor;