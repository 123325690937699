import React, { useState, useEffect } from 'react';

import CanvasJSReact from '../../assets/canvasjs.react';
import Spinner from "../loader";

import {convertTimezone} from '../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;


const ChartUniversal = ({ loading, responseData, baseOptions, timeZone }) => {
  const [chartData, setChartData] = useState([]);


  useEffect(() => {
    if (!responseData.attributes) {
      return;
    }
    const itemData = [];
    responseData.attributes.data.forEach(it => {
      itemData.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: it.v ? (it.v > 0 ? it.v : 0) : null  
      })
    });

    setChartData(itemData);
  }, [responseData]);


  const options = {
    data: [{
      xValueFormatString: "HH:mm:ss",
      type: "splineArea",
      connectNullData: true,
      // type: "area",
      color: '#113FB4',
      fillOpacity: .2,
      lineColor: '#113FB4',
      lineThickness: 1,
      markerType: "circle",
      markerSize: 0,
      dataPoints: chartData
    }]
  };

  return (
    <div className="text-sm">
      {loading ?     
      <div className="flex items-center justify-center w-full h-80">
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
      </div>  : 
        
        <CanvasJSChart options = {{...baseOptions, ...options}}/>

      }
    </div>
  )
};

export default ChartUniversal;
