import { useEffect, useState } from "react"
import useFetch from "../../../hooks/use-fetch";
import Select from '@atlaskit/select';
import { useTranslation } from "react-i18next";
import {Interval, DateTime, Duration} from 'luxon';
import useAsyncFetch from "../../../hooks/useAsyncFetch";
import { convertTimeHHMM, covertTimeToSeconds } from "../../../utils";
import IdelTimelineWidget from "../../../components/widgets/idle_timeline";
import Spinner from "../../../components/loader";

import CanvasJSReact from '../../../assets/canvasjs.react';
import { getPerfectDate, getPerfectDateTime, getPerfectDateTimeWithHours, convertTimezone } from '../../../utils';

export default function EquipmentIdleSwarmMap({selectedDate, convertedShift}) {
  const tz = DateTime.local().toFormat('ZZ');
  const {t} = useTranslation();
  const [dates, setDates] = useState();
  const [currentResource, setCurrentResource] = useState(null)
  const [equipmentList, setEquipmentList] = useState([])
  const [params, setParams] = useState(new URLSearchParams({'with_sensors': true}))
  const [usageParams, setUsageParams] = useState(new URLSearchParams({}))
  const [timeMarkers, setTimeMarkers] = useState([0, 21600, 43200, 64800, 86400])
  const [data, setData] = useState([]);

  const [{response, isLoading, error}, doFetchEquipment] = useFetch(`/directories/equipment_items?${params.toString()}`);
  const [{response: dataResponse, isLoading: dataIsLoading}, doFetchData] = useFetch(`/directories/ng_equipment_items_data/timeline?${usageParams.toString()}`)

  useEffect(() => {
    doFetchEquipment()
  }, [])

  useEffect(() => {
    setData(null)

    const shiftStart = covertTimeToSeconds(convertedShift.start);
    const shiftEnded = covertTimeToSeconds(convertedShift.end);

    setTimeMarkers([
      shiftStart, 
      shiftStart + (convertedShift.duration / 4) > 86400 ? (shiftStart + (convertedShift.duration / 4)) - 86400 : shiftStart + (convertedShift.duration / 4), 
      shiftStart + (convertedShift.duration / 2) > 86400 ? (shiftStart + (convertedShift.duration / 2)) - 86400 : shiftStart + (convertedShift.duration / 2), 
      shiftStart + (convertedShift.duration / 4) * 3 > 86400 ? (shiftStart + (convertedShift.duration / 4) * 3) - 86400 : shiftStart + (convertedShift.duration / 4) * 3, 
      shiftEnded
    ])

    setDates(Interval.fromDateTimes(selectedDate[0], selectedDate[1]).splitBy({ day: 1 }))
  }, [selectedDate, convertedShift])

  useEffect(() => {
    if(!response?.data) {
      return
    }
    setEquipmentList(response.data)
  }, [response?.data])

  useEffect(() => {
    setData(null)
  }, [currentResource?.id])

  function generate() {
    if(!currentResource) {return}

    const start = selectedDate[0].startOf('day').toFormat("yyyy-MM-dd")
    const end = selectedDate[1].endOf('day').toFormat("yyyy-MM-dd")

    let startDateString
    let endDateString

    if(covertTimeToSeconds(convertedShift.started_at) > covertTimeToSeconds(convertedShift.ended_at)) {
      startDateString = `${start}T${convertedShift.start}.000${tz}`

      let nextEndDayString = selectedDate.endOf('month').plus({day: 1}).toFormat("yyyy-MM-dd")
      endDateString = `${nextEndDayString}T${convertedShift.end}.000${tz}`
    } else {
      startDateString  = `${start}T${convertedShift.start}.000${tz}`
      endDateString = `${end}T${convertedShift.end}.000${tz}`
    }

    let newUsageParams = new URLSearchParams({})
    newUsageParams.append('from', startDateString)
    newUsageParams.append('to', endDateString)
    newUsageParams.append('day_time_from', convertedShift.start)
    newUsageParams.append('day_time_to', convertedShift.end)
    newUsageParams.append('equipment_item_ids[]', currentResource.id)

    setUsageParams(newUsageParams.toString())
    doFetchData();
  }

  useEffect(() => {
    if(!dataResponse?.data || !dataResponse?.data.length === 0) {return}
    let dataByDays = []

    dataResponse.data?.filter(i => i.state === "stopped" && i.duration > 60).forEach((i, index) => dataByDays.push(
      { 
        x: new Date(i.at), 
        y: i.duration / 60,
        date: i.at,
        time_start: DateTime.fromSQL(i.start).toISOTime({ suppressMilliseconds: true, includeOffset: false }),
        time_end: DateTime.fromSQL(i.finish).toISOTime({ suppressMilliseconds: true, includeOffset: false }),
        duration: Duration.fromMillis(i.duration * 1000).toISOTime({ suppressMilliseconds: true, includeOffset: false }),
        link: `/report/equipment/${i.equipment_item_id}?date=${DateTime.fromSQL(i.at, { setZone: false }).ts}`,
        total_idles_in_day: dataResponse.data?.filter(ii => ii.state === "stopped" && ii.duration > 60 && ii.at === i.at).length,
        position_in_day: dataResponse.data?.filter(ii => ii.state === "stopped" && ii.duration > 60 && ii.at === i.at).findIndex(v => v.start === i.start) + 1
      }))
    setData(dataByDays)
  }, [dataResponse])

  useEffect(() => {
    if(dataIsLoading) {return}
  }, [dataResponse])

  return (
    <>
      <div className="flex justify-between">
        <div className="w-96">
          <Select 
            getOptionLabel={(o) => o ? `${o.attributes.equipment_type_name} ${o.attributes.producer_name} ${o.attributes.equipment_model_name}` : "Оборудование не выбрано"}
            getOptionValue={(o) => o ? o.id : null}
            options={equipmentList} 
            onChange={setCurrentResource}
            value={currentResource} 
            placeholder={t('select_equipment_placeholder')} 
            className="text-sm"
          />
        </div>
      </div>
      <div className="py-10 w-full h-full min-h-96">
        {dataIsLoading ? <Spinner /> : data ? <IdleSwarmPlot data={data}/> :
        <div className="flex flex-col w-full h-full items-center justify-center">
          <span className="text-gray-600 py-8">Выберите оборудование и сформируйте аналитический отчет</span>
          <button className="bg-blue-600 text-white py-2 px-8 rounded-sm font-medium text-sm" onClick={() => generate()}>Сформировать</button>
        </div>
        }
      </div>
    </>
  )
}

const classNames = "pointer-events-auto font-sans"

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const IdleSwarmPlot = ({data}) =>  {
  const {t} = useTranslation(['widgets']);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setChartData(data);
    setLoading(false);
  }, [data]);

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: false,
    height: 400,
    culture:  "ru",
    toolTip: {
			shared: false,
      fontFamily: "helvetica, arial",
      cornerRadius: 2,
      borderThickness: 0
		},
    axisX:{
      title: "Дни",
      titleFontSize: 10,
      titleFontFamily: "helvetica, arial",
      lineColor: '#E7ECF8',
      intervalType: "day",
      valueFormatString: 'DD',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      title: "Минуты",
      titleFontSize: 10,
      titleFontFamily: "helvetica, arial",
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      // valueFormatString: 'DD',
      gridColor: '#E7ECF8',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
      // labelPlacement:"inside",
    },
  };

  const options = {
    data: [{
      type: "scatter",
      name: "Простой",
      markerType: "circle",
      showInLegend: true,
      toolTipContent: "<div class=\"canvas_js_tooltip_base_styles\"><span style=\"color:#4F81BC \">{date}</span><br>Начало: {time_start}<br>Конец: {time_end}<br>Продолжительность: {duration}<br>Всего простоев за день: {total_idles_in_day}<br>Позиция в дне: {position_in_day}<br><a target=\"_blank\" href= {link} >Перейти</a></div>",
      dataPoints: chartData
    }]
  };



  return (
    <div className="text-sm">
      <CanvasJSChart options = {{...baseOptions, ...options}}/>
    </div>
  );
}
