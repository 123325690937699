import React, {useContext} from 'react';
import {Switch} from 'react-router-dom';
import {CurrentUserContext} from "../contexts/current-user";
import AdminRouter from '../auth/admin';

import ModelsDirectory from '../pages/admin/directories/models';
import SensorsDirectory from '../pages/admin/directories/sensors';
import Devices from '../pages/admin/devices';
import DevicePage from "../pages/devices/device";


const AdminRoutes = () => {
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <Switch>
      <AdminRouter path="/admin" component={Devices} exact/>
      <AdminRouter path="/admin/directories/models" component={ModelsDirectory} exact/>
      <AdminRouter path="/admin/directories/sensors" component={SensorsDirectory} exact/>
      <AdminRouter path="/admin/devices" component={Devices} exact/>
      {/* <AdminRouter path="/admin/devices/:id" component={DevicePage} exact/> */}
    </Switch>
  )
};

export default AdminRoutes;