import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../components/loader';
import DateRangePicker from '../../../../components/form/calendar/dateRangePicker';
import Select from "@atlaskit/select";
import {DateTime} from 'luxon';

import useFetch from '../../../../hooks/use-fetch';

import MonthDatePicker from '../../../../components/form/calendar/monthDatePicker';
import {covertTimeToSeconds} from '../../../../utils';

import {CurrentUserContext} from "../../../../contexts/current-user";

import ElectricityDay from "./dataItem";

import locale from '../../../../components/form/locale/lang/ru';
import {useStore} from "../../../../hooks-store/store";


locale.el.datepicker.today = DateTime.local();

const DeviceData = ({ currentItem, rpSensor, phOne, phTwo, phThree }) => {
  const tz = DateTime.local().toFormat('ZZ');
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);

  const [loading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState([DateTime.local().startOf('month'), DateTime.local()]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(DateTime.local().toMillis()));

  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" });
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400});

  const [startDateString, setStartDateString] = useState('');
  const [endDateString, setEndDateString] = useState('');

  const [sensorsData, setSensorsData] = useState({})

  const [{response: realPowerResponse, isLoading: realPowerIsLoading, error: realPowerError}, doFetchRealPower] = useFetch(`/devices/sensors/${rpSensor.id}/received_data?from=${startDateString}&to=${endDateString}&capacity=one_hour&scalable_by=avg`);
  const [{response: phOneResponse, isLoading: phOneIsLoading, error: phOneError}, doFetchPhOne] = useFetch(`/devices/sensors/${phOne.id}/received_data?from=${startDateString}&to=${endDateString}&capacity=one_hour&scalable_by=avg`);
  const [{response: phTwoResponse, isLoading: phTwoIsLoading, error: phTwoError}, doFetchPhTwo] = useFetch(`/devices/sensors/${phTwo.id}/received_data?from=${startDateString}&to=${endDateString}&capacity=one_hour&scalable_by=avg`);
  const [{response: phThreeResponse, isLoading: phThreeIsLoading, error: phThreeError}, doFetchPhThree] = useFetch(`/devices/sensors/${phThree.id}/received_data?from=${startDateString}&to=${endDateString}&capacity=one_hour&scalable_by=avg`);

  useEffect(() => {
    if(!selectedDate[1]) {
      return
    }

    const start = selectedDate[0].toFormat("yyyy-MM-dd")
    const end = selectedDate[1].toFormat("yyyy-MM-dd")


    if(convertedShift.start < convertedShift.end) {
      setStartDateString(`${start}T${convertedShift.start}.000${tz}`);
      setEndDateString(`${end}T${convertedShift.end}.000${tz}`);
    } else {
      setStartDateString(`${start}T${convertedShift.start}.000${tz}`);

      let nextEndDayString = selectedDate[1].plus({day: 1}).toFormat("yyyy-MM-dd");
      setEndDateString(`${nextEndDayString}T${convertedShift.end}.000${tz}`);
    }
    
    setLoading(true);
    doFetchRealPower();
    doFetchPhOne();
    doFetchPhTwo();
    doFetchPhThree();

  },[selectedDate, convertedShift])

  useEffect(() => {
    if(!realPowerResponse || !phOneResponse || !phTwoResponse || !phThreeResponse) {
      return
    }

    const data = {
      totalPh1: getTotal(phOneResponse.data.attributes.data),
      totalPh2: getTotal(phTwoResponse.data.attributes.data),
      totalPh3: getTotal(phThreeResponse.data.attributes.data),
      total: getTotal(realPowerResponse.data.attributes.data),
      data: sliceByDay(
        {id: phOneResponse.data.id, data: phOneResponse.data.attributes.data},
        {id: phTwoResponse.data.id, data: phTwoResponse.data.attributes.data},
        {id: phThreeResponse.data.id, data: phThreeResponse.data.attributes.data},
        {id: realPowerResponse.data.id, data: realPowerResponse.data.attributes.data}
        ).reverse()
    }

    setSensorsData(data)
    setLoading(false);
    
  }, [realPowerResponse, phOneResponse, phTwoResponse, phThreeResponse])

  function getTotal(arr) {
    let total = 0;
    arr.map(h => {
      total = h.v ? total + h.v : total
    })
    return total / 1000
  }


  function sliceByDay(ph1, ph2, ph3, rp) {
    let start = selectedDate[0].ts

    const daysData = []

    while (start <= selectedDate[1].endOf('day').ts) { 
      let tsPh1 = []
      let tsPh2 = []
      let tsPh3 = []
      let tsRp = []
      ph1.data.map(it => {
        if(it.at >= start && it.at < start + 86400000) {
          tsPh1.push(it)
        }
      })
      ph2.data.map(it => {
        if(it.at >= start && it.at < start + 86400000) {
          tsPh2.push(it)
        }
      })
      ph3.data.map(it => {
        if(it.at >= start && it.at < start + 86400000) {
          tsPh3.push(it)
        }
      })
      rp.data.map(it => {
        if(it.at >= start && it.at < start + 86400000) {
          tsRp.push(it)
        }
      })
      daysData.push({day: start, sensors: [ph1.id, ph2.id, ph3.id, rp.id], ph1: tsPh1, ph2: tsPh2, ph3: tsPh3, rp: tsRp})
      start = start + 86400000;
    }


    return daysData
  }


  useEffect(() => {
    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]

    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat('HH:mm:ss'), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat('HH:mm:ss')})
      })
    }
    setShiftList(shifts);
  },[])

  const onMonthChoice = (date) => {
    if(date === null) {
      return
    }
    const ts = new Date(date).getTime();

    if(DateTime.local().startOf('month').ts === DateTime.fromMillis(ts).startOf('month').ts) {
      setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.local()]);
      setSelectedMonth(date)
    } else if (DateTime.fromMillis(ts).startOf('month').ts > DateTime.local().ts) {
      return
    } else {
      setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.fromMillis(ts).endOf('month')]);
      setSelectedMonth(date)
    }
  }


  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  return (
    <div className="device-details-history">
      <div className="flex flex-row justify-between mt-4 gap-4">
        <div className='md:w-1/4 w-1/2'>
          <Select 
            className="text-sm"
            options={shiftList} 
            value={currentShift.value === null ? shiftList.find(it => it.value === null) : shiftList.find(it => it.value === currentShift.value)}
            onChange={value => handleChangeShift(value)}
            isSearchable={false}
          />
        </div>
        <div className='md:w-1/4 w-1/2'>
          <MonthDatePicker onChange={onMonthChoice} selectedDate={selectedMonth} />
        </div>
      </div>
      {!loading && sensorsData ? 
      <>
      <div className="space-y-4 pb-10 mt-4">
        <div className='grid grid-cols-4 gap-4 border rounded-sm p-4'>
          <div className='flex flex-col'>
            <div className='text-gray-600'>Общее потребление за месяц</div>
            <div className='mt-8'>
              <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(sensorsData.total)}</span>
              <span className='text-gray-400 font-medium'>.{(sensorsData.total % 1).toFixed(2).substring(2)}</span>
              <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-gray-600'>Потребление по фазе 1 за месяц</div>
            <div className='mt-8'>
              <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(sensorsData.totalPh1)}</span>
              <span className='text-gray-400 font-medium'>.{(sensorsData.totalPh1 % 1).toFixed(2).substring(2)}</span>
              <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-gray-600'>Потребление по фазе 2 за месяц</div>
            <div className='mt-8'>
              <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(sensorsData.totalPh2)}</span>
              <span className='text-gray-400 font-medium'>.{(sensorsData.totalPh2 % 1).toFixed(2).substring(2)}</span>
              <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
            </div>
          </div>
          <div className='flex flex-col'>
            <div className='text-gray-600'>Потребление по фазе 3 за месяц</div>
            <div className='mt-8'>
              <span className='text-gray-600 font-bold' style={{fontSize: 32}}>{Math.floor(sensorsData.totalPh3)}</span>
              <span className='text-gray-400 font-medium'>.{(sensorsData.totalPh1 % 1).toFixed(2).substring(2)}</span>
              <span className='text-gray-400 font-medium ml-2'>{t('kVh')}</span>
            </div>
          </div>
        </div>
      </div>
      
      {sensorsData.data.map((item, idx) => (
        <ElectricityDay 
          key={idx}
          item={item}
        />
      ))}

      </>
      : <Spinner />}
      
    </div>
  )
};

export default DeviceData;
