import React, { useState, useEffect, useContext } from 'react';
import {CurrentUserContext} from '../../../../../contexts/current-user';
import TextField from '@atlaskit/textfield';
import Form, { Field, CheckboxField, ErrorMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import { useHistory } from "react-router-dom";
import useFetch from '../../../../../hooks/use-fetch';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@atlaskit/checkbox';


const ScreenSettings = ({ currentItem, divisions, onUpdate }) => {
  const {t} = useTranslation();
  const [currentUserState, ] = useContext(CurrentUserContext);
  const [publicScreen, setPublicScreen] = useState(currentItem.attributes.public);

  const handleUpdate = (formData) => {
    const data = 
      {
        name: formData.name,
        division_id: formData.division.value,
        public: publicScreen,
        // order_number: formData.order_number,
      }
    onUpdate(data);
  };

  function goToScreeen() {
    window.open(`/screen/${currentUserState?.currentSite?.subdomain}/${currentItem?.attributes?.slug}`);
  }

  return (
    <div className="mt-4">
      <Form onSubmit={data => handleUpdate(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} className="flex flex-col">
            <CheckboxField name="public" defaultIsChecked={publicScreen}>
              {({ fieldProps }) => <Checkbox className="in" size="medium" {...fieldProps} label={t('screen_public')} onChange={() => setPublicScreen(!publicScreen)}/>}
            </CheckboxField>
            <div className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1">
              <Field name="name" defaultValue={currentItem.attributes.name} label={t("screen_name")} isRequired>
                {({ fieldProps }) => <TextField {...fieldProps} />}
              </Field>
              <Field name="division" defaultValue={currentItem.attributes.parent_id ? divisions.find(it => it.value == currentItem.attributes.parent_id) : divisions[0]} label={t('division')}>
                {({ fieldProps }) => <Select {...fieldProps} options={divisions} className="text-sm" placeholder={t('select_placeholder')}/>}
              </Field>
              <Field name="slug" defaultValue={`/screen/${currentUserState.currentSite.subdomain}/${currentItem.attributes.slug}`} isDisabled={true} label={t('screen_slug')}>
                {({ fieldProps }) => {
                  return (
                    <div className='bg-gray-50 border-2 rounded-sm flex justify-between py-1 px-1'>
                      <span className='text-sm text-gray-600 pr-10 py-1 pl-1 truncate'>{fieldProps.value}</span>
                      <button type='button' onClick={() => goToScreeen()} className='bg-blue-600 text-white px-2 py-1 rounded-sm hover:bg-blue-700 cursor-pointer'>{t("go_to")}</button>
                    </div>
                  )
                }}
              </Field>
            </div>


            <div className="flex justify-between mt-8 mb-8">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("update")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
    </div>
  )
};

export default ScreenSettings;
