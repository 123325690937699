import React, { useEffect, useState, useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from './index';
import { CurrentUserContext } from '../contexts/current-user';

const AdminRouter = ({component: Component, ...rest}) => {
  const [currentUserState, ] = useContext(CurrentUserContext);
  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <Route {...rest} render={props => (
      isAuthenticated() && currentUserState?.currentUser?.attributes?.admin ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
  );
};

export default AdminRouter;