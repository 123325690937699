import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../hooks/use-fetch';
import {DateTime} from 'luxon';
import LazyLoad from 'react-lazyload';

import PersonLoadWidget from './graph';

const StaffMonthLoadWidget = ({person, selectedDate, convertedShift, onBarClick}) => {
  const {t} = useTranslation();
  const tz = DateTime.local().toFormat('ZZ');
  const [loading, setLoading] = useState(true);
  const [usageData, setUsageData] = useState([]);
  const [startDateString, setStartDateString] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endDateString, setEndDateString] = useState('');
  const [endTime, setEndTime] = useState('');
  const [shiftId, setShiftId] = useState('');
  const [duration, setDuration] = useState('');

  const [{response, isLoading, error}, doFetchUsage] = useFetch(`/directories/ng_personnel_data/usage_data_by_day?personnel_ids[]=${person.id}&from=${startDateString}&to=${endDateString}&day_time_from=${startTime}&day_time_to=${endTime}`);

  useEffect(() => {
    const startDate = selectedDate[0]
    const endDate = selectedDate[1]

    if(convertedShift.id === null) {
      setShiftId('')
      setDuration(convertedShift.duration)
      setStartTime(convertedShift.start)
      setEndTime(convertedShift.end)
    } else {
      setShiftId(convertedShift.id)
      setDuration(convertedShift.duration)
      setStartTime(convertedShift.start)
      setEndTime(convertedShift.end)
    }

    //TODO НА СЕРВЕРЕ ОШИБКА 
    if(convertedShift.start > convertedShift.end && endDate.endOf('day').ts === DateTime.local().endOf('day').ts) {
      setStartDateString(DateTime.fromMillis(startDate.ts).toISO())
      setEndDateString(DateTime.fromMillis(endDate.ts).plus({days: 1}).toISO())
    } else {
      setStartDateString(DateTime.fromMillis(startDate.ts).toISO())
      setEndDateString(DateTime.fromMillis(endDate.ts).toISO())
    }


    setLoading(true);
    doFetchUsage();
  },[selectedDate, convertedShift])

  useEffect(()=>{
    if(!response) {
      return
    }
    setUsageData(response.data)
    setLoading(false)
  },[response])

  function handleBarClick(date) {
    DateTime.fromSeconds(date)
    onBarClick(person.id, date)
  }

  return (
    <LazyLoad height={300} offset={100} once className='flex w-full h-full'>
      {loading ?  
        <div className="flex items-center justify-center w-full h-80">
          <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4"></div>
        </div> :
        <PersonLoadWidget dailyData={usageData} generalData={usageData.general} duration={duration} onBarClick={handleBarClick}/>
      }
    </LazyLoad>
  )
}

export default StaffMonthLoadWidget;