import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import EquipmentEdit from './edit';


export default function EditEquipmentModal({closeModal, isOpen, currentItemId, currentTab}) {
  const {t} = useTranslation();
  const tab = currentTab ? currentTab : null
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={closeModal}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="min-h-screen  text-center">
          {/* <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className='py-16 h-full min-h-screen'>
              <div className="inline-block w-full lg:w-3/4 p-6 h-full text-left transition-all transform bg-white shadow-xl rounded">
                <EquipmentEdit currentItemId={currentItemId} currentTab={tab}/>
              </div>
            </div>
          </Transition.Child>
        </div>
        
      </Dialog>
    </Transition>
  )
}
