import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import useFetch from '../../hooks/use-fetch';
import {DateTime} from 'luxon';

import ElectricityLoadWidget from '../../components/widgets/electricity_month_load';

const ElectricityItem = ({device, selectedDate}) => {
  const {t} = useTranslation();
  let history = useHistory();


  const onItemClick = (id) => {
    history.push({
      pathname: `/electricities/${id}`,
    });
  };
  

  return (
    <div className="flex h-full border border-gray-100 rounded-sm px-4 py-4 text-sm">
      <div className="w-full">
        <div className="flex justify-between">
          <div className="flex space-x-1 cursor-pointer">
            <span className="font-medium">{device.device_name}</span>
            {/* <span className="font-medium text-gray-500">({device.attributes.producer_name} {device.attributes.equipment_model_name})</span> */}
          </div>
          <button onClick={() => onItemClick(device.id)} className="px-2 py-1 bg-blue-100 rounded text-blue-700 font-medium text-sm">{t('more_details')}</button>
        </div>

        <div className="flex space-x-1 itmes-center mt-2">
          <span className={`rounded text-xs px-2 leading-6 ${device.online ? "bg-green-200" : "bg-red-200"}`}>{t(device.online ? t('online') : t('offline'))}</span>
          {/* <span className="font-medium text-gray-500 leading-6">{device.attributes.division_name}</span> */}
        </div>
        <div className="flex w-full">
          <ElectricityLoadWidget device={device} selectedDate={selectedDate}/>
        </div>
      </div>
    </div>
  )
}

export default ElectricityItem;