import React, {useContext} from 'react';
import {Redirect, Switch, useRouteMatch} from 'react-router-dom';
import {CurrentUserContext} from "../contexts/current-user";
import PrivateRoute from '../auth/private';

import AccountProfile from "../pages/account/profile";
// import AccountLogsPage from "../pages/account/logs-page/account-logs-page";
// import AccountNotificationPage from "../pages/account/notification-page/account-notification-page";
import Organizations from "../pages/account/organizations";
// import AccountSessionsPage from "../pages/account/sessions-page/account-sessions-page";

const AccountRoutes = () => {
  let { path, url } = useRouteMatch();
  const [currentUserState, ] = useContext(CurrentUserContext);

  return (
    <Switch>
      <PrivateRoute path={path} component={AccountProfile} exact/>
      <PrivateRoute path={`${path}/organizations`} component={Organizations} exact/>
    </Switch>
  )
};

export default AccountRoutes;