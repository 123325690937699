import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';

import { uniqBy } from 'lodash';
import {CurrentUserContext} from "../../../contexts/current-user";

import Spinner from '../../../components/loader';
import Layout from '../../../components/layout';
import ReactPaginate from 'react-paginate';
import PageFilter from '../../../components/page_filter';
import DevicesTable from "./table";
import AddDevice from './add';


const DevicesPage = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [devicesModels, setDevicesModels] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [showPaging, setShowPaging] = useState(false);
  const [totalItems, setTotalItems] = useState(null);

  const [sortedBy, setSortedBy] = useState('serialNumber');
  const [sortedDown, setSortedDown] = useState(true);

  const [item, setItem] = useState('');
  const [sensorTypes, setSensorTypes] = useState([]);

  const [filters, setFilters] = useState([]);
  const [pageSearch, setPageSearch] = useState('');
  const [filtersTypes, setFiltersTypes] = useState([]);
  const [filterListTypes, setFilterListTypes] = useState([]);

  let [isOpen, setIsOpen] = useState(false)

  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch('/administration/devices/devices', true);
  const [{response: unlinkResponse, isLoading: unlinkIsLoading}, doFetchUnlink] = useFetch(`/administration/devices/devices/${item}/unlink`, true)
  const [{response: recivedResponse, isLoading: reciveIsLoading}, doFetchRecive] = useFetch(`/administration/devices/devices/${item}`, true)
  const [{response: deleteResponse, isLoading: deleteIsLoading}, doFetchDelete] = useFetch(`/administration/devices/devices/${item}`, true)

  const [{response: devicesModelsResponse, isLoading: devicesModelsIsLoading, error: devicesModelsError}, doFetchDevicesModels] = useFetch('/administration/directories/device_models', true);


  const headerData = {
    title: 'devices',
    buttonsList: [
      {
        text: 'add_device',
        action: () => openModal()
      }
    ],
  };

  useEffect(() => {
    setLoading(true);
    doFetchTableData();
    doFetchDevicesModels();
  }, []);

  useEffect(() => {
    if (!response) {
      return
    }

    setTableData(response.data);

    setLoading(false);
  }, [response]);

  useEffect(() => {
    if (!devicesModelsResponse) {
      return
    }
    const list = []

    devicesModelsResponse.data.map(it => {
      list.push({label: it.attributes.name, value: it.id})
    })
    setDevicesModels(list)
  },[devicesModelsResponse])

  useEffect(() => {
    if(unlinkIsLoading || deleteIsLoading || reciveIsLoading) {
      return
    }
    setItem('')
    reloadTable()
  },[unlinkIsLoading, deleteIsLoading, reciveIsLoading])

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const reloadTable = () => {
    setLoading(true);
    doFetchTableData();
  };

  const onItemUnlink = (id) => {
    setItem(id);
    doFetchUnlink({
      method: 'DELETE'
    });
  }

  const onItemDelete = (id) => {
    setItem(id);
    doFetchDelete({
      method: 'DELETE'
    });
  }

  const onItemRecived = (id) => {
    setItem(id);
    const isRecived = tableData.find(item => item.id == id).attributes.receive_data
    doFetchRecive({
      method: 'PUT',
      data: {
          data: {
            receive_data: !isRecived
        }
      }
    });
  };

  return (
    <Layout
      isWide={true}
      headerData={headerData}
    >
      {loading ? <Spinner/> : (
        <div className="mt-8 pb-10">
          <DevicesTable
            tableData={tableData}
            onItemRecived={onItemRecived}
            onItemUnlink={onItemUnlink}
            onItemDelete={onItemDelete}
            isLoading={isLoading}
          />
        </div>
      )}
      <AddDevice closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} devicesModels={devicesModels}/>
    </Layout>
  );
};

export default DevicesPage;
