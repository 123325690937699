import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/use-fetch';
import { sortDataList } from '../../../utils';
import {DateTime} from 'luxon';
import Select from '@atlaskit/select';
import { XIcon } from '@heroicons/react/outline';
import MonthDatePicker from '../../../components/form/calendar/monthDatePicker';
import { convertToTimestamp, covertTimeToSeconds } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import {CurrentUserContext} from "../../../contexts/current-user";
import Filters from '../../../components/filters';
import ReportItem from './item';


const ReportStaffPage = (props) => {
  const {t} = useTranslation();
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([DateTime.local(), DateTime.local()]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(DateTime.local().toMillis()));
  const [persons, setPersons] = useState([]);
  const [empty, setEmpty] = useState(false)
  // const [pageSearch, setPageSearch] = useState("");

  const [divisionList, setDivisionList] = useState([]);
  const [postList, setPostList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [params, setParams] = useState(new URLSearchParams({}))

  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59", duration: 86400 });
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400})

  const [{response, isLoading, error}, doFetchPersons] = useFetch(`/directories/personnels/worked?from=${selectedDate[0]}&to=${selectedDate[1]}&${params.toString()}`);

   
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsDataError}, doFetchDivisions] = useFetch('/directories/divisions');
  const [{response: postsResponse, isLoading: postsIsLoading, error: postsDataError}, doFetchPosts] = useFetch('/directories/posts');


  useEffect(() => {
    setLoading(true)
    doFetchPersons();
    doFetchDivisions();
    doFetchPosts();
    
    setSelectedDate([DateTime.local().startOf('month'), DateTime.local().endOf('month')]);

    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]

    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat('HH:mm:ss'), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat('HH:mm:ss')})
      })
    }
    setShiftList(shifts);
  },[])


  useEffect(() => {
    if(!response) {
      return
    }

    if(!response?.data || !response.data.length) {
      setEmpty(true)
    }

    setPersons(response.data)
    setLoading(false)
  },[response])



  useEffect(() => {
    if(!divisionsResponse) {
      return
    }
    // const options = [{label: t('all_divisions'), value: null}]
    const options = []
    sortDataList(divisionsResponse.data).map(it => {
      options.push({label: it.attributes.name, value: it.id})
    })
    setDivisionList(options)
  },[divisionsResponse]);

  useEffect(() => {
    if(!postsResponse) {
      return
    }
    // const options = [{label: t('all_posts'), value: null}]
    const options = []
    sortDataList(postsResponse.data).map(it => {
      options.push({label: it.attributes.name, value: it.id})
    })
    setPostList(options)
  },[postsResponse])

  const onMonthChoice = (date) => {
    if(date === null) {
      return
    }
    const ts = new Date(date).getTime();

    setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.fromMillis(ts).endOf('month')]);

    setSelectedMonth(date)
    doFetchPersons();
  }

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  const headerData = {
    title: 'staff_report',
    isWide: true
  };

  const filtersData = {
    calendar: {
      type: 'month',
      selectedDate: selectedMonth,
      onChange: onMonthChoice
    },
    shifts: {
      options: shiftList,
      value: currentShift,
      onChange: handleChangeShift
    },
    filtersList: [
      {
        name: 'post',
        key: 'post_ids[]',
        type: 'multiselect',
        option_search: true,
        options: postList
      },
      {
        name: 'division',
        key: 'division_ids[]',
        type: 'multiselect',
        option_search: true,
        options: divisionList
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchPersons();

  }, [selectedFilters])

  const items = persons.map((item) => {  
    return (
      <ReportItem key={item.id} person={item} selectedDate={selectedDate} convertedShift={convertedShift}/>  
    )
  })

  // if(empty) return (
  //   <div className='flex h-full w-full items-center justify-center'>
  //     {t('no_staff')}
  //   </div>
  // )

  return (
    <Layout
      headerData={headerData}
    >
      <div className='my-6'>
        <Filters filtersData={filtersData}/>
      </div>
      {loading ? <Spinner/> :
        <div className="flex flex-col space-y-6 pb-10 mt-8">
          {persons.length > 0 || !empty ? items :     
          <div className='flex h-full w-full items-center justify-center'>
            {t('no_staff')}
          </div>}
        </div>
      }
    </Layout>
  );
};

export default ReportStaffPage;