import React, { useState, useEffect } from 'react';
import TextField from '@atlaskit/textfield';
import Form, { Field, CheckboxField, ErrorMessage } from '@atlaskit/form';
import Select from '@atlaskit/select';
import useFetch from '../../../../../hooks/use-fetch';
import { useTranslation } from 'react-i18next';


const DivisionSettings = ({ currentItem, divisions, onUpdate }) => {
  const {t} = useTranslation();
  const [attachment, setAttachment] = useState('');
  const [{response: fileUploadResponse, isLoading: fileUploadIsLoading, error: fileUploadError}, doFetchFileUpload] = useFetch(`/uploads`, true);


  useEffect(() => {
    setAttachment(currentItem.attributes.attachment)
  },[])

  useEffect(() => {
    if (!fileUploadResponse) {
      return;
    }
    setAttachment({ ...fileUploadResponse });
  }, [fileUploadResponse, fileUploadIsLoading]);


  const handleFileField = (file) => {
    const formData = new FormData();
    formData.set('attachment', file);
    doFetchFileUpload({
      method: 'POST',
      data: formData
    });
  };

  const handleUpdate = (formData) => {
    const data = 
      {
        name: formData.name,
        parent_id: formData.parent.value,
        cipher: formData.cipher,
        order_number: formData.order_number,
        attachment: attachment
      }
    onUpdate(data);
  };

  return (
    <div className="mt-4">
      <Form onSubmit={data => handleUpdate(data)}>
        {({ formProps }) => (
          <>
          <form {...formProps} className="grid md:grid-cols-2 gap-x-8 gap-y-4 grid-cols-1">
            <Field name="name" defaultValue={currentItem.attributes.name} label={t("division_name")} isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field name="parent" defaultValue={currentItem.attributes.parent_id ? divisions.find(it => it.value == currentItem.attributes.parent_id) : divisions[0]} label={t('parent_division')} isRequired>
              {({ fieldProps }) => <Select {...fieldProps} options={divisions} className="text-sm" placeholder={t('select_placeholder')}/>}
            </Field>
            <div className="text-sm font-medium col-span-full mt-4 mb-2">{t('additional')}</div>
            <Field name="cipher" defaultValue={currentItem.attributes.cipher} label={t('cipher')}>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field name="order_number" defaultValue={currentItem.attributes.order_number} label={t('division_order_number')}>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <div className="field mt-4">
              <div className="relative">
                <div className="flex flex-row justify-between items-center h-10">
                  {attachment.data ? 
                    <a className="text-sm text-blue-600 whitespace-nowrap truncate font-medium flex z-10" href={`${process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_BASE_URL}/uploads/${attachment.data.id}`} target="_blank">{attachment.data.attributes.filename}</a> : 
                    <div className="text-sm text-gray-600 whitespace-nowrap truncate font-medium">{t('upload_division_order')}</div>
                  }
                  <div className="bg-gray-100 rounded text-sm text-gray-800 px-4 py-2">
                    <span className="font-medium">{t('upload')}</span>
                  </div>
                </div>
                <input
                  name="attachment"
                  type="file"
                  className="absolute cursor-pointer opacity-0 block w-full h-10 top-0"
                  onChange={(evt) => handleFileField(evt.target.files[0])}
                />
              </div>
            </div>  
            <div className="flex justify-between mt-8 mb-8 col-span-full">
              <button 
                type="submit" 
                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              >
                {t("update")}
              </button>
            </div>
          </form>
          </>
        )}
      </Form>
    </div>
  )
};

export default DivisionSettings;
