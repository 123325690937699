import React, { useEffect, useState } from 'react';
import Table from '../../../../../components/form/table';
import { useTranslation } from 'react-i18next';
import { PencilAltIcon } from '@heroicons/react/outline';


const Persons = ({ tableData, onPersonClick }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        first_name: tableItem.attributes.first_name,
        middle_name: tableItem.attributes.middle_name,
        last_name: tableItem.attributes.last_name,
        leader: tableItem.attributes.leader,
        access_token_id: tableItem.attributes.access_token_id,
      }
    )
  });

  const getLeader = (leader) => {
    if(leader) {
      return (
        <span className="bg-green-100 text-green-800 px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
          {t('chief')}
        </span>
      )
    } 
  }
  
  const columns = [
    {
      label: t('leader'),
      prop: "leader",
      labelClassName: "w-full lg:w-1/12 invisible",
      className: "w-full lg:w-1/12 flex-shrink-0",
      render: ({leader}) => {
        return (
          getLeader(leader)
        )
      }
    },
    {
      label: t('surname'),
      prop: "last_name",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: ({last_name}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="font-medium text-gray-900 truncate">
              {last_name}
            </div>
          </div>
        )
      }
    },
    {
      label: t('full_name'),
      prop: "full_name",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: ({first_name, middle_name}) => {
        return (
          <div className="flex items-start truncate"> 
            <div className="text-gray-900 truncate">
              {first_name} {middle_name}
            </div>
          </div>
        )
      }
    },
    {
      label: t('access_token'),
      prop: "access_token_id",
      labelClassName: "w-full lg:w-2/12",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: ({access_token_id}) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {access_token_id}
            </div>
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onPersonClick(row.id)}
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
      onRowClick={(data) => onPersonClick(data.id)}
    />
  );
};

export default Persons;