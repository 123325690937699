import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../../../../assets/canvasjs.react';
import { convertTimezone } from '../../../../utils';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const RollsCountChart = ({data}) =>  {
  const [loading, setLoading] = useState(true);
  const [totalRolls, setTotalRolls] = useState();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if(!data) {
      return
    }
    let total = Number()
    const new_data = []
    data.data.map(it => {
      total = total + it.v
      new_data.push({
        x: new Date(it.at + convertTimezone(it.at)),
        y: it.v
      })
    })
    setTotalRolls(total);
    setChartData(new_data);
    setLoading(false);
  },[data])

  const baseOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    height: 200,
    culture:  "ru",
    axisX:{
      lineColor: '#E7ECF8',
      intervalType: "hours",
      valueFormatString: 'HH:mm:ss',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
    axisY: {
      includeZero: true,
      lineColor: 'transparent',
      gridDashType: 'dash',
      gridColor: '#E7ECF8',
      labelFontFamily: "helvetica, arial",
      labelFontColor: '#444444',
      labelFontSize: 10,
    },
  };

  const options = {
    data: [{
      xValueFormatString: "HH:mm",
      type:"column",
      name: "Rolls",
      dataPoints: chartData
    }
    ]
  };

  return (
    <div className="my-4">
      <div className="flex items-center h-10">
        <span>Rolls (Total rolls by day: {totalRolls})</span>
      </div>
      <div className="text-sm">
        <CanvasJSChart options = {{...baseOptions, ...options}}/>
      </div>
    </div>
  );
}

export default RollsCountChart;