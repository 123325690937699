import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../hooks/use-fetch';
import {DateTime} from 'luxon';
import { sortDataList } from '../../utils';
import { covertTimeToSeconds } from '../../utils';
import Layout from '../../components/layout';
import Spinner from '../../components/loader';
import {CurrentUserContext} from "../../contexts/current-user";
import ReportItem from './item';
import AnalyticFilters from '../../components/analytic_filters';
import EquipmentIdleHeatMap from './equpment_idle_heat_map';
import EquipmentBaseAnalytic from './equpment_base_analytic';
import EquipmentIdleSwarmMap from './equpment_idle_swarm_map';
import EquipmentsMonthAnalytic from './equipments_month_analytic';


const ReportEquipmentPage = (props) => {
  const {t} = useTranslation();
  
  const [currentUserState, _] = useContext(CurrentUserContext);
  const [equipment, setEquipment] = useState([]);
  const analyticTypes = [
    {label: t("no_chosen"), value: null, resource: null},
    // {label: t("equipments_month_analytic"), value: "equipments_month_analytic", resource: "equipment"},
    {label: t("equipment_heat_map"), value: "equipment_heat_map", resource: "equipment"},
    {label: t("equipment_swarm_map"), value: "equipment_swarm_map", resource: "equipment"},
    // {label: t("equipment_base_analytic"), value: "equipment_base_analytic", resource: "equipment"},
    // {label: t("person_heat_map"), value: "person_heat_map", resource: "person"}
  ]
  const [analyticType, setAnalyticType] = useState({label: t("no_chosen"), value: null, resource: null})

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [params, setParams] = useState(new URLSearchParams({'with_sensors': true}))

  const [selectedDate, setSelectedDate] = useState([DateTime.local(), DateTime.local()]);
  const [selectedMonth, setSelectedMonth] = useState(new Date(DateTime.local().toMillis()));
  const [shiftList, setShiftList] = useState([]);
  const [currentShift, setCurrentShift] = useState({label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59", duration: 86400});
  const [convertedShift, setConvertedShift] = useState({id: null, start: "00:00:00", end: "23:59:59", duration: 86400})


  useEffect(() => {
    setSelectedDate([DateTime.local().startOf('month'), DateTime.local().endOf('month')]);

    const shifts = [{label: t('full_day'), value: null, started_at: "00:00:00", ended_at: "23:59:59" }]

    if(currentUserState?.shifts.length > 0) {
      currentUserState?.shifts.map(shift => {
        shifts.push({label: shift.attributes.name, value: shift.id, started_at: DateTime.fromISO(shift.attributes.started_at).toFormat('HH:mm:ss'), ended_at: DateTime.fromISO(shift.attributes.ended_at).toFormat('HH:mm:ss')})
      })
    }
    setShiftList(shifts);
  },[])


  const onMonthChoice = (date) => {
    if(date === null) {
      return
    }
    const ts = new Date(date).getTime();

    setSelectedDate([DateTime.fromMillis(ts).startOf('month'), DateTime.fromMillis(ts).endOf('month')]);

    setSelectedMonth(date)
  }

  const handleChangeShift = (value) => {
    setCurrentShift(value);
    if (value.value === null) {
      setConvertedShift({id: null, start: value.started_at, end: value.ended_at, duration: 86400})
    } else {
      const startTime = covertTimeToSeconds(value.started_at)
      const endTime = covertTimeToSeconds(value.ended_at)

      if(endTime > startTime) {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime - startTime})
      } else {
        setConvertedShift({id: value.value, start: value.started_at, end: value.ended_at, duration: endTime + (86400 - startTime)})
      }
    }
  }

  function getItem() { 
    switch (analyticType.value) {
      case "equipments_month_analytic":
        return <EquipmentsMonthAnalytic selectedDate={selectedDate} convertedShift={convertedShift}/>
      case "equipment_heat_map":
        return <EquipmentIdleHeatMap selectedDate={selectedDate} convertedShift={convertedShift}/>
      case "equipment_swarm_map":
        return <EquipmentIdleSwarmMap selectedDate={selectedDate} convertedShift={convertedShift}/>
      case "equipment_base_analytic":
        return <EquipmentBaseAnalytic selectedDate={selectedDate} convertedShift={convertedShift}/>
      case "person_heat_map":
        return <ReportItem analyticType={analyticType} selectedDate={selectedDate} convertedShift={convertedShift}/>
      default:
        return "Выберите тип аналитического отчета"
    }
  }

  const headerData = {
    title: `${t('analytics')}`,
    isWide: true
  };

  const filtersData = {
    calendar: {
      type: 'month',
      selectedDate: selectedMonth,
      onChange: onMonthChoice
    },
    shifts: {
      options: shiftList,
      value: currentShift,
      onChange: handleChangeShift
    },
    analyticTypes: {
      options: analyticTypes,
      value: analyticType,
      onChange: setAnalyticType
    },
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }


  return (
    <Layout
      headerData={headerData}
    >
      <div className='my-6'>
        <AnalyticFilters filtersData={filtersData}/>
      </div>
      {getItem()}
    </Layout>
  );
};

export default ReportEquipmentPage;