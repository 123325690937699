import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Form, { Field } from '@atlaskit/form';
import Select, {AsyncSelect} from '@atlaskit/select';
import { DatePicker } from '@atlaskit/datetime-picker';
import { useTranslation } from "react-i18next";
import useFetch from '../../../../../../hooks/use-fetch';

import ErrorsBlock from '../../../../../../components/errors';

const ResponsibleForm = ({currentItem, onChangeItem, closeModal, open, persons}) => {
  const {t} = useTranslation();
  const [responsible, setResponsible] = useState(currentItem.attributes.responsible_id);
  const [errors, setErrors] = useState([]);

  const [{response: saveResponse, isLoading: saveIsLoading, error: saveError}, doFetchItemSave] = useFetch(`/directories/equipment_items/${currentItem.id}/changes`);

  useEffect(() => {
    if(!saveResponse) {
      return
    }
    closeModal()
  },[saveResponse])

  const handleUpdate = (data) => {
    let date = new Date()
    onChangeItem({...currentItem, attributes: {...currentItem.attributes, responsible_name: data.responsible.label, responsible_id: data.responsible.value, responsible_changed_at: date}})
    doFetchItemSave({
      method: 'POST',
      data: {
        data: {
          responsible_id: data.responsible.value ? Number(data.responsible.value) : null,
          at: date
        }
      }
    });
  };

  return (
    <Transition appear show={open} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-50 overflow-y-auto"
      onClose={closeModal}
    >
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="min-h-screen px-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="inline-block w-full max-w-md p-6 mt-32 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {t('change_responsible')}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {t('change_responsible_form_text')}
              </p>
            </div>
            {errors.length > 0 ? <ErrorsBlock errors={errors}/> : ''}
            <Form onSubmit={data => handleUpdate(data)}>
              {({ formProps }) => (
                <form {...formProps} className="text-sm">
                  <div className="text-sm">
                    <Field name="responsible" label={t('responsible')} defaultValue={persons.find(it => it.value == responsible)}>
                      {({ fieldProps, error, meta: { valid } }) => 
                        <Select {...fieldProps} options={persons}  placeholder={t('select_placeholder')} isSearchable={false} className="text-sm"/>
                      }
                    </Field>
                  </div>
                  <div className="flex justify-between mt-8">
                    <button 
                      onClick={() => closeModal()}
                      type="button" 
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 bg-gray-100 border border-transparent rounded hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      {t('cancel')}
                    </button>
                    <button 
                      // onClick={() => handleUpdate()}
                      type="submit" 
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      {t('save')}
                    </button>

                  </div>
                </form>
              )}
            </Form>
          </div>
        </Transition.Child>
      </div>
      
    </Dialog>
  </Transition>
  )
}

export default ResponsibleForm;