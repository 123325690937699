import React, { useEffect, useState } from 'react';
import Table from '../../../components/form/table';
import Spinner from "../../../components/loader";
import { useTranslation } from 'react-i18next';
import { FolderIcon, PencilAltIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';


const DivisionsTable = ({ error, tableData, isLoading, onChildAdd, onItemDelete, onItemEdit }) => {
  const {t} = useTranslation();

  const items = tableData.map(tableItem => {
    return (
      {
        id: tableItem.id,
        depth: tableItem.attributes.depth,
        name: tableItem.attributes.name,
        cipher: tableItem.attributes.cipher,
        equipment_items_count: tableItem.attributes.equipment_items_count,
        personnels_count: tableItem.attributes.personnels_count,
        accepted_personnels_count: tableItem.attributes.accepted_personnels_count,
        fired_personnels_count: tableItem.attributes.fired_personnels_count,
        vacation_personnels_count: tableItem.attributes.vacation_personnels_count,
      }
    )
  });

  const getIcon = (depth) => {
    if(depth === 0) {
      return (
        <div className="mr-2 text-gray-500">
          <FolderIcon className="w-4 h-4" />
        </div>
      )
    } else if (depth === 1) {
      return (
        <div className="mr-2 flex items-center text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}  d="M12 0V13H24" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24" stroke="none">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M24 13L14 7.2265V18.7735L24 13ZM0 14H15V12H0V14Z" />
          </svg>
        </div>
      )
    } else {
      return (
        <div className="mr-2 flex items-center text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}  d="M12 0V13H24" />
          </svg>
          <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
            <line y1="13" x2="24" y2="13" strokeWidth={2}/>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24" stroke="none">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M24 13L14 7.2265V18.7735L24 13ZM0 14H15V12H0V14Z" />
          </svg>
        </div>
      )
    }
  }
  
  const columns = [
    {
      label: t('title_name'),
      prop: "name",
      labelClassName: "w-full lg:w-4/12",
      className: "w-full lg:w-4/12 flex-shrink-0",
      render: ({name, depth, cipher}) => {
        return (
          <div className="flex items-start"> 
            {getIcon(depth)}
            <div className="truncate">
              <div className="font-medium text-gray-900 truncate">
                {name}
              </div>
              {cipher ? <div className="text-gray-500 truncate">
                {t('cipher')}: {cipher}
              </div> : ""}
            </div>
          </div>
        )
      }
    },
    {
      label: t('staff'),
      prop: "staff",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: ({personnels_count, accepted_personnels_count, fired_personnels_count, vacation_personnels_count}) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {t('staff_total')}: {personnels_count}
            </div>
            <div className="flex">
              <div className="text-gray-500 truncate">
                {t('staff_in_work')}: {accepted_personnels_count} {t('staff_in_vacation')}: {vacation_personnels_count} {t('staff_in_fired')}: {fired_personnels_count}
              </div>
            </div>
          </div>
        )
      }
    },
    {
      label: t('equipment'),
      prop: "equipment",
      labelClassName: "w-full lg:w-3/12",
      className: "w-full lg:w-3/12 flex-shrink-0",
      render: ({equipment_items_count}) => {
        return (
          <div className="truncate">
            <div className="font-medium text-gray-900 truncate">
              {t('staff_total')}: {equipment_items_count}
            </div>
          </div>
        )
      }
    },
    {
      label: t('actions'),
      prop: "actions",
      labelClassName: "w-full lg:w-2/12 invisible",
      className: "w-full lg:w-2/12 flex-shrink-0",
      render: (row) => {
        return (
          <div className="flex space-x-2 justify-end">
            <button 
              onClick={() => onChildAdd(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PlusCircleIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemEdit(row.id)} 
              className="text-indigo-600 hover:text-indigo-900 cursor-pointer w-8 h-8 bg-blue-100 hover:bg-blue-200 flex items-center justify-center rounded ">
              <PencilAltIcon className="w-4 h-4"/>
            </button>
            <button 
              onClick={() => onItemDelete(row.id)} 
              className="text-red-600 hover:text-red-900 cursor-pointer w-8 h-8 bg-pink-100 hover:bg-pink-200 flex items-center justify-center rounded-sm">
              <TrashIcon className="w-4 h-4"/>
            </button>
          </div>
        )
      }
    }
  ]

  return (
    <Table
      border={true}
      columns={columns}
      data={items}
      // onRowClick={(data) => onShow(data.id)}
    />
  );
};

export default DivisionsTable;