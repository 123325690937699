import { useTranslation } from "react-i18next";
import useFetch from '../../../../hooks/use-fetch';
import ProgressBar from '@atlaskit/progress-bar';
import {DateTime, Duration, Interval} from 'luxon';
import { useEffect, useState } from "react";
import {ArrowLeftIcon} from '@heroicons/react/outline'
import { secondsToTime } from '../../../../utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ChartDivision from './chart';

const DivisionItem = ({division, selectedDate, shift, changeDivision}) => {
  let {t} = useTranslation()
  const [dEquipmentUsage, setDEquipmentUsage] = useState();
  const [dPersonnelUsage, setDPersonnelUsage] = useState();
  const days = Interval.fromDateTimes(selectedDate[0], selectedDate[1]).count('day');

  const [{response: equipmentUsageResponse, isLoading: equipmentUsageIsLoading, error: equipmentUsageError}, doFetchEquimpentUsage] = useFetch(`/directories/divisions/${division.division.id}/daily_equipment_items_usage?&from=${selectedDate[0]}&to=${selectedDate[1]}&day_time_from=${shift.start}&day_time_to=${shift.end}`);
  const [{response: personalUsageResponse, isLoading: personalUsageIsLoading, error: personalUsageError}, doFetchPersonalUsage] = useFetch(`/directories/divisions/${division.division.id}/daily_personnel_usage?&from=${selectedDate[0]}&to=${selectedDate[1]}&day_time_from=${shift.start}&day_time_to=${shift.end}`);
  const [{response: deepEquipmentUsageResponse, isLoading: deepEquipmentUsageIsLoading, error: deepEquipmentUsageError}, doFetchDeepEquimpentUsage] = useFetch(`/directories/divisions/${division.division.id}/deep_daily_equipment_items_usage?&from=${selectedDate[0]}&to=${selectedDate[1]}&day_time_from=${shift.start}&day_time_to=${shift.end}`);
  const [{response: deepPersonalUsageResponse, isLoading: deepPersonalUsageIsLoading, error: deepPersonalUsageError}, doFetchDeepPersonalUsage] = useFetch(`/directories/divisions/${division.division.id}/deep_daily_personnel_usage?&from=${selectedDate[0]}&to=${selectedDate[1]}&day_time_from=${shift.start}&day_time_to=${shift.end}`);

  

  useEffect(() => {
    if(division.subdivisions.length > 0) {
      doFetchDeepEquimpentUsage()
      doFetchDeepPersonalUsage()
    } else {
      doFetchEquimpentUsage()
      doFetchPersonalUsage()
    }
  }, [division, selectedDate, shift])

  useEffect(() => {
    if(!equipmentUsageResponse) {
      return
    }
    setDEquipmentUsage(equipmentUsageResponse.data)
  },[equipmentUsageResponse])

  useEffect(() => {
    if(!deepEquipmentUsageResponse) {
      return
    }
    setDEquipmentUsage(deepEquipmentUsageResponse.data)
  },[deepEquipmentUsageResponse])

  useEffect(() => {
    if(!personalUsageResponse) {
      return
    }
    setDPersonnelUsage(personalUsageResponse.data)
  },[personalUsageResponse])

  useEffect(() => {
    if(!deepPersonalUsageResponse) {
      return
    }
    setDPersonnelUsage(deepPersonalUsageResponse.data)
  },[deepPersonalUsageResponse])

  function countProgress(value, total) {
    return value / total
  }

  return (
    <div className="border px-4 py-2 text-sm rounded">
      <div className="flex font-medium mb-4 items-center justify-between border-b h-12">
        
        <div className="flex flex-row items-center">
          {division.division.real_parent_id ? <div className="px-2 py-2 hover:bg-blue-100 hover:text-blue-600 rounded-sm h-full mr-2" onClick={() => changeDivision({value: division.division.real_parent_id.toString()})}><ArrowLeftIcon height={16}/></div> : <></>}
          {division.division.name || <Skeleton />}
        </div>
        {/* <button type="button" className="text-xs rounded-sm bg-blue-600 text-white px-2 py-1">{t('more_details')}</button> */}
      </div>
      <div className="mb-6">
        <div className="grid grid-cols-4 gap-4 mb-2">
          <div className="flex flex-col">
            <span className="text-gray-600">{t('total_worked_of_equipment_in_division')}</span>
            <span className="font-medium py-2">
              {
                dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? 
                <span>{secondsToTime(dEquipmentUsage.total_worked)} / {(dEquipmentUsage.total_worked / (dEquipmentUsage.total_worked + dEquipmentUsage.total_stopped + dEquipmentUsage.total_turned_off) * 100).toFixed(2)}% </span> : 
                <Skeleton width={100} height={16}/>
              }
            </span>
            <span className="flex w-3/4">
              <ProgressBar 
                ariaLabel={t('total_worked_of_equipment_in_division')} 
                value={dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? countProgress(dEquipmentUsage.total_worked, dEquipmentUsage.equipment_items_count * shift.duration * days) : 0} 
                isIndeterminate={!dEquipmentUsage || equipmentUsageIsLoading || deepEquipmentUsageIsLoading}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600">{t('total_stopped_of_equipment_in_division')}</span>
            <span className="font-medium py-2">
              {
              dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? 
              <span>{secondsToTime(dEquipmentUsage.total_stopped)} / {(dEquipmentUsage.total_stopped / (dEquipmentUsage.total_worked + dEquipmentUsage.total_stopped + dEquipmentUsage.total_turned_off) * 100).toFixed(2)}% </span> :
              <Skeleton width={100} height={16}/>}
            </span>
            <span className="flex w-3/4">
              <ProgressBar 
                ariaLabel={t('total_worked_of_equipment_in_division')} 
                value={dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? countProgress(dEquipmentUsage.total_stopped, dEquipmentUsage.equipment_items_count * shift.duration * days) : 0} 
                isIndeterminate={!dEquipmentUsage || equipmentUsageIsLoading || deepEquipmentUsageIsLoading}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600">{t('total_turned_off_of_equipment_in_division')}</span>
            <span className="font-medium py-2">{
              dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? 
              <span>{secondsToTime(dEquipmentUsage.total_turned_off)} / {(dEquipmentUsage.total_turned_off / (dEquipmentUsage.total_worked + dEquipmentUsage.total_stopped + dEquipmentUsage.total_turned_off) * 100).toFixed(2)}% </span> :
              <Skeleton width={100} height={16}/>}
            </span>
            <span className="flex w-3/4">
              <ProgressBar 
                ariaLabel={t('total_worked_of_equipment_in_division')} 
                value={dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? countProgress(dEquipmentUsage.total_turned_off, dEquipmentUsage.equipment_items_count * shift.duration * days) : 0} 
                isIndeterminate={!dEquipmentUsage || equipmentUsageIsLoading || deepEquipmentUsageIsLoading}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600">{t('count_of_equipment_in_division')}</span>
            <span className="font-medium py-2">{dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? dEquipmentUsage.equipment_items_count : <Skeleton width={100} height={16}/>}</span>
          </div>
        </div>
        <div className="flex w-full text-xs text-gray-500 italic py-1 items-center gap-4">{t('calculated_time')}: {dEquipmentUsage && !equipmentUsageIsLoading && !deepEquipmentUsageIsLoading ? secondsToTime(dEquipmentUsage.equipment_items_count * shift.duration * days) : <Skeleton width={40}/>} </div>
      </div>
      <div className="mb-6">
        <div className="grid grid-cols-4 gap-4 mb-2">
          <div className="flex flex-col">
            <span className="text-gray-600">{t('total_worked_of_personnels_in_division')}</span>
            <span className="font-medium py-2">
              {
                dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? 
                <span>{secondsToTime(dPersonnelUsage.total_worked)} / {(dPersonnelUsage.total_worked / (dPersonnelUsage.total_worked + dPersonnelUsage.total_stopped + dPersonnelUsage.total_turned_off) * 100).toFixed(2)}% </span> :
                <Skeleton width={100} height={16}/>
              }
            </span>
            <span className="flex w-3/4">
              <ProgressBar 
                ariaLabel={t('total_worked_of_equipment_in_division')} 
                value={dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? countProgress(dPersonnelUsage.total_worked, dPersonnelUsage.personnel_count * shift.duration * days) : 0} 
                isIndeterminate={!dPersonnelUsage || personalUsageIsLoading || deepPersonalUsageIsLoading}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600">{t('total_stopped_of_personnels_in_division')}</span>
            <span className="font-medium py-2">
              {
                dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? 
                <span>{secondsToTime(dPersonnelUsage.total_stopped)} / {(dPersonnelUsage.total_stopped / (dPersonnelUsage.total_worked + dPersonnelUsage.total_stopped + dPersonnelUsage.total_turned_off) * 100).toFixed(2)}% </span> :
                <Skeleton width={100} height={16}/>
              }
            </span>
            <span className="flex w-3/4">
              <ProgressBar 
                ariaLabel={t('total_worked_of_equipment_in_division')} 
                value={dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? countProgress(dPersonnelUsage.total_stopped, dPersonnelUsage.personnel_count * shift.duration * days) : 0} 
                isIndeterminate={!dPersonnelUsage || personalUsageIsLoading || deepPersonalUsageIsLoading}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600">{t('total_turned_off_of_personnels_in_division')}</span>
            <span className="font-medium py-2">
              {
                dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? 
                <span>{secondsToTime(dPersonnelUsage.total_turned_off)} / {(dPersonnelUsage.total_turned_off / (dPersonnelUsage.total_worked + dPersonnelUsage.total_stopped + dPersonnelUsage.total_turned_off) * 100).toFixed(2)}% </span> :
                <Skeleton width={100} height={16}/>
              }
            </span>
            <span className="flex w-3/4">
              <ProgressBar 
                ariaLabel={t('total_worked_of_equipment_in_division')} 
                value={dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? countProgress(dPersonnelUsage.total_turned_off, dPersonnelUsage.personnel_count * shift.duration * days) : 0} 
                isIndeterminate={!dPersonnelUsage || personalUsageIsLoading || deepPersonalUsageIsLoading}
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600">{t('count_of_personnels_in_division')}</span>
            <span className="font-medium py-2">{dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? dPersonnelUsage.personnel_count : <Skeleton width={100} height={16}/>}</span>
          </div>
        </div>
        <div className="flex w-full text-xs text-gray-500 italic py-1 items-center gap-4">{t('calculated_time')}: {dPersonnelUsage && !personalUsageIsLoading && !deepPersonalUsageIsLoading ? secondsToTime(dPersonnelUsage.personnel_count * shift.duration * days) : <Skeleton width={40}/>} </div>
      </div>
      <>
        <div className="text-sm font-medium mb-6">{t('effctivity_of_use')}</div>
        {dEquipmentUsage && dPersonnelUsage && <ChartDivision eqData={dEquipmentUsage.chart} staffData={dPersonnelUsage.chart}/>}
      </>
      <div className="flex flex-col">
        {division.subdivisions.map(item => (
          <div key={item.id} className="flex w-full h-10 border-t items-center">
            <div className="flex flex-row items-center w-full justify-between">
              <span className="flex font-medium">{item.name}</span>
              <div className="flex items-center justify-end">
                <button type="button" onClick={() => changeDivision({value: item.id})} className="text-xs rounded-sm bg-blue-100 text-blue-600 px-2 py-1 mr-4">{t('total_report')}</button>
                {/* <button type="button" className="text-xs rounded-sm bg-blue-600 text-white px-2 py-1">{t('more_details')}</button> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DivisionItem;