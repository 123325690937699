import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import { convertTime, secondsToTime } from '../../../utils';


const ElectricityDayWidget = ({at, ph1, ph2, ph3, rp}) => {
  const {t} = useTranslation(['widgets']);

  const data = [
    {id: 'ph1', label: t('ph1'), value: ph1, color: 'rgba(16, 185, 129)'},
    {id: 'ph2', label: t('ph2'), value: ph2, color: 'rgba(252, 211, 77)'},
    {id: 'ph3', label: t('ph3'), value: ph3, color: 'rgba(220, 38, 38)'}
  ]

  return (
    <>
      <div className="grid grid-cols-4 mt-4 gap-4">
        <div className="flex flex-col">
          <span className="text-gray-500">{t('rp')}</span>
          <span>{rp.toFixed(2)} {t('kVh')}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-500">{t('ph1')}</span>
          <span>{ph1.toFixed(2)} {t('kVh')}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-500">{t('ph2')}</span>
          <span>{ph2.toFixed(2)} {t('kVh')}</span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-500">{t('ph3')}</span>
          <span>{ph3.toFixed(2)} {t('kVh')}</span>
        </div>
      </div>
    </>
  )
}

export default ElectricityDayWidget;