import React, {useEffect, useRef, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../../../hooks/use-fetch';
import ChangesTable from './table';
import ChangesForm from './form';



const StaffChanges = ({ currentItem, reloadItem }) => {
  const {t} = useTranslation();
  const [changesData, setChangesData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [changeId, setChangeId] = useState('');

  const statuses = [
    { label: t('no_status'), value: ''},
    { label: t('staff_accepted'),  value: 'accepted'},
    { label: t('staff_transferred'),  value: 'transferred'},
    { label: t('staff_in_vacation'),  value: 'vacation'},
    { label: t('staff_in_fired'),  value: 'fired'},
  ];

  const [{response, isLoading, error}, doFetchChanges] = useFetch(`/directories/personnels/${currentItem.id}/changes`);
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/directories/personnels/${currentItem.id}/changes/${changeId}`);

  useEffect(() => {
    doFetchChanges()
  }, []);

  useEffect(() => {
    if(!response) {
      return;
    }
    setChangesData(response.data)
  },[response])

  function closeModal() {
    setIsOpen(false);
  };

  function openModal() {
    setIsOpen(true)
  };

  const onAdd = () => {
    openModal();
  };


  const handleDelete = (id) => {
    setChangeId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });

  };

  useEffect(() => {
    if(deleteIsLoading) {
      return;
    }
    setChangeId(null);
    reloadData();
  }, [deleteIsLoading]);

  const reloadData = () => {
    reloadItem();
    doFetchChanges();
  };


  return (
    <div className="pt-8 pb-10">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-col mb-4">
          <div className="inner-title">{t('staff_changes')}</div>
          <div className="text-xs text-gray-400 pt-1">{t('staff_changes_text')}</div>
        </div>
        <button
          className="focus:outline-none text-white text-sm font-medium py-2 px-4 rounded-sm bg-blue-700 hover:bg-blue-800"
          type="button"
          onClick={onAdd}
        >
          {t('add_change')}
        </button>
      </div>
      <ChangesTable
        tableData={changesData}
        onItemDelete={handleDelete}
      />
      <ChangesForm 
        currentItem={currentItem}
        statuses={statuses}
        closeModal={closeModal}
        isOpen={isOpen}
        reloadData = {reloadData}
      />
    </div>
  );
};

export default StaffChanges;
