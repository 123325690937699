import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';


const SearchInFilter = ({search}) => {
  const {t} = useTranslation();
  const handleSearch = (value) => {
    search.onChange(value)
  }

  const clearSearchFilter = () => {
    search.onChange('')
  };

  return (
    <div className="flex relative w-full max-w-sm">
      <input
        type="text"
        className="text-gray-800 w-full text-sm bg-gray-50 ring ring-gray-200 font-normal px-2.5 py-1.5 rounded-sm focus:outline-none focus:bg-white focus:ring-blue-300 placeholder-gray-500"
        placeholder={t(search.placeholder)}
        onChange={(evt) => handleSearch(evt.target.value)}
        value={search.value}
      />
      {search.value !== '' && (
        <div
          className="absolute right-0 py-2 px-2"
          onClick={clearSearchFilter}
        >
          <XIcon className="w-4 h-4"/>
        </div>
      )}
    </div>
  )
}

export default SearchInFilter;