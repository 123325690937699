import React, {useState, useCallback, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from "../../../hooks/use-fetch";

import Layout from '../../../components/layout';
import Spinner from '../../../components/loader';
import {getPerfectHours} from '../../../utils/index';

import UsersTable from './table';
import FormUsers from './form';


const SettingsShifts = () => {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [divisions, setDivisions] = useState([])
  const [tableData, setTableData] = useState([]);
  const [userId, setUserId] = useState("new");

  const [{response, isLoading, error, meta}, doFetch]  = useFetch('/users');
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError, meta: divisionsMeta}, doFetchDivisions]  = useFetch('/directories/divisions');
  const [{response: deleteResponse, isLoading: deleteIsLoading, error: deleteError}, doFetchItemDelete] = useFetch(`/users/${userId}`);


  useEffect(() => {
    doFetch();
    doFetchDivisions()
    setLoading(true);
  },[]);

  useEffect(() => {
    if(!divisionsResponse) {
      return
    }
  }, [divisionsResponse])



  useEffect(() => {
    if(!response || !divisionsResponse) {
      return
    }
    const list = [{label: "Все подразделения", value: "all"}]
    divisionsResponse.data.map(d => {
      list.push({label: d.attributes.name, value: d.id})
    })
    setDivisions(list);
    setTableData(response.data);
    setLoading(false);
  }, [response, divisionsResponse])

  const onAdd = () => {
    setUserId("new");
    openModal(true);
  }

  const onEdit = (id) => {
    setUserId(id);
    openModal(true);
  }
  
  const onDelete = (id) => {
    setUserId(id);
    doFetchItemDelete({
      method: 'DELETE'
    });
  }

  useEffect(() => {
    if (deleteIsLoading) {
      return;
    }
    setUserId("new");
    reloadTable();
  }, [deleteIsLoading]);


  const reloadTable = () => {
    doFetch();
  };

  function closeModal() {
    setIsOpen(false);
    setTimeout(() => {
      setUserId("new")
    }, 300);
  }

  function openModal() {
    setIsOpen(true)
  }

  const headerData = {
    title: 'participants',
    buttonsList: [
      {
        text: 'create_participant',
        action: () => onAdd()
      }
    ],
  };


  return (
    <>
      <Layout
        headerData={headerData}
        isWide={true}
      >
        <div className="content">
          {loading ? <Spinner/> : (
            <div className="flex flex-col mt-8">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded">
                    <UsersTable 
                      isLoading={isLoading}
                      tableData={tableData}
                      divisions={divisions}
                      error={error}
                      onItemEdit={onEdit}
                      onItemDelete={onDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}          
        </div>
        {isOpen && <FormUsers itemId={userId} closeModal={closeModal} isOpen={isOpen} reloadTable={reloadTable} tableData={tableData} divisions={divisions}/>}
      </Layout>
    </>

  );
};

export default SettingsShifts;
