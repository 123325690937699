import React, {useContext, useState, useRef, useEffect} from 'react';
import {CurrentUserContext} from "../../../contexts/current-user";
import { useTranslation } from 'react-i18next';
import { sortDataList } from '../../../utils';
import Layout from '../../../components/layout';
import Spinner from "../../../components/loader";
import _ from 'lodash';

import useFetch from "../../../hooks/use-fetch";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../hooks-store/store";

import Pagination from "../../../components/form/pagination";


import { equipmentStatuses, moveStatuses } from '../../../enums';
import Filters from '../../../components/filters';
import EquipmentTrackingTable from './table';
import EquipmentsOnMap from './map';
import EditEquipmentModal from '../../equipment/accounting/editModal';

const ITEMS_PER_PAGE = 2000;


const EquipmentLocation = (props) => {
  const {t} = useTranslation();
  const [currentUserState, setCurrentUserState] = useContext(CurrentUserContext);

  // DATA
  const [tableData, setTableData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);
  const [totals, setTotals] = useState();
  const [producersList, setProducersList] = useState([]);
  const [modelsList, setModelsList] = useState('');

  // FILTERS 
  const [search, setSearch] = useState('');
  const [view, setView] = useState('as_map');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterOptionsProducers, setFilterOptionsProducers] = useState([]);
  const [filterOptionsTypes, setFilterOptionsTypes] = useState([]);
  const [filterOptionsDivisions, setFilterOptionsDivisions] = useState([]);
  const filterOptionsStatuses = equipmentStatuses.map(status => ({label: t(status), value: status}))
  const filterOptionsMoveStatuses = moveStatuses.map(status => ({label: t(`move_${status}`), value: status}))
  const filterOtpionsWithSensors = [{label: t('sensors_connected'), value: true}, {label: t('sensors_disconnected'), value: false}]

  // PAGING
  const [showPaging, setShowPaging] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(2000);
  const [paginatedTableData, setPaginatedTableData] = useState([]);

  const [params, setParams] = useState(new URLSearchParams({}))

  const [pagingParams, setPagingParams] = useState(new URLSearchParams({
    'page': currentPage,
    'per': itemsPerPage,
    'sort': 'move_status_changed_at',
    'direction': 'asc'
  }))

  let history = useHistory();
  let [isOpen, setIsOpen] = useState(false);

  const [{response: producersResponse, isLoading: producersIsLoading, error: producersError}, doFetchProducers] = useFetch('/directories/producers');
  const [{response: divisionsResponse, isLoading: divisionsIsLoading, error: divisionsError}, doFetchDivisions] = useFetch('/directories/divisions');
  const [{response: equipmentTypesResponse, isLoading: equipmentTypesIsLoading, error: equipmentTypesError}, doFetchEquipmentTypes] = useFetch('/directories/equipment_types');
  
  const [{response, isLoading, error, meta}, doFetchTableData] = useFetch((`/directories/equipment_items?${params.toString()}&${pagingParams.toString()}`).replace(/,/g, ""));

  useEffect(() => {
    doFetchProducers();
    doFetchDivisions();
    doFetchEquipmentTypes();
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }

    let d = response.data.filter(i => i.attributes.latitude && i.attributes.longitude)
    let l = _.orderBy(d, ['id'], ['desc']);

    let hlist = []

    l.map(i => {
      hlist.push({id: i.id, name: `${i.attributes.equipment_type_name} ${i.attributes.producer_name} ${i.attributes.equipment_model_name}`, date: i.attributes.move_status_changed_at || i.attributes.status_changed_at, status: t(`move_${i.attributes.move_status}`) || t(`${i.attributes.status}`), inventory_number: i.attributes.inventory_number, coordinates: [Number.parseFloat(i.attributes.latitude), Number.parseFloat(i.attributes.longitude)]})
    })

    setMapData(hlist)

    let total = {ut: 0, st: 0, rt: 0, mt: 0, rest: 0}
    let tData = items(response.data, total)
    setTableData(tData);
    setCurrentItem(response.data[0])
    setTotals(total)
    setLoading(false);
  }, [response]);


  const items = (data, totals) => data.map(tableItem => {
    totals.ut = tableItem.attributes.move_status === 'usage' ? totals.ut + 1 : totals.ut
    totals.st = tableItem.attributes.move_status === 'storage' ? totals.st + 1 : totals.st
    totals.mt = tableItem.attributes.move_status === 'moving' ? totals.mt + 1 : totals.mt
    totals.rt = tableItem.attributes.move_status === 'repair' ? totals.rt + 1 : totals.rt
    totals.rest = !tableItem.attributes.move_status ? totals.rest + 1 : totals.rest

    return (
      {
        id: tableItem.id,
        inventory_number: tableItem.attributes.inventory_number,
        name: tableItem.attributes.name,
        equipment_model_name: tableItem.attributes.equipment_model_name,
        equipment_type_name: tableItem.attributes.equipment_type_name,
        producer_name: tableItem.attributes.producer_name,
        factory_number: tableItem.attributes.factory_number,
        division_name: tableItem.attributes.division_name,
        status: tableItem.attributes.status,
        move_status: tableItem.attributes.move_status,
        move_status_changed_at: tableItem.attributes.move_status_changed_at,
        responsible: tableItem.attributes.responsible_name,
        performer: tableItem.attributes.performer_name,
      }
    )
  });

  useEffect(() => {
    if (!producersResponse || !divisionsResponse || !equipmentTypesResponse) {
      return;
    }
    const newProducersList = [];    
    producersResponse.data.forEach(item => {
      newProducersList.push({label: item.attributes.name, value: item.id})
    });

    const newFilterListProducers = 
    sortDataList(producersResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    const newFilterListTypes = 
    sortDataList(equipmentTypesResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    const newFilterListDivisions = 
    sortDataList(divisionsResponse.data)
    .map(item => ({label: item.attributes.name, value: item.id}));

    setProducersList(newProducersList);

    setFilterOptionsProducers(newFilterListProducers);
    setFilterOptionsTypes(newFilterListTypes);
    setFilterOptionsDivisions(newFilterListDivisions);
  }, [producersResponse, divisionsResponse, equipmentTypesResponse]);

  const headerData = {
    title: 'equipment_tracking',
  };

  function handleChangeView() {
    switch (view) {
      case 'as_list': 
        setView('as_map') 
        break
      case 'as_map': 
        setView('as_list')
        break
      default: 
        setView('as_list')
        break
    }
  }

  const filtersData = {
    search: {
      placeholder: t('equipment_search'),
      value: search,
      onChange: setSearch
    },
    button: {
      value: t(`${view}`),
      onChange: handleChangeView
    },
    filtersList: [
      {
        name: 'producer',
        key: 'producer_ids[]',
        placeholder: 'Поиск',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsProducers
      },
      {
        name: 'equipment_type',
        key: 'equipment_type_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsTypes
      },
      {
        name: 'division',
        key: 'division_ids[]',
        type: 'multiselect',
        option_search: true,
        options: filterOptionsDivisions
      },
      {
        name: 'condition_status',
        key: 'statuses[]',
        type: 'multiselect',
        option_search: false,
        options: filterOptionsStatuses
      }
    ],
    selectedFilters: selectedFilters,
    onChangeFilters: setSelectedFilters
  }

  if(currentUserState.applications.devices) {
    filtersData.filtersList.push(
      {
        name: 'with_sensors',
        key: 'with_sensors',
        type: 'select',
        option_search: false,
        options: filterOptionsMoveStatuses
      }
    )
  }

  if(currentUserState.applications.tracking) {
    filtersData.filtersList.push(
      {
        name: 'move_status',
        key: 'move_statuses[]',
        type: 'multiselect',
        option_search: false,
        options: filterOptionsMoveStatuses
      }
    )
  }

  useEffect(() => {
    let newParams = new URLSearchParams({})

    search ? newParams.append('search', search) : newParams.delete('search')
    selectedFilters.map(filter => {
      newParams.append(filter.key, filter.value)
    })

    setParams(newParams)
    setLoading(true)
    doFetchTableData();

  }, [search, selectedFilters])
  
  // Paging
  
  useEffect(() => {
    if (!meta) {
      return;
    }
    setTotalItems(meta.total_pages * itemsPerPage);
    if (meta.total_pages > 1) {
      setShowPaging(true);
    } else {
      setShowPaging(false);
    }
  }, [meta]);

  const listPage = (page) => {
    setCurrentPage(page);
    let params = new URLSearchParams({
      'page': page,
      'per': itemsPerPage,
      'sort': 'inventory_number',
      'direction': 'asc'
    })
    setPagingParams(params);
    setLoading(true)
    doFetchTableData();
  };

  useEffect(() => {
    if (tableData.length <= ITEMS_PER_PAGE) {
      setPaginatedTableData(tableData);
      return;
    }
    const offset = currentPage === 0 ? 0 : currentPage * ITEMS_PER_PAGE;
    const fullTableData = tableData.slice();
    if (currentPage === Math.ceil(tableData.length / ITEMS_PER_PAGE) - 1) {
      setPaginatedTableData(fullTableData.slice(offset));
    } else {
      setPaginatedTableData(fullTableData.slice(offset, offset + ITEMS_PER_PAGE));
    }
  }, [tableData, currentPage]);

  function closeModal() {
    setIsOpen(false)
  }

  function openModal(item) {
    setCurrentItem(item)
    setIsOpen(true)
  }

  const reloadTable = () => {
    doFetchTableData();
  };

  return (
    loading ? <Spinner /> : 
      <Layout
        headerData={headerData}
        isWide={true}
      >
        <div className='my-6'>
          <Filters filtersData={filtersData}/>
        </div>
        <div className="content pb-10 relative h-full">

          <div className='h-full w-full'>
            <div className='w-full grid grid-cols-5 text-sm py-2 px-4 mb-2 bg-gray-50 rounded-sm'> 
              <div className='flex flex-col'>
                <div>{totals.ut}</div>
                <div>{t('move_usage')}</div>
              </div>
              <div className='flex flex-col'>
                <div>{totals.st}</div>
                <div>{t('move_storage')}</div>
              </div>
              <div className='flex flex-col'>
                <div>{totals.mt}</div>
                <div>{t('move_moving')}</div>
              </div>
              <div className='flex flex-col'>
                <div>{totals.rt}</div>
                <div>{t('move_repair')}</div>
              </div>
              <div className='flex flex-col'>
                <div>{totals.rest}</div>
                <div>{t('no_status')}</div>
              </div>
            </div>
            {view === 'as_map' ?
            <div className='flex flex-col h-full w-full'>
              <EquipmentTrackingTable data={tableData} onItemClick={setCurrentItem} onItemEditClick={openModal}/>
              {showPaging && (
                <Pagination layout="prev, pager, next" total={totalItems} pageSize={itemsPerPage} currentPage={currentPage} onCurrentChange={(currentPage) => listPage(currentPage)}/>
              )}
            </div>
            :
            <div className='flex h-full w-full gap-8'>
              {currentUserState.applications.tracking && <div className='w-full h-full'>
                <EquipmentsOnMap data={mapData} currentItem={currentItem} onItemEditClick={openModal}/>
              </div>}
            </div>
            }
          </div>
        </div>
        {isOpen && <EditEquipmentModal closeModal={closeModal} isOpen={isOpen} currentItemId={currentItem.id}/>}
      </Layout>
  );
};

export default EquipmentLocation;
